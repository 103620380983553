import Joi from "joi-browser";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { convertArrayToSelectOptions, pick } from "utils/helpers";
import APIError from "components/common/APIError";
import Loading from "components/common/Loading";
import Form from "components/common/Form";
import {
  useGetMetaQuery,
  useAddPocsMutation,
  useEditPocMutation
} from "services/api";
import useAuth from "hooks/useAuth";

const schema = {
  id: Joi.string().allow(""),
  companyName: Joi.string().required().label("Company Name"),
  pocName: Joi.string().required().label("Opportunity Name"),
  amount: Joi.number().integer().required().min(0).label("Opportunity Amount"),
  totalAcv: Joi.number()
    .integer()
    .required()
    .min(0)
    .label("Total Contract Value"),
  industryName: Joi.string().allow("").allow(null).label("Industry"),
  geographyName: Joi.string().allow("").allow(null).label("Geography"),
  segmentName: Joi.string().allow("").allow(null).label("Segment"),
  startDate: Joi.string().required().label("Start Date"),
  endDate: Joi.string().required().label("End Date"),
  phase: Joi.string().allow("").allow(null).label("Phase"),
  img: Joi.string().uri().label("Image")
};

const initialState = {
  id: "",
  companyName: "",
  pocName: "",
  amount: "",
  totalAcv: "",
  industryName: "",
  geographyName: "",
  segmentName: "",
  startDate: "",
  endDate: "",
  phase: "",
  img: "https://benti-energies.com/asset/images/clients/logo-default.svg"
};

const createOptions = data =>
  data ? convertArrayToSelectOptions(Object.values(data)) : [];

const PocForm = ({ poc }) => {
  const location = useLocation();
  const params = useParams();
  const { data, isLoading } = useGetMetaQuery();
  const [createPocs, { data: createData, isLoading: createIsLoading, error }] =
    useAddPocsMutation();
  const [editPoc, { isLoading: editIsLoading, error: editError }] =
    useEditPocMutation();
  const { currentUser } = useAuth();

  if (isLoading) return <Loading />;
  if (createData) return <Navigate to="/dashboard" />;
  const { industries, geographies, segments, pocPhases } = data;

  const handleValidSubmit = data => {
    const dataCopy = { ...data };
    const selectKeys = [
      "industryName",
      "geographyName",
      "segmentName",
      "phase"
    ];
    selectKeys.forEach(key => {
      if (!dataCopy[key]) {
        delete dataCopy[key];
      }
    });
    if (dataCopy.id) {
      editPoc(dataCopy);
      toast.success("Information saved");
    } else {
      delete dataCopy.id;
      dataCopy.createdByUserId = currentUser.id;
      dataCopy.vendorId = currentUser.vendorId;
      createPocs([dataCopy]);
    }
  };

  const fields = [
    {
      inputType: "datepicker",
      name: "startDate",
      label: "Start Date"
    },
    {
      inputType: "datepicker",
      name: "endDate",
      label: "End Date"
    },
    {
      name: "companyName",
      label: "Account Name"
    },
    {
      name: "pocName",
      label: "Opportunity Name"
    },
    {
      name: "img",
      label: "Company Logo",
      hidden: location.pathname.indexOf(`${params.pocId}/edit`) ? true : false
    },
    {
      name: "amount",
      label: "Opportunity Amount",
      addOnPrefix: "$"
    },
    {
      name: "totalAcv",
      label: "Total Contract Value",
      addOnPrefix: "$"
    },
    {
      inputType: "select",
      name: "industryName",
      label: "Industry",
      options: createOptions(industries),
      includeEmpty: !poc?.industryName
    },
    {
      inputType: "select",
      name: "geographyName",
      label: "Geography",
      options: createOptions(geographies),
      includeEmpty: !poc?.geographyName
    },
    {
      inputType: "select",
      name: "segmentName",
      label: "Segment",
      options: createOptions(segments),
      includeEmpty: !poc?.segmentName
    },
    {
      inputType: "select",
      name: "phase",
      label: "Stage",
      options: createOptions(pocPhases),
      includeEmpty: !poc?.pocPhases
    }
  ];

  return (
    <div className="card">
      <div className="card-content">
        <div className="columns">
          <div className="column is-6">
            <APIError error={editError || error} />
            <Form
              onValidSubmit={handleValidSubmit}
              schema={schema}
              initialState={
                poc ? pick(poc, Object.keys(initialState)) : initialState
              }
              fields={fields}
              loading={editIsLoading || createIsLoading}
              resetOnSubmit={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PocForm;
