import { Menu } from "@headlessui/react";

const Opportunity = ({ item = {} }) => {
  if (!Object.keys(item).length) {
    return "";
  }

  return (
    <div className="is-flex" style={{ justifyContent: "center" }}>
      <Menu
        as="div"
        className="dropdown is-flex is-align-items-center is-right is-active"
      >
        <Menu.Button className="button is-white is-compact">
          {item?.pocUsecases?.length}
        </Menu.Button>

        {item?.pocUsecases?.length > 0 && (
          <Menu.Items
            as="div"
            className="dropdown-menu card"
            style={{ outlineStyle: "none", minWidth: "fit-content" }}
          >
            {item &&
              item.pocUsecases &&
              item.pocUsecases.map((val, i) => (
                <>
                  <Menu.Item as="div" key={i}>
                    {({ active }) => (
                      <button
                        className={`button is-white is-fullwidth`}
                        onClick={val.onClick}
                      >
                        {val?.poc?.pocName}
                      </button>
                    )}
                  </Menu.Item>
                </>
              ))}
          </Menu.Items>
        )}
      </Menu>
    </div>
  );
};

export default Opportunity;
