import api from "./_createApi";
import { sortBy } from "utils/helpers";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["PocQuestions"] }).injectEndpoints({
    endpoints: build => ({
      getPocQuestions: build.query({
        query: pocId => `/pocs/${pocId}/pocQuestions`,
        transformResponse: res => {
          // Since the backend cannot sort the eagerly loaded sub-entities, we do it here
          res = res.map(item => {
            item.question.answers = sortBy(
              item.question.answers,
              x => x.weight
            );
            return item;
          });

          return res;
        },
        providesTags: (result, _, pocId) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "PocQuestions", id })),
                { type: "PocQuestions", id: `${pocId}-LIST` }
              ]
            : [{ type: "PocQuestions", id: `${pocId}-LIST` }]
      }),
      addPocQuestions: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const pocQuestionResponse = await Promise.all(
            body.map(({ pocId, questionId }) =>
              fetchWithBaseQuery({
                url: "/pocQuestions",
                method: "POST",
                body: { pocId, questionId }
              })
            )
          );
          const successes = pocQuestionResponse
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = pocQuestionResponse
            .filter(res => !res.data)
            .map(res => res.error);
          return {
            data: successes.length > 0 ? successes : null,
            error: failures.length > 0 ? failures : null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const addResult = dispatch(
            api.util.updateQueryData(
              "getPocQuestions",
              body[0].pocId,
              draft => {
                return draft.concat(body);
                // .sort((q1, q2) => {
                //   if (q1.question.body < q2.question.body) return -1;
                //   if (q1.question.body > q2.question.body) return 1;
                //   return 0;
                // });
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            addResult.undo();
          }
        },
        invalidatesTags: (_result, _error, arg) => [
          "PocScore",
          { type: "PocQuestions", id: `${arg[0].pocId}-LIST` }
        ]
      }),
      importPocQuestions: build.mutation({
        query: ({ pocId, template }) => ({
          url: `/pocs/${pocId}/pocQuestions/templates`,
          method: "POST",
          body: {
            template,
            isPreviewMode: false
          }
        }),
        invalidatesTags: (_result, _error, { pocId }) => {
          return ["PocScore", { type: "PocQuestions", id: `${pocId}-LIST` }];
        }
      }),
      editPocQuestions: build.mutation({
        query: ({ pocId, id, notes }) => ({
          url: `/pocQuestions/${id}`,
          method: "PATCH",
          body: {
            pocId,
            notes
          }
        }),
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData("getPocQuestions", body.pocId, draft => {
              const data = draft.find(x => x.id === body.id);
              if (data) {
                for (let key in body) {
                  data[key] = body[key];
                }
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: (_result, _error, arg) => [
          "PocScore",
          { type: "PocQuestions", id: `${arg[0].pocId}-LIST` }
        ]
      }),
      deletePocQuestions: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const pocResponse = await Promise.all(
            body.map(({ id }) =>
              fetchWithBaseQuery({
                url: `/pocQuestions/${id}`,
                method: "DELETE"
              })
            )
          );
          const successes = pocResponse
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = pocResponse
            .filter(res => !res.data)
            .map(res => res.error);
          return {
            data: successes.length > 0 ? successes : null,
            error: failures.length > 0 ? failures : null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            api.util.updateQueryData(
              "getPocQuestions",
              body[0].pocId,
              draft => {
                const idsToRemove = new Set(body.map(({ id }) => id));
                return draft.filter(pocQ => !idsToRemove.has(pocQ.id));
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            deleteResult.undo();
          }
        },
        invalidatesTags: (_result, _error, arg) => [
          "PocScore",
          { type: "PocQuestions", id: `${arg[0].pocId}-LIST` }
        ]
      }),
      reorderPocQuestions: build.mutation({
        query: ({ pocId, ids }) => ({
          url: `pocs/${pocId}/pocQuestions/reorder`,
          method: "POST",
          body: { ids }
        }),
        invalidatesTags: (_result, _error, arg) => [
          "PocScore",
          { type: "PocQuestions", id: `${arg[0].pocId}-LIST` }
        ]
      })
    })
  });
