import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ChatLeft,
  GripVertical,
  ThreeDotsVertical
} from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import { Menu, Popover } from "@headlessui/react";
import { parseISO } from "date-fns";

import usePocs from "hooks/usePocs";
import { SelectableStakeholder } from "components/common/TableCells/SelectableStakeholder";
import ChecklistDueDate from "./ChecklistDueDate";
import ChecklistTemplate from "./ChecklistTemplate";
import { Draggable } from "react-beautiful-dnd";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";

export default function ChecklistItem({
  gid = null,
  items,
  edit = () => {},
  remove = () => {},
  provided
}) {
  const { pocId } = useParams();
  const { currentPoc: poc } = usePocs();
  const [editable, setEditable] = useState(false);

  const { userHasPermission } = useRbac();

  const assignPerson = (item, assigneeId) => {
    edit({
      id: item.id,
      gid,
      assignedToStakeholderId: assigneeId
    });
  };
  const check = item => {
    edit({ id: item.id, gid, addressed: !item.addressed });
  };
  const changeDue = (item, date) => {
    edit({ id: item.id, gid, dueDate: date.toISOString() });
  };
  const addNote = (e, item, close) => {
    e.preventDefault();
    edit({ id: item.id, gid, notes: e.target.note.value });
    close();
  };
  const removeNote = item => {
    edit({ id: item.id, gid, notes: "" });
  };
  const removeTask = item => {
    remove({ id: item.id, gid });
  };
  const changeBody = (e, item) => {
    if (e.keyCode === 13) {
      edit({ id: item.id, gid, body: e.target.value });
      setEditable(null);
    }
  };

  const useOutsideAlerter = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setEditable(null);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [gribShow, setGribShow] = useState(null);

  return items.map(
    (item, iIndex) =>
      item && (
        <Draggable
          key={item.id}
          draggableId={"item-" + item.id}
          index={iIndex}
          isDragDisabled={
            !userHasPermission(UserPermissions.EVALACTIONPLAN_REORDER)
          }
        >
          {(provided4, snapshot4) => (
            <div
              ref={provided4.innerRef}
              {...provided4.draggableProps}
              {...provided4.dragHandleProps}
            >
              <div
                className="columns has-background-white"
                onMouseEnter={() => setGribShow(item.id)}
                onMouseLeave={() => setGribShow(null)}
              >
                <div className="column is-7 is-flex is-align-items-center">
                  <div
                    style={{
                      visibility: gribShow === item.id ? "visible" : "hidden"
                    }}
                    className="is-flex is-align-items-center"
                  >
                    <WithRbac
                      permissionOneOf={UserPermissions.EVALACTIONPLAN_REORDER}
                    >
                      <GripVertical color="gray" size={20} />
                    </WithRbac>
                  </div>
                  <div className="is-flex is-justify-content-center ">
                    <input
                      type="checkbox"
                      readOnly
                      checked={item?.addressed || ""}
                      disabled={
                        !userHasPermission(
                          UserPermissions.EVALACTIONPLAN_MARKCOMPLETE
                        )
                      }
                      onClick={() => check(item)}
                      className="ChecklistItem_checkbox larger mr-3"
                    />
                  </div>

                  {userHasPermission(UserPermissions.EVALACTIONPLAN_UPDATE) &&
                  editable === item.id &&
                  !item.addressed ? (
                    <input
                      autoFocus
                      className="w-100 input"
                      defaultValue={item.body}
                      disabled={item.addressed}
                      onKeyUp={e => changeBody(e, item)}
                      ref={wrapperRef}
                    />
                  ) : (
                    <label
                      className={item.addressed ? "item-completed" : ""}
                      onClick={() => {
                        setEditable(item.id);
                      }}
                      data-weight={item.weight}
                    >
                      {item.body}
                    </label>
                  )}
                </div>

                <Popover
                  as="div"
                  className="column is-5 columns is-flex is-align-items-center"
                >
                  {pocId ? (
                    <>
                      <SelectableStakeholder
                        className="column is-5"
                        stakeholders={poc?.stakeholders || []}
                        selectedStakeholderId={item.assignedToStakeholderId}
                        onChange={val => assignPerson(item, val)}
                        addressed={item.addressed}
                        editable={
                          userHasPermission(
                            UserPermissions.EVALACTIONPLAN_UPDATE
                          ) && !item.addressed
                        }
                      />
                      <div className="column is-4">
                        <DatePicker
                          selected={
                            item.dueDate ? parseISO(item.dueDate) : null
                          }
                          onChange={val => changeDue(item, val)}
                          customInput={
                            <ChecklistDueDate
                              checkPast={true}
                              addressed={item.addressed}
                            />
                          }
                          disabled={
                            !userHasPermission(
                              UserPermissions.EVALACTIONPLAN_UPDATE
                            ) || item.addressed
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <ChecklistTemplate
                      className="column is-9"
                      item={item}
                      gid={gid}
                      edit={edit}
                    />
                  )}
                  <div className="column is-3 is-flex is-justify-content-flex-end">
                    {item.notes ? (
                      <Popover.Button className="button is-ghost">
                        <ChatLeft className="bi bi-chat-left " />
                      </Popover.Button>
                    ) : null}
                    <Popover.Panel
                      as="div"
                      className="box top-0 right-4 is-absolute z-30 w-20r"
                    >
                      {({ open, close }) => (
                        <form
                          onSubmit={e => addNote(e, item, close)}
                          className="field"
                        >
                          <label className="label">Add Note</label>
                          <div className="control">
                            <textarea
                              autoFocus
                              className="textarea"
                              name="note"
                              cols="30"
                              rows="4"
                              defaultValue={item.notes || ""}
                            />
                          </div>
                          <div className="control mt-3">
                            <button type="submit" className="button is-info">
                              Save
                            </button>
                          </div>
                        </form>
                      )}
                    </Popover.Panel>
                    {!item.addressed ? (
                      <Menu
                        as="div"
                        className="w-1r dropdown is-flex is-align-items-center is-right is-active"
                      >
                        <Menu.Button
                          as="div"
                          className="dropdown-trigger is-align-content-end"
                          disabled={item.addressed}
                        >
                          <ThreeDotsVertical />
                        </Menu.Button>
                        <Menu.Items
                          as="div"
                          className="dropdown-menu card"
                          style={{ outlineStyle: "none" }}
                        >
                          <WithRbac
                            permissionOneOf={
                              UserPermissions.EVALACTIONPLAN_DELETE
                            }
                          >
                            <Menu.Item
                              as="div"
                              className="p-2 pl-4 is-clickable"
                              onClick={() => removeTask(item)}
                            >
                              Delete
                            </Menu.Item>
                          </WithRbac>
                          {item.notes ? (
                            <WithRbac
                              permissionOneOf={
                                UserPermissions.EVALACTIONPLAN_UPDATE
                              }
                            >
                              <Menu.Item
                                as="div"
                                className="p-2 pl-4 is-clickable"
                                onClick={() => removeNote(item)}
                              >
                                Delete Note
                              </Menu.Item>
                            </WithRbac>
                          ) : (
                            <WithRbac
                              permissionOneOf={
                                UserPermissions.EVALACTIONPLAN_UPDATE
                              }
                            >
                              <Popover.Button
                                as="div"
                                className="py-3 is-flex is-align-items-center p-2 pl-4 is-clickable"
                              >
                                <Menu.Item as="span">Add Note</Menu.Item>
                              </Popover.Button>
                            </WithRbac>
                          )}
                        </Menu.Items>
                      </Menu>
                    ) : (
                      <div className="w-1r" />
                    )}
                  </div>
                </Popover>
              </div>
            </div>
          )}
        </Draggable>
      )
  );
}
