import { CurrencyDollar } from "react-bootstrap-icons";

const PocPaidIcon = ({ poc }) => {
  if (!poc.isPaid) {
    return null;
  }

  return (
    <span
      className="m-1 is-flex is-align-items-center"
      title="This Opportunity is a Paid PoV"
    >
      <CurrencyDollar />
    </span>
  );
};

export default PocPaidIcon;
