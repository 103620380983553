import React from "react";

export default function SettingsIntegrationCallback() {
  return (
    <div>
      <div className="is-relative box mt-5">
        <div className="is-size-4 is-flex is-justify-content-space-between">
          <div className="is-size-4">Integration Callback!</div>
        </div>
        <section className="is-relative my-5">
          <ul>
            <li>
              <button className="button">Salesforce</button>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
