import { useRef, useEffect } from "react";

const useModalClose = closeFn => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClick = evt => {
      if (
        modalRef.current &&
        document.contains(evt.target) &&
        !modalRef.current.contains(evt.target)
      ) {
        closeFn();
      }
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [closeFn]);

  return modalRef;
};

export default useModalClose;
