import { UserPermissions } from "config/userPermissions";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  useAddUseCasesMutation,
  useEditUseCaseMutation,
  useGetMetaQuery
} from "services/api";
import WithRbac from "../Rbac/withRbac";
import TextEditor from "components/common/TextEditor";
import { debounce } from "debounce";
import usePocs from "hooks/usePocs";
// import { useEffect } from "react";

const EditUsecaseForm = ({
  usecase = {},
  onSuccess = () => {},
  onCancel = () => {}
}) => {
  const [addItem, { isLoading: isAddItemLoading }] = useAddUseCasesMutation();
  const [editItem, { isLoading: isEditItemLoading }] = useEditUseCaseMutation();
  const { data: metadata } = useGetMetaQuery();
  const { currentPoc: poc } = usePocs();

  // const [visibilityValue, setVisibilityValue] = useState(usecase?.visibility);
  const [notesValue, setNotesValue] = useState(usecase?.notes);

  const onSubmit = e => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    const payload = {};

    formdata.forEach((value, key) => {
      payload[key] = value;
    });

    // Special for TextEditor
    payload["notes"] = notesValue || "";
    payload["createdViaPocId"] = poc?.id || null;

    if (usecase?.id) {
      payload.id = usecase.id;
      editItem(payload)
        .unwrap()
        .catch(error => {
          toast.error(`Error: ${error?.data?.message}`);
          throw error?.data?.message;
        })
        .then(() => {
          onSuccess();
          toast.success("Updated!");
        });
    } else {
      addItem(payload)
        .unwrap()
        .then(res => {
          toast.success("Added!");
          onSuccess(res);
        })
        .catch(error => {
          toast.error(`Error: ${error?.data?.message}`);
          throw error?.data?.message;
        });
    }
  };

  // useEffect(() => {
  //   if (!!metadata && metadata?.entityVisibility)
  //     setVisibilityValue(metadata.entityVisibility["ONE_OFF"]);
  // }, metadata);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Title</label>
              <div className="control">
                <input
                  name="body"
                  defaultValue={usecase?.body}
                  className="input"
                  type="text"
                  placeholder="Title"
                />
              </div>
            </div>
          </div>
          <div className="column">
            <WithRbac permissionOneOf={UserPermissions.SCOPING_MANAGE}>
              <div className="field">
                <label className="label">Scope</label>
                <div className="control">
                  <div className="select">
                    <select
                      defaultValue={
                        usecase?.visibility ||
                        metadata?.entityVisibility?.ONE_OFF
                      }
                      name="visibility"
                    >
                      {Object.keys(metadata?.entityVisibility)?.map(
                        (key, i) => {
                          const value = metadata?.entityVisibility[key];
                          return (
                            <option value={value} key={i}>
                              {value}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </WithRbac>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Description</label>
              <div className="control">
                <TextEditor
                  name={"notes"}
                  defaultValue={usecase?.notes}
                  onChange={debounce(body => setNotesValue(body), 100)}
                />
              </div>
            </div>
          </div>
        </div>

        <nav className="level">
          <div className="level-left"></div>
          <div className="level-right">
            <p className="level-item">
              <button
                type="submit"
                className={`button is-info ${
                  isAddItemLoading || isEditItemLoading ? "is-loading" : ""
                }`}
              >
                Save
              </button>
            </p>
            <p className="level-item">
              <button
                className="button is-light"
                onClick={e => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                Cancel
              </button>
            </p>
          </div>
        </nav>
      </form>
    </>
  );
};

export default EditUsecaseForm;
