import React, { useState, useEffect } from "react";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import "./AddQuestion.css";
import { toast } from "react-toastify";
import {
  useAddPocQuestionsMutation,
  useAddQuestionsAndAnswersMutation,
  useGetMetaQuery
} from "../../../services/api";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import useFeatureFilter from "hooks/useFeatureFilter";

function AddNewQuestion(props) {
  const { pocId } = useParams();
  const { currentUser } = useAuth();
  const { data: metadata } = useGetMetaQuery();
  const [questionInput, setQuestionInput] = useState("");
  const [wizardOpen, setWizardOpen] = useState(false);
  const [answers, setAnswers] = useState([
    {
      id: 0,
      answer: "",
      isFreeText: false,
      isActive: true,
      usage: "0",
      winRate: "0"
    }
  ]);
  const [oneOff, setOneOff] = useState(null);
  const [add] = useAddQuestionsAndAnswersMutation({
    fixedCacheKey: "batch-add-questions"
  });
  const [addQs, { isLoading: batchAddIsLoading }] = useAddPocQuestionsMutation({
    fixedCacheKey: `batch-add-pocQuestions-${pocId}`
  });

  const { showExtraFeatures } = useFeatureFilter();

  useEffect(() => {
    // Getting one off data from metadata.
    async function func() {
      const oneOffRes = await metadata?.entityVisibility?.ONE_OFF;
      setOneOff(oneOffRes);
    }
    func();
  }, []);

  const handleAddAnswer = () => {
    let findLastId = answers.sort((a, b) => b.id - a.id)[0].id;
    let newAnswers = [
      ...answers,
      {
        id: findLastId + 1,
        answer: "",
        isFreeText: false,
        isActive: true,
        usage: "0",
        winRate: "0"
      }
    ];
    setAnswers(newAnswers.sort((a, b) => a.id - b.id));
  };

  const handleDeleteAnswer = id => {
    setAnswers(answers.filter(answer => answer.id !== id));
  };

  const handleAnswerChanges = (id, type, value) => {
    let tempAnswers = [...answers];
    let findAnswer = tempAnswers.find(answer => answer.id === id);
    findAnswer[type] = value;
    setAnswers(tempAnswers);
  };

  const handleCancel = () => {
    setQuestionInput("");
    setAnswers([
      {
        id: 0,
        answer: "",
        isFreeText: false,
        isActive: true,
        usage: "0",
        winRate: "0"
      }
    ]);
    setWizardOpen(false);
  };

  const handleSave = async () => {
    if (!questionInput) {
      toast.error("Question must not be empty!");
      return;
    }
    if (answers.filter(item => !item.answer && !item.isFreeText)?.length) {
      toast.error("Answer must not be empty!");
      return;
    }
    let payload = {
      question: {
        body: questionInput,
        notes: "",
        vendorId: currentUser.vendorId,
        archived: false,
        visibility: oneOff
      },
      answerList: answers
        .filter(item => item.answer || item.isFreeText)
        .map(item => ({
          body: item.answer,
          isFreeText: item.isFreeText,
          archived: !item.isActive,
          notes: ""
        }))
    };
    const { question, answerList } = payload;
    const { data } = await add({
      question,
      answers: answerList
    });
    if (data?.question) {
      addQs([{ questionId: data.question.id, pocId, question: data.question }]);
    }

    // Resetting state
    handleCancel();
  };

  return (
    <div>
      {showExtraFeatures && (
        <div
          onClick={() => setWizardOpen(!wizardOpen)}
          className="p-2 has-text-grey-light is-flex is-align-items-center pointer"
        >
          {!wizardOpen ? (
            <PlusCircle className="mr-1" />
          ) : (
            <DashCircle className="mr-1" />
          )}
          <span>Create new custom question</span>
        </div>
      )}
      {wizardOpen && (
        <div className="wizard-menu">
          <input
            type="text"
            placeholder="Please type your question"
            value={questionInput}
            onChange={e => setQuestionInput(e.target.value)}
            autoFocus={true}
          />
          {answers.map((answer, index) => (
            <div className="answer-options" key={answer.id}>
              <div className="is-flex is-flex-direction-column">
                <PlusCircle onClick={() => handleAddAnswer()} />
                {answers.length > 1 && (
                  <DashCircle
                    className="mt-1"
                    onClick={() => handleDeleteAnswer(answer.id)}
                  />
                )}
              </div>
              <input
                type="text"
                placeholder={
                  answer.isFreeText ? "Free text" : "Please type your answer"
                }
                autoFocus={answers?.length > 1}
                className="mr-5"
                value={!answer?.isFreeText ? answer.answer : ""}
                disabled={answer.isFreeText}
                onChange={e => {
                  if (!answer.isFreeText) {
                    handleAnswerChanges(answer.id, "answer", e.target.value);
                  }
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleAddAnswer();
                  }
                }}
              />
              <div className="is-flex is-flex-direction-column is-align-items-center has-text-grey-light mr-5">
                Free text ?
                <input
                  type="checkbox"
                  placeholder={"Your answer"}
                  className="form-check-input larger"
                  checked={answer.isFreeText}
                  onChange={e =>
                    handleAnswerChanges(
                      answer.id,
                      "isFreeText",
                      e.target.checked
                    )
                  }
                />
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-center has-text-grey-light mr-5">
                Active
                <input
                  type="checkbox"
                  placeholder={"Your answer"}
                  className="form-check-input larger"
                  checked={answer.isActive}
                  onChange={e =>
                    handleAnswerChanges(answer.id, "isActive", e.target.checked)
                  }
                />
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-center has-text-grey-light mr-5">
                Usage
                <div>{`${answer.usage}%`}</div>
              </div>
              <div className="is-flex is-flex-direction-column is-align-items-center has-text-grey-light">
                Win Rate
                <div>{`${answer.winRate}%`}</div>
              </div>
            </div>
          ))}
          <div className="mt-3">
            <button className="mr-2 button is-info" onClick={handleSave}>
              Save
            </button>
            <button onClick={handleCancel} className="button is-light">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddNewQuestion;
