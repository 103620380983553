import api from "./_createApi";

export default !!api &&
  api.injectEndpoints({
    endpoints: build => ({
      authenticate: build.mutation({
        query: ({ mode, ...body }) => ({
          url: mode === "manual" ? `/auth/login` : "/auth/autologin",
          method: "POST",
          body
        }),
        invalidatesTags: result => [{ type: "Vendors", id: result.vendorId }]
      }),
      getAnalytics: build.query({
        query: () => `/analytics/pocs`
      }),
      getMeta: build.query({
        query: () => `/metadata`
      })
    })
  });
