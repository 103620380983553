import { useMemo, useEffect, useCallback } from "react";
import { useGetPocUseCaseSuccessCriteriaQuery } from "services/api";
import Table from "components/common/Table";
import Loading from "components/common/Loading";
import useAuth from "hooks/useAuth";
import useBatch from "hooks/useBatch";
import {
  successCriteriaColumns as allColumns,
  prospectHeaders
} from "./constants";
import { createMap } from "utils/helpers";

const NestedSuccessCriteriaTable = ({
  data,
  pocUsecaseId,
  usecaseId,
  onAdd,
  onRemove,
  parentChecked
}) => {
  const { isProspect } = useAuth();
  const { data: useCaseSuccessCriteria, isLoading } =
    useGetPocUseCaseSuccessCriteriaQuery();
  const mapBySuccessCriteriaId = useMemo(
    () =>
      createMap(
        useCaseSuccessCriteria?.filter(
          pocUCSC => pocUCSC.pocUsecaseId === pocUsecaseId
        ) || [],
        "successcriteriaId"
      ),
    [useCaseSuccessCriteria, pocUsecaseId]
  );

  const add = useCallback(ids => onAdd(usecaseId, ids), [usecaseId, onAdd]);
  const remove = useCallback(
    ids => onRemove(usecaseId, ids),
    [usecaseId, onRemove]
  );

  const columns = useMemo(
    () =>
      isProspect
        ? allColumns.filter(
            ({ Header }) =>
              prospectHeaders.includes(Header) || typeof Header === "function"
          )
        : allColumns,
    [isProspect]
  );
  const { ids, toggleId, setIds } = useBatch({
    onToggleOff: remove,
    onToggleOn: add
  });

  const getRowProps = useCallback(
    row => {
      if (row.original.archived) return { className: "archived" };
      const newSelection = ids.has(row.original.id);
      const oldSelection = mapBySuccessCriteriaId[row.original.id];
      const highlight =
        (oldSelection && !newSelection) || (!oldSelection && newSelection);
      return { className: highlight ? "active" : "" };
    },
    [mapBySuccessCriteriaId, ids]
  );

  useEffect(() => {
    if (useCaseSuccessCriteria) {
      const successcriteriaIds = new Set(
        useCaseSuccessCriteria
          .filter(pocUCSC => pocUCSC.pocUsecaseId === pocUsecaseId)
          .map(({ successcriteriaId }) => successcriteriaId)
      );
      setIds(successcriteriaIds);
    }
  }, [setIds, useCaseSuccessCriteria, pocUsecaseId]);

  useEffect(() => {
    if (parentChecked === false && ids.size > 0) {
      remove(ids);
      setIds(new Set());
    }
  }, [parentChecked, remove, ids, setIds]);

  if (isLoading) return <Loading />;
  return (
    <Table
      className="NestedSuccessCriteriaTable mb-0"
      columns={columns}
      data={data}
      getRowProps={getRowProps}
      extraCellInfo={{
        toggleId,
        ids,
        existingIds: new Set(Object.keys(mapBySuccessCriteriaId))
      }}
    />
  );
};

export default NestedSuccessCriteriaTable;
