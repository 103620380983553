import { Popover } from "@headlessui/react";
import React, { useRef } from "react";
import { X } from "react-bootstrap-icons";

const TemplatePicker = ({ row, editTemplate, value }) => {
  const buttonRef = useRef(null);
  const onEnter = e => {
    if (e.key === "Enter") {
      const payload = value ? [...value, e.target.value] : [e.target.value];
      editTemplate(row.original.id, payload);
      buttonRef.current.click();
    }
  };
  const onRemove = v => {
    const payload = value.filter(x => x !== v);
    editTemplate(row.original.id, payload);
  };
  return (
    <Popover className="is-relative">
      {value?.length
        ? value.map((v, i) => {
            return (
              <span key={i} className="tag is-info is-light m-1">
                {v}
                <X onClick={() => onRemove(v)} className="is-clickable ml-1" />
              </span>
            );
          })
        : null}
      <Popover.Button
        ref={buttonRef}
        as="span"
        className="is-clickable tag is-info is-light m-1"
      >
        +
      </Popover.Button>

      <Popover.Panel
        className={`is-absolute z-10 ${value?.length ? "right-0" : "left-0"}`}
      >
        <div className="box p-2 w-15r">
          <input
            type="text"
            autoFocus
            placeholder="Press enter to add new tag"
            className="input is-small"
            onKeyDown={onEnter}
          />
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default TemplatePicker;
