import { CheckCircle, XCircle } from "react-bootstrap-icons";

const PocOutcomeIcon = ({ poc }) => {
  if (!poc.status || poc.status === "Pending") {
    return null;
  }

  let icon = "";
  switch (poc.status) {
    case "Pass":
      icon = <CheckCircle className="has-text-success" />;
      break;
    case "Fail":
      icon = <XCircle className="has-text-danger" />;
      break;
  }

  const hoverMsg = "Opp Outcome: " + poc.status;

  return (
    <span className="m-1 is-flex is-align-items-center" title={hoverMsg}>
      {icon}
    </span>
  );
};

export default PocOutcomeIcon;
