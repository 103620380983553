import api from "./_createApi";
import { current } from "@reduxjs/toolkit";

import { sortBy } from "utils/helpers";

export default !!api &&
  api
    .enhanceEndpoints({
      addTagTypes: [
        "Checklistgroups",
        "Checklistitems",
        "PocChecklistgroups",
        "PocChecklistitems"
      ]
    })
    .injectEndpoints({
      endpoints: build => ({
        importPocChecklist: build.mutation({
          query: ({ pocId, template }) => ({
            url: `/pocs/${pocId}/checklist/templates`,
            method: "POST",
            body: {
              template,
              isPreviewMode: false
            }
          }),
          invalidatesTags: ({ pocId }) => [
            "PocChecklistgroups",
            "PocChecklistitems",
            { type: "PocProgress", id: pocId }
          ]
        }),

        // PocChecklistgroups
        getPocChecklistgroups: build.query({
          query: pocId => `/pocs/${pocId}/pocChecklistgroups`,
          providesTags: result =>
            result
              ? [
                  ...result.map(({ id }) => ({
                    type: "PocChecklistgroups",
                    id
                  })),
                  { type: "PocChecklistgroups", id: "LIST" }
                ]
              : [{ type: "PocChecklistgroups", id: "LIST" }],

          transformResponse: res => sortBy(res, "weight")
        }),
        detailPocChecklistgroups: build.query({
          query: id => `/PocChecklistgroups/${id}`
        }),
        addPocChecklistgroups: build.mutation({
          query: body => ({
            url: `/PocChecklistgroups`,
            method: "POST",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getPocChecklistgroups",
                body.pocId,
                draft => {
                  let newDraft = [...draft];
                  newDraft.unshift({
                    ...body
                  });
                  return sortBy(newDraft, "weight");
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: [{ type: "PocChecklistgroups", id: "LIST" }]
        }),
        editPocChecklistgroups: build.mutation({
          query: ({ id, ...body }) => ({
            url: `/PocChecklistgroups/${id}`,
            method: "PATCH",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getPocChecklistgroups",
                body.pocId,
                draft => {
                  const data = draft?.find(x => x.id === body.id);
                  if (data) {
                    for (let key in body) {
                      data[key] = body[key];
                    }
                  }
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          }
          // invalidatesTags: (r, e, { id }) => [{ type: "PocChecklistgroups", id }]
        }),
        deletePocChecklistgroups: build.mutation({
          query: ({ id }) => ({
            url: `/PocChecklistgroups/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getPocChecklistgroups",
                body.pocId,
                draft => {
                  const index = draft?.findIndex(x => x.id === body.id);
                  draft.splice(index, 1);
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          }
          // invalidatesTags: (r, e, { id }) => [{ type: "PocChecklistgroups", id }]
        }),
        reorderPocChecklistgroups: build.mutation({
          query: ({ pocId, body }) => ({
            url: `/pocs/${pocId}/pocChecklistgroups/reorder`,
            method: "POST",
            body: { ids: body.map(x => x.id) }
          }),
          async onQueryStarted({ body, pocId }, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getPocChecklistgroups",
                pocId,
                draft => {
                  return body;
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: [{ type: "PocChecklistgroups", id: "LIST" }]
        }),

        // PocChecklistitems
        getPocChecklistitems: build.query({
          query: pocId => `/pocs/${pocId}/pocChecklistItems`,
          providesTags: result =>
            result
              ? [
                  ...result.map(({ id }) => ({
                    type: "PocChecklistitems",
                    id
                  })),
                  { type: "PocChecklistitems", id: "LIST" }
                ]
              : [{ type: "PocChecklistitems", id: "LIST" }],
          transformResponse: res => sortBy(res, "weight")
        }),
        detailPocChecklistitems: build.query({
          query: id => `/PocChecklistitems/${id}`
        }),
        addPocChecklistitems: build.mutation({
          query: body => ({
            url: `/PocChecklistitems`,
            method: "POST",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getPocChecklistitems",
                body.pocId,
                draft => {
                  draft.push({ ...body });
                  // sortBy(draft, "weight");
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: [{ type: "PocChecklistitems", id: "LIST" }]
        }),
        editPocChecklistitems: build.mutation({
          query: ({ id, ...body }) => ({
            url: `/PocChecklistitems/${id}`,
            method: "PATCH",
            body
          }),
          async onQueryStarted(
            { id, pocId, ...body },
            { dispatch, queryFulfilled }
          ) {
            const result = dispatch(
              api.util.updateQueryData("getPocChecklistitems", pocId, draft => {
                const data = draft?.find(x => x.id === id);
                if (data) {
                  for (let key in body) {
                    data[key] = body[key];
                  }
                }
                sortBy(draft, "weight");
              })
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          }
          // invalidatesTags: (r, e, { id }) => [{ type: "PocChecklistitems", id }]
        }),
        deletePocChecklistitems: build.mutation({
          query: ({ id }) => ({
            url: `/PocChecklistitems/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted({ id, pocId }, { dispatch, queryFulfilled }) {
            const result = dispatch(
              api.util.updateQueryData("getPocChecklistitems", pocId, draft => {
                const index = draft.findIndex(x => x.id === id);
                draft.splice(index, 1);
              })
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          }
          // invalidatesTags: (r, e, { id }) => [{ type: "PocChecklistitems", id }]
        }),
        reorderPocChecklistitems: build.mutation({
          query: ({ pocId, body }) => ({
            url: `/pocs/${pocId}/pocChecklistitems/reorder`,
            method: "POST",
            body: { ids: body.map(x => x.id) }
          }),
          async onQueryStarted({ body, pocId }, { dispatch, queryFulfilled }) {
            const result = dispatch(
              api.util.updateQueryData("getPocChecklistitems", pocId, draft => {
                return body;
                // const reordered = body.map((x, i) => ({
                //   ...x,
                //   weight: i * 100
                // }));
                // return reordered;
              })
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          }
          // invalidatesTags: [{ type: "PocChecklistitems", id: "LIST" }]
        }),

        // Checklistgroups
        getChecklistgroups: build.query({
          query: vendorId => `/vendors/${vendorId}/checklistgroups`,
          providesTags: result =>
            result
              ? [
                  ...result.map(({ id }) => ({
                    type: "Checklistgroups",
                    id
                  })),
                  { type: "Checklistgroups", id: "LIST" }
                ]
              : [{ type: "Checklistgroups", id: "LIST" }],
          transformResponse: res => {
            const sortedChecklistItems = res.map(x => ({
              ...x,
              checklistitems: sortBy(x.checklistitems, x => x.weight)
            }));
            return sortBy(sortedChecklistItems, x => x.weight);
          }
        }),
        addChecklistgroups: build.mutation({
          query: body => ({
            url: `/checklistgroups`,
            method: "POST",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getChecklistgroups",
                body.vendorId,
                draft => {
                  let newDraft = [...draft];
                  newDraft.push({
                    ...body
                  });
                  return sortBy(newDraft, "weight");
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: [{ type: "Checklistgroups", id: "LIST" }]
        }),
        editChecklistgroups: build.mutation({
          query: ({ id, ...body }) => ({
            url: `/checklistgroups/${id}`,
            method: "PATCH",
            body
          }),
          async onQueryStarted(
            { vendorId, body },
            { dispatch, queryFulfilled }
          ) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getChecklistgroups",
                vendorId,
                draft => {
                  const data = draft?.find(x => x.id === body.id);
                  if (data) {
                    for (let key in body) {
                      data[key] = body[key];
                    }
                  }

                  return sortBy(draft, "weight");
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: (r, e, { id }) => [{ type: "Checklistgroups", id }]
        }),
        deleteChecklistgroups: build.mutation({
          query: ({ id }) => ({
            url: `/checklistgroups/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted(
            { body, vendorId },
            { dispatch, queryFulfilled }
          ) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getChecklistgroups",
                vendorId,
                draft => {
                  const index = draft?.findIndex(x => x.id === body.id);
                  draft?.splice(index, 1);
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: (r, e, { id }) => [{ type: "Checklistgroups", id }]
        }),
        reorderChecklistgroups: build.mutation({
          query: ({ vendorId, body }) => ({
            url: `/vendors/${vendorId}/checklistgroups/reorder`,
            method: "POST",
            body: { ids: body.map(x => x.id) }
          }),
          async onQueryStarted(
            { vendorId, body },
            { dispatch, queryFulfilled }
          ) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getChecklistgroups",
                vendorId,
                draft => {
                  return body;
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: [{ type: "Checklistgroups", id: "LIST" }]
        }),

        // Checklistitems
        getChecklistitems: build.query({
          query: vendorId => `/vendors/${vendorId}/checklistitems`,
          providesTags: result =>
            result
              ? [
                  ...result.map(({ id }) => ({ type: "Checklistitems", id })),
                  { type: "Checklistitems", id: "LIST" }
                ]
              : [{ type: "Checklistitems", id: "LIST" }],
          transformResponse: res => sortBy(res, "weight")
        }),
        addChecklistitems: build.mutation({
          query: body => ({
            url: "/checklistitems",
            method: "POST",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const result = dispatch(
              api.util.updateQueryData(
                "getChecklistitems",
                body.vendorId,
                draft => {
                  draft.push({ ...body });
                  // sortBy(draft, "weight");
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          },
          invalidatesTags: [{ type: "Checklistitems", id: "LIST" }]
        }),
        editChecklistitems: build.mutation({
          query: ({ id, ...body }) => ({
            url: `/checklistitems/${id}`,
            method: "PATCH",
            body
          }),
          async onQueryStarted(
            { id, vendorId, ...body },
            { dispatch, queryFulfilled }
          ) {
            const postResult = dispatch(
              api.util.updateQueryData("getChecklistitems", vendorId, draft => {
                const data = draft?.find(x => x.id === id);
                if (data) {
                  for (let key in body) {
                    data[key] = body[key];
                  }
                }
                sortBy(draft, "weight");
              })
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          }
          // invalidatesTags: (r, e, { id }) => [{ type: "Checklistitems", id }]
        }),
        deleteChecklistitems: build.mutation({
          query: ({ id }) => ({
            url: `/checklistitems/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted({ id, vendorId }, { dispatch, queryFulfilled }) {
            const result = dispatch(
              api.util.updateQueryData("getChecklistitems", vendorId, draft => {
                const index = draft?.findIndex(x => x.id === id);
                draft?.splice(index, 1);
              })
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          }
          // invalidatesTags: (r, e, { id }) => [{ type: "Checklistitems", id }]
        }),
        reorderChecklistitems: build.mutation({
          query: ({ vendorId, body }) => ({
            url: `/vendors/${vendorId}/checklistitems/reorder`,
            method: "POST",
            body: { ids: body.map(x => x.id) }
          }),
          async onQueryStarted(
            { body, vendorId },
            { dispatch, queryFulfilled }
          ) {
            const postResult = dispatch(
              api.util.updateQueryData("getChecklistitems", vendorId, draft => {
                return body;
              })
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          }
          // invalidatesTags: [{ type: "Checklistitems", id: "LIST" }]
        })
      })
    });
