import useAuth from "hooks/useAuth";
import { CircleFill } from "react-bootstrap-icons";
import styles from "./EntityVisibilityIcon.module.css"; // Import css modules stylesheet as styles

const EntityVisibilityIcon = ({
  entity,
  className = "",
  title = "",
  ...rest
}) => {
  const { isProspect } = useAuth();

  if (isProspect) {
    return null;
  }

  let cssClass = [styles.entityVisibility];
  let hover = [];
  if (entity.visibility === "Company-wide") {
    cssClass.push(styles.isCompanyWide);
    hover.push(
      "This element is managed at the Company-level, i.e. shared across Opportunities."
    );
  } else {
    cssClass.push(styles.isOneOff);
    // hover.push(
    //   "This element has been added to this Opportunity as a one-off, but can be promoted to Company-wide in the Settings."
    // );
  }

  return (
    <span title={hover.join("; ")}>
      <CircleFill className={`is-small ${cssClass.join(" ")}`} />
    </span>
  );
};

export default EntityVisibilityIcon;
