import Joi from "joi-browser";
import Form from "components/common/Form";

const fields = [
  {
    name: "body",
    spellcheck: "true"
  }
];

const schema = {
  body: Joi.string().required()
};

const FeedbackBodyForm = ({ handleSubmit, body = "" }) => {
  const initialState = { body };
  fields[0].onBlur = evt =>
    handleSubmit({ [evt.target.name]: evt.target.value });
  return (
    <Form
      className=""
      fields={fields}
      schema={schema}
      initialState={initialState}
      onValidSubmit={handleSubmit}
      hideButton
      resetOnSubmit={false}
    />
  );
};

export default FeedbackBodyForm;
