import { useState, useMemo } from "react";
import useModalClose from "hooks/useModalClose";
import { useGetMetaQuery } from "services/api";

import "./SelectableStakeholder.css";
import UserAvatar from "../UserAvatar";
import { PersonPlus } from "react-bootstrap-icons";

export const SelectableStakeholder = ({
  className,
  stakeholders,
  selectedStakeholderId,
  editable,
  onChange,
  addressed,
  onlyImage = false
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const close = () => setDropdownActive(false);
  const toggle = () => setDropdownActive(active => !active);
  const modalRef = useModalClose(close);

  const { data } = useGetMetaQuery();

  const activeStakeholders = stakeholders?.filter(
    item => item?.status !== data?.userStatuses?.INACTIVE
  );

  const selectedStakeholder = activeStakeholders?.find(
    item => item?.id === selectedStakeholderId
  );

  const handleChange = async stakeholderId => {
    close();
    if (stakeholderId !== selectedStakeholderId) {
      if (typeof onChange === "function") {
        onChange(stakeholderId);
      }
    }
  };

  return (
    <div className={`is-clickable ${className}`} ref={modalRef}>
      <div
        className={`is-flex ${addressed ? "item-completed" : ""}`}
        onClick={!addressed ? toggle : null}
      >
        {selectedStakeholder ? (
          <>
            <UserAvatar user={selectedStakeholder} />
            {!onlyImage ? (
              <p
                className={
                  selectedStakeholder?.status === data?.userStatuses?.INACTIVE
                    ? "has-text-grey-light"
                    : ""
                }
              ></p>
            ) : null}
          </>
        ) : (
          <>{editable ? <PersonPlus /> : null}</>
        )}
      </div>
      {editable && dropdownActive && (
        <div className="FeedbackCell-modal drop-shadow">
          <ul>
            {activeStakeholders.map(sh => (
              <li key={sh.id} onClick={() => handleChange(sh.id)}>
                {sh.firstName} {sh.lastName}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
