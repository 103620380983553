import useAuth from "hooks/useAuth";
import React from "react";
import {
  useAddAttachmentsMutation,
  useDeleteAttachmentsMutation,
  useGetAttachmentsQuery
} from "services/api";
import AttachmentCard from "./AttachmentCard";

export default function Attachments({ addSection = null, handleClose }) {
  const { currentUser } = useAuth();
  const { data, isLoading, isFetching } = useGetAttachmentsQuery(
    currentUser.vendorId
  );
  const [del, { isLoading: deleteLoading, error: deleteError }] =
    useDeleteAttachmentsMutation();
  const [add, { isLoading: addLoading, isError, error }] =
    useAddAttachmentsMutation();

  const onDrop = acceptedFiles => {
    const payload = [];
    acceptedFiles.forEach(file => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("isListable", "true");
      payload.push(formData);
    });
    add({ payload });
  };

  const handleAddToSection = files => {
    const payload = files
      .map(
        ({ fileName, fileDownloadUrl }) =>
          `<img src="${fileDownloadUrl}" title="${fileName}"/>`
      )
      .join("<br />");

    addSection(payload);
  };

  return (
    <AttachmentCard
      addToSection={!!addSection ? handleAddToSection : null}
      data={data || []}
      onDelete={id => del({ id })}
      onDrop={onDrop}
      isLoading={isLoading}
      isFetching={isFetching || addLoading || deleteLoading}
      isError={isError}
      error={error || deleteError}
      handleClose={handleClose}
    />
  );
}
