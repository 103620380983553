/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";
import { htmlEncode, truncate } from "../../utils/helpers";
import useAuth from "hooks/useAuth";
import usePocs from "hooks/usePocs";
import useSearch from "hooks/useSearch";
import {
  useAddPocQuestionsMutation,
  useAddPocUseCasesAndSuccessCriteriaMutation,
  useGetPocQuestionsQuery,
  useGetPocUseCasesQuery,
  useGetQuestionsQuery,
  useGetUseCasesQuery
} from "services/api";

export default function PlusSearchBox({
  className,
  position = { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 1 },
  search,
  addSection,
  onClose = () => {},
  onBrowseAll = () => {}
}) {
  const { pocId } = useParams();
  const { currentPoc } = usePocs();
  const { currentUser, getCurrentStakeholder } = useAuth();
  const currentStakeholder = useMemo(
    () => getCurrentStakeholder(currentPoc),
    [currentPoc, getCurrentStakeholder]
  );

  const [addRequirement, { isLoading: isLoadingAddReq }] =
    useAddPocUseCasesAndSuccessCriteriaMutation({
      fixedCacheKey: `batch-add-pocUseCases-${pocId}`
    });
  const [addQuestion, { isLoading: isLoadingAddReqAddQs }] =
    useAddPocQuestionsMutation({
      fixedCacheKey: `batch-add-pocQuestions-${pocId}`
    });

  const { data: usecases, isLoading: isLoadingUsecases } =
    useGetUseCasesQuery();
  const { data: pocUsecases, isLoading: pocUsecaseIsLoading } =
    useGetPocUseCasesQuery(pocId);

  const { data: questions, isLoading: isLoadingQuestions } =
    useGetQuestionsQuery();
  const { data: pocQuestions, isLoading: isLoadingPocQuestions } =
    useGetPocQuestionsQuery(pocId);

  const { results, searchValue, setSearchValue } = useSearch({
    dataSet: search.type === "requirements" ? usecases : questions,
    keys: ["body", "notes"],
    initialSearchValue: ""
  });

  const maxItemsToShow = 7;

  useEffect(() => {
    setSearchValue(search.value);
  }, [search.value]);

  const handleAddRequirement = rq => {
    const pocUseCaseIds = pocUsecases?.map(x => x.usecase?.id) || [];
    if (!pocUseCaseIds.includes(rq.id)) {
      const payload = {
        assignedToStakeholderId:
          currentStakeholder?.id || currentPoc?.stakeholders[0]?.id,
        pocId,
        usecaseIds: [rq.id],
        successCriteria: [],
        isBusiness: false
      };
      addRequirement(payload);
    }
    if (addSection) {
      const sectionPayload = `<span class="embeddedObjectTag grayTag mceNonEditable"
        data-object-type="usecase" data-object-id="${rq.id}"
        data-object-description="${rq.body}">${rq.body}</span>`;
      addSection(sectionPayload);
    }
  };

  const handleAddQuestions = async qs => {
    const pocQuestionsIds = pocQuestions?.map(x => x.questionId) || [];
    if (!pocQuestionsIds.includes(qs.id)) {
      const payload = [{ questionId: qs.id, pocId, question: qs.body }];
      addQuestion(payload);
    }
    if (addSection) {
      const inlineAnswers = qs.answers.map(item => item.body).join(", ");
      const sectionPayload = `<span class="embeddedObjectTag orangeTag mceNonEditable"
        data-object-type="question" data-object-id="${qs.id}"
        data-object-description="${qs.body}">${qs.body}
      </span> ${inlineAnswers ? `- Answers (${inlineAnswers}) ` : ""}`;
      addSection(sectionPayload);
    }
  };

  const customPosition = useMemo(
    () => ({ top: position.top + 70 || 0, left: position.left }),
    [position]
  );

  return (
    <div
      style={customPosition}
      className={`plusSearchBox is-absolute dropdown ${
        search.type && "is-active"
      } ${className}`}
    >
      <div className="dropdown-trigger">
        {/* <SearchBox
          dataSet={fullData}
          keys={["body", "notes"]}
          setDataSet={setFilteredData}
          setLiftedSearchValue={setSearchValue}
          onPressEnter={handlePressEnter}
          onCloseSearch={setShowSearchUsecases}
          placeholder={"Type your new Requirement"}
          icon={<PlusLg />}
        /> */}
      </div>
      <div className="dropdown-menu" id="dropdown-menu4" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <b>
              Add a{" "}
              {search.type === "requirements" ? " Requirement" : " Question"}:
            </b>
          </div>
          {results?.length === 0 && (
            <div className="dropdown-item">No results...</div>
          )}
          {results?.length > 0 &&
            results.slice(0, maxItemsToShow).map(row => (
              <a
                className="dropdown-item"
                key={row.id}
                onClick={() => {
                  if (search.type === "requirements") {
                    handleAddRequirement(row);
                  }
                  if (search.type === "questions") {
                    handleAddQuestions(row);
                  }
                }}
              >
                {row.body}
                <EntityVisibilityIcon entity={row} />
                {row.notes ? <small> - {truncate(row.notes)}</small> : ""}
              </a>
            ))}
          <hr className="dropdown-divider" />
          <div className="dropdown-item">
            <button
              className="button is-info is-outlined is-small"
              onClick={onBrowseAll}
            >
              Browse all
              {search.type === "requirements" ? " Requirements" : " Questions"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
