import NiceTabs from "components/common/NiceTabs";
import { NavLink, useLocation } from "react-router-dom";

const tabs = [
  {
    urlTo: "/analytics",
    title: `Overview`
  },
  {
    urlTo: "/analytics/feedback",
    title: `Feedback Analysis`
  },
  {
    urlTo: "/analytics/opportunities",
    title: `Opportunities`
  },
  {
    urlTo: "/analytics/utilization",
    title: `Team Utilization`
  }
];

const tabsFeedbackAnalysis = [
  {
    urlTo:
      "/analytics/feedback?feedbackPriorities=Blocker&pocPhases=Scoping&pocPhases=Execution&pocPhases=On-Hold",
    title: `Deal Blockers`
  },
  {
    urlTo: "/analytics/feedback?pocStatuses=Pass",
    title: `Win Analysis`
  },
  {
    urlTo: "/analytics/feedback?pocStatuses=Fail",
    title: `Loss Analysis`
  }
];

const AnalyticsMenu = () => {
  const { pathname, search } = useLocation();

  return (
    <>
      <NiceTabs tabs={tabs} />

      {pathname.startsWith("/analytics/feedback") ? (
        <>
          <NiceTabs
            tabs={tabsFeedbackAnalysis}
            className="is-toggle is-small"
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default AnalyticsMenu;
