import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import { useGetFeedbackByExternalItemsQuery } from "services/api";
import FeedbackIssue from "pages/FeedbackIssue";
import Feedback from "pages/Feedback";
import Loading from "components/common/Loading";
import APIError from "components/common/APIError";
import { useEffect, useState } from "react";

const FeedbackRoutes = () => {
  const [filter, setFilter] = useState({});
  const filterQuery = createFilterFeedbackUQuery(filter);
  const { data, error, isLoading, isFetching } =
    useGetFeedbackByExternalItemsQuery(filterQuery);
  useEffect(() => {}, [filter]);
  let [searchParams] = useSearchParams();
  let location = useLocation();
  const search = location.search;
  const searchparam = new URLSearchParams(search);
  let keys = [];
  searchparam.forEach((el, key) => {
    keys.push(key);
  });
  const uniqKeys = [...new Set(keys)];
  const setFilterbyQuery = uniqKeys => {
    let obj = {};
    uniqKeys.forEach(v => {
      obj[v] = searchParams.getAll(v);
    });

    setFilter(obj);
  };
  useEffect(() => {
    if (uniqKeys.length > 0) {
      setFilterbyQuery(uniqKeys);
    } else {
      setFilter({});
    }
  }, [search]);

  if (isLoading) return <Loading />;
  if (error) return <APIError error={error} />;

  return (
    <Routes>
      <Route
        path=":feedbackCollectionId"
        element={
          <FeedbackIssue
            feedbackCollections={data}
            filter={filter}
            setFilter={setFilter}
          />
        }
      />
      <Route
        path=""
        element={
          <Feedback
            filter={filter}
            setFilter={setFilter}
            feedbacks={data}
            isFetching={isFetching}
          />
        }
      />
    </Routes>
  );
};

export default FeedbackRoutes;

export const createFilterFeedbackUQuery = filter => {
  let newFilter = { ...filter };
  let url = "";

  if (newFilter) {
    delete newFilter["search"];
    const keys = Object.keys(newFilter);
    if (keys.length > 0) {
      keys.forEach((key, i) => {
        if (key !== "search" && !key.includes("Date") && !key.includes("Acv")) {
          let values = newFilter[key];
          if (key.includes("Names")) {
            values = [encodeURIComponent(newFilter[key])];
          }
          const joined = values.join(`&${key}[]=`);
          if (i > 0) {
            url += `&${key}[]=${joined}`;
          } else {
            url += `?${key}[]=${joined}`;
          }
        }
        if (key.includes("Date") || key.includes("Acv")) {
          if (i > 0) {
            url += `&${key}=${newFilter[key]}`;
          } else {
            url += `?${key}=${newFilter[key]}`;
          }
        }
      });
    }
  }
  return url;
};
