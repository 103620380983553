import api from "./_createApi";
import Authenticate from "./authenticate";
import Attachments from "./attachments";
import Checklists from "./checklists";
import Comments from "./comments";
import Feedbacks from "./feedbacks";
import PocQuestionAnswers from "./pocQuestionAnswers";
import Questions from "./questions";
import Stakeholders from "./stakeholders";
import Usecases from "./usecases";
import Specdocsections from "./specdocsections";
import Users from "./users";
import Vendors from "./vendors";

import Pocs from "./pocs";
import PocAttachments from "./pocAttachments";
import PocQuestions from "./pocQuestions";
import PocUsecases from "./pocUsecases";
import PocUsecaseSuccesscriterias from "./pocUsecaseSuccesscriterias";
import PocSpecdocsections from "./pocSpecdocsections";

export const {
  useAuthenticateMutation,
  useGetAnalyticsQuery,
  useGetMetaQuery
} = Authenticate;

export const {
  useGetAttachmentsQuery,
  useAddAttachmentsMutation,
  useDeleteAttachmentsMutation,
  useUploadFileMutation
} = Attachments;

export const {
  useImportPocChecklistMutation,
  useGetPocChecklistgroupsQuery,
  useAddPocChecklistgroupsMutation,
  useEditPocChecklistgroupsMutation,
  useDeletePocChecklistgroupsMutation,
  useReorderPocChecklistgroupsMutation,

  useGetPocChecklistitemsQuery,
  useDetailPocChecklistitemsQuery,
  useAddPocChecklistitemsMutation,
  useEditPocChecklistitemsMutation,
  useDeletePocChecklistitemsMutation,
  useReorderPocChecklistitemsMutation,

  useGetChecklistgroupsQuery,
  useAddChecklistgroupsMutation,
  useEditChecklistgroupsMutation,
  useDeleteChecklistgroupsMutation,
  useReorderChecklistgroupsMutation,

  useGetChecklistitemsQuery,
  useAddChecklistitemsMutation,
  useEditChecklistitemsMutation,
  useDeleteChecklistitemsMutation,
  useReorderChecklistitemsMutation
} = Checklists;

export const {
  useGetCommentsQuery,
  useAddCommentsMutation,
  useEditCommentMutation,
  useDeleteCommentMutation
} = Comments;

export const {
  useGetFeedbacksQuery,
  useGetAllFeedbacksQuery,
  useAddFeedbacksMutation,
  useEditFeedbackMutation,
  useDeleteFeedbackMutation,
  useLinkFeedbackToExternalItemMutation,
  useUnlinkFeedbackToExternalItemMutation,
  useGetFeedbackByExternalItemsQuery
} = Feedbacks;

export const {
  useGetPocAttachmentsQuery,
  useAddPocAttachmentsMutation,
  useDeletePocAttachmentsMutation
} = PocAttachments;

export const {
  useGetPocQuestionAnswersQuery,
  useAddPocQuestionAnswersMutation,
  useEditPocQuestionAnswersMutation,
  useDeletePocQuestionAnswersMutation
} = PocQuestionAnswers;

export const {
  useGetPocQuestionsQuery,
  useAddPocQuestionsMutation,
  useImportPocQuestionsMutation,
  useEditPocQuestionsMutation,
  useDeletePocQuestionsMutation,
  useReorderPocQuestionsMutation
} = PocQuestions;

export const {
  useGetPocsQuery,
  useAddPocMutation,
  useAddPocsMutation,
  useEditPocMutation,
  useDeletePocMutation,
  useGetPocScoreQuery,
  useGetPocProgressQuery
} = Pocs;

export const {
  useGetPocUseCasesQuery,
  useImportPocUseCaseMutation,
  useEditPocUseCasesMutation,
  useDeletePocUseCasesMutation,
  useEditMultiPocUseCasesMutation,
  useAddPocUseCasesAndSuccessCriteriaMutation,
  useDeletePocUseCasesAndSuccessCriteriaMutation,
  useReorderPocUsecasesMutation,
  useApprovalInvitePocUsecasesMutation,
  useApprovePocUsecasesMutation,
  useRevisePocUsecasesMutation
} = PocUsecases;

export const {
  useGetPocUseCaseSuccessCriteriaQuery,
  useEditPocUseCaseSuccessCriteriaMutation,
  useDeletePocSuccesscriteriasMutation
} = PocUsecaseSuccesscriterias;

export const {
  useGetPocSpecdocsectionsQuery,
  useAddPocSpecdocsectionMutation,
  useAddPocSpecdocsectionFromSpecdocsectionMutation,
  useImportPocSpecdocsectionMutation,
  useEditPocSpecdocsectionsMutation,
  useDeletePocSpecdocsectionMutation,
  useReorderPocSpecdocsectionsMutation
} = PocSpecdocsections;

export const {
  useGetQuestionsQuery,
  useAddQuestionsAndAnswersMutation,
  useEditQuestionsAndAnswersMutation,
  useDeleteQuestionMutation,
  useReorderQuestionsMutation,
  useReorderAnswersMutation
} = Questions;

export const {
  useInviteStakeholderMutation,
  useGetStakeholdersQuery,
  useEditStakeholderMutation,
  useDeleteStakeholderMutation
} = Stakeholders;

export const {
  useGetUseCasesQuery,
  useAddUseCasesMutation,
  useEditUseCaseMutation,
  useAddUseCasesAndSuccessCriteriaMutation,
  useEditUseCasesAndSuccessCriteriaMutation,
  useDeleteUseCaseMutation,
  useReorderUseCasesMutation
} = Usecases;

export const {
  useGetSpecdocsectionsQuery,
  useAddSpecdocsectionsMutation,
  useEditSpecdocsectionMutation,
  useDeleteSpecdocsectionMutation,
  useReorderSpecdocsectionsMutation
} = Specdocsections;

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useGetUserActivityLogsQuery,
  useCreateAutoLoginLinkMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useLinkSalesforceOAuthMutation,
  useUnlinkSalesforceOAuthMutation,
  useAddUserMutation,
  useEditUserMutation,
  useSalesforceSearchMutation,
  useSalesforceSyncMutation
} = Users;

export const {
  useGetVendorQuery,
  useGetVendorUsersQuery,
  useEditVendorMutation,
  useGetExternalSearchQuery,
  useGetExternalSearchFeedbacksQuery,
  useAddExternalCommentMutation,
  useLazyGetExternalSearchQuery,
  useGetChecklistTemplateListQuery,
  useGetQuestionTemplateListQuery,
  useGetUsecaseTemplateListQuery,
  useGetVendorRbacQuery,
  useSearchUserAutocompleteQuery
} = Vendors;

export default api;
