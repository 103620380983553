import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useUrlQuery from "hooks/useUrlQuery";
import useAuth from "hooks/useAuth";
import {
  useLinkSalesforceOAuthMutation,
  useUnlinkSalesforceOAuthMutation
} from "services/api";
import Loading from "components/common/Loading";
import APIError from "components/common/APIError";

export default function SalesforceOAuthAction() {
  const { action } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [linkSalesforce, { error: errorLink, isLoading: linkLoading }] =
    useLinkSalesforceOAuthMutation();
  const [unlinkSalesforce, { error: errorUnlink, isLoading: unlinkLoading }] =
    useUnlinkSalesforceOAuthMutation();
  const urlQuery = useUrlQuery();

  const actionError = urlQuery.get("error");
  const actionErrorDescription = urlQuery.get("error_description");

  // We only want to process this block once!
  useEffect(() => {
    const process = async () => {
      switch (action) {
        case "disconnect":
          setConfirmationMessage("Disconnected!");
          unlinkSalesforce({
            id: currentUser.id
          });
          break;

        default:
          if (!actionError) {
            setConfirmationMessage("Success!");
            linkSalesforce({
              id: currentUser.id,
              body: {
                authCode: urlQuery.get("code")
              }
            });
          } else {
            setConfirmationMessage(actionErrorDescription);
          }
          break;
      }
    };
    process();
  }, []);

  return (
    <>
      <br />
      <div className="card">
        <div className="card-content">
          <h1 className="is-size-4 mb-5">
            Salesforce connection:{" "}
            {linkLoading || unlinkLoading ? "..." : confirmationMessage}
          </h1>
          <APIError error={errorLink || errorUnlink} />
          {linkLoading || unlinkLoading ? (
            <Loading />
          ) : (
            <Link className="button" to="/settings/integrations">
              {`Go to Settings > Integrations`}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
