import { ExclamationTriangleFill } from "react-bootstrap-icons";
import "./Error.css";

const Error = ({ message }) => {
  if (!message) return null;
  return (
    <div className="error-message is-flex is-align-items-center">
      <ExclamationTriangleFill className="mr-1" />
      {message}
    </div>
  );
};

export default Error;
