import { Link } from "react-router-dom";
import classNames from "classnames";
import { useGetPocScoreQuery } from "services/api";
import Tooltip from "rc-tooltip";

const nullScoreDetails = [
  {
    score: "",
    category: "Tasks",
    details: ""
  },
  {
    score: "",
    category: "Success Criterias",
    details: ""
  },
  {
    score: "",
    category: "Activities",
    details: ""
  },
  {
    score: "",
    category: "Qualification",
    details: ""
  }
];

const getScoreDetails = score => {
  const result = { url: "" };
  switch (score.category) {
    case "Tasks":
      result.url = "overview";
      break;
    case "Success Criterias":
      result.url = "";
      result.splited = score.details.split("\n");
      break;
    case "Activities":
      result.url = "feedback";
      break;
    case "Qualification":
      result.url = "questions";
      break;
    default:
      break;
  }
  return result;
};

export default function TopBarScore({
  poc,
  className = "is-size-3 w-5r",
  ...props
}) {
  const { data: pocScore } = useGetPocScoreQuery(poc.id);

  const hoverable = pocScore?.length;
  const scoreDetails = pocScore?.length
    ? pocScore[0].scoreDetails
    : nullScoreDetails;
  const score = pocScore?.length ? pocScore[0].score : null;
  const scoreBg = classNames("scoreBg", {
    "border-grey has-text-black has-background-white":
      score == null || score === "",
    "has-background-success has-text-white": score >= 75,
    "has-background-warning has-text-white": score >= 25 && score < 75,
    "has-background-danger has-text-white": score && score < 25 && score !== ""
  });
  const scoreColor = classNames("scoreColor", {
    "": score == null || score === "",
    "has-text-success": score >= 75,
    "has-text-warning": score >= 25 && score < 75,
    "has-text-danger": score && score < 25 && score !== ""
  });

  const scoreHover = hoverable ? (
    <div className="scoreTooltip">
      <div className="columns m-0 px-3 is-size-6">
        <div className="column is-3">Score</div>
        <div className="column is-3">Section</div>
        <div className="column is-6">Description</div>
      </div>
      {scoreDetails.map((s, i) => {
        const sd = getScoreDetails(s);
        return (
          <Link to={`/pocs/${poc.id}/${sd.url}`} key={i}>
            <div className="columns m-0 px-3 border-top is-size-6">
              <div className={`${scoreColor} column is-3`}>{s.score}%</div>
              <div className="column is-3">{s.category}</div>
              {!sd.splited ? (
                <div className="column is-6">{s.details}</div>
              ) : (
                <div className="column is-6">
                  <div>{sd.splited[0]}</div>
                  <div>{sd.splited[1]} </div>
                </div>
              )}
            </div>
          </Link>
        );
      })}
    </div>
  ) : (
    <div>No score yet</div>
  );

  return (
    <Tooltip placement="left" trigger={["hover"]} overlay={scoreHover}>
      <div
        className={`${scoreBg} ${className} ${
          hoverable ? "is-clickable" : ""
        } is-relative py-2 is-flex is-justify-content-center is-align-items-center rounded`}
      >
        <>{score || "-"}</>
      </div>
    </Tooltip>
  );
}
