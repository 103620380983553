import { useState, memo } from "react";
import { CircleFill } from "react-bootstrap-icons";
import useModalClose from "hooks/useModalClose";
import { useGetMetaQuery } from "services/api";
import Loading from "components/common/Loading";

const colorMap = {
  Issue: "is-danger",
  High: "is-danger",
  Medium: "is-warning",
  Blocker: "is-blocker"
};

const FeedbackCellSelectableButton = ({ cell, value, edit, disabled, row }) => {
  const addressed = row?.original?.addressed;
  const feedback = cell?.row?.original;
  const header = cell?.column?.Header;
  const [dropdownActive, setDropdownActive] = useState(false);
  const { data, isLoading } = useGetMetaQuery();

  const close = () => setDropdownActive(false);
  const toggle = () => !disabled && setDropdownActive(active => !active);
  const modalRef = useModalClose(close);

  if (isLoading) return <Loading />;
  if (!value) return "-";

  const { priorities, feedbackTypes } = data;
  const metaValues = header === "Type" ? feedbackTypes : priorities;
  const options = Object.values(metaValues || {});
  const handleChange = async opt => {
    close();
    if (opt !== value) {
      await edit({ ...feedback, [header.toLowerCase()]: opt });
    }
  };

  const prefix =
    header === "Type" ? "" : <CircleFill className="mr-1" size={9} />;
  return (
    <div ref={modalRef} className={dropdownActive ? "active-dropdown" : ""}>
      <button
        onClick={!addressed ? toggle : null}
        className={`button ${header} FeedbackCell-button is-small is-outlined px-2 py-1 ${
          addressed ? "item-completed" : ""
        } ${colorMap[value] || "is-success"} ${disabled ? "disabled" : ""}`}
      >
        {prefix}
        {value}
      </button>
      {edit && dropdownActive && (
        <div className="FeedbackCell-modal drop-shadow">
          <ul>
            {options.map(opt => (
              <li key={opt} onClick={() => handleChange(opt)}>
                {opt}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(FeedbackCellSelectableButton);
