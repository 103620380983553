import { useEffect } from "react";
import APIError from "components/common/APIError";
import useAuth from "hooks/useAuth";
import {
  useSalesforceSearchMutation,
  useGetVendorQuery,
  useGetUserQuery
} from "services/api";
import BatchImportTable from "./BatchImportTable";
import GettingStarted from "components/common/GettingStarted";
import { useNavigate } from "react-router-dom";
import Loading from "components/common/Loading";
const ImportSalesforce = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { data: user } = useGetUserQuery(currentUser?.id, {
    skip: !currentUser
  });
  const { data: vendor } = useGetVendorQuery(currentUser?.vendorId);
  const [findOpportunities, { isLoading, error, data }] =
    useSalesforceSearchMutation();

  useEffect(() => {
    if (user?.salesforceIsConnected) {
      const queryData = vendor?.salesforceQueryData
        ? JSON.parse(vendor?.salesforceQueryData)
        : {};
      const payload = {
        id: user?.id,
        ...queryData
      };
      findOpportunities(payload);
    }
  }, [user, findOpportunities, vendor]);

  if (isLoading) return <Loading />;
  return (
    <>
      <APIError error={error} />
      {data?.length ? (
        <BatchImportTable data={data || []} vendor={vendor} />
      ) : (
        <GettingStarted
          message="Connect your Salesforce account now"
          buttonText="Go to Settings > Integrations"
          setShow={() => navigate("/settings/integrations")}
        />
      )}
    </>
  );
};

export default ImportSalesforce;
