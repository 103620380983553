import React, { useEffect, useMemo, useState } from "react";
import { ChevronRight, ChevronDown } from "react-bootstrap-icons";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ConfirmChange from "../Settings/ConfirmChange";
import { v4 as uuid4 } from "uuid";
import {
  useEditQuestionsAndAnswersMutation,
  useGetMetaQuery
} from "../../../services/api";
import useAuth from "../../../hooks/useAuth";
import EntityVisibilityIcon from "../EntityVisibilityIcon";
import { QuestionTableActions } from "../../../pages/PocQuestions/constants";
import useRbac from "../Rbac/useRbac";
import { UserPermissions } from "../../../config/userPermissions";
import TemplatePicker from "../TemplatePicker";

/**
 * @param questionSnapshot snapshot object keeps drag and drop values.
 * @param provided provided object keeps drag and drop values.
 * @param filterKey filter key
 * @param onDragEndAnswers on drag end answers
 * @param showAnswersInitial show answers initial, this state controls expand or collapse all answers
 * @param questionData question data
 * @param toggleId toggle id, using for controlling answering states
 * @param answerData it takes question answers as array, we are keeping it different property cause sometimes we need update specific array
 * @param selectedAnswers selected answers as array -> its directly api response
 * @param selectedAnswersIds selected answer ids as array -> its state
 * @param setSelectedAnswersIds set selected answer ids
 * @param answerColumns answer table column headers
 * @param answerColumnsEditMode answer table column headers in edit mode
 * @param isManageQuestion is manage question page or not
 * @param handleArchiveToggle takes function who takes question to archive, just using in manage question page
 * @param handleDeleteQuestion takes function who deletes questions, just using in manage question page
 * @param deleteId question delete id, using in action popover menu
 * @param editTemplate takes function who edits question template, just using in manage question page
 */

function TableBody({
  questionSnapshot,
  provided,
  filterKey,
  onDragEndAnswers,
  showAnswersInitial,
  questionData,
  toggleId,
  answerData,
  selectedAnswers,
  selectedAnswersIds,
  setSelectedAnswersIds,
  answerColumns,
  answerColumnsEditMode,
  isManageQuestion,
  handleArchiveToggle,
  handleDeleteQuestion,
  deleteId,
  editTemplate,
  freetextFields,
  setFreeTextFields
}) {
  const { isProspect } = useAuth();
  const [edit, { error: editError }] = useEditQuestionsAndAnswersMutation();
  const { data: metadata } = useGetMetaQuery();
  const [editStatus, setEditStatus] = useState(false);
  const [showAnswers, setShowAnswers] = useState(showAnswersInitial);
  const [answersSortBy, setAnswersSortBy] = useState({
    key: "",
    order: ""
  });
  const [answers, setAnswers] = useState([]);
  const [editQuestionBody, setEditQuestionBody] = useState("");
  const [editQuestionVisibility, setEditQuestionVisibility] = useState("");
  const [editAnswers, setEditAnswers] = useState([]);
  const [confirmData, setConfirmData] = useState(null);
  const [answerBodyError, setAnswerBodyError] = useState([]);
  const { userHasPermission } = useRbac();

  const handleEditStatus = () => {
    setEditAnswers(
      questionData.answers.map(item => ({
        body: item.body,
        archived: item.archived,
        toDelete: false,
        id: item.id,
        isNew: false,
        isFreeText: item.isFreeText,
        isDirty: true,
        usage: item.usage,
        winRate: item.winRate
      }))
    );
    setEditQuestionBody(questionData.body);
    setEditQuestionVisibility(questionData.visibility);
    setEditStatus(true);
    setShowAnswers(true);
  };

  useMemo(() => {
    if (answerData?.length) {
      setAnswers(answerData);
    }
  }, [answerData]);

  useEffect(() => {
    setShowAnswers(showAnswersInitial);
  }, [showAnswersInitial]);

  const handleAddAnswer = () => {
    setEditAnswers([
      ...editAnswers,
      {
        body: "",
        archived: false,
        toDelete: false,
        id: uuid4(),
        isNew: true,
        isDirty: true,
        isFreeText: false
      }
    ]);
  };

  const handleDeleteAnswer = id => {
    let tempAnswers = [...editAnswers];
    let findAnswer = tempAnswers.find(answer => answer.id === id);
    if (findAnswer) {
      findAnswer.toDelete = true;
      setEditAnswers(tempAnswers);
    }
  };

  const handleQuestionBodyChange = val => setEditQuestionBody(val);
  const handleQuestionVisibilityChange = val => setEditQuestionVisibility(val);

  const handleAnswerChanges = (id, type, value) => {
    let tempAnswers = [...editAnswers];
    let findAnswer = tempAnswers.find(answer => answer.id === id);
    if (findAnswer) {
      findAnswer[type] = value;
    }
    setEditAnswers(tempAnswers);
  };

  const handleSubmit = () => {
    let editData = {
      id: questionData.id,
      question: editQuestionBody,
      visibility: editQuestionVisibility,
      nbPocs: questionData.nbPocs,
      answers: editAnswers.map(item => ({
        body: item?.isFreeText ? "" : item.body,
        archived: item.archived,
        toDelete: item.toDelete,
        id: item.id,
        isNew: item.isNew,
        isFreeText: item.isFreeText,
        isDirty: item.isDirty
      }))
    };
    edit(editData);
    setEditStatus(false);
  };

  const cancelEdit = () => {};

  const handleEditSave = () => {
    let checkEmptyAnswers = editAnswers.filter(
      item => !item.isFreeText && !item.body
    );
    if (checkEmptyAnswers?.length) {
      setAnswerBodyError(checkEmptyAnswers.map(item => item.id));
      return;
    }
    if (questionData?.nbPocs === 0) {
      handleSubmit();
    } else {
      setConfirmData({
        message:
          "Other Opportunities are referencing this question, are you sure you want to make these changes?",
        onAccept: handleSubmit,
        onCancel: cancelEdit
      });
    }
  };

  const sortAnswers = column => {
    let newSort = {
      key: column,
      order:
        answersSortBy.key === column
          ? answersSortBy.order === "desc"
            ? "asc"
            : "desc"
          : "asc"
    };
    setAnswersSortBy(newSort);
    setAnswers(
      [...answers].sort((a, b) => {
        if (newSort.order === "asc") {
          return a[column] > b[column] ? 1 : -1;
        } else {
          return a[column] < b[column] ? 1 : -1;
        }
      })
    );
  };

  const selectAnswer = answerId => {
    toggleId(answerId);
    let checkAnswerExist = selectedAnswersIds.find(item => item === answerId);
    if (!checkAnswerExist) {
      setSelectedAnswersIds([...selectedAnswersIds, answerId]);
    }
  };

  const deselectAnswer = answerId => {
    toggleId(answerId);
    setSelectedAnswersIds(selectedAnswersIds.filter(item => item !== answerId));
    let newFreeText = { ...freetextFields };
    delete newFreeText[answerId];
    setFreeTextFields(newFreeText);
  };

  const handleAnswerCheckbox = answerId => {
    let checkSelect = selectedAnswersIds.find(item => item === answerId);
    if (checkSelect) {
      deselectAnswer(answerId);
    } else {
      selectAnswer(answerId);
    }
  };

  const isAnswerSelected = answerId => {
    return !!selectedAnswersIds.find(item => item === answerId);
  };

  return (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <TableRow
        icon={
          showAnswers ? (
            <ChevronDown
              className="mr-1"
              onClick={() => setShowAnswers(false)}
            />
          ) : (
            <ChevronRight
              className="mr-1"
              onClick={() => setShowAnswers(true)}
            />
          )
        }
        firstColumn={
          <span>
            {questionData.body}
            <EntityVisibilityIcon entity={questionData} />
          </span>
        }
        secondColumn={
          isManageQuestion
            ? questionData?.answers
                ?.filter(item => !item.isFreeText)
                ?.map(item => item.body)
                .join(", ") || true
            : selectedAnswers?.map(item => item.body).join(", ") || true
        }
        thirdColumn={
          isManageQuestion && (
            <TemplatePicker
              editTemplate={editTemplate}
              value={questionData?.templates || []}
              row={{ original: { id: questionData.id } }}
            />
          )
        }
        fourthColumn={
          isManageQuestion
            ? questionData.visibility
            : /*!isProspect &&
              `${questionData.usage > 0 ? `${questionData.usage}%` : ""}`*/ ""
        }
        // fifthColumn={
        //   !isProspect &&
        //   `${questionData.winRate > 0 ? `${questionData.winRate}%` : ""}`
        // }
        MenuComponent={
          <QuestionTableActions
            value={questionData}
            handleEdit={handleEditStatus}
            questionVisibility={questionData?.visibility}
            isManageQuestion={isManageQuestion}
            handleArchiveToggle={handleArchiveToggle}
            handleDeleteQuestion={handleDeleteQuestion}
            deleteId={deleteId}
            metadata={metadata}
          />
        }
        question={questionData}
        editMode={editStatus}
        isQuestion={true}
        editQuestionBody={editQuestionBody}
        editQuestionVisibility={editQuestionVisibility}
        handleQuestionBodyChange={handleQuestionBodyChange}
        handleQuestionVisibilityChange={handleQuestionVisibilityChange}
        dragHandleProps={!isProspect && provided.dragHandleProps}
        isManageQuestion={isManageQuestion}
        metadata={metadata}
        snapshot={questionSnapshot}
      />
      {showAnswers && (
        <DragDropContext onDragEnd={onDragEndAnswers}>
          <div className="answer-container">
            <TableHeader
              columns={editStatus ? answerColumnsEditMode : answerColumns}
              sortTable={sortAnswers}
              isMenuAvailable={false}
              sortBy={answersSortBy}
              icon={<div></div>}
            />
            <Droppable droppableId="droppableAnswers">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {(editStatus
                    ? editAnswers.filter(item => !item.toDelete)
                    : !isManageQuestion
                    ? answers.filter(item => !item.archived)
                    : answers
                  ).map((answer, i) => (
                    <Draggable
                      key={answer.id}
                      draggableId={`${filterKey}*question*${questionData.id}*answer*${answer.id}`}
                      index={i}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <TableRow
                            icon={
                              !editStatus && !isManageQuestion ? (
                                <input
                                  type="checkbox"
                                  className="form-check-input larger"
                                  disabled={
                                    answer.archived ||
                                    !userHasPermission(
                                      UserPermissions.EVALQUESTIONNAIRE_ANSWER
                                    )
                                  }
                                  onChange={() =>
                                    handleAnswerCheckbox(answer.id)
                                  }
                                  checked={isAnswerSelected(answer.id)}
                                />
                              ) : (
                                ""
                              )
                            }
                            firstColumn={
                              answer.isFreeText ? (
                                <input
                                  type={"text"}
                                  className="answer-input"
                                  disabled={isManageQuestion || answer.archived}
                                  placeholder={
                                    isManageQuestion ? "Free Text" : ""
                                  }
                                  value={freetextFields?.[answer.id] || ""}
                                  onChange={e => {
                                    if (isManageQuestion) return;
                                    setFreeTextFields({
                                      ...freetextFields,
                                      [answer.id]: e.target.value
                                    });
                                  }}
                                  onFocus={() => {
                                    selectAnswer(answer.id);
                                  }}
                                  onBlur={() => {
                                    if (!freetextFields[answer.id]) {
                                      deselectAnswer(answer.id);
                                    }
                                  }}
                                />
                              ) : (
                                answer.body
                              )
                            }
                            // fourthColumn={
                            //   !isProspect &&
                            //   `${answer.usage > 0 ? `${answer.usage}%` : "-"}`
                            // }
                            // fifthColumn={
                            //   !isProspect &&
                            //   `${
                            //     answer.winRate > 0 ? `${answer.winRate}%` : "-"
                            //   }`
                            // }
                            isMenuAvailable={false}
                            editMode={editStatus}
                            isQuestion={false}
                            answer={answer}
                            answersLength={editAnswers.length}
                            handleAddAnswer={handleAddAnswer}
                            handleDeleteAnswer={handleDeleteAnswer}
                            handleAnswerChanges={handleAnswerChanges}
                            dragHandleProps={
                              !isProspect && provided.dragHandleProps
                            }
                            isManageQuestion={isManageQuestion}
                            answerBodyError={answerBodyError}
                            snapshot={snapshot.isDraggingOver}
                            questionSnapshot={questionSnapshot}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {editStatus &&
              userHasPermission(UserPermissions.EVALQUESTIONNAIRE_ANSWER) && (
                <div className="py-3" style={{ textAlign: "end" }}>
                  <button className="button is-info" onClick={handleEditSave}>
                    Save
                  </button>
                  <button
                    className="button is-light ml-1"
                    onClick={() => setEditStatus(false)}
                  >
                    Cancel
                  </button>
                </div>
              )}
          </div>
          {confirmData && <ConfirmChange {...confirmData} />}
        </DragDropContext>
      )}
    </div>
  );
}

export default TableBody;
