import { v4 as uuid4 } from "uuid";
import api from "./_createApi";
import { sortBy, sortSubEntitiesBy } from "utils/helpers";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Questions"] }).injectEndpoints({
    endpoints: build => ({
      getQuestions: build.query({
        query: () => `/questions`,
        transformResponse: res => {
          res = sortBy(res, x => x.weight);

          // Since the backend cannot sort the eagerly loaded sub-entities, we do it here
          res = sortSubEntitiesBy(res, "answers", x => x.weight);

          return res;
        },
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Questions", id })),
                { type: "Questions", id: `LIST` }
              ]
            : [{ type: "Questions", id: `LIST` }]
      }),
      addQuestionsAndAnswers: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const { data, error } = await fetchWithBaseQuery({
            url: "/questions",
            method: "POST",
            body: body.question
          });
          if (error) return { data: null, error };
          const questionId = data.id;
          const answerResponse = await Promise.all(
            body.answers.map(answer =>
              fetchWithBaseQuery({
                url: "/answers",
                method: "POST",
                body: { ...answer, questionId }
              })
            )
          );
          const successes = answerResponse
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = answerResponse
            .filter(res => !res.data)
            .map(res => res.error);
          if (failures.length > 0) return { data: null, error: failures };
          return {
            data: {
              question: data,
              answers: successes.length > 0 ? successes : []
            },
            error: null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const tempId = uuid4();
          const postResult = dispatch(
            api.util.updateQueryData("getQuestions", undefined, draft => {
              draft.unshift({
                ...body.question,
                createdAt: new Date().toISOString(),
                id: tempId,
                answers: body.answers
                  .map(answer => ({
                    ...answer,
                    id: uuid4(),
                    questionId: tempId
                  }))
                  .sort((ans1, ans2) => {
                    if (ans1.body < ans2.body) return -1;
                    if (ans1.body > ans2.body) return 1;
                    return 0;
                  })
              });
            })
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
        invalidatesTags: [{ type: "Questions", id: "LIST" }]
      }),
      editQuestionsAndAnswers: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const [editedAnswers, deletedAnswers, newAnsers] =
            body.answers?.reduce(
              (aggregate, answer) => {
                if (!answer.isNew && !answer.toDelete) {
                  aggregate[0].push(answer);
                } else if (!answer.isNew && answer.toDelete) {
                  aggregate[1].push(answer);
                } else if (!answer.toDelete) {
                  aggregate[2].push(answer);
                }
                return aggregate;
              },
              [[], [], []]
            ) || [];
          const questionBody = {};
          if ("question" in body) {
            questionBody.body = body.question;
          }
          if ("archived" in body) {
            questionBody.archived = body.archived;
          }
          if ("templates" in body) {
            questionBody.templates = body.templates;
          }
          if ("visibility" in body) {
            questionBody.visibility = body.visibility;
          }
          const questionRequest = fetchWithBaseQuery({
            url: `/questions/${body.id}`,
            method: "PATCH",
            body: questionBody
          });
          const editRequests = editedAnswers?.length
            ? editedAnswers.map(answer =>
                fetchWithBaseQuery({
                  url: `/answers/${answer.id}`,
                  method: "PATCH",
                  body: {
                    body: answer.body,
                    archived: answer.archived,
                    isFreeText: answer.isFreeText
                  }
                })
              )
            : [];
          const deleteRequests = deletedAnswers?.length
            ? deletedAnswers.map(answer =>
                fetchWithBaseQuery({
                  url: `/answers/${answer.id}`,
                  method: "DELETE"
                })
              )
            : [];
          const addRequests = newAnsers?.length
            ? newAnsers.map(answer =>
                fetchWithBaseQuery({
                  url: `/answers`,
                  method: "POST",
                  body: {
                    body: answer.body,
                    archived: answer.archived,
                    isFreeText: answer.isFreeText,
                    questionId: body.id,
                    notes: ""
                  }
                })
              )
            : [];
          const responseArray = await Promise.all([
            questionRequest,
            ...editRequests,
            ...deleteRequests,
            ...addRequests
          ]);
          const [successes, failures] = responseArray.reduce(
            (aggregate, response) => {
              if (response.data) {
                aggregate[0].push(response.data);
              } else if (response.error) {
                aggregate[1].push(response.error);
              }
              return aggregate;
            },
            [[], []]
          );
          if (failures.lenth > 0) return { data: null, error: failures };
          return { data: successes, error: null };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const batchResult = dispatch(
            api.util.updateQueryData("getQuestions", undefined, draft => {
              const qIdx = draft.findIndex(question => question.id === body.id);
              if (body.question) {
                draft[qIdx].body = body.question;
              }
              if (body.archived) {
                draft[qIdx].archived = body.archived;
              }
              if (body.templates) {
                draft[qIdx].templates = body.templates;
              }
              if (body.visibility) {
                draft[qIdx].visibility = body.visibility;
              }
              if (body.answers?.length > 0) {
                draft[qIdx].answers = body.answers
                  .filter(answer => !answer.toDelete)
                  .sort((ans1, ans2) => {
                    if (ans1.body < ans2.body) return -1;
                    if (ans1.body > ans2.body) return 1;
                    return 0;
                  });
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            batchResult.undo();
          }
        },
        invalidatesTags: result => {
          if (result)
            return [
              { type: "Questions", id: result[0].id },
              { type: "Questions", id: "LIST" },
              "PocQuestions"
            ];
          return [{ type: "Questions", id: "LIST" }, "PocQuestions"];
        }
      }),
      deleteQuestion: build.mutation({
        query: id => ({
          url: `/questions/${id}`,
          method: "DELETE"
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            api.util.updateQueryData("getQuestions", undefined, draft => {
              const questionIdx = draft.findIndex(
                question => question.id === id
              );
              if (questionIdx > -1) {
                draft.splice(questionIdx, 1);
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            deleteResult.undo();
          }
        },
        invalidatesTags: id => [{ type: "Questions", id }, "PocQuestions"]
      }),
      reorderQuestions: build.mutation({
        query: ({ vendorId, ids }) => ({
          url: `vendors/${vendorId}/questions/reorder`,
          method: "POST",
          body: { ids }
        }),
        invalidatesTags: ["Questions"]
      }),
      reorderAnswers: build.mutation({
        query: ({ vendorId, ids }) => ({
          url: `vendors/${vendorId}/answers/reorder`,
          method: "POST",
          body: { ids }
        }),
        invalidatesTags: ["Questions"]
      })
    })
  });
