import { useState, useCallback } from "react";

const useBatch = ({ onToggleOn, onToggleOff } = {}) => {
  const [ids, setIds] = useState(new Set());

  const clearIds = useCallback(() => setIds(new Set()), []);

  const toggleId = useCallback(
    id => {
      if (ids.has(id)) {
        onToggleOff && onToggleOff([id]);
      } else {
        onToggleOn && onToggleOn([id]);
      }
      setIds(oldIds => {
        const setCopy = new Set(oldIds);
        if (setCopy.has(id)) {
          setCopy.delete(id);
        } else {
          setCopy.add(id);
        }
        return setCopy;
      });
    },
    [onToggleOff, onToggleOn, ids]
  );

  return { ids, clearIds, toggleId, setIds };
};

export default useBatch;
