import { useState, memo } from "react";
import useModalClose from "hooks/useModalClose";
import { useGetMetaQuery } from "services/api";
import Loading from "components/common/Loading";

const FeedbackCellSelectableText = ({ cell, value, edit, disabled, row }) => {
  const addressed = row?.original?.addressed;
  const feedback = cell?.row?.original;
  const header = cell?.column?.Header;
  const [dropdownActive, setDropdownActive] = useState(false);
  const { data, isLoading } = useGetMetaQuery();

  const close = () => setDropdownActive(false);
  const toggle = () => !disabled && setDropdownActive(active => !active);
  const modalRef = useModalClose(close);

  if (isLoading) return <Loading />;

  const { feedbackStatuses } = data;
  const options = ["", ...Object.values(feedbackStatuses || {})];

  const handleChange = async opt => {
    const sameNull = value === null && opt === "";
    close();
    if (!sameNull && opt !== value) {
      await edit({
        ...feedback,
        [header.toLowerCase()]: opt || null
      });
    }
  };

  return (
    <div className={`${disabled ? "disabled" : "is-clickable"}`} ref={modalRef}>
      <div
        onClick={!addressed ? toggle : null}
        className={addressed ? "item-completed" : ""}
      >
        {value || "-"}
      </div>
      {edit && dropdownActive && (
        <div className="FeedbackCell-modal drop-shadow">
          <ul>
            {options.map(opt => (
              <li key={opt} onClick={() => handleChange(opt)}>
                {opt || "<unset>"}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(FeedbackCellSelectableText);
