import React from "react";
import { Route, Routes } from "react-router-dom";
import ManageQuestions from "./index";

export default function ManageQuestionsRoutes() {
  return (
    <Routes>
      <Route path="" element={<ManageQuestions type="active" />} />
      <Route path="archived" element={<ManageQuestions type="archived" />} />
    </Routes>
  );
}
