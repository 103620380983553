import Button from "components/common/Button";
import "./ConfirmChange.css";

const ConfirmChange = ({ message, onAccept, onCancel }) => {
  return (
    <div className="dimmed-wrapper">
      <div className="drop-shadow ConfirmChange">
        <p className="title is-5">{message}</p>
        <div className="buttons is-justify-content-end">
          <Button className="button is-info" onClick={onAccept}>
            Ok
          </Button>
          <Button className="button is-light" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmChange;
