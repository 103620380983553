import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const GoogleAnalyticsTracker = () => {
  const { currentUser } = useAuth();

  let location = useLocation();

  ReactGA.initialize(GA_TRACKING_ID);

  useEffect(() => {
    // @todo: Would be better to set the user_id once on login and unset on logout
    ReactGA.gtag("config", GA_TRACKING_ID, {
      user_id: currentUser ? currentUser.id + " " + currentUser.email : ""
    });

    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

export default GoogleAnalyticsTracker;
