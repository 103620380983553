const defaultPocRoutes = id => [
  {
    key: "overview",
    title: "Overview",
    urlTo: `/pocs/${id}/overview`
  },
  {
    key: "checklist",
    title: "Action Plan",
    urlTo: `/pocs/${id}/checklist`
  },
  {
    key: "questionnaire",
    title: "Discovery",
    urlTo: `/pocs/${id}/questionnaire`
  },
  {
    key: "usecases",
    title: "Requirements",
    urlTo: `/pocs/${id}/usecases`
  },
  {
    key: "collaborators",
    title: "Collaborators",
    urlTo: `/pocs/${id}/collaborators`
  },
  {
    key: "feedback",
    title: "Requests & Blockers",
    urlTo: `/pocs/${id}/feedback`
  },
  {
    key: "attachments",
    title: "Attachments",
    urlTo: `/pocs/${id}/attachments`
  }
];

export default defaultPocRoutes;
