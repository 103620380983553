import React from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

function TableHeader({
  columns,
  isMenuAvailable,
  sortTable,
  sortBy,
  icon,
  iconOnClick
}) {
  return (
    <div className="table-header">
      {/**
       * The below line for empty header item for chevron icons
       */}

      {icon && (
        <div
          className={`py-4 has-text-grey-light icon-row is-clickable has-text-weight-light whitespace-nowrap is-flex is-justify-content-center`}
          onClick={() => (iconOnClick ? iconOnClick() : null)}
        >
          {icon}
        </div>
      )}
      {columns.map((column, i) => (
        <div
          key={i}
          className={`py-4 has-text-grey-light ${
            i === 0 ? "question-row" : ""
          } is-clickable has-text-weight-light whitespace-nowrap table-header-item is-flex is-align-items-center ${
            i === columns.length - 1 && isMenuAvailable ? "menu" : ""
          }`}
          onClick={() => {
            if (column.isSortable) {
              sortTable(column.key);
            }
          }}
        >
          {sortBy.key === column.key &&
            (sortBy.order === "asc" ? (
              <ChevronUp className="mr-1" />
            ) : (
              <ChevronDown className="mr-1" />
            ))}
          {column.label}
        </div>
      ))}
    </div>
  );
}

export default TableHeader;
