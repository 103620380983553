import React, { useState } from "react";
import { useGetMetaQuery } from "services/api";
import { ArrowRepeat, ChevronDown } from "react-bootstrap-icons";
import "./TopBarStyle.css";

export const TypeExport = {
  WORD: "word",
  PDF: "pdf"
};

const ExportOptions = [
  {
    key: TypeExport.WORD,
    value: "Export to Word"
  },
  {
    key: TypeExport.PDF,
    value: "Export to PDF"
  }
];

export default function TopBarExportWord({
  onSuccess = () => {},
  onCancel = () => {},
  isFetching = false
}) {
  const { data: metadata } = useGetMetaQuery();
  const exportType = metadata.exportType;
  const allType = Object.values(exportType) || [];
  const SelectAll = "Select All";
  const [dropPriority, setDropPriority] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedType, setSelectedType] = useState(ExportOptions[0]);

  const handleCheck = event => {
    var updatedList = [...checked];

    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
      if (updatedList.length === allType.length) {
        setIsCheckAll(true);
      }
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
      setIsCheckAll(false);
    }

    setChecked(updatedList);
  };

  const handleCheckAll = event => {
    setIsCheckAll(event.target.checked);

    if (event.target.checked) {
      setChecked(allType);
    } else {
      setChecked([]);
    }
  };

  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
        return total + "," + item;
      })
    : "";

  const isDropPriority = dropPriority ? "is-active" : "";

  return (
    <div className="top-bar-export-word">
      <div className="checkbox-all">
        <input
          type="checkbox"
          value={isCheckAll}
          onChange={handleCheckAll}
          checked={isCheckAll}
          className="ChecklistItem_checkbox larger mr-3"
        />
        <span>{SelectAll}</span>
      </div>

      <div className="checkbox-group">
        {allType.map((item, index) => (
          <div key={index} className="checkbox-item">
            <input
              type="checkbox"
              value={item}
              onChange={handleCheck}
              checked={checked.includes(item)}
              className="ChecklistItem_checkbox larger mr-3"
            />
            <span>{item}</span>
          </div>
        ))}
      </div>

      <div className="buttons is-justify-content-end mt-5">
        <div
          className={`dropdown ${isDropPriority} button is-light select-filter`}
        >
          <div
            tabIndex="0"
            onClick={() => setDropPriority(!dropPriority)}
            onBlur={() => setDropPriority(false)}
            className="dropdown-trigger form-control form-select"
          >
            {selectedType.value}
            <ChevronDown className="ml-2" />
          </div>
          <div className="dropdown-menu" id="dropdown-menu3" role="menu">
            <div className="dropdown-content">
              {ExportOptions.map((item, i) => (
                <div
                  key={i}
                  onMouseDown={() => setSelectedType(item)}
                  className={`dropdown-item`}
                >
                  {item.value}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="spin-export">
          {isFetching && <ArrowRepeat className="is-spinning" />}
        </div>

        <button
          className="button is-info"
          type="submit"
          disabled={checked.length === 0 || isFetching}
          onClick={() => onSuccess(selectedType.key, checkedItems)}
        >
          Export
        </button>
        <button className="button is-light" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
}
