import { memo, useCallback, useState } from "react";
import { Slack } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import FeedbackSourceChannelIcon from "../FeedbackSourceChannelIcon";
import FeedbackBodyForm from "./FeedbackBodyForm";
import { UserPermissions } from "../../../config/userPermissions";
import useRbac from "../Rbac/useRbac";

const commentCountForDisplay = count => {
  if (count === 0) return <span className="showOnRowHover">(+) Reply</span>;
  if (count === 1) return "1 Reply";
  return `${count} Replies`;
};

function FeedbackBodyAndCommentCount({ cell, value, handleClick, edit, row }) {
  const addressed = row?.original?.addressed;
  const feedback = cell?.row?.original;
  const idx = cell?.row?.index;
  const [editing, setEditing] = useState(false);

  const { userHasPermission } = useRbac();

  const handleBodyClick = useCallback(
    evt => handleClick(evt, idx),
    [idx, handleClick]
  );

  const handleSubmit = data => {
    edit({ ...feedback, body: data.body });
    setEditing(false);
  };

  return (
    <div>
      {editing && userHasPermission(UserPermissions.EVALFEEDBACK_UPDATE) ? (
        <FeedbackBodyForm body={value} handleSubmit={handleSubmit} />
      ) : (
        <p
          onClick={() => (!addressed ? setEditing(true) : null)}
          className={addressed ? "item-completed" : ""}
        >
          {value}
        </p>
      )}
      <div className="is-flex is-justify-content-end is-align-items-center">
        <FeedbackSourceChannelIcon sourceChannel={feedback.sourceChannel} />
        <div className="is-size-7 ml-2 comment-count">
          <Link to={{}} onClick={!addressed ? handleBodyClick : null}>
            {commentCountForDisplay(feedback.commentCount)}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default memo(FeedbackBodyAndCommentCount);
