import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeletePocMutation,
  useGetFeedbacksQuery,
  useGetPocChecklistgroupsQuery,
  useGetPocChecklistitemsQuery,
  useGetPocQuestionAnswersQuery,
  useGetPocQuestionsQuery,
  useGetPocUseCasesQuery,
  useGetUseCasesQuery
} from "services/api";
import APIError from "components/common/APIError";
import PocTopBar from "components/TopBar/TopBarPoc";
import "./PocOverview.css";
import { setPoc } from "services/pocSlice";
import LayoutContainer from "../../components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import PocOverviewCards from "./PocOverviewCards";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";

const PocOverview = ({ poc, pocId }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const activePhase = useSelector(state => state.poc.phase);
  const activePoc = useSelector(state => state.poc);
  // const checklistByCategories = groupChecklist(checklistitems) || {};

  /*
   * Preloading entities to speed up other POC tabs
   */
  useGetFeedbacksQuery(pocId);
  useGetPocQuestionsQuery(pocId);
  useGetPocQuestionAnswersQuery(pocId);
  useGetPocChecklistgroupsQuery(pocId);
  useGetPocChecklistitemsQuery(pocId);
  useGetPocUseCasesQuery(pocId);
  useGetUseCasesQuery();

  useEffect(() => {
    if (
      (poc.id && !activePoc.id) ||
      (poc.id && activePoc.id !== params.pocId)
    ) {
      dispatch(setPoc(poc));
    }
  }, [activePoc, poc]);

  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar poc={poc} title={poc?.pocName} />
      </LayoutContainer>
      <LayoutContainer>
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>
        <PocOverviewCards poc={poc} />
      </LayoutContainer>
    </>
  );
};

export default PocOverview;
