import React, { useMemo, useState } from "react";
import "./Questions.css";
import NoData from "../NoData/index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { DashSquare, PlusSquare } from "react-bootstrap-icons";
import {
  NewAnswerColumns,
  NewAnswerColumnsEditMode
} from "../../../pages/PocQuestions/constants";
import useAuth from "../../../hooks/useAuth";
import useRbac from "../Rbac/useRbac";
import { UserPermissions } from "../../../config/userPermissions";
import * as _ from "lodash";

const rowsPerPageOptions = [10, 20, 50, 100];

/**
 * @param columns takes table header columns
 * @param questionData takes question data as array
 * @param onDragEndQuestions takes onDragEndQuestions function
 * @param filterKey takes filterKey as string
 * @param onDragEndAnswers takes onDragEndAnswers function
 * @param toggleId is takes answer id for managing answering states
 * @param selectedAnswersIds is takes selected answers ids for managing answering states
 * @param setSelectedAnswersIds is takes setSelectedAnswersIds function for managing answering states
 * @param freetextFields is takes freetextfields states
 * @param setFreeTextFields it updates freetextfields state
 */

const Questions = ({
  columns,
  questionData,
  onDragEndQuestions,
  filterKey,
  onDragEndAnswers,
  toggleId,
  selectedAnswersIds,
  setSelectedAnswersIds,
  freetextFields,
  setFreeTextFields
}) => {
  const { isProspect } = useAuth();
  const [showAllAnswer, setShowAllAnswer] = useState(false);
  const [sortBy, setSortBy] = useState({
    key: "",
    order: ""
  });
  const [data, setData] = useState([]);

  const { userHasPermission } = useRbac();

  useMemo(() => {
    if (questionData?.length) {
      const temp = _.cloneDeep(questionData);
      temp.forEach(item => {
        item.question["poc_question_id"] = item.id;
        item.question["poc_question_notes"] = item.notes;
      });

      setData(temp);
    }
  }, [questionData]);

  const sortTable = column => {
    let newSort = {
      key: column,
      order:
        sortBy.key === column
          ? sortBy.order === "desc"
            ? "asc"
            : "desc"
          : "asc"
    };
    setSortBy(newSort);
    setData(
      data.sort((a, b) => {
        if (newSort.order === "asc") {
          return a.question[column] > b.question[column] ? 1 : -1;
        } else {
          return a.question[column] < b.question[column] ? 1 : -1;
        }
      })
    );
  };

  return (
    <>
      {!data?.length ? (
        <NoData />
      ) : (
        <DragDropContext onDragEnd={onDragEndQuestions}>
          <div className="table-container">
            <TableHeader
              columns={columns}
              isMenuAvailable={true}
              sortTable={sortTable}
              sortBy={sortBy}
              icon={showAllAnswer ? <DashSquare /> : <PlusSquare />}
              iconOnClick={() => setShowAllAnswer(!showAllAnswer)}
            />
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data.map((row, i) => (
                    <Draggable
                      key={row.question.id}
                      draggableId={`${filterKey}-${row.question.id}`}
                      index={i}
                      isDragDisabled={
                        !userHasPermission(
                          UserPermissions.EVALQUESTIONNAIRE_REORDER
                        )
                      }
                    >
                      {provided => (
                        <TableBody
                          questionSnapshot={snapshot?.isDraggingOver}
                          provided={provided}
                          filterKey={filterKey}
                          onDragEndAnswers={onDragEndAnswers}
                          showAnswersInitial={showAllAnswer}
                          questionData={row.question}
                          answerData={row.question.answers}
                          toggleId={toggleId}
                          selectedAnswers={row.selectedAnswers}
                          selectedAnswersIds={selectedAnswersIds}
                          setSelectedAnswersIds={setSelectedAnswersIds}
                          answerColumns={
                            isProspect
                              ? NewAnswerColumns.filter(
                                  item => item.isProspectUser
                                )
                              : NewAnswerColumns
                          }
                          answerColumnsEditMode={NewAnswerColumnsEditMode}
                          isManageQuestion={false}
                          deleteId={row.id}
                          freetextFields={freetextFields}
                          setFreeTextFields={setFreeTextFields}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
    </>
  );
};

export default Questions;
