import React, { useState } from "react";
import LayoutContainer from "components/Container/LayoutContainer";
import Modal from "components/common/Modal/index";
import ChangeUserPasswordForm from "components/common/ChangeUserPasswordForm";

export default function UserProfile({ user = {} }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <LayoutContainer>
      <div className="is-relative box mt-4">
        <div className="is-size-4 is-flex is-justify-content-space-between">
          <div className="is-size-4">My Profile</div>
        </div>

        <section className="section">
          <h1 className="title is-size-4">Basic Information</h1>
          <div className="columns">
            <div className="column is-4">
              <label className="label">First Name</label>
              <div className="control">
                <input
                  name="title"
                  defaultValue={user.firstName}
                  className="input"
                  type="text"
                  placeholder="First Name"
                  disabled={true}
                />
              </div>
            </div>
            <div className="column is-4">
              <label className="label">Last Name</label>
              <div className="control">
                <input
                  name="title"
                  defaultValue={user.lastName}
                  className="input"
                  type="text"
                  placeholder="Last Name"
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-4">
              <label className="label">Email</label>
              <div className="control">
                <input
                  name="title"
                  defaultValue={user.email}
                  className="input"
                  type="text"
                  placeholder="Email"
                  disabled={true}
                />
              </div>
            </div>
            <div className="column is-4">
              <label className="label">Password</label>
              <div className="control">
                <button className="button" onClick={() => setIsModalOpen(true)}>
                  Change Password
                </button>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-4">
              <label className="label">Team Roles</label>
              <div className="control">
                <span className="tag ml-1">{user.roles.join(", ")}</span>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={`Change ${user.firstName}'s Password`}
        body={
          <ChangeUserPasswordForm
            user={user}
            onSuccess={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
          />
        }
        buttons={[]}
      />
    </LayoutContainer>
  );
}
