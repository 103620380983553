import { useState, useMemo, useEffect } from "react";
import Fuse from "fuse.js";

const SCORE_THRESHOLD = 0.4;

export default function useSearch({ dataSet, keys, initialSearchValue = "" }) {
  const [searchValue, setSearchValue] = useState("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      includeMatches: true,
      threshold: SCORE_THRESHOLD,
      ignoreLocation: true,
      //   distance: 100,
      keys
    };

    return new Fuse(dataSet, options);
  }, [dataSet, keys]);

  useEffect(() => {
    if (initialSearchValue) {
      setSearchValue(initialSearchValue);
    }
  }, [initialSearchValue]);

  const results = useMemo(() => {
    if (!searchValue) return dataSet;

    const searchResults = fuse.search(searchValue);

    return (
      searchResults
        //   .filter(fuseResult => fuseResult.score < SCORE_THRESHOLD)
        .map(fuseResult => fuseResult.item)
    );
  }, [fuse, searchValue, dataSet]);

  return {
    searchValue,
    setSearchValue,
    results
  };
}
