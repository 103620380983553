import axios from "axios";
import { baseUrl } from "utils/helpers";

const { vendorId } = localStorage?.user ? JSON.parse(localStorage?.user) : {};
axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage?.token}`;
axios.defaults.headers.common["x-tenant-id"] = vendorId;

export default axios;
