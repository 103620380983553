import { htmlSanitize } from "../../../utils/helpers";

const SanitizeHtml = ({ html, options = {} }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlSanitize(html, options)
      }}
    />
  );
};

export default SanitizeHtml;
