export const hasPermission = (userPermissions, oneOf) => {
  if (!userPermissions) {
    userPermissions = [];
  }

  if (typeof oneOf === "string") {
    oneOf = [oneOf];
  }

  const oneOfMap = {};
  oneOf.forEach(perm => {
    oneOfMap[perm] = true;
  });

  return userPermissions.some(permission => oneOfMap[permission]);
};
