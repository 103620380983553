import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dialog } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { ArrowRepeat, CircleFill } from "react-bootstrap-icons";
import dayjs from "dayjs";
import Stepper from "components/common/Stepper";
import { PHASES } from "../PocUseCases/constants";
import UserAvatar from "components/common/UserAvatar";
import { useGetMetaQuery } from "services/api";

import {
  useGetPocUseCasesQuery,
  useImportPocUseCaseMutation
} from "services/api";
import useAuth from "hooks/useAuth";

export default function PocUseCaseHeader({
  poc,
  setReloadMatrix,
  templates,
  templateList,
  mode,
  onImportRequirement
}) {
  const { pocId } = useParams();
  const { isProspect } = useAuth();
  const { metaData } = useGetMetaQuery();
  const [agreedStakeholders, setAgreedStakeholders] = useState([]);
  const [pendingStakeholders, setPendingStakeholders] = useState([]);

  useEffect(() => {
    const activeInvitedStakeholders = poc?.stakeholders?.filter(
      item =>
        item?.status !== metaData?.userStatuses?.INACTIVE &&
        poc?.scopingApproverUserIds.includes(item.userId)
    );

    if (poc?.createdByUser && agreedStakeholders.length <= 0)
      setAgreedStakeholders([poc?.createdByUser]);

    if (poc?.scopingPhase !== PHASES.AGREED) {
      setAgreedStakeholders([poc?.createdByUser]);
      setPendingStakeholders(activeInvitedStakeholders);
    } else {
      setPendingStakeholders([]);
      setAgreedStakeholders(() => [
        ...[poc?.createdByUser],
        ...activeInvitedStakeholders
      ]);
    }
  }, [poc]);

  const {
    data: pocUseCases,
    isLoading,
    isFetching
  } = useGetPocUseCasesQuery(pocId);

  const [importTemplate, { isLoading: importLoading }] =
    useImportPocUseCaseMutation();

  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const startImportTemplate = () => {
    setIsOpen(false);
    importTemplate({ pocId, template: selectedTemplate });
    toast.success(`Template "${selectedTemplate}" was imported`);
    setReloadMatrix(true);
  };

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate === "-" || selectedTemplate === "") {
        toast.warning("Please select one of the available templates!");
      } else {
        if (pocUseCases.length) {
          setIsOpen(true);
        } else {
          startImportTemplate();
        }
      }
    }
  }, [selectedTemplate]);

  const recommendedKey =
    templates?.find(t => t === "Recommended") || "Recommended";

  return (
    <div className="is-flex is-align-items-center is-justify-content-space-between mb-4">
      {mode === "list" ? (
        <div className="column is-3">
          <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
            <span className="is-capitalized is-size-4">
              {isLoading ||
                isFetching ||
                (importLoading && <ArrowRepeat className="is-spinning" />)}
            </span>
            {poc?.scopingPhase === PHASES.DRAFT && !isProspect && (
              <button
                className="button is-info"
                onClick={() => onImportRequirement()}
              >
                Import Requirement
              </button>
            )}
          </div>
        </div>
      ) : null}

      <div className="column is-6">
        {poc?.scopingPhase && (
          <Stepper
            phases={PHASES}
            phase={poc?.scopingPhase}
            mainClassName={"poc-phase-steps"}
          />
        )}
      </div>

      <div className="column is-3">
        {!isProspect && poc?.scopingPhase == PHASES.DRAFT && templates ? (
          <span className="is-flex is-justify-content-flex-end">
            <div className="select is-small">
              <select
                onChange={e => setSelectedTemplate(e.target.value)}
                className="form-control form-select"
              >
                <option>Import a template</option>
                <option
                  disabled={templateList && !templateList[recommendedKey]}
                  value="Recommended"
                >
                  Recommended (
                  {(templateList && templateList[recommendedKey]) || "-"})
                </option>
                {templates.map((t, i) =>
                  t !== "Recommended" ? (
                    <option value={t} key={i}>
                      {t} {`(${templateList[t]})`}
                    </option>
                  ) : null
                )}
              </select>
            </div>
          </span>
        ) : null}

        {poc?.scopingPhase && poc?.scopingPhase !== PHASES.DRAFT ? (
          <div className="is-flex is-size-7 is-align-items-flex-start is-justify-content-flex-end">
            {pendingStakeholders?.length > 0 && (
              <div className="is-flex is-align-items-center is-justify-content-space-around mr-6">
                <label className="is-flex is-align-items-center mr-2">
                  <CircleFill
                    className="mr-1"
                    style={{ color: "#d4a62e", width: "10px" }}
                  />
                  <span>PENDING: </span>
                </label>
                <ul className="is-flex is-justify-content-space-around list-none">
                  {pendingStakeholders?.map(user => (
                    <li key={user?.userId || user?.id} className="is-rounded">
                      <UserAvatar user={user} showName={false} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-flex-start is-justify-content-space-around">
              <div className="is-flex is-flex-direction-row">
                <label className="is-flex is-align-items-center mr-2">
                  <CircleFill
                    className="mr-1"
                    style={{ color: "#2ed47a", width: "10px" }}
                  />
                  <span>AGREED: </span>
                </label>
                <ul className="is-flex is-justify-content-space-around list-none">
                  {agreedStakeholders.map(user => (
                    <li key={user?.userId || user?.id} className="is-rounded">
                      <UserAvatar user={user} showName={false} />
                    </li>
                  ))}
                </ul>
              </div>
              {poc?.puScopingAgreedAt && (
                <div className="is-flex is-flex-direction-column">
                  <span className="mr-2">
                    {
                      (agreedStakeholders || []).find(
                        i =>
                          (i?.userId || i?.id) ===
                          poc?.puScopingAgreedMarkedByUserId
                      )?.fullName
                    }
                  </span>
                  <span>
                    {dayjs(poc?.puScopingAgreedAt).format("DD MMM YYYY h:mm A")}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      {confirmationDialog({
        isOpen,
        setIsOpen,
        selectedTemplate,
        page: "Requirements",
        startImportTemplate
      })}
    </div>
  );
}

const confirmationDialog = ({
  isOpen,
  setIsOpen,
  selectedTemplate,
  page = "",
  startImportTemplate
}) => {
  return (
    <Dialog
      as="div"
      open={isOpen}
      className="is-fixed inset-0 modal is-flex is-align-items-center is-justify-content-center"
      onClose={() => setIsOpen(false)}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
      <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
        <div className="mx-auto has-text-danger-dark">
          Importing ({selectedTemplate}) will overwrite your existing {page}!
        </div>
        <div className="mt-5 is-flex is-justify-content-center">
          Click OK to continue!
        </div>
        <div className="buttons mt-5 is-justify-content-end">
          <button onClick={() => setIsOpen(false)} className="button is-light">
            CANCEL
          </button>
          <button onClick={startImportTemplate} className="button is-info w-6r">
            OK
          </button>
        </div>
      </div>
    </Dialog>
  );
};
