import { invertColor } from "utils/helpers";

export default function UserAvatar({ user, showName = true }) {
  if (!user) return "--";

  const userImgUrl = `https://imagecdn.app/v1/images/${encodeURI(
    user.profilePic
  )}?height=40&width=40`;

  return (
    <p
      className="user-avatar is-flex is-align-items-center"
      title={user.fullName}
    >
      {user.profilePic ? (
        <img
          src={userImgUrl}
          alt={`${user?.fullName}`}
          className="is-rounded mr-2"
        />
      ) : (
        <span
          style={{
            backgroundColor: user.color,
            color: "white"
          }}
        >
          {user.initials}
        </span>
      )}

      {showName && user.fullName}
    </p>
  );
}
