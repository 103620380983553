import "./Button.css";

const Button = ({ disabled, label, children, onClick, className }) => (
  <button
    disabled={disabled}
    className={`button ${className || ""}`}
    onClick={onClick}
  >
    {label || children}
  </button>
);

export default Button;
