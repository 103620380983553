import { React } from "react";
import { ToastContainer } from "react-toastify";
import SignInForm from "pages/SignInForm";
import { useGetMetaQuery } from "services/api";
import useAuth from "hooks/useAuth";
import GoogleAnalyticsTracker from "utils/GoogleAnalyticsTracker";
import AutoLogin from "pages/SignInForm/AutoLogin";
import AppLoggedIn from "./AppLoggedIn";
import TopBar from "components/TopBar/TopBar";
import HotJarTracker from "utils/HotJarTracker";

const App = () => {
  // eagerly load this data for UI improvement,
  // since so many pages need it and the data
  // rarely changes.
  useGetMetaQuery();
  const { currentUser } = useAuth();

  GoogleAnalyticsTracker();
  HotJarTracker();

  const pathname = window.location.pathname;
  if (pathname.includes("autologin")) {
    const spliced = pathname.split("/");
    if (spliced[1] === "autologin" && spliced[2]) {
      return <AutoLogin magicToken={spliced[2]} />;
    }
  }

  if (!currentUser) return <SignInForm />;

  return (
    <>
      <main className="h-100">
        {!currentUser ? (
          <SignInForm />
        ) : (
          <>
            <ToastContainer />
            <TopBar />
            <div className="min-h-screen m-0">
              <div id="mainContent">
                <AppLoggedIn />
              </div>
            </div>
          </>
        )}
      </main>
      <footer className="my-5">
        <br />
      </footer>
    </>
  );
};

export default App;
