import { useGetAnalyticsQuery, useGetMetaQuery } from "services/api";
import Loading from "components/common/Loading";
import APIError from "components/common/APIError";
import { intToDollars } from "utils/helpers";
import { Link, useLocation } from "react-router-dom";
import AnalyticsMenu from "./AnalyticsMenu";
import usePocs from "hooks/usePocs";
import { useMemo } from "react";
import { subtractDate } from "utils/helpers";
import "./AnalyticsCard.css";
import { useState } from "react";
import { cloneDeep } from "lodash";
import { sortBy } from "../../utils/helpers";
import PocOutcomeIcon from "components/common/PocOutcomeIcon";
import PocPaidIcon from "components/common/PocPaidIcon";

let pocsByPhase = {};
let pocsByPhaseData = {};

const typeSort = {
  pocName: "pocName",
  totalACV: "totalAcv",
  averageDays: "daySinceStart"
};

const Analytics = () => {
  const { isLoading, data, error } = useGetAnalyticsQuery();
  const { userPocs, isLoading: pocIsLoading, error: pocError } = usePocs();
  const { data: metadata } = useGetMetaQuery();
  const location = useLocation();
  const [desc, setDesc] = useState(false);

  let phasesToDisplay = [
    metadata?.pocPhases?.SCOPING,
    metadata?.pocPhases?.EXECUTION,
    metadata?.pocPhases?.ON_HOLD,
    metadata?.pocPhases?.COMPLETED
  ];

  useMemo(() => {
    pocsByPhase = {};
    pocsByPhaseData = {};

    phasesToDisplay.map(phase => {
      if (!pocsByPhase[phase]) {
        pocsByPhase[phase] = [];
      }

      if (!pocsByPhaseData[phase]) {
        pocsByPhaseData[phase] = {};
        pocsByPhaseData[phase]["totalACV"] = 0;
        pocsByPhaseData[phase]["averageDays"] = 0;
      }
    });

    userPocs.map(item => {
      if (
        phasesToDisplay.length &&
        phasesToDisplay.indexOf(item.phase) !== -1
      ) {
        pocsByPhase[item.phase].push({
          ...item,
          daySinceStart: !!item.startDateActual
            ? subtractDate(item.startDateActual)
            : null
        });
      }
    });

    for (const [key, value] of Object.entries(pocsByPhase)) {
      pocsByPhaseData[key]["totalACV"] = intToDollars(
        (pocsByPhase[key] || []).reduce(
          (total, value) => total + (value?.totalAcv || 0),
          0
        )
      );

      let dateLength = 0;
      let totalDay = 0;

      pocsByPhase[key].forEach(element => {
        if (element.startDateActual) {
          dateLength++;
          totalDay += element.daySinceStart;
        }
      });

      if (dateLength) {
        pocsByPhaseData[key]["averageDays"] = Math.round(totalDay / dateLength);
      } else {
        pocsByPhaseData[key]["averageDays"] = 0;
      }
    }
  }, [userPocs, metadata]);

  const sortColumn = (phase, type) => {
    const temp = cloneDeep(pocsByPhase);
    setDesc(!desc);

    temp[phase] = sortBy(pocsByPhase[phase], type, desc ? "DESC" : "ASC");

    pocsByPhase = temp;
  };

  if (isLoading) return <Loading />;
  if (error) return <APIError message={error} />;

  return (
    <>
      <AnalyticsMenu />
      <div className="card analytics-overview">
        <div className="card-content">
          <div className="columns">
            {Object.keys(pocsByPhase).map(phase => (
              <div className="column is-fifth" key={phase}>
                <nav className="panel">
                  <div className="panel-heading">
                    <p className="title-analytics">
                      {phase} ({pocsByPhase[phase].length})
                    </p>

                    <p className="item-analytics">
                      Total ACV:{" "}
                      <span className="number-a">
                        {pocsByPhaseData[phase]["totalACV"]}
                      </span>
                    </p>
                    <p className="item-analytics">
                      Avg Days since start:{" "}
                      <span className="number-a">
                        {pocsByPhaseData[phase]["averageDays"]} days
                      </span>
                    </p>
                  </div>

                  <div className="panel-block-header">
                    <div className="head-ao poc">
                      <span
                        className="head-ao day"
                        onClick={() => sortColumn(phase, typeSort.pocName)}
                      >
                        Opp
                      </span>
                    </div>
                    <span
                      className="head-ao day"
                      onClick={() => sortColumn(phase, typeSort.averageDays)}
                    >
                      Avg Days
                    </span>
                    <span
                      className="head-ao total"
                      onClick={() => sortColumn(phase, typeSort.totalACV)}
                    >
                      Total ACV
                    </span>
                  </div>

                  {pocsByPhase[phase].map(poc => (
                    <div className="panel-block" key={poc.id}>
                      <p className="control is-flex is-align-items-center is-justify-content-space-between">
                        <span className="poc-name">
                          <Link
                            to={`/pocs/${poc.id}/overview`}
                            className="is-clipped is-flex"
                            title={poc.pocName}
                          >
                            {poc.pocName}
                            <PocPaidIcon poc={poc} />
                            <PocOutcomeIcon poc={poc} />
                          </Link>
                        </span>

                        <span className="number-day">
                          {poc.daySinceStart !== null
                            ? poc?.daySinceStart
                            : "-"}
                        </span>

                        <span className="tag is-light">
                          {intToDollars(poc.totalAcv)}
                        </span>
                      </p>
                    </div>
                  ))}
                </nav>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
