/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  ChevronDown,
  Grid3x2,
  LayoutThreeColumns,
  List,
  Plus
} from "react-bootstrap-icons";
import { toast } from "react-toastify";

import {
  useEditPocMutation,
  useGetPocUseCasesQuery,
  useAddPocUseCasesAndSuccessCriteriaMutation,
  useReorderPocUsecasesMutation,
  useApprovePocUsecasesMutation,
  useRevisePocUsecasesMutation,
  useDeletePocUseCasesMutation,
  useGetUsecaseTemplateListQuery
} from "services/api";
import GettingStarted from "components/common/GettingStarted";
import Loading from "components/common/Loading";
import APIError from "components/common/APIError";
import PocTopBar from "components/TopBar/TopBarPoc";
import TextEditor from "components/common/TextEditor";
import PocUseCasesBulkAttach from "./PocUseCasesBulkAttach";
import UseCaseFilter from "./PocUseCaseFilter";
import UseCaseHeader from "./PocUseCaseHeader";
import UseCaseMatrix from "./PocUseCaseMatrix";
import "./PocUseCases.css";
import { PHASES } from "./constants";
import { reorderListAfterDragEnd } from "utils/helpers";
import PocUseCaseTableMode from "./PocUseCaseTableMode";
import useAuth from "hooks/useAuth";
import LayoutContainer from "../../components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import usePocs from "hooks/usePocs";
import { debounce } from "lodash";
import ApiCallIndicator from "components/common/ApiCallIndicator";
import useFeatureFilter from "hooks/useFeatureFilter";
import SendApprovalDialog from "./SendApprovalDialog";
import UserAvatar from "components/common/UserAvatar";
import UsecaseCardList from "./UsecaseCardList";
import WithRbac from "../../components/common/Rbac/withRbac";
import useRbac from "../../components/common/Rbac/useRbac";
import { UserPermissions } from "../../config/userPermissions";
import SearchCreateRequirement from "./SearchCreateRequirement";
import Modal from "components/common/Modal";
import EditUsecaseForm from "../../components/common/EditUsecaseForm/index";

const PocUseCases = ({ pocId }) => {
  const { currentPoc: poc } = usePocs();
  const editorRef = useRef();
  const [editPoc, editPocResponse] = useEditPocMutation();
  const { data: pocUseCases, error, isLoading } = useGetPocUseCasesQuery(pocId);
  const { error: batchAddError } = useAddPocUseCasesAndSuccessCriteriaMutation({
    fixedCacheKey: `batch-add-pocUseCases-${pocId}`
  })[1];
  const [deleteItem, { isLoading: isDeleteItemLoading, error: deleteError }] =
    useDeletePocUseCasesMutation();

  const [businessValues, setBusinessValue] = useState([]);
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState(null);
  const [currentEdit, setCurrentEdit] = useState();
  const [mode, setMode] = useState("list");
  const [reloadMatrix, setReloadMatrix] = useState(false);
  const [filter, setFilter] = useState({});
  const [dropProduct, setDropProduct] = useState(false);
  const [scopingPhase, setScopingPhase] = useState(poc?.scopingPhase);
  const [showSearchUsecases, setShowSearchUsecases] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemBeingDeleted, setItemBeingDeleted] = useState(null);

  const { currentUser, isProspect, isSalesEngineer, isAdmin } = useAuth();
  const { userHasPermission } = useRbac();

  const { data: templateList } = useGetUsecaseTemplateListQuery(
    currentUser.vendorId
  );
  const [reorderPocUsecases, { isLoading: isOrderLoading }] =
    useReorderPocUsecasesMutation();

  const [approvePocUsecases, { isLoading: isAgreeLoading }] =
    useApprovePocUsecasesMutation();

  const [revisePocUsecases, { isLoading: isReviseLoading }] =
    useRevisePocUsecasesMutation();

  const {
    showUsecaseDisplayModes,
    showUsecaseLineOfProduct,
    showSearchFilters,
    showExtraFeatures,
    showVcDemoFeatures
  } = useFeatureFilter();

  const templates = templateList ? Object.keys(templateList) : [];

  const handleOpenEditModal = item => {
    setIsEditModalOpen(true);
    setItemBeingEdited(item);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    // Making sure that the item being edited is reset
    setItemBeingEdited({});
  };

  const handleOpenDeleteModal = item => {
    setIsDeleteModalOpen(true);
    setItemBeingDeleted(item);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    // Making sure that the item being edited is reset
    setItemBeingDeleted({});
  };

  const onDeleteItem = () => {
    deleteItem({ id: itemBeingDeleted.id })
      .unwrap()
      .then(() => {
        toast.success(`Deleted!`);
        setItemBeingDeleted({});
        setIsDeleteModalOpen(false);
      })
      .catch(error => toast.error(`Error: ${error.message}`));
  };

  const onSelectProduct = template => {
    editPoc({ id: poc.id, usecaseAssignedTemplate: template });
  };

  const saveNotes = current => {
    if (!!current?.getContent() || current?.getContent() === "") {
      editPoc({ id: poc.id, scopingNotes: current?.getContent() });
    }
  };

  const setShowAdd = () => {
    setShow(true);
    setShowType("add");
  };

  const handleClose = () => {
    setShow(false);
    setShowType(null);
  };

  const [filteredPocUsecases, setFilteredPocUsecases] = useState([]);
  const [filteredDiscovery, setFilteredDiscovery] = useState([]);
  useEffect(() => {
    if (pocUseCases)
      setFilteredPocUsecases(pocUseCases.filter(({ usecase }) => usecase));
  }, [pocUseCases]);

  useEffect(() => {
    if (!scopingPhase && poc) setScopingPhase(poc?.scopingPhase);
  }, [poc]);

  useEffect(() => {
    if (pocUseCases && filteredPocUsecases)
      setFilteredDiscovery(pocUseCases ? [...filteredPocUsecases] : []);
  }, [pocUseCases, filteredPocUsecases]);

  const isDropProduct = dropProduct ? "is-active" : "";

  const onDragEndPocUsecase = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderListAfterDragEnd(
      filteredDiscovery,
      result.source.index,
      result.destination.index
    );

    setFilteredDiscovery(items);

    reorderPocUsecases({
      pocId: pocId,
      ids: items.map(item => item.id)
    });
  };

  const onAgreeUsecase = () => {
    approvePocUsecases({ pocId: pocId })
      .unwrap()
      .then(data => toast.success(`Approval recorded`))
      .catch(error => toast.error(`Error: ${error.data.message}`));
  };

  const onReviseUsecase = () => {
    revisePocUsecases({ pocId: pocId })
      .unwrap()
      .catch(error => toast.error(`Error: ${error.data.message}`));
  };

  const SalesEngineerBtn = () => {
    if ([PHASES.PENDING, PHASES.AGREED].indexOf(poc?.scopingPhase) !== -1) {
      return (
        <button
          disabled={isReviseLoading}
          className={`button`}
          onClick={() => onReviseUsecase()}
        >
          Revise Scope
        </button>
      );
    } else if (
      filteredDiscovery.length > 0 &&
      poc?.scopingPhase === PHASES.DRAFT
    ) {
      return (
        <div>
          <SendApprovalDialog poc={poc} />
          <button className={`button is-info`} onClick={() => onAgreeUsecase()}>
            Agreed
          </button>
        </div>
      );
    }
    return null;
  };

  const getCustomerEmailUrl = () => {
    let body = `Hi [Prospect Name]
It was a pleasure chatting with you earlier, we enjoyed learning about [Company Name].  Based on our conversation, the key areas of focus for you are:

- ${filteredDiscovery.map(item => `${item.usecase?.body}`).join("\r\n- ")}

Feel free to add anything I missed by clicking on the button below!

[AGREE/REVIEW BUTTON]

Thanks,
`;
    let url = `mailto:XX@xx.com?subject=Requirements&body=${encodeURIComponent(
      body
    )}`;
    return url;
  };

  const SalesEngineerBtnEmail = () => {
    if (filteredDiscovery.length > 0 && poc?.scopingPhase === PHASES.DRAFT) {
      return (
        <a className={`button`} target="_blank" href={getCustomerEmailUrl()}>
          Email Customer
        </a>
      );
    }
    return null;
  };

  const ProspectUserBtn = () => {
    if (
      poc?.scopingPhase === PHASES.PENDING &&
      poc.scopingApproverUserIds.includes(currentUser.id)
    ) {
      return (
        <>
          <button
            disabled={isAgreeLoading}
            className={`button is-info`}
            onClick={() => onAgreeUsecase()}
          >
            <UserAvatar user={currentUser} showName={false} />
            <span>Scope looks good, I agree!</span>
          </button>
          <button
            disabled={isReviseLoading}
            className={`button ml-2`}
            onClick={() => onReviseUsecase()}
          >
            Revise Scope
          </button>
        </>
      );
    }
    return null;
  };

  const UsecaseListMode = () => {
    if (isLoading) {
      return (
        <section className="is-flex is-align-items-center is-justify-content-center">
          <Loading />
        </section>
      );
    }

    if (filteredPocUsecases?.length > 0 || showSearchUsecases) {
      return (
        <div className="mt-6 mx-5">
          <UsecaseCardList
            poc={poc}
            filteredDiscovery={filteredDiscovery}
            onOpenEditModal={handleOpenEditModal}
            onOpenDeleteModal={handleOpenDeleteModal}
            onDragEnd={onDragEndPocUsecase}
          />
          {showSearchUsecases && (
            <SearchCreateRequirement
              poc={poc}
              setShowSearchUsecases={setShowSearchUsecases}
              onBrowseAll={() => {
                setShowAdd();
              }}
            />
          )}
        </div>
      );
    } else {
      return !isProspect ? (
        <section className="PocUseCases-container drop-shadow">
          <div className="PocUseCases p-5">
            <GettingStarted
              message="Start by Importing a New Requirement"
              buttonText="IMPORT REQUIREMENT"
              setShow={setShowAdd}
            />
          </div>
        </section>
      ) : (
        <></>
      );
    }
  };

  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar poc={poc} showScore={true} title={poc?.pocName} />
      </LayoutContainer>
      <LayoutContainer>
        <APIError error={error || batchAddError || deleteError} />
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>
        <section className="card">
          <div className="card-content">
            <div className="is-flex is-justify-content-space-between is-size-3">
              <div className="is-flex is-align-items-center">
                {showUsecaseLineOfProduct ? (
                  <>
                    <h1 className="w-100">Discovery for</h1>
                    <div className={` dropdown ${isDropProduct} w-auto ml-4`}>
                      <div
                        tabIndex="0"
                        onClick={() => setDropProduct(!dropProduct)}
                        onBlur={() => setDropProduct(false)}
                        className={`${
                          isProspect
                            ? "text-center"
                            : "dropdown-trigger is-clickable"
                        } px-2 whitespace-nowrap is-flex is-align-items-center`}
                      >
                        <button className="button">
                          <span>
                            {poc.usecaseAssignedTemplate
                              ? poc.usecaseAssignedTemplate
                              : "All Products"}
                          </span>
                          <span className="icon is-small">
                            <ChevronDown />
                          </span>
                        </button>
                      </div>
                      {!isProspect && (
                        <div
                          className="dropdown-menu py-0"
                          id="dropdown-menu3"
                          role="menu"
                        >
                          <div className="dropdown-content">
                            <a
                              href="#"
                              onMouseDown={() => onSelectProduct(null)}
                              className="dropdown-item is-clickable"
                            >
                              All Products
                            </a>
                            {templates?.length
                              ? templates.map((t, i) => (
                                  <a
                                    href="#"
                                    key={i}
                                    onMouseDown={() => onSelectProduct(t)}
                                    className={`dropdown-item is-clickable ${
                                      poc.usecaseAssignedTemplate === t
                                        ? "has-text-info"
                                        : ""
                                    }`}
                                  >
                                    {t}
                                  </a>
                                ))
                              : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="is-flex gap-3">
                {showUsecaseDisplayModes ? (
                  <div className="buttons has-addons">
                    <button
                      className={`button ${
                        mode === "list" ? "is-active is-dark" : ""
                      }`}
                      onClick={() => setMode("list")}
                    >
                      <List />
                    </button>
                    <button
                      className={`button ${
                        mode === "matrix" ? "is-active is-dark" : ""
                      }`}
                      onClick={() => setMode("matrix")}
                    >
                      <Grid3x2 />
                    </button>
                    <button
                      className={`button ${
                        mode === "table" ? "is-active is-dark" : ""
                      }`}
                      onClick={() => setMode("table")}
                    >
                      <LayoutThreeColumns className="w-1r" />
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <UseCaseHeader
              poc={poc}
              setReloadMatrix={setReloadMatrix}
              templates={mode === "list" ? templates : null}
              templateList={templateList}
              mode={mode}
              onImportRequirement={setShowAdd}
            />
            {mode === "list" && showSearchFilters && (
              <UseCaseFilter filter={filter} setFilter={setFilter} />
            )}

            {mode === "matrix" ? (
              <UseCaseMatrix
                pocUseCases={filteredPocUsecases}
                poc={poc}
                reload={reloadMatrix}
                setReload={setReloadMatrix}
                setShow={setShowAdd}
                businessValues={businessValues}
                setBusinessValue={setBusinessValue}
              />
            ) : null}

            {mode === "list" ? <UsecaseListMode /> : null}

            <div className="mx-5 mt-5 is-flex">
              <div className="is-size-12 column is-4 pl-0">
                {userHasPermission(UserPermissions.EVALSCOPING_CREATE) &&
                  poc?.scopingPhase === PHASES.DRAFT &&
                  !showSearchUsecases && (
                    <button
                      className={`button is-white`}
                      onClick={() => setShowSearchUsecases(true)}
                    >
                      <span className="icon">
                        <Plus className="has-text-info is-size-3" />
                      </span>
                      <span>Create new Requirement</span>
                    </button>
                  )}
              </div>

              <div className="is-size-12 column is-48 is-flex is-justify-content-flex-end pr-0">
                <div className="buttons">
                  {(isSalesEngineer || isAdmin) && showVcDemoFeatures && (
                    <SalesEngineerBtnEmail />
                  )}
                  {(isSalesEngineer || isAdmin) && <SalesEngineerBtn />}
                  {isProspect && <ProspectUserBtn />}
                </div>
              </div>
            </div>

            {mode === "table" ? (
              <PocUseCaseTableMode
                pocUseCases={filteredPocUsecases}
                poc={poc}
                data={filteredPocUsecases}
                businessValues={businessValues}
                reload={reloadMatrix}
                setReload={setReloadMatrix}
                setBusinessValue={setBusinessValue}
                setShow={setShow}
                setShowType={setShowType}
              />
            ) : null}
          </div>
        </section>

        {showExtraFeatures && (
          <>
            <h1 className="is-size-5 mt-5 mb-2">
              Discovery Notes <ApiCallIndicator apiResponse={editPocResponse} />
            </h1>
            <TextEditor
              ref={editorRef}
              defaultValue={poc.scopingNotes}
              placeholder="Take some shared Discovery notes here!"
              onChange={debounce(() => saveNotes(editorRef?.current), 1000)}
              className="mb-4"
            />
          </>
        )}

        <Dialog
          as="div"
          open={show}
          className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
          onClose={handleClose}
        >
          <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
          <PocUseCasesBulkAttach
            show={show}
            showType={showType}
            currentEdit={currentEdit}
            templatesData={mode === "list" ? templates : null}
            setShow={setShow}
            handleClose={handleClose}
          />
        </Dialog>
      </LayoutContainer>

      <Modal
        isOpen={isEditModalOpen}
        setIsOpen={handleCloseEditModal}
        title={
          itemBeingEdited.id
            ? `Editing Requirement "${itemBeingEdited?.body}"`
            : "Add New Requirement"
        }
        body={
          <EditUsecaseForm
            usecase={itemBeingEdited}
            onSuccess={() => {
              setItemBeingEdited({});
              setIsEditModalOpen(false);
            }}
            onCancel={() => {
              setItemBeingEdited({});
              setIsEditModalOpen(false);
            }}
          />
        }
      />
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={handleCloseDeleteModal}
        title={`Deleting Requirement "${itemBeingDeleted?.usecase?.body}"?`}
        body="You're about to permanently delete this requirement, and its data."
        buttons={[
          <button
            onClick={() => onDeleteItem()}
            className={`button is-danger ${
              isDeleteItemLoading ? "is-loading" : ""
            }`}
          >
            Delete
          </button>,
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="button is-light"
          >
            Cancel
          </button>
        ]}
      />
    </>
  );
};

export default PocUseCases;
