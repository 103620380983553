import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Table from "components/common/Table";
import { ArrowRepeat } from "react-bootstrap-icons";
import { columns as allColumns, sectionHeader } from "./constants";
import "./Attachments.css";
import Loading from "components/common/Loading";
import APIError from "components/common/APIError";
import Modal from "components/common/Modal/index";
import SearchBox from "../../components/common/SearchBox/index";
import { NoDataBlock } from "components/common/NoData";

export default function AttachmentCard({
  data,
  addToSection = null,
  handleClose = null,
  onDelete = () => {},
  onDrop,
  isLoading,
  isFetching,
  isError,
  error
}) {
  const [files, setFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(false);

  const {
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    open
  } = useDropzone({
    onDrop: params => {
      onDrop(params);
      setIsModalOpen(false);
    }
  });

  const displayData = useMemo(() => {
    return searchValue ? filteredData : data;
  }, [filteredData, data]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "25vh",
    justifyContent: "center",
    cursor: "pointer"
  };

  const focusedStyle = {
    borderColor: "#2196f3"
  };

  const acceptStyle = {
    borderColor: "#00e676"
  };

  const rejectStyle = {
    borderColor: "#ff1744"
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const columns = useMemo(
    () =>
      addToSection
        ? allColumns.filter(({ Header }) => sectionHeader.includes(Header))
        : allColumns,
    [addToSection]
  );

  return (
    <div className="card mt-4">
      <div className="card-content">
        {isError && <APIError error={error} />}
        <div className="table-container w-full">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <button
                  className="button is-info"
                  onClick={() => setIsModalOpen(true)}
                >
                  + Upload
                </button>
              </div>
              <div className="level-item">
                {isFetching && <ArrowRepeat className="is-spinning" />}
              </div>
            </div>

            <div className="level-right">
              <div className="level-item">
                <SearchBox
                  dataSet={data}
                  keys={["fileName", "mimeType"]}
                  setDataSet={setFilteredData}
                  setLiftedSearchValue={setSearchValue}
                />
              </div>
            </div>
          </nav>
          <div className="mt-5 h-auto min-h-50screen max-h-screen overflow-auto">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {displayData.length ? (
                  <Table
                    className="Attachment-table"
                    data={displayData}
                    columns={columns}
                    extraCellInfo={{
                      disabled: true,
                      onDelete,
                      addToSection,
                      files,
                      setFiles
                    }}
                  />
                ) : (
                  <NoDataBlock
                    message={
                      searchValue ? "No items match your search" : "No items"
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
        {addToSection && (
          <div className="buttons is-justify-content-end">
            <button
              onClick={() => {
                addToSection(files);
                handleClose();
              }}
              className="button is-info"
            >
              Insert Selected Files
            </button>
            <button
              onClick={() => {
                handleClose();
              }}
              className="button is-light"
            >
              Cancel
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={"Upload Files"}
        body={
          <div {...getRootProps({ className: "dropzone", style })}>
            <input {...getInputProps()} />
            <p>
              Drag & Drop <br />
              or click to browse
            </p>
          </div>
        }
        buttons={[
          <button
            onClick={() => setIsModalOpen(false)}
            className="button is-light"
          >
            Close
          </button>
        ]}
      />
    </div>
  );
}
