import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Dialog, Menu } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  ChevronDown,
  ChevronRight,
  ExclamationDiamond,
  ThreeDotsVertical
} from "react-bootstrap-icons";

import ChecklistItem from "./ChecklistItem";
import CreateChecklistItem from "./CreateChecklistItem";
import { debounce } from "lodash";
import { findCollectionItemMaxWeight } from "utils/helpers";
import useFeatureFilter from "hooks/useFeatureFilter";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";

const AddSection = ({ index, itemWeightValue = 0, add = () => {} }) => {
  const [hoverSection, setHoverSection] = useState(null);
  const [isAdd, setIsAdd] = useState(null);
  const handleClick = () => {
    setIsAdd(index);
  };
  const handleAddGroup = e => {
    if (e.key === "Enter") {
      add({ index, body: e.target.value, weight: itemWeightValue });
      setIsAdd(null);
    }
    if (e.key === "Escape") {
      setIsAdd(null);
    }
  };

  return isAdd === index ? (
    <div className="box my-3">
      <input
        autoFocus
        className="input w-20r ml-6"
        onKeyDown={e => handleAddGroup(e)}
        onBlur={() => setIsAdd(null)}
        placeholder="New section name"
      />
    </div>
  ) : (
    <div
      className={`is-clickable`}
      // style={{ border: "1px solid #000" }}
      onMouseOver={() => setHoverSection(index)}
      onMouseLeave={() => setHoverSection(null)}
      onClick={handleClick}
    >
      <div
        className={`separator ${hoverSection !== null ? "" : "is-invisible"}`}
      >
        <span className="is-small" data-weight={itemWeightValue}>
          Add section
        </span>
      </div>
    </div>
  );
};

export default function ChecklistGroup({
  grouped = [],
  ungrouped = [],
  items = [],
  onDragEnd,
  addGroup = () => {},
  editGroup = () => {},
  removeGroup = () => {},
  addItem = () => {},
  editItem = () => {},
  removeItem = () => {}
}) {
  const { pocId } = useParams();
  const [titleEditable, setTitleEditable] = useState(null);
  const [disclosure, setDisclosure] = useState([]);
  const [delGroup, setDelGroup] = useState(null);
  const dsc = useMemo(() => [...disclosure], [disclosure]);

  const { showChecklistPoints } = useFeatureFilter();

  let maxChecklistgroupWeight = findCollectionItemMaxWeight(grouped);

  const { userHasPermission } = useRbac();

  const toggleDisclosure = id => {
    if (dsc.includes(id)) {
      setDisclosure(dsc.filter(x => x !== id));
    } else {
      dsc.push(id);
      setDisclosure(dsc);
    }
  };

  const handleEditTitle = (e, id) => {
    if (e.key === "Enter") {
      const index = grouped.findIndex(x => x.id === id);
      grouped[index] = { ...grouped[index], body: e.target.value };
      editGroup({ id, body: e.target.value });
      setTitleEditable(null);
    }
    if (e.key === "Escape") {
      setTitleEditable(null);
    }
  };

  const handleDeleteGroup = () => {
    removeGroup({ id: delGroup?.id });
    onClose();
  };

  const handleChangePoint = (e, id) => {
    editGroup({ id, points: parseInt(e.target.value) });
  };

  const onClose = () => {
    setDelGroup(null);
  };

  useEffect(() => {
    setDisclosure(grouped.map(x => x.id));
  }, [grouped]);

  const newWeight = useMemo(
    () => (items.length ? items[items.length - 1]?.weight + 10 : 10),
    [items]
  );

  return (
    <>
      <WithRbac permissionOneOf={UserPermissions.EVALACTIONPLAN_CREATE}>
        <CreateChecklistItem add={addItem} itemWeightValue={newWeight} />
      </WithRbac>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          type="item"
          droppableId={"ungroup"}
          ignoreContainerClipping={false}
          isDropDisabled={false}
          isCombineEnabled={false}
        >
          {(provided, snapshot) => (
            <div
              className={classnames(
                "is-relative",
                snapshot.isDraggingOver ? "has-background-light" : ""
              )}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <ChecklistItem
                items={ungrouped}
                edit={editItem}
                remove={removeItem}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable
          type="group"
          droppableId="board"
          ignoreContainerClipping={false}
          isCombineEnabled={false}
        >
          {(provided, snapshot) => (
            <div
              className={snapshot.isDraggingOver ? "has-background-light" : ""}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {grouped?.map((group, gIndex) => {
                let items = pocId
                  ? group?.pocChecklistitems
                  : group?.checklistitems;
                let maxChecklistitemWeight = findCollectionItemMaxWeight(items);
                return (
                  <>
                    <WithRbac
                      permissionOneOf={UserPermissions.EVALACTIONPLAN_CREATE}
                    >
                      <AddSection
                        add={addGroup}
                        index={gIndex}
                        itemWeightValue={group.weight - 1}
                      />
                    </WithRbac>
                    <Draggable
                      key={group.id}
                      draggableId={"group-" + group.id}
                      index={gIndex}
                      isDragDisabled={
                        !userHasPermission(
                          UserPermissions.EVALACTIONPLAN_REORDER
                        )
                      }
                    >
                      {(provided2, snapshot2) => (
                        <div
                          key={gIndex}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          className="my-0"
                        >
                          <div key={gIndex} className="box my-3">
                            <div className="columns h-12r">
                              <div className="column is-9 is-flex is-align-items-center">
                                {dsc.includes(group.id) ? (
                                  <ChevronDown
                                    className="is-size-7 mr-2"
                                    onClick={() => toggleDisclosure(group.id)}
                                  />
                                ) : (
                                  <ChevronRight
                                    className="is-size-7 mr-2"
                                    onClick={() => toggleDisclosure(group.id)}
                                  />
                                )}
                                {titleEditable === group.id ? (
                                  <input
                                    autoFocus
                                    className="input ml-5"
                                    defaultValue={group.body}
                                    onBlur={() => setTitleEditable(null)}
                                    onKeyDown={e =>
                                      handleEditTitle(e, group.id)
                                    }
                                  />
                                ) : (
                                  <div
                                    className="is-size-5"
                                    onClick={() => setTitleEditable(group.id)}
                                    data-weight={group.weight}
                                  >
                                    {group.body}
                                  </div>
                                )}
                                {showChecklistPoints ? (
                                  <input
                                    type="number"
                                    className="mx-5 input w-4r"
                                    title="The number of points for this section"
                                    defaultValue={group.points}
                                    onChange={debounce(
                                      e => handleChangePoint(e, group.id),
                                      500
                                    )}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="column is-3 is-flex is-justify-content-flex-end">
                                <Menu as="div" className="dropdown pt-2">
                                  <Menu.Button
                                    as="div"
                                    className="dropdown-trigger"
                                    // disabled={item.addressed}
                                  >
                                    <ThreeDotsVertical />
                                  </Menu.Button>
                                  <Menu.Items
                                    as="div"
                                    className="dropdown-menu card"
                                    style={{ outlineStyle: "none" }}
                                  >
                                    <Menu.Item
                                      as="div"
                                      className="p-2 pl-4 is-clickable is-size-6"
                                      onClick={() =>
                                        setDelGroup({
                                          id: group.id,
                                          body: group.body,
                                          size: items.length
                                        })
                                      }
                                    >
                                      Delete
                                    </Menu.Item>
                                  </Menu.Items>
                                </Menu>
                              </div>
                            </div>
                            {dsc.includes(group.id) && (
                              <Droppable
                                type="item"
                                droppableId={group.id}
                                ignoreContainerClipping={false}
                                isDropDisabled={false}
                                isCombineEnabled={false}
                              >
                                {(provided3, snapshot3) => (
                                  <div
                                    className={classnames(
                                      "is-relative",
                                      snapshot3.isDraggingOver
                                        ? "has-background-light"
                                        : ""
                                    )}
                                    ref={provided3.innerRef}
                                    {...provided3.droppableProps}
                                  >
                                    <ChecklistItem
                                      gid={group.id}
                                      items={items}
                                      edit={editItem}
                                      remove={removeItem}
                                    />
                                    {provided3.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            )}
                            {dsc.includes(group.id) && (
                              <WithRbac
                                permissionOneOf={
                                  UserPermissions.EVALACTIONPLAN_CREATE
                                }
                              >
                                <CreateChecklistItem
                                  gid={group.id}
                                  itemWeightValue={maxChecklistitemWeight + 10}
                                  add={addItem}
                                />
                              </WithRbac>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                );
              })}
              <WithRbac permissionOneOf={UserPermissions.EVALACTIONPLAN_CREATE}>
                <AddSection
                  add={addGroup}
                  index={777}
                  itemWeightValue={maxChecklistgroupWeight + 1}
                />
              </WithRbac>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Dialog
        as="div"
        open={delGroup !== null}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={onClose}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <div className="is-flex is-align-items-center">
            <ExclamationDiamond
              size={"25px"}
              className="has-text-danger mr-3"
            />
            <div className="is-size-4">Delete section "{delGroup?.body}"?</div>
          </div>
          <div className="my-5">
            You're about to permanently delete this section, and its{" "}
            {delGroup?.size} tasks.
          </div>
          <div className="buttons is-justify-content-end">
            <button
              onClick={() => handleDeleteGroup()}
              className="button is-danger"
            >
              Delete
            </button>
            <button onClick={() => onClose()} className="button is-light">
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
