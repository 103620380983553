import React from "react";
import { ArrowLeftRight, GripVertical } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { importances, outcomes, PHASES } from "./constants";
import { useGetMetaQuery, useEditPocUseCasesMutation } from "services/api";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";
import Select from "components/common/Select";
import PocAddProof from "./PocAddProof";
import { toast } from "react-toastify";
import { SelectableStakeholder } from "../../components/common/TableCells/SelectableStakeholder";
import PocProof from "./PocProof";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";
import WithRbac from "../../components/common/Rbac/withRbac";
import ThreeDotMenu from "../../components/common/ThreeDotMenu/index";
import EntityNotesWidget from "components/common/EntityNotesWidget";
import Tooltip from "rc-tooltip";
import { htmlSanitize, htmlDecode } from "utils/helpers";

const PocUsecaseCard = ({
  poc,
  data,
  onOpenEditModal,
  onOpenDeleteModal,
  dragHandleProps
}) => {
  const [editPocUseCases] = useEditPocUseCasesMutation();
  const { data: metadata } = useGetMetaQuery();
  const { userHasPermission } = useRbac();
  const { pocId } = useParams();

  const onAssignedStakeholderChange = stakeholderId => {
    editPocUseCases({
      id: data.id,
      assignedToStakeholderId: stakeholderId,
      pocId: poc.id // needed for optimistic update
    });
  };

  const onImportancesChange = value => {
    editPocUseCases({
      id: data.id,
      importance: parseInt(value),
      pocId: poc.id // needed for optimistic update
    });
  };

  const onOutcomesChange = value => {
    editPocUseCases({
      id: data.id,
      score: parseInt(value),
      pocId: poc.id // needed for optimistic update
    });
  };

  const onUsecaseChecked = event => {
    editPocUseCases({
      id: data.id,
      addressed: event.target.checked,
      pocId: poc.id // needed for optimistic update
    }).catch(({ message }) => {
      toast.error(`Error while marking as complete: ${message}`);
    });
  };

  const menu = React.useMemo(() => {
    const menuItems = [];

    if (poc?.scopingPhase === PHASES.DRAFT) {
      if (
        userHasPermission(UserPermissions.EVALSCOPING_UPDATE) &&
        data.usecase.visibility == metadata.entityVisibility.ONE_OFF &&
        data?.usecase?.createdViaPocId === pocId
      ) {
        menuItems.push({
          label: "Edit",
          onClick: () => {
            // We edit the USECASE here
            onOpenEditModal(data.usecase);
          }
        });
      }

      if (userHasPermission(UserPermissions.EVALSCOPING_DELETE)) {
        menuItems.push({
          label: "Delete",
          onClick: () => {
            // We delete the POCUSECASE here
            onOpenDeleteModal(data);
          }
        });
      }
    }

    return <ThreeDotMenu items={menuItems} />;
  }, [data]);

  return (
    <div className="columns is-align-items-center drop-shadow mb-5 rounded">
      <div
        className={`column is-flex is-align-items-center ${
          data.addressed ? "item-completed" : ""
        }`}
      >
        {userHasPermission(UserPermissions.EVALSCOPING_REORDER) ? (
          <div {...dragHandleProps} className="pl-1 hidden-item">
            <GripVertical color="gray" size={20} />
          </div>
        ) : (
          ""
        )}

        <input
          type="checkbox"
          disabled={
            !userHasPermission(UserPermissions.EVALSCOPING_MARKCOMPLETE) ||
            poc?.scopingPhase === PHASES.PENDING
          }
          defaultChecked={data.addressed}
          onChange={e => onUsecaseChecked(e)}
          className="larger mr-3"
        />

        {data?.usecase?.notes ? (
          <Tooltip
            placement="top"
            trigger={["click"]}
            overlay={
              <span
                dangerouslySetInnerHTML={{
                  __html: htmlSanitize(htmlDecode(data?.usecase?.notes))
                }}
              ></span>
            }
          >
            <a>{data?.usecase?.body}</a>
          </Tooltip>
        ) : (
          data?.usecase?.body
        )}

        <EntityVisibilityIcon entity={data?.usecase} />
        {data.businessValues?.length ? (
          <React.Fragment>
            <ArrowLeftRight className="mx-4" />
            <span className=" is-flex is-size-6 overflow-hidden">
              {data.businessValues?.map((bv, i) => (
                <div
                  key={i}
                  className="has-text-info border has-border-info px-1 rounded mx-1"
                >
                  {bv}
                </div>
              ))}
            </span>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      <div className="column is-2 user-image">
        <SelectableStakeholder
          stakeholders={poc?.stakeholders}
          selectedStakeholderId={data.assignedToStakeholderId}
          editable={
            userHasPermission(UserPermissions.EVALSCOPING_UPDATE) &&
            !data.addressed &&
            poc?.scopingPhase === PHASES.DRAFT
          }
          onChange={onAssignedStakeholderChange}
          addressed={data.addressed}
        />
      </div>

      <div className="column is-2 is-flex">
        <Select
          name="importances"
          className="is-flex is-align-items-center is-size-7 mr-2"
          options={importances}
          value={data.importance}
          onChange={e => onImportancesChange(e.target.value)}
          disabled={
            !userHasPermission(UserPermissions.EVALSCOPING_UPDATE) ||
            poc?.scopingPhase !== PHASES.DRAFT
          }
        />
        <Select
          name="outcomes"
          className="is-flex is-align-items-center is-size-7"
          options={outcomes}
          value={data.score}
          onChange={e => onOutcomesChange(e.target.value)}
          disabled={
            !userHasPermission(UserPermissions.EVALSCOPING_UPDATE) ||
            poc?.scopingPhase === PHASES.PENDING
          }
        />
      </div>

      <div className="column is-3 is-flex  is-align-items-center is-justify-content-space-between">
        <div className="ml-1">
          {data?.evidences?.length ? (
            <PocProof data={data} />
          ) : (
            <WithRbac permissionOneOf={UserPermissions.EVALSCOPING_UPDATE}>
              <PocAddProof data={data} />
            </WithRbac>
          )}
        </div>
        <div className="is-flex">
          <EntityNotesWidget
            entity={data}
            canEdit={userHasPermission(UserPermissions.EVALSCOPING_UPDATE)}
            editNotesHandler={notes => {
              editPocUseCases({
                id: data.id,
                notes,
                pocId: poc.id // needed for optimistic update
              });
            }}
            remove={() => {
              editPocUseCases({
                id: data.id,
                notes: "",
                pocId: poc.id
              });
            }}
          />
          {menu}
        </div>
      </div>
    </div>
  );
};

export default PocUsecaseCard;
