import { useState, useRef, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import {
  useGetPocQuestionsQuery,
  useAddPocQuestionsMutation,
  useDeletePocQuestionsMutation,
  useImportPocQuestionsMutation,
  useEditPocMutation,
  useGetQuestionTemplateListQuery
} from "services/api";
import PocTopBar from "components/TopBar/TopBarPoc";
import CardContainer from "components/Container/CardContainer";
import APIError from "components/common/APIError";
import TextEditor from "components/common/TextEditor";
import usePocs from "hooks/usePocs";
import QuestionFilter from "./PocQuestionsFilter";
import PocQuestionsBulkAttach from "./PocQuestionsBulkAttach";
import PocQuestionsTable from "./PocQuestionsTable";
import useAuth from "hooks/useAuth";
import LayoutContainer from "../../components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import "./PocQuestions.css";
import { debounce } from "lodash";
import ApiCallIndicator from "components/common/ApiCallIndicator";
import useFeatureFilter from "hooks/useFeatureFilter";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";

const PocQuestions = ({ pocId }) => {
  const editorRef = useRef();
  const { currentPoc } = usePocs();
  const [editPoc, editPocResponse] = useEditPocMutation();
  const { data, error, isLoading, isFetching } = useGetPocQuestionsQuery(pocId);
  const [filter, setFilter] = useState({});
  const [showModalAdd, setShowAddModal] = useState(false);
  const { currentUser } = useAuth();
  const { data: templateList } = useGetQuestionTemplateListQuery(
    currentUser.vendorId
  );

  const { showExtraFeatures } = useFeatureFilter();
  const { userHasPermission } = useRbac();

  const templates = templateList ? Object.keys(templateList) : [];
  const [importTemplate, { isLoading: importLoading }] =
    useImportPocQuestionsMutation();

  const { error: batchAddError } = useAddPocQuestionsMutation({
    fixedCacheKey: `batch-add-pocQuestions-${pocId}`
  })[1];
  const { error: batchDeleteError } = useDeletePocQuestionsMutation({
    fixedCacheKey: `batch-delete-pocQuestions-${pocId}`
  })[1];

  const saveNotes = current => {
    if (!!current?.getContent() || current?.getContent() === "") {
      editPoc({ id: currentPoc.id, discoveryNotes: current?.getContent() });
    }
  };

  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar
          poc={currentPoc}
          showScore={true}
          title={currentPoc?.pocName}
        />
      </LayoutContainer>

      <LayoutContainer>
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>

        <CardContainer
          page="Questions"
          addButtonText={
            userHasPermission(UserPermissions.EVALQUESTIONNAIRE_CREATE)
              ? "Import Questions"
              : false
          }
          className="mb-4 PocQuestions-container"
          isLoading={isLoading}
          allLoading={isFetching}
          importLoading={importLoading}
          pocId={pocId}
          data={data}
          templates={templates}
          templateList={templateList}
          permissionImportTemplates={userHasPermission(
            UserPermissions.EVALQUESTIONNAIRE_IMPORTTEMPLATE
          )}
          importTemplate={importTemplate}
          setShowAddModal={setShowAddModal}
        >
          <QuestionFilter filter={filter} setFilter={setFilter} />
          <APIError error={error || batchAddError || batchDeleteError} />
          <PocQuestionsTable
            setShowAddModal={setShowAddModal}
            filter={filter}
          />

          <Dialog
            as="div"
            open={showModalAdd}
            className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
            onClose={() => setShowAddModal(false)}
          >
            <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
            <PocQuestionsBulkAttach
              handleClose={() => setShowAddModal(false)}
              templates={templates}
              templateList={templateList}
            />
          </Dialog>
        </CardContainer>
        {showExtraFeatures && (
          <>
            <h1 className="is-size-5 mt-5 mb-2">
              Questionnaire Notes{" "}
              <ApiCallIndicator apiResponse={editPocResponse} />
            </h1>
            <TextEditor
              ref={editorRef}
              defaultValue={currentPoc.discoveryNotes}
              placeholder="Take some shared Discovery notes here!"
              onChange={debounce(() => saveNotes(editorRef?.current), 1000)}
              className="mb-4"
            />
          </>
        )}
      </LayoutContainer>
    </>
  );
};

export default PocQuestions;
