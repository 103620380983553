import { React, useCallback } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";

import NotFound from "components/common/NotFound";
import NewPoc from "pages/NewPoc";
import PocOverview from "pages/PocOverview";
import PocFeedback from "pages/PocFeedback";
import PocStakeholder from "pages/PocStakeholder";
import PocQuestions from "pages/PocQuestions";
import PocUseCases from "pages/PocUseCases";
import PocChecklist from "pages/PocChecklist";
import PocAttachments from "pages/PocAttachments";

import usePocs from "hooks/usePocs";

import ManageChecklist from "pages/ManageChecklist";
import Attachments from "pages/Attachments";
import AnalyticsRoutes from "pages/Analytics/AnalyticsRoutes";
import LayoutContainer from "components/Container/LayoutContainer";
import DashboardRoutes from "pages/Dashboard/DashboardRoutes";
import ManageQuestionsRoutes from "pages/ManageQuestions/ManageQuestionsRoutes";
import ManageUsecasesRoutes from "pages/ManageUsecases/ManageUsecasesRoutes";
import SettingsIntegrationRoutes from "pages/SettingsIntegrations/SettingsIntegrationRoutes";
import SalesforceOAuthAction from "./pages/SettingsIntegrations/SalesforceOAuthAction";
import PocTabNotFound from "pages/PocTabNotFound";
import VendorDetails from "pages/VendorDetails";
import useRbac from "./components/common/Rbac/useRbac";
import { UserRoles } from "config/userRoles";
import { UserPermissions } from "./config/userPermissions";
import ManageSpecdocsections from "./pages/ManageSections";
import ManageUsers from "./pages/ManageUsers";
import UserProfile from "./pages/UserProfile/index";
import useAuth from "./hooks/useAuth";
import ScrollToTop from "components/common/ScrollToTop";

const PocRoutes = () => {
  const { pocId } = useParams();
  const { currentPoc } = usePocs();
  const { userHasRole } = useRbac();

  const accessCheck = useCallback(
    page => {
      return (
        userHasRole([
          UserRoles.ADMIN,
          UserRoles.SE_MANAGER,
          UserRoles.SALES_ENGINEER
        ]) || currentPoc.prospectUserTabAccess?.includes(page)
      );
    },
    [currentPoc.prospectUserTabAccess, userHasRole]
  );

  return (
    <>
      <Routes>
        {accessCheck("overview") && (
          <Route
            path="overview"
            element={<PocOverview poc={currentPoc} pocId={pocId} />}
          />
        )}
        {accessCheck("questionnaire") && (
          <Route
            path="questionnaire/*"
            element={<PocQuestions pocId={pocId} />}
          />
        )}
        {accessCheck("usecases") && (
          <Route
            path="usecases/*"
            element={<PocUseCases poc={currentPoc} pocId={pocId} />}
          />
        )}
        {accessCheck("feedback") && (
          <Route
            path="feedback/*"
            element={<PocFeedback poc={currentPoc} pocId={pocId} />}
          />
        )}
        {accessCheck("checklist") && (
          <Route
            path="checklist/*"
            element={<PocChecklist poc={currentPoc} pocId={pocId} />}
          />
        )}
        {accessCheck("attachments") && (
          <Route
            path="attachments"
            element={<PocAttachments poc={currentPoc} pocId={pocId} />}
          />
        )}
        {accessCheck("collaborators") && (
          <Route
            path="collaborators/*"
            element={<PocStakeholder poc={currentPoc} pocId={pocId} />}
          />
        )}
        <Route path="*" element={<PocTabNotFound poc={currentPoc} />} />
      </Routes>
    </>
  );
};

const AppLoggedIn = () => {
  const { userPocs } = usePocs();
  const firstPoc = userPocs[0]?.id || null;
  const { currentUser } = useAuth();

  const { userHasRole, userHasPermission } = useRbac();

  const renderDefaultRoute = () => {
    if (userHasRole(UserRoles.PROSPECT_USER)) {
      if (firstPoc) {
        return <Navigate to={`/pocs/${firstPoc}/overview`} />;
      } else {
        return <NotFound />;
      }
    } else {
      return <Navigate to={`dashboard`} />;
    }
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        {userHasPermission([UserPermissions.VENDOR_MANAGE]) && (
          <>
            <Route
              path="/vendor"
              element={
                <LayoutContainer>
                  <VendorDetails />
                </LayoutContainer>
              }
            />
          </>
        )}

        {userHasPermission(UserPermissions.RESOURCES_READ) && (
          <Route
            path="/attachments"
            element={
              <LayoutContainer>
                <Attachments />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.ANALYTICS_READ) && (
          <Route
            path="/analytics/*"
            element={
              // <LayoutContainer>
              <AnalyticsRoutes />
              // </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.QUESTIONNAIRE_MANAGE) && (
          <Route
            path="settings/manage-questions/*"
            element={
              <LayoutContainer>
                <ManageQuestionsRoutes />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.SCOPING_MANAGE) && (
          <Route
            path="settings/manage-usecases/*"
            element={
              <LayoutContainer>
                <ManageUsecasesRoutes />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.ACTIONPLAN_MANAGE) && (
          <Route
            path="/settings/manage-checklist/*"
            element={
              <LayoutContainer>
                <ManageChecklist />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.SPECDOCSECTIONS_MANAGE) && (
          <Route
            path="/settings/manage-specdocsections/*"
            element={
              <LayoutContainer>
                <ManageSpecdocsections />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.SCOPING_MANAGE) && (
          <Route
            path="settings/manage-users/*"
            element={
              <LayoutContainer>
                <ManageUsers />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.INTEGRATION_MANAGE) && (
          <Route
            path="/settings/integrations/*"
            element={
              <LayoutContainer>
                <SettingsIntegrationRoutes />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.INTEGRATION_UPDATE) && (
          <Route
            path="/oauth-salesforce/:action"
            element={
              <LayoutContainer>
                <SalesforceOAuthAction />
              </LayoutContainer>
            }
          />
        )}

        <Route
          path="user/profile"
          element={<UserProfile user={currentUser} />}
        />

        {userHasRole([
          UserRoles.ADMIN,
          UserRoles.SE_MANAGER,
          UserRoles.SALES_ENGINEER
        ]) && (
          <Route
            path="/dashboard/*"
            element={
              <LayoutContainer>
                <DashboardRoutes />
              </LayoutContainer>
            }
          />
        )}

        {userHasPermission(UserPermissions.EVAL_CREATE) && (
          <Route
            path="/pocs/new/:type"
            element={
              <LayoutContainer>
                <NewPoc />
              </LayoutContainer>
            }
          />
        )}
        {userHasPermission(UserPermissions.EVALACTIONPLAN_READ) && (
          <Route path="/pocs/:pocId/*" element={<PocRoutes />} />
        )}
        <Route path="*" element={renderDefaultRoute()} />
      </Routes>
    </>
  );
};

export default AppLoggedIn;
