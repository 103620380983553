import useAuth from "hooks/useAuth";
import { hasPermission } from "./rbac.utils";

const useRbac = () => {
  const { currentUser } = useAuth();

  const userHasRole = function (roles) {
    return hasPermission(currentUser.roles, roles);
  };

  const userHasPermission = function (permissions) {
    return hasPermission(currentUser.permissions, permissions);
  };

  return { userHasRole, userHasPermission };
};

export default useRbac;
