import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "./FooterPaginatedTable.css";

const FooterPaginatedTable = ({
  canNextPage,
  canPreviousPage,
  handleNext,
  handlePageSize,
  handlePrevious,
  numRows,
  pageIndex,
  pageSize
}) => (
  <nav className="level FooterPaginatedTable">
    <div className="level-left">
      <div className="level-item">Rows per page:</div>
      <div className="level-item">
        <div className="select is-small">
          <select
            value={pageSize}
            className="page-size"
            onChange={handlePageSize}
          >
            {[10, 20, 50, 100].map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>

    <div className="level-right">
      <p className="level-item">
        <span>
          {pageSize * pageIndex + 1} -{" "}
          {Math.min(pageSize * (pageIndex + 1), numRows)} of {numRows} items
        </span>
        <ChevronLeft
          onClick={handlePrevious}
          size={18}
          className={`mx-3 page-change ${canPreviousPage ? "" : "invisible"}`}
        />
        <ChevronRight
          onClick={handleNext}
          size={18}
          className={`page-change ${canNextPage ? "" : "invisible"}`}
        />
      </p>
    </div>
  </nav>
);

export default FooterPaginatedTable;
