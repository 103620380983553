import React from "react";

const LayoutContainer = ({ parentClassName, children }) => {
  return (
    <div className={`${parentClassName}`}>
      <div className={`container is-widescreen`}>{children}</div>
    </div>
  );
};

export default LayoutContainer;
