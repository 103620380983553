import dayjs from "dayjs";
import { forwardRef } from "react";

const ChecklistDueDate = forwardRef(
  ({ value, onClick, addressed, checkPast }, ref) => {
    const isPast = dayjs(value).isBefore(dayjs());
    return (
      <div
        className={`is-clickable ${addressed ? "item-completed" : ""}`}
        onClick={onClick}
        ref={ref}
      >
        {value ? (
          <button
            className={`button ${
              isPast && checkPast ? "is-danger has-text-white" : "is-light"
            } is-size-7 is-capitalized`}
          >
            {isPast && checkPast ? dayjs(value).fromNow() : value}
          </button>
        ) : (
          <div className="button is-light px-6 is-small">...</div>
        )}
      </div>
    );
  }
);
export default ChecklistDueDate;
