import Error from "./Error";

const Select = ({
  id,
  name,
  label,
  error,
  options,
  includeEmpty = true,
  value,
  className = "mb-5",
  disabled,
  ...rest
}) => (
  <div className={`form-group ${className}`}>
    {label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div className={`select ${disabled && "disabled"}`}>
      <select
        {...rest}
        id={id || name}
        name={name}
        className={`form-control form-select ${error ? "error" : ""}`}
        value={value || ""}
        disabled={disabled}
      >
        {includeEmpty ? <option value="" /> : ""}
        {options?.map(option => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
    <Error message={error} />
  </div>
);

export default Select;
