import { useEditPocMutation, useGetMetaQuery } from "services/api";
import React from "react";
import { LoadingIconOnly } from "components/common/Loading";

export default function TopBarPocSteps({ poc }) {
  const { data: metadata } = useGetMetaQuery();

  const [editPoc, { isLoading }] = useEditPocMutation();

  const handlePhaseUpdate = phase => {
    const payload = {
      id: poc.id,
      phase: phase
    };
    editPoc(payload);
  };

  return (
    <React.Fragment>
      <div className="is-size-4 mb-3 pr-3 is-flex is-align-items-center">
        Update Opportunity Phase{" "}
        <span className={`${isLoading ? "is-visible" : "is-hidden"}`}>
          <LoadingIconOnly />
        </span>
      </div>

      <div className="buttons">
        {Object.keys(metadata?.pocPhases)?.map((phaseKey, key) => (
          <button
            className={`button ${
              poc.phase === metadata.pocPhases[phaseKey] ? "is-info" : ""
            }`}
            onClick={() => handlePhaseUpdate(metadata.pocPhases[phaseKey])}
          >
            {metadata.pocPhases[phaseKey]}
          </button>
        ))}
      </div>
    </React.Fragment>
  );
}
