import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../UserAvatar";
import styles from "./UserAvatarGroup.module.css"; // Import css modules stylesheet as styles

export default function UserAvatarGroup({ users = [], pocId = "" }) {
  return (
    <div className={styles.compressed}>
      {users.map(user => (
        <Link to={`/pocs/${pocId}/collaborators`} key={user.id}>
          <UserAvatar user={user} showName={false} />
        </Link>
      ))}
    </div>
  );
}
