import React, { useState } from "react";
import { debounce } from "debounce";
import { Dialog } from "@headlessui/react";
import { useGetMetaQuery } from "services/api";
import { convertArrayToSelectOptions } from "utils/helpers";
import { FunnelFill, Search } from "react-bootstrap-icons";
import useAuth from "hooks/useAuth";

export default function PocFeedbackFilter({ filter, setFilter }) {
  const [open, setOpen] = useState(false);

  const { isProspect } = useAuth();

  const { data: metadata } = useGetMetaQuery();
  const { priorities, feedbackTypes, feedbackStatuses } = metadata || {};

  const createOptions = data =>
    data ? convertArrayToSelectOptions(Object.values(data)) : [];

  const statusOpt = Object.values(feedbackStatuses || {});
  const prioritiesOpt = Object.values(priorities || {});
  const typesOpt = Object.values(feedbackTypes || {});

  const { industries, geographies, segments, phases } = metadata || {};

  const onClickFilter = value => {
    const key = Object.keys(value)[0];
    const val = value[key];
    const newFilter = { ...filter };
    if (newFilter[key]?.length) {
      if (newFilter[key].includes(val)) {
        const index = newFilter[key].indexOf(val);
        newFilter[key].splice(index, 1);
      } else {
        newFilter[key].push(val);
      }
    } else {
      newFilter[key] = [val];
    }
    setFilter(newFilter);
  };

  return (
    <>
      {!isProspect && (
        <div className="is-flex is-align-items-center mb-3">
          <span onClick={() => setOpen(true)} className="button is-light">
            <FunnelFill className="has-text-grey-light mr-1" /> Filter
          </span>

          <div className="control has-icons-left ml-4 w-30r">
            <input
              onChange={debounce(
                e => setFilter({ search: e.target.value }),
                500
              )}
              placeholder="Filter Priority, Collaborator, Type, etc."
              className="input"
            />
            <span className="icon is-left">
              <Search />
            </span>
          </div>
        </div>
      )}
      <Dialog
        as="div"
        open={open}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setOpen(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="p-6 my-8 overflow-hidden has-background-white z-30 rounded-md w-50">
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Type
            </div>
            <div className="buttons are-small column is-flex is-align-items-center gap-3">
              {typesOpt.map(t => (
                <div
                  key={t}
                  onClick={() => onClickFilter({ type: t })}
                  className={`w-auto h-75 ${
                    filter?.type?.includes(t)
                      ? "button is-info"
                      : "button is-light"
                  }  is-flex is-align-items-center is-justify-content-center`}
                >
                  {t}
                </div>
              ))}
              <div
                onClick={() =>
                  onClickFilter({ linked_item_priority: "Blocker" })
                }
                className={`w-35 h-75 ${
                  filter?.linked_item_priority?.includes("Blocker")
                    ? "button is-info"
                    : "button is-light"
                } is-flex is-align-items-center is-justify-content-center`}
              >
                Blocker
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Priority
            </div>
            <div className="buttons are-small column is-flex is-align-items-center gap-3">
              {prioritiesOpt.map(p => (
                <div
                  key={p}
                  onClick={() => onClickFilter({ linked_item_priority: p })}
                  className={`w-auto h-75 ${
                    filter?.linked_item_priority?.includes(p)
                      ? "button is-info"
                      : "button is-light"
                  }  is-flex is-align-items-center is-justify-content-center`}
                >
                  {p}
                </div>
              ))}
            </div>
          </div>
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Status
            </div>
            <div className="buttons are-small column is-flex is-align-items-center gap-3">
              {statusOpt.map(s => (
                <div
                  key={s}
                  onClick={() => onClickFilter({ status: s })}
                  className={`w-auto h-75 ${
                    filter?.status?.includes(s)
                      ? "button is-info"
                      : "button is-light"
                  } is-flex is-align-items-center is-justify-content-center`}
                >
                  {s}
                </div>
              ))}
            </div>
          </div>

          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Geography
            </div>
            <div className="column is-flex is-align-items-center">
              <div className="select ">
                <select
                  onChange={e => onClickFilter({ geography: e.target.value })}
                  className="w-50 h-8r rounded form-control form-select"
                >
                  <option>-</option>
                  {createOptions(geographies)?.map(option => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Industry
            </div>
            <div className="column is-flex is-align-items-center">
              <div className="select ">
                <select
                  onChange={e => onClickFilter({ industry: e.target.value })}
                  className="w-50 h-8r rounded form-control form-select"
                >
                  <option>-</option>
                  {createOptions(industries)?.map(option => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Segment
            </div>
            <div className="column is-flex is-align-items-center">
              <div className="select ">
                <select
                  onChange={e => onClickFilter({ segment: e.target.value })}
                  className="w-50 h-8r rounded form-control form-select"
                >
                  <option>-</option>
                  {createOptions(segments)?.map(option => (
                    <option key={option.key} value={option.key}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
