import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import {
  useEditPocUseCasesMutation,
  useEditMultiPocUseCasesMutation,
  useGetVendorQuery
} from "services/api";
import ReadMore from "utils/ReadMore";
import useAuth from "hooks/useAuth";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";

export const fetchBusinessValues = (
  pocUseCases,
  setBusinessValue,
  businessValues
) => {
  if (pocUseCases.length > 0) {
    const getBV = pocUseCases.reduce((prev, usecase) => {
      if (usecase.businessValues) {
        prev = prev.concat(usecase.businessValues);
      }
      return prev;
    }, []);
    const uniqueBV = getBV.filter(e => !(getBV[e] = e in getBV));
    const sortedBV = uniqueBV.sort((a, b) => a.localeCompare(b));
    setBusinessValue([...new Set(sortedBV.concat(businessValues))]);
  }
};

export const handleEditBusiness = async (
  e,
  i,
  businessValues,
  pocUseCases,
  multiEdit,
  setBusinessValue,
  setEditable,
  poc
) => {
  let clonedBusiness = [...businessValues];
  let clonedPocUseCases = [...pocUseCases];
  let currentBusiness = clonedBusiness[i];
  let pocBusiness = clonedPocUseCases.filter(x =>
    x.businessValues?.includes(currentBusiness)
  );
  clonedBusiness[i] = e.target.value;
  if (e.target.value === "") {
    clonedBusiness.splice(i, 1);
  }
  if (e.keyCode === 13) {
    if (pocBusiness.length > 0) {
      const payload = pocBusiness.map(v => {
        const index = v.businessValues.indexOf(currentBusiness);
        let newData = [...v.businessValues];
        if (e.target.value === "") {
          newData.splice(index, 1);
        } else {
          newData[index] = e.target.value;
        }

        return { id: v.id, businessValues: newData };
      });
      multiEdit({ pocId: poc.id, payload });
    }
    await setBusinessValue(clonedBusiness);
    setEditable(false);
  }
};

export default function PocUseCaseMatrix({
  poc,
  reload,
  setReload,
  setShow,
  businessValues,
  setBusinessValue,
  pocUseCases
}) {
  const { pocId } = useParams();
  const [edit] = useEditPocUseCasesMutation();
  const [multiEdit] = useEditMultiPocUseCasesMutation();
  const [addBusiness, setAddBusiness] = useState(false);
  const [editable, setEditable] = useState(false);
  const { currentUser } = useAuth();
  const { data: vendorData, isLoading: vendorIsLoading } = useGetVendorQuery(
    currentUser.vendorId
  );

  const handleAddBusiness = e => {
    if (e.key === "Enter") {
      const newData = [...businessValues];
      newData.push(e.target.value);
      setBusinessValue(newData);
      e.target.value = "";
    }
  };

  const handleCheck = e => {
    const { id, value, checked } = e.target;
    const getUseCase = pocUseCases.find(x => x.id === id);
    const newBusinessValues = [...(getUseCase.businessValues || [])];
    if (checked) {
      newBusinessValues.push(value);
    } else {
      const index = newBusinessValues.indexOf(value);
      newBusinessValues.splice(index, 1);
    }
    edit({ pocId, id, businessValues: newBusinessValues });
  };

  useEffect(() => {
    fetchBusinessValues(pocUseCases, setBusinessValue, businessValues);
  }, []);

  useEffect(() => {
    if (reload) {
      setBusinessValue([]);
      setReload(false);
    }
  }, [reload, setReload]);

  return (
    <section className="table-container ">
      <table className="table is-bordered">
        <thead className="border-none text-center">
          <tr className="whitespace-nowrap border-none">
            <td className="border-0"></td>
            <td colSpan={pocUseCases.length + 1}>
              <strong>{vendorData?.name} Success Criteria</strong>
            </td>
          </tr>
          <tr className=" border-none whitespace-nowrap">
            <td
              className="p-4 w-30r h-auto"
              style={{ verticalAlign: "middle" }}
            >
              <strong>{poc.companyName} Required Capabilities</strong>
            </td>
            {pocUseCases.map(
              (puc, a) =>
                puc.usecase && (
                  <td
                    key={a}
                    className="p-4 w-30r h-auto"
                    style={{ verticalAlign: "middle" }}
                  >
                    <div className="is-flex is-align-items-center">
                      <EntityVisibilityIcon entity={puc.usecase} />
                      <ReadMore charLength={30}>
                        {puc.usecase.body === "loading-from-api"
                          ? "loading..."
                          : puc.usecase.body}
                      </ReadMore>
                    </div>
                  </td>
                )
            )}
            <td className="p-4 h-auto " style={{ verticalAlign: "middle" }}>
              <div className="is-flex is-justify-content-center is-align-items-center">
                <div
                  onClick={setShow}
                  className="has-text-info border  border-info rounded is-clickable px-2 "
                >
                  <PlusCircle className="mr-1" /> Add Success Criteria
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {businessValues.map((business, b) => {
            return (
              <tr className="whitespace-nowrap" key={b}>
                {editable === b ? (
                  <td className="p-4 ">
                    <input
                      key={b}
                      autoFocus
                      className="w-100 input"
                      defaultValue={business}
                      onKeyUp={e =>
                        handleEditBusiness(
                          e,
                          b,
                          businessValues,
                          pocUseCases,
                          multiEdit,
                          setBusinessValue,
                          setEditable,
                          poc
                        )
                      }
                      onBlur={() => setEditable(false)}
                    />
                  </td>
                ) : (
                  <td className="p-4" onClick={() => setEditable(b)}>
                    <ReadMore children={business} charLength={30} />
                  </td>
                )}
                {pocUseCases.map((puc, c) => {
                  const isChecked = puc.businessValues?.includes(business);
                  return (
                    <td
                      key={c}
                      className="p-4 has-text-centered"
                      style={{ verticalAlign: "middle" }}
                    >
                      <input
                        id={puc.id}
                        value={business}
                        type="checkbox"
                        defaultChecked={isChecked}
                        onChange={handleCheck}
                        className="ChecklistItem_checkbox larger"
                      />
                    </td>
                  );
                })}

                <td></td>
              </tr>
            );
          })}
          <tr className="whitespace-nowrap border-none">
            <td className="p-4 is-flex is-justify-content-center">
              {addBusiness ? (
                <input
                  autoFocus
                  className="input is-small is-size-6"
                  onKeyDown={handleAddBusiness}
                  onBlur={() => setAddBusiness(false)}
                />
              ) : (
                <div
                  onClick={() => setAddBusiness(true)}
                  className="has-text-info border  border-info rounded is-clickable px-2 "
                >
                  <PlusCircle className="mr-1" /> Add Capability
                </div>
              )}
            </td>
            {Array(pocUseCases.length + 1)
              .fill()
              .map((v, d) => (
                <td key={d} className="py-5 " />
              ))}
          </tr>
          {businessValues.length < 6 &&
            Array(6 - businessValues.length)
              .fill()
              .map((dummy, e) => {
                return (
                  <tr className="whitespace-nowrap border-none" key={e}>
                    {Array(pocUseCases.length + 2)
                      .fill()
                      .map((dummy, f) => (
                        <td key={f} className="py-5 " />
                      ))}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </section>
  );
}
