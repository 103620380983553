/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bell, Gear } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import useAuth from "hooks/useAuth";
import { logout } from "services/authSlice";
import { useGetVendorQuery } from "services/api";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UserAvatar from "components/common/UserAvatar";
import useFeatureFilter from "hooks/useFeatureFilter";
import useRbac from "../common/Rbac/useRbac";
import WithRbac from "../common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";

const TopBar = () => {
  const { currentUser, isProspect } = useAuth();
  const dispatch = useDispatch();
  const { data: vendor } = useGetVendorQuery(currentUser?.vendorId);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  const { pathname } = useLocation();

  const { showResourceTab, showAnalyticsTab } = useFeatureFilter();
  const { userHasPermission } = useRbac();

  let navLinkActiveClassName = "is-active";

  return (
    <nav
      className="navbar is-white is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      id="topNavBar"
    >
      <div className="navbar-brand">
        {vendor && (
          <Link to="/dashboard" className="navbar-item mx-4 is-size-4">
            {vendor.name}
          </Link>
        )}

        <a
          role="button"
          className="navbar-burger is-pulled-right"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setIsActive(act => !act)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${isActive && "is-active"}`}>
        <div className="navbar-start">
          <div className="navbar-start">
            <NavLink
              to="/dashboard"
              className={({ isActive }) => {
                if (
                  pathname.startsWith("/dashboard") ||
                  pathname.startsWith("/pocs/")
                ) {
                  isActive = true;
                }
                return `navbar-item ${isActive ? navLinkActiveClassName : ""}`;
              }}
            >
              Opportunities
            </NavLink>

            {userHasPermission(UserPermissions.RESOURCES_READ) &&
              showResourceTab && (
                <NavLink
                  to="/attachments"
                  className={({ isActive }) => {
                    return `navbar-item ${
                      isActive ? navLinkActiveClassName : ""
                    }`;
                  }}
                >
                  Resources
                </NavLink>
              )}

            {userHasPermission(UserPermissions.ANALYTICS_READ) &&
              showAnalyticsTab && (
                <NavLink
                  to="/analytics"
                  className={({ isActive }) => {
                    return `navbar-item ${
                      isActive ? navLinkActiveClassName : ""
                    }`;
                  }}
                >
                  Analytics
                </NavLink>
              )}
          </div>
        </div>
        <div className="navbar-end is-align-items-center">
          {!isProspect && (
            <>
              <div className="navbar-item has-dropdown is-hoverable">
                <WithRbac
                  permissionOneOf={[
                    UserPermissions.QUESTIONNAIRE_MANAGE,
                    UserPermissions.SCOPING_MANAGE,
                    UserPermissions.ACTIONPLAN_MANAGE,
                    UserPermissions.SPECDOCSECTIONS_MANAGE,
                    UserPermissions.INTEGRATION_MANAGE
                  ]}
                >
                  <NavLink
                    // to={"/settings/manage-questions"}
                    className={({ isActive }) => {
                      if (pathname.startsWith("/settings")) {
                        isActive = true;
                      }
                      return `navbar-link ${
                        isActive ? navLinkActiveClassName : ""
                      }`;
                    }}
                  >
                    <Gear className="mr-2" />
                    Settings
                  </NavLink>
                </WithRbac>

                <div className="navbar-dropdown">
                  <WithRbac
                    permissionOneOf={UserPermissions.QUESTIONNAIRE_MANAGE}
                  >
                    <NavLink
                      to="/settings/manage-questions"
                      className={({ isActive }) => {
                        return `navbar-item ${
                          isActive ? navLinkActiveClassName : ""
                        }`;
                      }}
                    >
                      Manage Questions
                    </NavLink>
                  </WithRbac>
                  <WithRbac permissionOneOf={UserPermissions.SCOPING_MANAGE}>
                    <NavLink
                      to="/settings/manage-usecases"
                      className={({ isActive }) => {
                        return `navbar-item ${
                          isActive ? navLinkActiveClassName : ""
                        }`;
                      }}
                    >
                      Manage Requirements
                    </NavLink>
                  </WithRbac>
                  <WithRbac permissionOneOf={UserPermissions.ACTIONPLAN_MANAGE}>
                    <NavLink
                      to="/settings/manage-checklist"
                      className={({ isActive }) => {
                        return `navbar-item ${
                          isActive ? navLinkActiveClassName : ""
                        }`;
                      }}
                    >
                      Manage Action Plan
                    </NavLink>
                  </WithRbac>
                  <WithRbac
                    permissionOneOf={UserPermissions.SPECDOCSECTIONS_MANAGE}
                  >
                    <NavLink
                      to="/settings/manage-specdocsections"
                      className={({ isActive }) => {
                        return `navbar-item ${
                          isActive ? navLinkActiveClassName : ""
                        }`;
                      }}
                    >
                      Manage Overview Sections
                    </NavLink>
                  </WithRbac>

                  <WithRbac
                    permissionOneOf={UserPermissions.SPECDOCSECTIONS_MANAGE}
                  >
                    <NavLink
                      to="/settings/manage-users"
                      className={({ isActive }) => {
                        return `navbar-item ${
                          isActive ? navLinkActiveClassName : ""
                        }`;
                      }}
                    >
                      Manage Users
                    </NavLink>
                  </WithRbac>

                  <WithRbac
                    permissionOneOf={UserPermissions.INTEGRATION_MANAGE}
                  >
                    <NavLink
                      to="/settings/integrations"
                      className={({ isActive }) => {
                        return `navbar-item ${
                          isActive ? navLinkActiveClassName : ""
                        }`;
                      }}
                    >
                      Integrations
                    </NavLink>
                  </WithRbac>
                </div>
              </div>
            </>
          )}

          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link">
              <UserAvatar user={currentUser} />
            </div>

            <div className="navbar-dropdown is-right">
              <div className="navbar-item">
                Roles:{" "}
                <span className="tag ml-1">{currentUser.roles.join(", ")}</span>
              </div>
              <div className="navbar-item">
                <Link to="/user/profile">My Profile</Link>
              </div>
              <hr className="dropdown-divider" />
              <div className="navbar-item">
                <Link to="/" onClick={handleLogout}>
                  Log out
                </Link>
              </div>
            </div>
          </div>
          <Bell className="mx-5" />
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
