import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useGetMetaQuery } from "services/api";
import { convertArrayToSelectOptions } from "utils/helpers";
import { ChevronDown, FunnelFill, Search } from "react-bootstrap-icons";
import useAuth from "hooks/useAuth";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import SliderCard from "./SliderCard";
import { currency } from "utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { createFilterFeedbackUQuery } from "pages/FeedbackRoutes";

const dateType = [
  { label: "Start Date", type: "startDateList" },
  { label: "End Date", type: "endDateList" }
];
const dateList = {
  startDateList: [
    { label: "From", key: "pocStartDateFrom" },
    { label: "To", key: "pocStartDateTo" }
  ],
  endDateList: [
    { label: "From", key: "pocEndDateFrom" },
    { label: "To", key: "pocEndDateTo" }
  ]
};

export default function FilterCard({ filter, setFilter }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isProspect } = useAuth();
  const [dropPriority, setDropPriority] = useState(false);
  const [dropOutCome, setDropOutcome] = useState(false);
  const [dropGeo, setDropGeo] = useState(false);
  const [dropIndustry, setDropIndustry] = useState(false);
  const [dropSegment, setDropSegment] = useState(false);
  const [open, setOpen] = useState(false);
  const { data: metadata } = useGetMetaQuery();
  const {
    industries,
    geographies,
    segments,
    pocPhases,
    pocStatuses,
    priorities,
    feedbackTypes
    // feedbackStatuses,
    // personas
  } = metadata || {};

  const createOptions = data =>
    data ? convertArrayToSelectOptions(Object.values(data)) : [];
  const onClickFilter = value => {
    const singles = [
      "pocIndustryNames",
      "pocGeographyNames",
      "pocSegmentNames"
    ];
    const keys = Object.keys(value);
    const key = keys[0];
    const val = value[key];
    let newFilter = { ...filter };

    if (key.includes("Date") || singles.includes(key)) {
      newFilter[key] = [val];
    } else if (key.includes("Acv")) {
      newFilter[keys[0]] = [value[keys[0]]];
      newFilter[keys[1]] = [value[keys[1]]];
    } else if (key !== "search") {
      if (newFilter[key]?.length) {
        if (newFilter[key].includes(val)) {
          const index = newFilter[key].indexOf(val);
          let arr = [...newFilter[key]];
          if (newFilter[key]?.length > 1) {
            arr.splice(index, 1);
            newFilter[key] = arr;
          } else {
            delete newFilter[key];
          }
        } else {
          let arr = [...newFilter[key]];
          arr.push(val);
          newFilter[key] = arr;
        }
      } else {
        newFilter[key] = [val];
      }
    } else {
      newFilter[key] = val;
    }
    const filterQuery = createFilterFeedbackUQuery(newFilter);
    const replacedFilterQuery = filterQuery.replaceAll("[]=", "=");

    navigate(pathname + replacedFilterQuery);
    setFilter(newFilter);
  };

  const clearFilter = key => {
    let newFilter = { ...filter };
    if (newFilter[key]) {
      delete newFilter[key];
      if (key.includes("StartDate")) {
        delete newFilter["pocStartDateTo"];
      }
      if (key.includes("EndDate")) {
        delete newFilter["pocEndDateTo"];
      }
      if (key.includes("pocTotalAcv")) {
        delete newFilter["pocTotalAcvMax"];
        delete newFilter["pocTotalAcvMin"];
      }
      const filterQuery = createFilterFeedbackUQuery(newFilter);
      const replacedFilterQuery = filterQuery.replaceAll("[]=", "=");

      navigate(pathname + replacedFilterQuery);
      setFilter(newFilter);
    }
  };

  const isDropPriority = dropPriority ? "is-active" : "";
  const isDroptOutcome = dropOutCome ? "is-active" : "";

  const dropList = [
    {
      label: "Geography",
      isDrop: dropGeo ? "is-active" : "",
      drop: dropGeo,
      setDrop: setDropGeo,
      key: "pocGeographyNames",
      options: geographies
    },
    {
      label: "Industry",
      isDrop: dropIndustry ? "is-active" : "",
      drop: dropIndustry,
      setDrop: setDropIndustry,
      key: "pocIndustryNames",
      options: industries
    },
    {
      label: "Segment",
      isDrop: dropSegment ? "is-active" : "",
      drop: dropSegment,
      setDrop: setDropSegment,
      key: "pocSegmentNames",
      options: segments
    }
  ];

  const frontFilter = ["feedbackPriorities", "pocStatuses"];
  const otherFilter = Object.keys(filter).filter(x => !frontFilter.includes(x));

  const otherFilterLength = otherFilter.reduce((akumulator, currentValue) => {
    return currentValue !== "search"
      ? akumulator + filter[currentValue].length
      : 0;
  }, 0);

  return (
    <>
      {!isProspect && (
        <>
          <div className="columns mb-0">
            <div className="column is-6">
              <div className="buttons">
                <div className={`dropdown ${isDropPriority} button is-light`}>
                  <div
                    tabIndex="0"
                    onClick={() => setDropPriority(!dropPriority)}
                    onBlur={() => setDropPriority(false)}
                    className="dropdown-trigger form-control form-select whitespace-nowrap overflow-hidden is-flex is-align-items-center"
                  >
                    Feedback Priority:{" "}
                    <span>{filter?.feedbackPriorities?.join(", ")}</span>
                    <ChevronDown className="ml-2" />
                  </div>
                  <div
                    className="dropdown-menu py-0 w-100"
                    id="dropdown-menu3"
                    role="menu"
                  >
                    <div className="dropdown-content">
                      <div
                        onMouseDown={() => clearFilter("feedbackPriorities")}
                        className="dropdown-item fst-italic text-muted"
                      >
                        Clear
                      </div>
                      {createOptions(priorities)?.map(({ key }, i) => (
                        <div
                          key={i}
                          onMouseDown={() =>
                            onClickFilter({ feedbackPriorities: key })
                          }
                          className={`dropdown-item ${
                            filter?.feedbackPriorities?.includes(key)
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          {key}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className={`dropdown ${isDroptOutcome} button is-light`}>
                  <div
                    tabIndex="1"
                    onClick={() => setDropOutcome(!dropOutCome)}
                    onBlur={() => setDropOutcome(false)}
                    className="dropdown-trigger form-control form-select whitespace-nowrap overflow-hidden is-flex is-align-items-center"
                  >
                    Eval Outcome: <span>{filter?.pocStatuses?.join(", ")}</span>
                    <ChevronDown className="ml-2" />
                  </div>
                  <div
                    className="dropdown-menu py-0 w-100"
                    id="dropdown-menu3"
                    role="menu"
                  >
                    <div className="dropdown-content">
                      <div
                        onMouseDown={() => clearFilter("pocStatuses")}
                        className="dropdown-item fst-italic text-muted"
                      >
                        Clear
                      </div>
                      {createOptions(pocStatuses)?.map(({ key }, i) => (
                        <div
                          onMouseDown={() =>
                            onClickFilter({ pocStatuses: key })
                          }
                          key={i}
                          className={`dropdown-item ${
                            filter?.pocStatuses?.includes(key)
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          {key}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-2 is-flex is-align-items-center is-justify-content-center">
              <span
                onClick={() => setOpen(true)}
                className={`p-2 is-clickable button  ${
                  otherFilterLength > 0 ? "has-text-info" : "is-light"
                }`}
              >
                <FunnelFill
                  className={`w-1r h-4r mr-1 ${
                    otherFilterLength > 0
                      ? "has-text-info"
                      : "has-text-grey-light"
                  }`}
                />
                Other Filters: {otherFilterLength}
              </span>
            </div>
            <div className="column is-4">
              <div className="input w-100">
                <Search />
                <input
                  onChange={e => onClickFilter({ search: e.target.value })}
                  placeholder="Search here"
                  className="ml-3 w-100"
                  style={{ border: "none", outline: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="tags mb-3">
            {Object.keys(filter).map((key, i) => {
              let dateObj;
              let label = key;
              switch (key) {
                case "feedbackTypes":
                  label = "Feedback Type";
                  break;
                case "pocGeographyNames":
                  label = "Geography";
                  break;
                case "pocIndustryNames":
                  label = "Industry";
                  break;
                case "pocSegmentNames":
                  label = "Segment";
                  break;
                case "pocPhases":
                  label = "Eval Phase";
                  break;

                default:
                  break;
              }

              if (key?.includes("StartDateFrom")) {
                dateObj = `Start Date: ${filter.pocStartDateFrom}${
                  filter.pocStartDateTo ? " to " + filter.pocStartDateTo : ""
                }`;
              }

              if (key?.includes("EndDateFrom")) {
                dateObj = `End Date: ${filter.pocEndDateFrom}${
                  filter.pocEndDateTo ? " to " + filter.pocEndDateTo : ""
                }`;
              }
              if (key?.includes("Acv")) {
                dateObj = `Total Acv: ${currency(filter.pocTotalAcvMin)}${
                  " - " + currency(filter.pocTotalAcvMax)
                }`;
              }
              if (key.includes("DateTo") || key.includes("TotalAcvMax")) {
                dateObj = null;
                label = null;
              }

              const selectedLabel = dateObj || label;

              if (
                !frontFilter.includes(label) &&
                key !== "search" &&
                selectedLabel
              ) {
                return (
                  <div className="tag is-small" key={i}>
                    <div className="whitespace-nowrap  is-flex is-align-items-center is-justify-content-space-between border rounded px-2 py-1">
                      {dateObj
                        ? dateObj
                        : `${label}: ${filter[key]?.join(", ")}`}
                      <button
                        onClick={() => clearFilter(key)}
                        className="delete is-small ml-2"
                      ></button>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      )}

      <Dialog
        as="div"
        open={open}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setOpen(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 overflow-hidden has-background-white z-30 rounded-md w-45r">
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
              Feedback Types
            </div>
            <div className="column buttons is-flex is-align-items-center gap-1">
              {createOptions(feedbackTypes)?.map(({ key }, i) => (
                <div
                  key={i}
                  onClick={() => onClickFilter({ feedbackTypes: key })}
                  className={`${
                    filter?.feedbackTypes?.includes(key)
                      ? "button is-small is-info"
                      : "button is-small is-light"
                  } h-75 is-flex is-align-items-center is-justify-content-center`}
                >
                  {key}
                </div>
              ))}
            </div>
          </div>
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
              Deal Status
            </div>
            <div className="column buttons is-flex is-align-items-center gap-1">
              <div
                // onClick={() => onClickFilter({ dealStatus: "Current" })}
                className={`button h-75 ${
                  filter?.dealStatus?.includes("Current")
                    ? "button is-small is-info"
                    : "button is-small is-light"
                } is-flex is-align-items-center is-justify-content-center disabled`}
              >
                Current
              </div>
              <div
                // onClick={() => onClickFilter({ dealStatus: "Won" })}
                className={` h-75 ${
                  filter?.dealStatus?.includes("Won")
                    ? "button is-small is-info"
                    : "button is-small is-light"
                } is-flex is-align-items-center is-justify-content-center disabled`}
              >
                Won
              </div>
              <div
                // onClick={() => onClickFilter({ dealStatus: "Lost" })}
                className={` h-75 ${
                  filter?.dealStatus?.includes("Lost")
                    ? "button is-small is-info"
                    : "button is-small is-light"
                } is-flex is-align-items-center is-justify-content-center disabled`}
              >
                Lost
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
              Total ACV
            </div>
            <div className="column w-100">
              <div className="py-auto w-100 is-flex">
                <div className="mr-4">$0</div>
                <div className="w-100 is-flex is-align-items-center">
                  <div className="w-100">
                    <SliderCard filter={filter} onClickFilter={onClickFilter} />
                  </div>
                </div>
                <div className="ml-4">$5M</div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
              Persona
            </div>
            <div className="column buttons is-flex is-align-items-center gap-1">
              <div
                // onClick={() => onClickFilter({ persona: "Tech Buyer" })}
                className={`h-75 ${
                  filter?.persona?.includes("Tech Buyer")
                    ? "button is-small is-info"
                    : "button is-small is-light"
                } is-flex is-align-items-center is-justify-content-center disabled`}
              >
                Tech Buyer
              </div>
              <div
                // onClick={() => onClickFilter({ persona: "Economic Buyer" })}
                className={`h-75 ${
                  filter?.persona?.includes("Economic Buyer")
                    ? "button is-small is-info"
                    : "button is-small is-light"
                } is-flex is-align-items-center is-justify-content-center disabled`}
              >
                Economic Buyer
              </div>
            </div>
          </div>
          {dropList.map((d, i) => (
            <div key={i} className="columns">
              <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
                {d.label}
              </div>
              <div className="column is-flex is-align-items-center">
                <div className={` dropdown ${d.isDrop} w-50 h-8r`}>
                  <div
                    tabIndex="1"
                    onClick={() => d.setDrop(!d.drop)}
                    onBlur={() => d.setDrop(false)}
                    className={`dropdown-trigger form-control is-clickable rounded p-2 whitespace-nowrap overflow-hidden is-flex is-align-items-center ${
                      filter[d.key] ? "has-text-info is-border-info" : ""
                    }`}
                  >
                    {filter[d.key] || "All"}
                  </div>
                  <div
                    className="dropdown-menu py-0 min-w-100 is-absolute "
                    id="dropdown-menu3"
                    role="menu"
                  >
                    <div className="dropdown-content max-h-50r w-auto overflow-y-auto ">
                      <div
                        onMouseDown={() => {
                          clearFilter(d.key);
                        }}
                        className="dropdown-item fst-italic text-muted py-0"
                      >
                        All
                      </div>
                      {createOptions(d.options)?.map(({ key }, i) => (
                        <div
                          onMouseDown={() => {
                            let obj = {};
                            obj[d.key] = key;
                            onClickFilter(obj);
                          }}
                          key={i}
                          className={`dropdown-item py-0 is-clickable ${
                            filter[d.key]?.includes(key) ? "text-primary" : ""
                          }`}
                        >
                          {key}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
              Eval Phase
            </div>
            <div className="column buttons is-flex is-align-items-center gap-1 whitespace-nowrap">
              {createOptions(pocPhases)?.map(({ key }, i) => (
                <div
                  key={i}
                  onClick={() => onClickFilter({ pocPhases: key })}
                  className={`${
                    filter?.pocPhases?.includes(key)
                      ? "button is-small is-info"
                      : "button is-small is-light"
                  } h-75 is-flex is-align-items-center is-justify-content-center`}
                >
                  {key}
                </div>
              ))}
            </div>
          </div>
          {dateType.map(({ label, type }, i) => (
            <div key={i} className="columns">
              <div className="column is-flex is-align-items-center is-one-quarter px-0 has-text-weight-semibold">
                {label}
              </div>
              {dateList[type].map(({ key, ...dl }, i2) => {
                return (
                  <div
                    key={i2}
                    className="column is-flex is-align-items-center gap-2"
                  >
                    <div>{dl.label}:</div>
                    <DatePicker
                      disabled={
                        (key === "pocStartDateTo" &&
                          !filter.pocStartDateFrom) ||
                        (key === "pocEndDateTo" && !filter.pocEndDateFrom)
                      }
                      className={`input w-75 h-8r is-clickable text-center ${
                        filter[key] ? "has-text-info is-border-info" : ""
                      }`}
                      value={filter[key] ? filter[key][0] : ""}
                      customInput={
                        !filter[key] ? (
                          <div className="is-flex is-justify-content-center w-75">
                            <div>-</div>
                          </div>
                        ) : null
                      }
                      onChange={date => {
                        let obj = {};
                        obj[key] = dayjs(date).format("YYYY-MM-DD");
                        onClickFilter(obj);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </Dialog>
    </>
  );
}
