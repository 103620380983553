import { useState, memo } from "react";
import useAuth from "hooks/useAuth";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import ActionsModal from "components/common/ActionsModal";
import WithRbac from "../Rbac/withRbac";
import { UserPermissions } from "../../../config/userPermissions";

export const FeedbackDoneCell = ({ cell, value, disabled, edit }) => {
  const feedback = cell?.row?.original;

  return (
    <WithRbac permissionOneOf={UserPermissions.EVALFEEDBACK_MARKCOMPLETE}>
      <div className="d-flex align-items-center justify-content-center">
        <input
          className={`form-checked-input FeedbackCell-checkbox larger ${
            disabled ? "disabled" : ""
          }`}
          type="checkbox"
          checked={value}
          title="Mark as Done"
          onChange={evt =>
            !disabled && edit({ ...feedback, addressed: evt.target.checked })
          }
        />
      </div>
    </WithRbac>
  );
};

export const FeedbackActionCell = ({ cell, disabled, del = () => {} }) => {
  const feedback = cell?.row?.original;
  const [actionsModalOpen, setActionsModalOpen] = useState(false);
  const { currentUser, isProspect } = useAuth();
  const close = () => setActionsModalOpen(false);
  const canDelete =
    !isProspect || currentUser.id === feedback.stakeholder.userId;
  return (
    <div className="d-flex align-items-center justify-content-center">
      {!disabled && (
        <ThreeDotsVertical
          className={`action-icon ${canDelete ? "" : "disabled"}`}
          onClick={() => setActionsModalOpen(true)}
        />
      )}
      {actionsModalOpen && (
        <ActionsModal
          addToSalesforce
          close={close}
          del={del}
          feedback={feedback}
          id={feedback.id}
          right="-1rem"
        />
      )}
    </div>
  );
};
