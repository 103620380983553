import React, { useCallback, useMemo, useRef, useState } from "react";
// import Select from "react-select";
import { ExclamationDiamond, GripVertical, Trash } from "react-bootstrap-icons";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

import {
  useDeletePocSpecdocsectionMutation,
  useEditPocSpecdocsectionsMutation
} from "services/api";
import TextEditor from "components/common/TextEditor";
import ApiCallIndicator from "components/common/ApiCallIndicator";
import PocUseCasesBulkAttach from "pages/PocUseCases/PocUseCasesBulkAttach";
import PocQuestionsBulkAttach from "pages/PocQuestions/PocQuestionsBulkAttach";
import PlusSearchBox from "pages/PocOverview/PlusSearchBox";
import Attachment from "pages/Attachments";
import useRbac from "components/common/Rbac/useRbac";
import { getInitialCamelCase } from "utils/helpers";
import { UserRoles } from "config/userRoles";

export default function PocSpecdocsection({
  data,
  isDeletable = false,
  isEditable = false,
  isReorderable = false,
  dragHandleProps = {}
}) {
  const navigate = useNavigate();
  const { pocId } = useParams();
  const [editPocSpecdocsection, editPocSpecdocsectionResponse] =
    useEditPocSpecdocsectionsMutation();
  const [deletePocSpecdocsection] = useDeletePocSpecdocsectionMutation();
  const [titleEditing, setTitleEditing] = useState(false);
  const [bodyEditing, setBodyEditing] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [plusSearch, setPlusSearch] = useState({ type: null, value: "" });
  const [cursorPos, setCursorPos] = useState({});
  const { userHasRole } = useRbac();

  const allowedToSelectRole = userHasRole([
    UserRoles.ADMIN,
    UserRoles.SE_MANAGER,
    UserRoles.SALES_ENGINEER
  ]);

  const editorRef = useRef(null);
  const handleEditTitle = (e, data) => {
    if (e.key === "Enter" || !e.key) {
      let newTitle = e.target.value;
      if (data.title !== newTitle) {
        editPocSpecdocsection({ pocId, id: data.id, title: e.target.value });
      }
      setTitleEditing(false);
    }
  };

  const handleEditBody = (newBody, data) => {
    if (data.body !== newBody) {
      editPocSpecdocsection({ pocId, id: data.id, body: newBody });
    }
  };

  const handleRemoveSection = id => {
    deletePocSpecdocsection(id)
      .unwrap()
      .catch(error => toast.error(`Error: ${error.data.message}`));
    setShowModal(null);
  };

  const editorContextMenu = [
    {
      name: "addRequirements",
      text: "+ Search Requirements",
      action: () => setShowModal("addRequirements")
    },
    {
      name: "addQuestions",
      text: "+ Search Questions",
      action: () => setShowModal("addQuestions")
    },
    {
      name: "addDiagrams",
      text: "+ Add Diagrams",
      action: () => setShowModal("addDiagrams")
    },
    {
      name: "goToRequirement",
      text: "Go to requirement",
      action: () => navigate(`/pocs/${pocId}/usecases`)
    }
  ];

  const handleAddToSection = payload => {
    const editor = editorRef.current;
    const symbol = plusSearch.type === "requirements" ? "+" : "?";
    editor.selection.setContent(payload);
    if (plusSearch.type) {
      editor.setContent(
        editor.getContent().replace(symbol + plusSearch.value, "")
      );
      setPlusSearch({ type: null, value: "" });
    }
    editor.focus();
  };

  const handleEditorKeydown = e => {
    setCursorPos(editorRef?.current.selection.getBoundingClientRect());

    if (bodyEditing) {
      if (e.key === "+" && !plusSearch.type) {
        setPlusSearch({ type: "requirements", value: "" });
      }
      if (e.key === "?" && !plusSearch.type) {
        setPlusSearch({ type: "questions", value: "" });
      }
      if (plusSearch.type) {
        if (
          e.key === "Escape" ||
          e.key === "Enter" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight" ||
          e.key === "ArrowUp" ||
          e.key === "ArrowDown"
        ) {
          setPlusSearch({ type: null, value: "" });
        }
        if (e.key === "Backspace") {
          if (plusSearch.value === "") {
            setPlusSearch({ type: null, value: "" });
          } else {
            setPlusSearch({
              ...plusSearch,
              value: plusSearch.value.slice(0, -1)
            });
          }
        }
        if (
          e.key !== "Enter" &&
          String.fromCharCode(e.keyCode).match(/(\w|\s)/g)
        ) {
          setPlusSearch({
            ...plusSearch,
            value: plusSearch.value + e.key
          });
        }
      }
    }
  };

  const highlightedSection = useMemo(() => {
    const text = document.createElement("textarea");
    text.innerHTML = editorRef.current?.selection
      ?.getContent()
      .replace(/<[^>]*>?/gm, "");
    return text.value;
  }, [editorRef, showModal]);

  const MultiValueLabel = useCallback(({ children }) => {
    return <div title={children}>{getInitialCamelCase(children)}</div>;
  }, []);

  const roleOptions = useMemo(() => {
    const keys = Object.keys(UserRoles || {});
    const adminIdx = keys.indexOf("ADMIN");
    const option = keys.map(key => ({
      label: UserRoles[key],
      value: UserRoles[key]
    }));
    option.splice(adminIdx, 1);
    return option;
  }, [UserRoles]);

  return (
    <div className="mb-5">
      <nav className="level  mb-2">
        <div className="level-left">
          {isReorderable ? (
            <div className="level-item" {...dragHandleProps}>
              <GripVertical />
            </div>
          ) : (
            ""
          )}
          <div className="level-item">
            {titleEditing ? (
              <input
                autoFocus
                defaultValue={data.title}
                className="input form-control"
                onKeyDown={e => handleEditTitle(e, data)}
                onBlur={e => handleEditTitle(e, data)}
              />
            ) : (
              <div
                className="label"
                onClick={() => isEditable && setTitleEditing(true)}
              >
                {data.title}
              </div>
            )}
          </div>
          <div className="level-item">
            <ApiCallIndicator apiResponse={editPocSpecdocsectionResponse} />
          </div>
        </div>

        <div className="level-right is-flex is-align-items-center ">
          {/* {allowedToSelectRole && (
            <div className="level-item is-flex is-align-items-center">
              <div className="mr-4">Visibility:</div>
              <div className="level-item is-small">
                <Select
                  components={{ MultiValueLabel }}
                  placeholder="Select Role"
                  closeMenuOnSelect={false}
                  isMulti
                  onChange={handleEditVisibility}
                  options={roleOptions}
                />
              </div>
            </div>
          )} */}

          {isDeletable ? (
            <p className="level-item is-flex is-align-items-center">
              <span
                onClick={() => setShowModal("deleteSection")}
                className="is-clickable is-flex is-align-items-center"
                title={`Delete section "${data.title}"`}
              >
                <Trash />
              </span>
            </p>
          ) : (
            ""
          )}
        </div>
      </nav>

      <div className="is-relative">
        <TextEditor
          ref={editorRef}
          defaultValue={data.body}
          contextmenu={isEditable && editorContextMenu}
          height={data.height ? data.height : "500px"}
          disabled={!bodyEditing}
          onBlur={e => {
            if (isEditable) {
              if (!plusSearch.type && showModal === null) {
                handleEditBody(e.target.getContent(), data);
                setBodyEditing(false);
              }
            }
          }}
          onClick={() => isEditable && setBodyEditing(true)}
          onKeyDown={() => isEditable && handleEditorKeydown}
        />
        <PlusSearchBox
          position={cursorPos}
          search={plusSearch}
          addSection={handleAddToSection}
          onClose={() => {
            setPlusSearch({ type: null, value: "" });
          }}
          onBrowseAll={() => {
            setShowModal(
              plusSearch.type === "requirements"
                ? "addRequirements"
                : "addQuestions"
            );
            setPlusSearch({ type: null, value: "" });
          }}
        />
        {bodyEditing && (
          <div className="is-size-7 mt-1">
            Keyboard shortcuts: <kbd className="keyboard-shortcut ml-2">?</kbd>{" "}
            = Add a Question <kbd className="keyboard-shortcut ml-2">+</kbd> =
            Add a Requirement
            <kbd className="keyboard-shortcut ml-2">Ctrl + Right Click</kbd> =
            Browser context menu
          </div>
        )}
      </div>

      <Dialog
        as="div"
        open={showModal !== null}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setShowModal(null)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        {showModal === "deleteSection" && (
          <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
            <div className="is-flex is-align-items-center">
              <ExclamationDiamond
                size="25px"
                className="has-text-danger mr-3"
              />
              <div className="is-size-4">Remove section "{data?.title}"?</div>
            </div>
            <div className="my-5">
              You're about to permanently delete this section, and its data.
            </div>
            <div className="buttons is-justify-content-end">
              <button
                onClick={() => handleRemoveSection(data.id)}
                className="button is-danger"
              >
                Remove
              </button>
              <button
                onClick={() => setShowModal(null)}
                className="button is-light"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {showModal === "addRequirements" && (
          <PocUseCasesBulkAttach
            show={!!showModal}
            showType="section"
            handleClose={() => setShowModal(null)}
            addSection={handleAddToSection}
            highlightedSection={highlightedSection}
          />
        )}
        {showModal === "addQuestions" && (
          <PocQuestionsBulkAttach
            handleClose={() => setShowModal(null)}
            addSection={handleAddToSection}
            highlightedSection={highlightedSection}
          />
        )}
        {showModal === "addDiagrams" && (
          <div className="w-75">
            <Attachment
              addSection={handleAddToSection}
              handleClose={() => setShowModal(null)}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}
