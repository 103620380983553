import dayjs from "dayjs";
import UserAvatar from "components/common/UserAvatar";
import {
  FileEarmark,
  FileEarmarkPdf,
  FileEarmarkWord,
  FileEarmarkPpt,
  FileEarmarkExcel,
  FileEarmarkText,
  FileEarmarkImage,
  FileEarmarkZip,
  FileEarmarkMusic,
  FileEarmarkPlay,
  Trash
} from "react-bootstrap-icons";
const handleType = (type, view) => {
  if (type === "") {
    type = "unknown";
  }
  let currentType = type;
  let icon = <FileEarmark />;
  switch (true) {
    case type.includes("pdf"):
      icon = <FileEarmarkPdf className="has-text-danger" />;
      currentType = "Adobe Acrobat Document";
      break;
    case type.includes("word"):
      icon = <FileEarmarkWord className="has-text-info" />;
      currentType = "Microsoft Word Document";
      break;
    case type.includes("presentation"):
      icon = <FileEarmarkPpt className="has-text-warning" />;
      currentType = "Microsoft PowerPoint Presentation";
      break;
    case type.includes("excel"):
      icon = <FileEarmarkExcel className="has-text-success" />;
      currentType = "Microsoft Excel Worksheet";
      break;
    case type.includes("text"):
      icon = <FileEarmarkText />;
      currentType = "Text Document";
      break;
    case type.includes("image"):
      icon = <FileEarmarkImage className="has-text-link" />;
      break;
    case type.includes("zip"):
    case type.includes("rar"):
      icon = <FileEarmarkZip className="has-text-warning-dark" />;
      break;
    case type.includes("audio"):
      icon = <FileEarmarkMusic className="has-text-info" />;
      break;
    case type.includes("video"):
      icon = <FileEarmarkPlay className="has-text-danger-dark" />;
      break;
    default:
      icon = <FileEarmark />;
      currentType = type;
      break;
  }

  if (view === "icon") {
    return icon;
  } else {
    return currentType;
  }
};

export const columns = [
  {
    Header: "",
    accessor: "addToSection",
    Cell: ({ row, addToSection, files, setFiles }) => {
      const newFiles = [...files];

      return !!addToSection ? (
        <input
          type="checkbox"
          className="form-check-input larger"
          onChange={e => {
            e.target.checked
              ? newFiles.push(row.original)
              : newFiles.splice(
                  files.find(({ id }) => id === row.id),
                  1
                );
            setFiles(newFiles);
          }}
        />
      ) : null;
    }
  },
  {
    Header: "Name",
    accessor: "fileName",
    Cell: col => {
      const atc = col.row.original;
      const icon = handleType(atc.mimeType, "icon");
      return (
        <div
          className="is-flex is-clickable"
          onClick={() =>
            window.open(col.row.original.fileDownloadUrl, "_blank").focus()
          }
        >
          <div className="h-4r w-1r mr-3">
            {atc.fileIconUrl ? <img src={atc.fileIconUrl} alt="icon" /> : icon}
          </div>
          <div>{atc.fileName}</div>
        </div>
      );
    }
  },
  {
    Header: "User",
    accessor: "createdByUser",
    Cell: ({ value: user }) => {
      return <UserAvatar user={user} showName={false} />;
    }
  },
  {
    Header: "Date Modified",
    accessor: atc => dayjs(atc.updatedAt).format("M/D/YYYY  h:mm A")
  },
  {
    Header: "Type",
    accessor: atc => handleType(atc.mimeType, "extension")
  },
  {
    Header: "Action",
    accessor: "id",
    Cell: ({ onDelete, value }) => {
      return (
        <div className="is-flex is-justify-content-center">
          <Trash onClick={() => onDelete(value)} />
        </div>
      );
    }
  }
];

export const sectionHeader = [
  "",
  "User",
  "Name",
  "Date Modified",
  "Type",
  "Action"
];
