import React, { useState } from "react";
import AnalyticsMenu from "./AnalyticsMenu";

export default function TeamUtilization() {
  const images = [
    "team-capacity1.svg",
    "team-capacity2.svg",
    "team-capacity3.svg"
  ];
  const [index, setIndex] = useState(0);
  const handleToggle = () => {
    if (index === images.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <div onClick={handleToggle} className="is-clickable">
      <AnalyticsMenu />
      <div className="card">
        <div className="card-content">
          <img
            className="h-auto w-auto"
            src={`/analytics/${images[index]}`}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
