import React from "react";
// import PillSelector from "./PillSelector";
import EditableDate from "components/common/TableCells/EditableDate";
import TableCellSelectableStakeholder from "components/common/TableCells/TableCellSelectableStakeholder";
import Select from "components/common/Select";
import {
  useDeletePocSuccesscriteriasMutation,
  useEditPocUseCaseSuccessCriteriaMutation
} from "services/api";
import { useParams } from "react-router-dom";
import { Menu, Popover } from "@headlessui/react";
import { ChatLeft, ThreeDotsVertical } from "react-bootstrap-icons";
import useAuth from "hooks/useAuth";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";
import ReactShowMoreText from "react-show-more-text";
import SanitizeHtml from "components/common/SanitizeHtml";

export const importances = [
  // { key: 0, value: "" },
  { key: 1, value: "Nice" },
  { key: 2, value: "Want" },
  { key: 3, value: "Need" }
];
export const outcomes = [
  // { key: 0, value: "" },
  { key: 1, value: "Fail" },
  { key: 2, value: "Pass" }
];

export const PHASES = {
  DRAFT: "Draft",
  PENDING: "Pending Agreement",
  AGREED: "Agreed"
};

const Actions = ({ base = {} }) => {
  const value = base.value;
  const { pocId } = useParams();
  const { isProspect } = useAuth();
  const [edit] = useEditPocUseCaseSuccessCriteriaMutation();
  const [remove] = useDeletePocSuccesscriteriasMutation();

  const id = value.id;
  const handleDelete = () => {
    remove({
      id,
      pocId
    });
  };
  const pocUsecaseId = base.currentPocUseCase.id;
  const addNote = (e, close) => {
    e.preventDefault();
    edit({
      id,
      pocUsecaseId,
      assignedToStakeholder: { pocId },
      notes: e.target.note.value
    });
    close();
  };
  const removeNote = () => {
    edit({ id, pocUsecaseId, assignedToStakeholder: { pocId }, notes: null });
  };

  return (
    <>
      <Popover
        as="div"
        className="is-flex is-align-items-center is-justify-content-flex-end gap-2 is-relative"
      >
        {value?.notes ? (
          <Popover.Button className="button is-ghost">
            <ChatLeft className="bi bi-chat-left " />
          </Popover.Button>
        ) : null}
        <Menu
          as="div"
          className="w-1r dropdown is-flex is-align-items-center is-right is-active is-relative"
        >
          <Menu.Button
            as="div"
            className="dropdown-trigger is-align-content-end is-absolute"
            // disabled={item.addressed}
          >
            <ThreeDotsVertical />
          </Menu.Button>
          <Menu.Items
            as="div"
            className="dropdown-menu card"
            style={{ outlineStyle: "none" }}
          >
            {!isProspect && (
              <Menu.Item
                as="div"
                className="p-2 pl-4 is-clickable"
                onClick={handleDelete}
              >
                Delete Test Plan
              </Menu.Item>
            )}
            {value?.notes ? (
              <Menu.Item
                as="div"
                className="p-2 pl-4 is-clickable"
                onClick={removeNote}
              >
                Delete Note
              </Menu.Item>
            ) : (
              <Popover.Button
                as="div"
                className="py-3 is-flex is-align-items-center p-2 pl-4 is-clickable "
              >
                <Menu.Item as="span">Add Note</Menu.Item>
              </Popover.Button>
            )}
          </Menu.Items>
        </Menu>

        <Popover.Panel
          as="div"
          className="box top-0 right-4 is-absolute z-30 w-20r"
        >
          {({ open, close }) => (
            <form onSubmit={e => addNote(e, close)} className="field">
              <label className="label">Add Note</label>
              <div className="control">
                <textarea
                  autoFocus
                  className="textarea"
                  name="note"
                  cols="30"
                  rows="4"
                  placeholder="Enter Test Plan notes here"
                  defaultValue={value?.notes || ""}
                />
              </div>
              <div className="control mt-3">
                <button type="submit" className="button is-info">
                  Save
                </button>
              </div>
            </form>
          )}
        </Popover.Panel>
      </Popover>
    </>
  );
};

const bulkAttachColumn = {
  id: "bulk-attach",
  Header: ({ ids, data, setIds, existingIds }) => (
    <input
      type="checkbox"
      className="form-check-input larger"
      onChange={e => {
        const allids = new Set(ids);
        data.map(x => allids.add(x.id));
        if (e.target.checked) {
          setIds(allids);
        } else {
          setIds(existingIds);
        }
      }}
    />
  ),
  Cell: ({ toggleId, ids, row, existingIds, showType }) => {
    return (
      <input
        type="checkbox"
        className="form-check-input larger"
        onChange={() => toggleId(row.original.id)}
        defaultChecked={
          showType !== "section" && existingIds.has(row.original.id)
        }
        checked={ids.has(row.original.id)}
        disabled={showType !== "section" && existingIds.has(row.original.id)}
      />
    );
  }
};

export const bulkAttachColumns = [
  { ...bulkAttachColumn },
  {
    Header: "Requirements",
    thClassName: "w-25",
    accessor: usecase => {
      let prefix = "";
      if (usecase.archived) {
        prefix = "ARCHIVED: ";
      }
      return (
        <React.Fragment>
          {`${prefix}${usecase.body}`}
          <EntityVisibilityIcon entity={usecase} />
          {usecase?.usage > 60 && (
            <span className="tag is-info is-light ml-2">Recommended</span>
          )}
        </React.Fragment>
      );
    }
  },
  {
    Header: "Description",
    accessor: "notes",
    Cell: ({ value }) => (
      <ReactShowMoreText anchorClass="button is-small is-text">
        <SanitizeHtml html={value} />
      </ReactShowMoreText>
    )
  }
  // {
  //   Header: "Visibility",
  //   accessor: question => question.visibility
  // },
  // {
  //   Header: "Usage %",
  //   thClassName: "w-6r",
  //   accessor: "usage",
  //   Cell: ({ value }) => (value ? value + "%" : "0%")
  // },
  // {
  //   Header: "Win Rate",
  //   thClassName: "w-6r",
  //   accessor: "winRate",
  //   Cell: ({ value }) => (value ? value + "%" : "0%")
  // }
];

export const successCriteriaColumns = [
  { ...bulkAttachColumn },
  { Header: "Test Plan", accessor: "body" },
  {
    Header: "Usage %",
    accessor: "usage",
    Cell: ({ value }) => (value ? value + "%" : "0%")
  },
  {
    Header: "Win Rate",
    accessor: "winRate",
    Cell: ({ value }) => (value ? value + "%" : "0%")
  }
];

export const usecaseCardColumns = [
  {
    Header: "Test Plan",
    accessor: pocSC => {
      return `${pocSC.successcriteria.body}`;
    }
  },
  {
    Header: "Assignee",
    thClassName: "w-10r",
    accessor: "assignedToStakeholder",
    Cell: TableCellSelectableStakeholder
  },
  {
    Header: "Due Date",
    thClassName: "w-8r",
    accessor: pocSC => pocSC.dueDate || "",
    Cell: EditableDate
  },

  // {
  //   Header: "Importance",
  //   Cell: () => <PillSelector options={["Need", "Want", "Nice"]} />
  // },
  // {
  //   Header: "Status",
  //   Cell: () => <PillSelector options={["To Do", "Ongoing", "Done"]} />
  // }
  {
    Header: "Importance",
    thClassName: "w-10r",
    Cell: ({ row, handleSelect }) => {
      return (
        <Select
          id={row.original.id}
          name="importance"
          className="is-flex is-align-items-center"
          onChange={handleSelect}
          value={row.original.importance}
          options={importances}
        />
      );
    }
  },
  {
    Header: "Outcome",
    thClassName: "w-10r",
    Cell: ({ row, handleSelect }) => (
      <Select
        id={row.original.id}
        name="score"
        className="is-flex is-align-items-center"
        onChange={handleSelect}
        value={row.original.score}
        options={outcomes}
      />
    )
  },
  {
    id: "actions",
    thClassName: "w-4r",
    tdClassName: " mr-5",
    Header: "",
    accessor: pocSC => pocSC,
    Cell: props => <Actions base={props} />
  }
];

export const prospectHeaders = [
  "bulk-attach",
  "Requirements",
  "Description",
  "Usage %",
  "Test Plan"
];
