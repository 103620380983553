import { useRef, useState } from "react";
import debounce from "debounce";
import dayjs from "dayjs";
import { InfoCircleFill, ThreeDotsVertical } from "react-bootstrap-icons";
import { Popover } from "@headlessui/react";
import {
  useCreateAutoLoginLinkMutation,
  useGetMetaQuery,
  useGetUserActivityLogsQuery
} from "services/api";
import { Dialog } from "@headlessui/react";
import Loading from "components/common/Loading";
import { copyText } from "utils/helpers";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import WithRbac from "components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";

export const CellInput = ({ row, column, value, updateUser }) => {
  const [editable, setEditable] = useState(false);
  const onUpdate = value => {
    const payload = {
      id: row.original.id,
      pocId: row.original.pocId,
      [column.id]: value
    };
    updateUser(payload);
  };
  const onChange = debounce(e => {
    onUpdate(e.target.value);
  }, 1000);
  const onEnter = e => {
    if (e.key === "Enter") {
      onChange.clear();
      onUpdate(e.target.value);
      setEditable(false);
    }
  };
  const status = row.original.status;

  return column.editable !== false && editable ? (
    <input
      autoFocus
      className="input"
      type={column.type}
      defaultValue={value}
      onChange={onChange}
      onKeyDown={onEnter}
      onBlur={() => setEditable(false)}
    />
  ) : (
    <label
      className={status === "active" ? "" : "has-text-grey-light"}
      onClick={() => status === "active" && setEditable(true)}
    >
      {value ? value : "N/A"}
    </label>
  );
};

export const CellSelect = ({ row, column, value, updateUser }) => {
  const { data: metadata } = useGetMetaQuery();
  const personaOptions = metadata?.personas
    ? Object.keys(metadata.personas).map(x => ({
        value: metadata.personas[x],
        label: metadata.personas[x]
      }))
    : [];
  const [editable, setEditable] = useState(false);
  const onChange = e => {
    const payload = {
      id: row.original.id,
      [column.id]: e.target.value || null
    };
    updateUser(payload);
  };
  const status = row.original.status;
  const options = column.options?.length ? column.options : personaOptions;

  return editable ? (
    <div className="select">
      <select
        autoFocus
        defaultValue={value}
        onChange={debounce(onChange, 500)}
        onBlur={() => setEditable(false)}
      >
        <option value="">-</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <div
      className={
        status === "active" ? "w-100 is-clickable" : "has-text-grey-light"
      }
      onClick={() => status === "active" && setEditable(true)}
    >
      {value || "-"}
    </div>
  );
};

export const CellAction = ({
  row,
  updateUser,
  setOpen,
  setLink,
  setUserChangePassword,
  setUserEdit,
  setShowModalAdd
}) => {
  const [createLink, { isLoading: isLoadingCreateLink }] =
    useCreateAutoLoginLinkMutation();
  const { id, email, fullName } = row.original;
  const { pocId } = useParams();
  const buttonRef = useRef();

  const onStatus = activate => {
    updateUser({
      id: row.original.id,
      status: activate ? "active" : "inactive"
    });
  };
  const handleCreateLink = async close => {
    close();
    setOpen(true);
    const payload = {
      key1: "value1",
      redirectTo: `/pocs/${pocId}/overview`
    };
    const result = await createLink({
      userId: id,
      notifyByEmail: false,
      emailToNotify: email,
      payload
    });
    let data = { ...result.data };
    data["email"] = email;
    data["fullName"] = fullName;
    data["userId"] = id;
    data["payload"] = payload;
    setLink(data);
  };
  const handleChangePassword = () => {
    setUserChangePassword(row.original);
  };
  const handleEditUser = () => {
    setUserEdit(row.original);
    setShowModalAdd(true);
  };

  return (
    <Popover className="w-100 text-center">
      <Popover.Button className="button is-white" ref={buttonRef}>
        <ThreeDotsVertical />
      </Popover.Button>

      <Popover.Panel className="card is-absolute mr-5 z-30 mt-1 right-0 ">
        {({ close }) => (
          <ul className="px-4 py-4 has-text-left">
            <WithRbac
              permissionOneOf={UserPermissions.EVALCOLLABORATORS_MANAGE}
            >
              {row.original.status === "active" ? (
                <li
                  className="is-clickable p-2"
                  onClick={() => {
                    onStatus(false);
                    close();
                  }}
                >
                  De-activate
                </li>
              ) : (
                <li
                  className="is-clickable p-2"
                  onClick={() => {
                    onStatus(true);
                    close();
                  }}
                >
                  Activate
                </li>
              )}
            </WithRbac>
            <WithRbac
              permissionOneOf={UserPermissions.USERS_CREATEAUTOLOGINLINK}
            >
              <li
                className="is-clickable p-2"
                onClick={() => handleCreateLink(close)}
              >
                Create Autologin Link
              </li>
            </WithRbac>
            <WithRbac permissionOneOf={UserPermissions.USERS_MANAGE}>
              <li className="is-clickable p-2" onClick={handleEditUser}>
                Edit
              </li>
            </WithRbac>
            <WithRbac permissionOneOf={UserPermissions.USERS_MANAGE}>
              <li
                className="is-clickable p-2"
                onClick={() => handleChangePassword(close)}
              >
                Change Password
              </li>
            </WithRbac>
          </ul>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export const AutoLoginLinkCard = ({ open, setOpen, link, setLink }) => {
  const [createLink, { isLoading: isLoadingCreateLink }] =
    useCreateAutoLoginLinkMutation();
  const handleSendLinkToEmail = async () => {
    const result = await createLink({
      userId: link.userId,
      notifyByEmail: true,
      emailToNotify: link.email,
      payload: link.payload
    });
    let newLink = { ...link };
    newLink["emailSent"] = result.data.emailSent;

    setLink(newLink);
    result.data.emailSent && toast.success("Email Sent!");
  };

  return (
    <Dialog
      as="div"
      open={open}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => setOpen(false)}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
      <div className="p-5 overflow-hidden has-background-white z-30 rounded-md w-45r min-h-20r">
        {isLoadingCreateLink ? (
          <Loading />
        ) : (
          <>
            <div className="mb-6">
              <div className="is-size-6 mb-2">Autologin Link</div>
              <div className="is-flex mb-2">
                <input
                  value={link.autologinLink}
                  className="input w-100 ml-6"
                />
                <div className="is-flex is-align-items-center">
                  <button
                    onClick={() => copyText(link.autologinLink, "Link Copied!")}
                    className="button is-info ml-3 mr-1 w-7r h-75 "
                  >
                    Copy
                  </button>
                </div>
              </div>
              <div className="is-size-7 is-flex is-justify-content-center">
                <InfoCircleFill className="mr-1" /> Use this link to
                automatically login as {link.fullName}
              </div>
            </div>
            <div className="mb-6">
              <div className="is-size-6 mb-2">Send Link by Email</div>
              <div className="is-flex mb-2">
                <input value={link.email} className="input w-100 ml-6" />
                <div className="is-flex is-align-items-center text-center">
                  {!link.emailSent ? (
                    <button
                      onClick={handleSendLinkToEmail}
                      className={` button is-info ml-3 mr-1 w-7r h-75`}
                    >
                      Send Email
                    </button>
                  ) : (
                    <div className="border border-2 is-clickable rounded ml-3 mr-1 w-7r whitespace-nowrap ">
                      Send Email
                    </div>
                  )}
                </div>
              </div>
              {link.emailSent && <div className="text-center">Email Sent!</div>}
            </div>
            <div className="buttons is-justify-content-end">
              <button
                onClick={() => setOpen(false)}
                className="button is-light"
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export const LastActivityCell = ({ value, row }) => {
  const [open, setOpen] = useState(false);
  const id = row.original.id;
  const { userHasPermission } = useRbac();

  return value && userHasPermission(UserPermissions.ACTIVITYLOG_READ) ? (
    <>
      <div
        onClick={() => setOpen(true)}
        className="is-clickable has-text-info text-capitalize"
      >
        {dayjs(value).fromNow()}
      </div>
      <ActivityLogs id={id} user={row.original} open={open} setOpen={setOpen} />
    </>
  ) : (
    <div className="text-capitalize">--</div>
  );
};

const ActivityLogs = ({ id, user, open, setOpen }) => {
  const { data, isLoading } = useGetUserActivityLogsQuery(id);
  return (
    <Dialog
      as="div"
      open={open}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => setOpen(false)}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
      <div className="card p-5 w-50 is-relative max-h-screen w-50r ">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="is-size-4">{user.fullName}'s Activity Log</div>
            <div className="overflow-auto p-3">
              <table className="table">
                <thead>
                  <tr>
                    <th width="30%">Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((d, i) => {
                      let text = "";
                      if (d.verb === "Complete") {
                        text = `Completed ${d.object.type} "${d.object?.displayName}" in Eval ${d.target?.displayName}`;
                      }
                      if (
                        d.verb === "Create" &&
                        !d.object.type.includes("Poc")
                      ) {
                        text = `Created new ${d.object.type} "${d.object?.displayName}" on Eval ${d.target?.displayName}`;
                      }
                      if (d.verb === "Create" && d.object.type === "Poc") {
                        text = `Created new Eval ${d.object?.displayName}`;
                      }
                      if (
                        d.verb === "Create" &&
                        d.object.type.includes("Poc") &&
                        d.target
                      ) {
                        text = `Added ${d.object.type} "${d.object?.displayName}" to Eval ${d.target?.displayName}`;
                      }
                      if (d.verb === "Invite") {
                        text = `Invited "${d.object?.displayName}" to Eval ${d.target?.displayName}`;
                      }
                      if (d.verb === "Access") {
                        text = `Logged-in to ${d.object?.displayName}`;
                      }

                      return (
                        // <div key={i} className="ml-5 my-3 lh-sm">
                        //   <div>
                        //     {dayjs(d.createdAt).fromNow()}{" "}
                        //   </div>
                        //   <div>{text}</div>
                        // </div>
                        <tr key={i}>
                          <td>{dayjs(d.createdAt).fromNow()} </td>
                          <td>{text}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="buttons mt-3 is-flex is-align-items-center is-justify-content-end">
              <div onClick={() => setOpen(false)} className="button is-info">
                Close
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
