import { useMemo, useState } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";

// import useAuth from "hooks/useAuth";
import usePocs from "hooks/usePocs";
import APIError from "components/common/APIError";
import ChecklistGroup from "pages/PocChecklist/ChecklistGroup";

import {
  useAddChecklistgroupsMutation,
  useAddChecklistitemsMutation,
  useDeleteChecklistgroupsMutation,
  useDeleteChecklistitemsMutation,
  useEditChecklistgroupsMutation,
  useEditChecklistitemsMutation,
  useGetChecklistgroupsQuery,
  useGetChecklistitemsQuery,
  useReorderChecklistgroupsMutation,
  useReorderChecklistitemsMutation
} from "services/api";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";

const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export default function PocChecklist() {
  const { currentUser: user } = useAuth();
  const { currentPoc: poc } = usePocs();
  const { data: groups, isLoading: isGetGroups } = useGetChecklistgroupsQuery(
    user.vendorId
  );
  const { data: items, isLoading: isGetItems } = useGetChecklistitemsQuery(
    user.vendorId
  );
  const [addGroup, { isLoading: isAddGroup }] = useAddChecklistgroupsMutation();
  const [editGroup, { isLoading: isEditGroup }] =
    useEditChecklistgroupsMutation();
  const [removeGroup, { isLoading: isRemoveGroup }] =
    useDeleteChecklistgroupsMutation();
  const [reorderGroup, { isLoading: isReorderGroup }] =
    useReorderChecklistgroupsMutation();
  const [reorderItem, { isLoading: isReorderItem }] =
    useReorderChecklistitemsMutation();
  const [addItem] = useAddChecklistitemsMutation();
  const [editItem] = useEditChecklistitemsMutation();
  const [removeItem] = useDeleteChecklistitemsMutation();
  const [cg, setcg] = useState([]);
  const [ucg, setucg] = useState([]);
  const ncg = useMemo(() => cg?.map(x => ({ ...x })), [cg]);
  const nucg = useMemo(() => ucg?.map(x => ({ ...x })), [ucg]);

  useEffect(() => {
    if (groups && items) {
      const groupIds = groups.map(x => x.id);
      const ungroupItems = items.filter(
        x => !groupIds.includes(x.checklistgroupId)
      );
      const groupItems = groups.reduce((s, { checklistitems, ...group }) => {
        group.checklistitems = [];
        for (const item of items) {
          if (item.checklistgroupId === group.id) {
            group.checklistitems.push(item);
          }
        }
        s.push(group);
        return s;
      }, []);
      setcg(groupItems);
      setucg(ungroupItems);
    }
  }, [groups, items]);

  const onDragEnd = result => {
    const { type, draggableId, source: s, destination: d } = result;
    const itemId = draggableId.replace(/item-/g, "");
    if (d !== null) {
      if (
        type === "group" &&
        s.index !== d.index &&
        draggableId.indexOf("item") < 0
      ) {
        arraymove(ncg, s.index, d.index);
        setcg(ncg);
        reorderGroup({ pocId: poc.id, body: ncg });
      }
      if (type === "item") {
        if (s.droppableId === d.droppableId) {
          const newItems = [...items];
          const gi = ncg.findIndex(x => x.id === s.droppableId);

          const sUcgIdx = newItems.findIndex(
            ({ id }) => ucg[s.index]?.id === id
          );
          const dUcgIdx = newItems.findIndex(
            ({ id }) => ucg[d.index]?.id === id
          );
          const sCgIdx = newItems.findIndex(
            ({ id }) => ncg[gi]?.checklistitems[s.index]?.id === id
          );
          const dCgIdx = newItems.findIndex(
            ({ id }) => ncg[gi]?.checklistitems[d.index]?.id === id
          );

          if (s.droppableId === "ungroup") {
            arraymove(nucg, s.index, d.index);
            setucg(nucg);
            arraymove(newItems, sUcgIdx, dUcgIdx);
          } else {
            arraymove(newItems, sCgIdx, dCgIdx);
            arraymove(ncg[gi].checklistitems, s.index, d.index);
            ncg[gi] = { ...ncg[gi], checklistitems: ncg[gi].checklistitems };
          }
          reorderItem({ pocId: poc.id, body: items });
        } else if (d.droppableId === "ungroup") {
          const gindex = ncg.findIndex(x => x.id === s.droppableId);
          const item = ncg[gindex].checklistitems.find(x => x.id === itemId);
          ucg.splice(d.index, 0, item);
          ncg[gindex].checklistitems.splice(s.index, 1);
          editItem({
            id: itemId,
            checklistgroupId: null,
            weight: ucg[d.index].weight
          });
        } else if (s.droppableId === "ungroup") {
          const item = ucg.find(x => x.id === itemId);
          const gindex = ncg.findIndex(x => x.id === d.droppableId);

          editItem({
            id: itemId,
            checklistgroupId: ncg[gindex].id,
            weight:
              ncg[gindex].weight > 0
                ? ncg[gindex].weight - 10
                : ncg[gindex].weight
          });

          ncg[gindex].checklistitems.splice(d.index, 0, item);
          ucg.splice(s.index, 1);
        } else if (s.droppableId !== d.droppableId) {
          const sIndex = ncg.findIndex(x => x.id === s.droppableId);
          const dIndex = ncg.findIndex(x => x.id === d.droppableId);
          const item = ncg[sIndex].checklistitems[s.index];
          editItem({
            id: itemId,
            checklistgroupId: ncg[dIndex].id,
            weight: ncg[dIndex]?.checklistitems[d.index?.weight]
          });
          ncg[sIndex].checklistitems.splice(s.index, 1);
          ncg[dIndex].checklistitems.splice(d.index, 0, item);
        }
      }
    }
  };

  const handleAddGroup = ({ index, body, weight }) => {
    addGroup({ body, vendorId: user.vendorId, weight });
  };

  const handleEditGroup = payload => {
    editGroup(payload);
  };

  const handleRemoveGroup = ({ id }) => {
    removeGroup({ id });
  };

  const handleAddItem = payload => {
    addItem(payload);
  };

  const handleEditItem = ({ id, gid, ...body }) => {
    editItem({ id, vendorId: user.vendorId, ...body });
  };

  const handleRemoveItem = ({ id, gid }) => {
    removeItem({ id, vendorId: user.vendorId });
  };

  const isLoading =
    isGetGroups ||
    isGetItems ||
    isAddGroup ||
    isEditGroup ||
    isRemoveGroup ||
    isReorderGroup ||
    isReorderItem;
  return (
    <>
      <section className="card mt-4">
        <APIError error={""} />

        <div className="ManageChecklist p-5">
          <div className="mb-5">
            <div className="is-size-4">Manage Action Plan</div>
          </div>
          <div className="is-flex is-align-items-center is-justify-content-space-between">
            <div>
              {isLoading && (
                <ArrowRepeat className="is-spinning mx-auto w-5r h-5r" />
              )}
            </div>
          </div>

          <ChecklistGroup
            grouped={ncg}
            ungrouped={ucg}
            items={items}
            onDragEnd={onDragEnd}
            addGroup={handleAddGroup}
            editGroup={handleEditGroup}
            removeGroup={handleRemoveGroup}
            addItem={handleAddItem}
            editItem={handleEditItem}
            removeItem={handleRemoveItem}
          />
        </div>
      </section>
    </>
  );
}
