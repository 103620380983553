import React from "react";
import AnalyticsMenu from "./AnalyticsMenu";

export default function Opportunities() {
  return (
    <>
      <AnalyticsMenu />
      <div className="card">
        <div className="card-content">
          <img className="h-auto w-auto " src="/analytics/opportunity.svg" />
        </div>
      </div>
    </>
  );
}
