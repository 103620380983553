import { useRef } from "react";
import { Popover } from "@headlessui/react";

const ChecklistTemplate = ({ item, gid, edit, className }) => {
  const buttonRef = useRef(null);
  const onEnter = e => {
    if (e.key === "Enter") {
      const payload = item.templates?.length
        ? [...item.templates, e.target.value]
        : [e.target.value];
      edit({ id: item.id, gid, templates: payload });
      buttonRef.current.click();
    }
  };
  const onRemove = val => {
    const templates = item.templates?.filter(x => x !== val);
    edit({ id: item.id, gid, templates });
  };

  return (
    <Popover className={`is-relative ${className}`}>
      {item.templates?.length
        ? item.templates.map((v, i) => {
            return (
              <div key={i} className="tag is-info is-light m-1">
                {v}
                <span onClick={() => onRemove(v)} className="ml-4 is-clickable">
                  &times;
                </span>
              </div>
            );
          })
        : null}
      <Popover.Button
        ref={buttonRef}
        as="span"
        className="is-clickable tag is-info is-light m-1"
      >
        +
      </Popover.Button>

      <Popover.Panel
        className={`is-absolute z-10 ${
          item.templates?.length ? "right-0" : "left-0"
        }`}
      >
        <div className="box p-2 w-15r">
          <input
            type="text"
            autoFocus
            placeholder="Press enter to add new tag"
            className="input is-small"
            onKeyDown={onEnter}
          />
        </div>
      </Popover.Panel>
    </Popover>
  );
};
export default ChecklistTemplate;
