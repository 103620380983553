import LayoutContainer from "../../components/Container/LayoutContainer";
import PocTopBar from "../../components/TopBar/TopBarPoc";

export default function PocTabNotFound({ poc }) {
  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar poc={poc} showScore={false} title={poc?.pocName} />

        <div className="is-relative box">
          <div className="is-size-6">
            Looks like you don't have access to this module.
          </div>
        </div>
      </LayoutContainer>
    </>
  );
}
