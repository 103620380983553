import React, { useCallback, useMemo, useState } from "react";

import { reorderListAfterDragEnd } from "utils/helpers";
import {
  useDeleteUseCaseMutation,
  useEditUseCaseMutation,
  useGetUseCasesQuery,
  useReorderUseCasesMutation
} from "services/api";
import useAuth from "../../hooks/useAuth";
import ThreeDotMenu from "../../components/common/ThreeDotMenu/index";
import withReordering from "../../utils/WithReordering";
import Modal from "../../components/common/Modal";
import EditUsecaseForm from "../../components/common/EditUsecaseForm/index";
import { LoadingCentered } from "../../components/common/Loading";
import APIError from "../../components/common/APIError/index";
import Table from "../../components/common/Table";
import TemplatePicker from "../../components/common/TemplatePicker/index";
import TableRowDragHandle from "../../components/common/TableRowDragHandle/index";
import { toast } from "react-toastify";
import { ArrowRepeat } from "react-bootstrap-icons";
import SearchBox from "components/common/SearchBox";
import { NoDataBlock } from "components/common/NoData";
import ReactShowMoreText from "react-show-more-text";
import SanitizeHtml from "components/common/SanitizeHtml";
import Opportunity from "./Opportunity";

import useOption from "hooks/useOption";
import SelectFilter from "components/common/SelectFilter";

const ManageUsecases = () => {
  const { currentUser } = useAuth();

  const {
    data: usecaseData,
    isLoading: isListLoading,
    error
  } = useGetUseCasesQuery();
  const [reorderItems, { isLoading: isReorderItemsLoading }] =
    useReorderUseCasesMutation();

  const [editItem, { isLoading: isEditItemLoading }] = useEditUseCaseMutation();
  const [deleteItem, { isLoading: isDeleteItemLoading }] =
    useDeleteUseCaseMutation();

  const [filter, setfilter] = useState({ tag: "", scope: "" });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState({});
  const [itemBeingDeleted, setItemBeingDeleted] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { scopes, tags } = useOption("usecase");

  const filterOptions = useMemo(
    () => [
      {
        label: "Tag",
        defaultValue: "Show All",
        options: tags
      },
      {
        label: "Scope",
        defaultValue: "All Requirements",
        options: scopes
      }
    ],
    [tags, scopes]
  );

  const handleEditTemplate = useCallback(
    (id, templates) => {
      editItem({ id, templates });
    },
    [editItem]
  );

  const data = React.useMemo(() => {
    return usecaseData
      ? usecaseData?.map(item => {
          // build 3 dot menu
          const menuItems = [
            {
              label: "Edit",
              onClick: () => {
                setItemBeingEdited(item);
                setIsEditModalOpen(true);
              }
            },
            {
              label: "Delete",
              onClick: () => {
                {
                  setItemBeingDeleted(item);
                  setIsDeleteModalOpen(true);
                }
              }
            }
          ];

          const rowItem = { ...item };
          rowItem.opportunities = <Opportunity item={item} />;
          rowItem.menu = <ThreeDotMenu items={menuItems} />;

          return rowItem;
        })
      : [];
  }, [usecaseData]);

  const columns = React.useMemo(
    () => [
      {
        id: "dragHandle",
        isDragHandle: true,
        showsWhileDragging: true,
        Cell: TableRowDragHandle
      },
      {
        Header: "Title",
        accessor: "body", // accessor is the "key" in the data
        showsWhileDragging: true,
        Cell: ({ value, row }) => {
          return (
            <div
              onClick={() => {
                setItemBeingEdited(row.original);
                setIsEditModalOpen(true);
              }}
              className="is-clickable"
            >
              {value}
            </div>
          );
        }
      },
      {
        Header: "Description",
        accessor: "notes",
        Cell: ({ value }) => (
          <ReactShowMoreText anchorClass="button is-small is-text">
            <SanitizeHtml html={value} />
          </ReactShowMoreText>
        )
      },
      {
        Header: "Tags",
        accessor: "templates",
        Cell: ({ row, value }) => (
          <TemplatePicker
            row={row}
            value={value}
            editTemplate={handleEditTemplate}
          />
        )
      },
      {
        Header: "Scope",
        accessor: "visibility"
      },
      {
        Header: "Opportunities",
        accessor: "opportunities"
      },
      {
        Header: "Action",
        accessor: "menu"
      }
    ],
    [usecaseData]
  );

  const handleFiltering = useCallback(
    (key, newValue) => {
      const newFilter = { ...filter };
      newFilter[key] = newValue;
      setfilter(newFilter);
    },
    [filter]
  );

  const TableWithReordering = withReordering(Table);

  const onDragEnd = result => {
    const items = reorderListAfterDragEnd(
      data,
      result.source.index,
      result.destination.index
    );

    reorderItems({
      vendorId: currentUser.vendorId,
      ids: items.map(item => item.id)
    });
  };

  const onDeleteItem = () => {
    deleteItem({ id: itemBeingDeleted.id })
      .unwrap()
      .then(() => {
        toast.success(`Deleted!`);
        setItemBeingDeleted({});
        setIsDeleteModalOpen(false);
      })
      .catch(error => toast.error(`Error: ${error.message}`));
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    // Making sure that the item being edited is reset
    setItemBeingEdited({});
  };

  const displayData = useMemo(() => {
    let newFilteredData = (searchValue ? filteredData : data) || [];
    const { tag, scope } = filter;

    if (tag) {
      newFilteredData = newFilteredData.filter(item =>
        item.templates?.includes(tag)
      );
    }
    if (scope) {
      newFilteredData = newFilteredData.filter(
        item => item.visibility === scope
      );
    }

    return newFilteredData;
  }, [filteredData, data, filter, searchValue]);

  return (
    <>
      <section className="card mt-4">
        <APIError error={error} />
        <div className="ManageQuestions p-5">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="is-size-4">Manage Requirements</div>
              </div>
              <div className="level-item">
                <button
                  onClick={() => setIsEditModalOpen(true)}
                  className="ml-3 button is-info is-small"
                >
                  Create a New Requirement
                </button>
              </div>
              <div className="level-item">
                {(isReorderItemsLoading || isEditItemLoading) && (
                  <ArrowRepeat className="is-spinning" />
                )}
              </div>
            </div>

            <div className="level-right w-20r">
              <SearchBox
                dataSet={data}
                keys={["body", "notes", "templates"]}
                setDataSet={setFilteredData}
                setLiftedSearchValue={setSearchValue}
              />
            </div>
          </nav>
          <div className="is-flex mb-5" style={{ gap: "1rem" }}>
            {filterOptions.map((item, idx) => (
              <SelectFilter
                key={idx}
                filter={filter}
                setFilter={setfilter}
                defaultValue={item.options[0]}
                controlLabel={item.label}
                options={item.options}
              />
            ))}
          </div>

          {isListLoading ? (
            <LoadingCentered />
          ) : (
            <>
              {displayData.length ? (
                <TableWithReordering
                  data={displayData}
                  columns={columns}
                  onDragEnd={onDragEnd}
                  isPaginated={true}
                />
              ) : (
                <NoDataBlock
                  message={
                    searchValue ? "No items match your search" : "No items"
                  }
                />
              )}
            </>
          )}

          <Modal
            isOpen={isEditModalOpen}
            setIsOpen={handleCloseEditModal}
            title={
              itemBeingEdited.id
                ? `Editing Requirement "${itemBeingEdited?.body}"`
                : "Add New Requirement"
            }
            body={
              <EditUsecaseForm
                usecase={itemBeingEdited}
                onSuccess={() => {
                  setItemBeingEdited({});
                  setIsEditModalOpen(false);
                }}
                onCancel={() => {
                  setItemBeingEdited({});
                  setIsEditModalOpen(false);
                }}
              />
            }
          />
          <Modal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            title={`Deleting Requirement "${itemBeingDeleted?.body}"?`}
            body="You're about to permanently delete this requirement, and its data."
            buttons={[
              <button
                onClick={() => onDeleteItem()}
                className={`button is-danger ${
                  isDeleteItemLoading ? "is-loading" : ""
                }`}
              >
                Delete
              </button>,
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="button is-light"
              >
                Cancel
              </button>
            ]}
          />
        </div>
      </section>
    </>
  );
};

export default ManageUsecases;
