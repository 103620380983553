import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";

export function init() {
  const history = createBrowserHistory();

  Sentry.init({
    dsn: process.env.REACT_APP_LOG_SERVICE_URL,
    release: "1-0-0",
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // DEBUG
    // debug: true,
    attachStacktrace: true
  });
}

export function log(error) {
  Sentry.captureException(error);
}
