import { useMemo, useEffect, useState } from "react";
import { usePagination, useTable } from "react-table";
import { toast } from "react-toastify";
import FooterPaginatedTable from "components/common/FooterPaginatedTable";
import PocStakeholderAdd from "./UserAdd";
import {
  CellInput,
  CellAction,
  AutoLoginLinkCard,
  LastActivityCell
} from "./UserComponents";
import {
  useDeleteStakeholderMutation,
  useAddUserMutation,
  useEditUserMutation,
  useGetVendorUsersQuery
} from "services/api";
import "./ManageUsers.css";
import UserAvatar from "components/common/UserAvatar";
import ChangeUserPasswordForm from "../../components/common/ChangeUserPasswordForm/index";
import Modal from "components/common/Modal/index";
import APIError from "../../components/common/APIError/index";
import { LoadingCentered } from "../../components/common/Loading";
import CreateUserSuccess from "./CreateUserSuccess";
import useAuth from "hooks/useAuth";
import SearchBox from "components/common/SearchBox";

const tableColumns = [
  {
    Header: "",
    accessor: "profilePic",
    editable: false,
    width: 40,
    Cell: ({ row }) => <UserAvatar user={row.original} showName={false} />
  },
  {
    Header: "First Name",
    accessor: "firstName",
    type: "text",
    width: 150,
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    type: "text",
    width: 150,
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Email",
    accessor: "email",
    type: "email",
    width: "auto",
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Role",
    accessor: item => item?.roles?.toString(),
    type: "role",
    width: 300,
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Last Activity",
    accessor: item => item?.lastActivityAt,
    type: "role",
    width: 300,
    editable: false,
    Cell: LastActivityCell
  },
  {
    Header: "",
    accessor: "action",
    type: "submit",
    Cell: CellAction
  }
];

export default function ManageUsers() {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState({});
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(false);

  const [
    createUser,
    {
      data: createdUser,
      isLoading: isLoadingCreateUser,
      error: errorCreateUsesr
    }
  ] = useAddUserMutation();

  const [userChangePassword, setUserChangePassword] = useState(false);

  const {
    data,
    isLoading: listLoading,
    isFetching,
    error
  } = useGetVendorUsersQuery(currentUser?.vendorId);
  const users = useMemo(() => data || [], [data]);

  const [
    updateUser,
    { data: updatedUser, error: errorUpdate, isLoading: updateLoading }
  ] = useEditUserMutation();

  const [deleteStakeholder, { error: errorDelete, isLoading: deleteLoading }] =
    useDeleteStakeholderMutation();
  const [userCreated, setUserCreated] = useState(null);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);
  const [userEdit, setUserEdit] = useState(null);

  const displayData = useMemo(() => {
    let newFilteredData = searchValue ? filteredData : users;
    return newFilteredData;
  }, [users, searchValue, filteredData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      open: open,
      setOpen: setOpen,
      setLink: setLink,
      setUserChangePassword: setUserChangePassword,
      columns: tableColumns,
      data: displayData,
      initialState: { pageIndex: 0 },
      updateUser,
      deleteStakeholder,
      autoResetPage: false,
      setUserEdit,
      setShowModalAdd
    },
    usePagination
  );

  useEffect(() => {
    let error = errorUpdate || errorDelete || errorCreateUsesr;
    let errorMessage = error?.data?.message || "";

    if (errorMessage && errorMessage.slice(0, 13) === "duplicate key") {
      toast.error("User already exists");
    } else {
      errorMessage &&
        toast.error(
          typeof errorMessage === "string" ? errorMessage : errorMessage[0]
        );
    }

    setIsOpenModalSuccess(false);
  }, [errorUpdate, errorDelete, errorCreateUsesr]);

  useEffect(() => {
    if (createdUser) {
      setIsOpenModalSuccess(true);
      setUserCreated(createdUser);
      setShowModalAdd(false);
      setUserEdit(null);
    }

    if (updatedUser) {
      setShowModalAdd(false);
      setUserEdit(null);
    }
  }, [createdUser, updatedUser]);

  useEffect(() => {
    if (showModalAdd && createdUser) {
      setUserEdit(null);
    }
  }, [showModalAdd]);

  const hidenForm = () => {
    setShowModalAdd(false);
    setUserEdit(null);
  };

  const handleCreateNew = () => {
    setShowModalAdd(true);
    setUserEdit(null);
  };

  return (
    <>
      <section className="card mt-4">
        <APIError error={error} />
        <div className="ManageQuestions p-5">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="is-size-4">Manage Collaborator</div>
              </div>
              <div className="level-item">
                <button
                  onClick={handleCreateNew}
                  className="ml-3 button is-info is-small"
                >
                  Create a New Collaborator
                </button>
              </div>
            </div>
            <div className="level-right">
              <SearchBox
                dataSet={users}
                keys={["firstName", "lastName", "email"]}
                setDataSet={setFilteredData}
                setLiftedSearchValue={setSearchValue}
              />
            </div>
          </nav>

          <div className="table-container">
            {(isFetching || listLoading || updateLoading || deleteLoading) && (
              <LoadingCentered />
            )}
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map(group => (
                  <tr {...group.getHeaderGroupProps()}>
                    {group.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        width={column.width}
                        className="py-4 is-size-6 has-text-grey-light has-text-weight-light"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map(cell => (
                        <td className="py-4" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <FooterPaginatedTable
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            handlePageSize={e => setPageSize(+e.target.value)}
            handleNext={() => nextPage()}
            handlePrevious={() => previousPage()}
            numRows={rows.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />

          <PocStakeholderAdd
            showModalAdd={showModalAdd}
            hidenForm={hidenForm}
            createUser={createUser}
            updateUser={updateUser}
            isCreating={isLoadingCreateUser}
            userEdit={userEdit}
          />

          <CreateUserSuccess
            userCreated={userCreated}
            setUserCreated={setUserCreated}
            isOpenModal={isOpenModalSuccess}
            setIsOpenModal={setIsOpenModalSuccess}
            setShowModalAdd={setShowModalAdd}
            setUserEdit={setUserEdit}
          />

          <AutoLoginLinkCard
            open={open}
            setOpen={setOpen}
            link={link}
            setLink={setLink}
          />

          <Modal
            isOpen={!!userChangePassword}
            setIsOpen={setUserChangePassword}
            title={`Change ${userChangePassword.firstName}'s Password`}
            body={
              <ChangeUserPasswordForm
                user={userChangePassword}
                onSuccess={() => setUserChangePassword(false)}
                onCancel={() => setUserChangePassword(false)}
              />
            }
            buttons={[]}
          />
        </div>
      </section>
    </>
  );
}
