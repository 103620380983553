import React, { useEffect } from "react";
import { Search } from "react-bootstrap-icons";
import useSearch from "hooks/useSearch";

const SearchBox = ({
  dataSet = [],
  keys = [],
  initialSearchValue = "",
  setDataSet = () => {},
  setLiftedSearchValue = () => {},
  onPressEnter = () => {},
  placeholder = "Search",
  icon = <Search />
}) => {
  const { results, searchValue, setSearchValue } = useSearch({
    dataSet,
    keys,
    initialSearchValue
  });

  useEffect(() => {
    setDataSet(results);
  }, [searchValue]);

  return (
    <div className={`control has-icons-left`}>
      <input
        autoFocus
        onChange={e => {
          setSearchValue(e.target.value);
          setLiftedSearchValue(e.target.value);
        }}
        onKeyDown={e => {
          if (e.code === "Enter" || e.code === "NumpadEnter") onPressEnter();
        }}
        placeholder={placeholder}
        className="input"
        value={searchValue}
      />
      <span className="icon is-left">{icon}</span>
    </div>
  );
};

export default SearchBox;
