import { Dialog } from "@headlessui/react";
import useAuth from "hooks/useAuth";
import React, { useEffect } from "react";
import { ExclamationDiamond } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { useSalesforceSyncMutation } from "services/api";

export default function SyncSalesforce({ pocs, show, close }) {
  const { currentUser } = useAuth();
  const [salesforceSync, { data }] = useSalesforceSyncMutation();
  const isSinglePoc = pocs.length === 1;
  const ids = pocs.map(x => x.salesforceOpportunityId);

  const handleOverwrite = () => {
    salesforceSync({ id: currentUser.id, body: ids });
    toast.info(
      isSinglePoc
        ? `Starting to sync poc ${pocs[0].pocName}.`
        : `Starting to sync ${pocs.length} pocs`
    );
    close();
  };

  useEffect(() => {
    if (data) {
      toast.success(
        isSinglePoc
          ? `Poc ${pocs[0].pocName} successfully synced.`
          : `Successfully synced ${pocs.length} pocs.`
      );
    }
  }, [data]);

  return (
    <Dialog
      as="div"
      open={show}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => close()}
    >
      <div className="card w-55r h-auto max-h-screen p-5 is-relative centered z-30">
        <h1 className="m-4 is-size-5 is-flex is-align-items-center">
          <ExclamationDiamond className="mr-4 has-text-danger" />
          Overwriting {isSinglePoc
            ? `"${pocs[0].pocName}"`
            : "All Opportunity"}{" "}
          fields with Salesforce fields
        </h1>
        <div className="m-4 w-50">
          You are about to permanently overwrite{" "}
          {isSinglePoc ? "this " : "all "}
          opportunities data fields <br />
          with the linked Salesforce opportunity fields.
        </div>
        <div className="buttons mt-5 is-flex is-justify-content-end">
          <button className="button is-danger" onClick={handleOverwrite}>
            Overwrite
          </button>
          <button className="button is-light" onClick={close}>
            Cancel
          </button>
        </div>
      </div>

      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30 z-10" />
    </Dialog>
  );
}
