import dayjs from "dayjs";
import { toast } from "react-toastify";
import sanitize from "sanitize-html";
import * as moment from "moment";

export const baseUrl = process.env.REACT_APP_BASE_URL || "";

export const getInitialCamelCase = str => {
  let initials = "";
  for (let i = 0; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase()) {
      initials += str[i];
    }
  }
  return initials;
};
export const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const copyText = async (text, toastMessage) => {
  toastMessage && toast.success(toastMessage);
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const steps = ["Scoping", "Execution", "Completed"]; // Archived
export const groupChecklist = data => {
  const output = {};
  for (const phase of steps) {
    output[phase] = [];
  }
  if (data) {
    for (const item of data) {
      if (item) {
        const keyCap = capitalize(item?.phase?.toLowerCase());
        output[keyCap].push(item);
      }
    }
  }
  return output;
};

export const inputWithCommas = e => {
  if (e.target.value) {
    const onlyNumber = e.target.value.replace(/[^0-9 ,]/, "");
    const removeComma = onlyNumber.replaceAll(",", "");
    e.target.value = removeComma.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export function urlFriendly(text) {
  return encodeURIComponent(text.toLowerCase().replace(/[^A-Z0-9]/gi, "-"));
}

export function capitalize(text) {
  return text?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export function intToDollars(value, maximumSignificantDigits = 3) {
  const options = {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short",
    maximumSignificantDigits
  };
  return Intl.NumberFormat("en-US", options).format(value);
}

export function stringToDate(value) {
  if (!value) return new Date();
  if (value.toString().match(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/)) {
    const [year, month, day] = value.split("-");
    return new Date(year, month - 1, day);
  }
  return new Date(value);
}

export function dateToShort(value) {
  const date = stringToDate(value);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return Intl.DateTimeFormat("en-US", options).format(date);
}

export function isDateExpired(date1, date2 = "") {
  const date1Obj = stringToDate(date1);
  const date2Obj = stringToDate(date2);

  return date2Obj > date1Obj;
}

export function daysSince(date1, date2 = "") {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const date1Obj = stringToDate(date1);
  const date2Obj = stringToDate(date2);

  const diffDays = Math.round(Math.abs((date2Obj - date1Obj) / oneDay));

  return diffDays;
}

export function createKey(...args) {
  return args.join("-");
}

export function renderYesNo(value) {
  return value ? "Yes" : "No";
}

export function convertArrayToSelectOptions(array) {
  return array.map(item => ({ key: item, value: item }));
}

export function convertObjectToSelectOptions(
  array,
  objKey = "key",
  objValue = "value",
  indentOnKey = false
) {
  return array.map(item => {
    let itemValue = item[objValue];

    // If a indentOnKey has been specified and is present in the item, we indent the label
    if (indentOnKey && item[indentOnKey]) {
      itemValue = `  - ${itemValue}`;
    }
    return { key: item[objKey], value: itemValue };
  });
}

export function pick(object, keys) {
  const newObj = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    newObj[key] = object[key];
  }
  return newObj;
}

export function currency(num) {
  return `$${(+num).toLocaleString()}`;
}

export function formatDate(dateStr) {
  return dayjs(dateStr).format("MM/DD/YYYY");
}

export function formatHeader(column) {
  if (typeof column.Header !== "string") return "";
  return (
    column.Header.replace("%", "").replace("#", "").trim().replace(/ /g, "-") +
    "-header"
  );
}

export function createMap(array, key = "id") {
  return (
    array?.reduce((acc, current) => {
      acc[current[key]] = current;
      return acc;
    }, {}) || []
  );
}

export function sortBy(array, accessor, direction = "ASC") {
  let accessorFn = accessor;
  if (typeof accessor === "string") {
    accessorFn = el => el[accessor];
  }
  return [...array].sort((el1, el2) => {
    if (accessorFn(el1) < accessorFn(el2)) return direction === "ASC" ? -1 : 1;
    if (accessorFn(el1) > accessorFn(el2)) return direction === "ASC" ? 1 : -1;
    return 0;
  });
}

export function sortSubEntitiesBy(array, subEntityKey, accessor) {
  // Since the backend cannot sort the eagerly loaded sub-entities, we do it here
  array = array.map(item => {
    item[subEntityKey] = sortBy(item[subEntityKey], accessor);
    return item;
  });
  return array;
}

/*
 * Returns the highest "weight" in the collection of items
 */
export function findCollectionItemMaxWeight(itemCollection) {
  const maxWeight = itemCollection.length
    ? Math.max(...itemCollection.map(item => item.weight))
    : 0;
  return maxWeight ? maxWeight : 0;
}

/*
 * Returns the highest "weight" in the collection of items
 */
export function calculateItemWeightValue(previousWeight, nextWeight) {
  return 0;
}

export const createTemplatesSelect = data => {
  const initArray = [];
  data &&
    data.forEach(q => {
      if (q.templates) {
        q.templates.forEach(t => {
          initArray.push(t);
        });
      }
    });
  const templates = [...new Set(initArray)];
  templates.sort();
  return templates;
};

export const groupByTemplate = (data, templates) => {
  const output = {};
  if (data && templates) {
    for (const template of templates) {
      output[template] = [];
      data.forEach(element => {
        if (element.templates && element.templates.includes(template)) {
          output[template].push(element);
        }
      });
    }
  }
  return output;
};

export const createTagsSelect = data => {
  const initArray = [];
  data &&
    data.forEach(d => {
      if (d.tags) {
        d.tags.forEach(t => {
          initArray.push(t);
        });
      }
    });
  const tags = [...new Set(initArray)];
  tags.sort();
  return tags;
};

export const groupByBusiness = (pocUseCases, businessValues) => {
  const output = {};
  if (pocUseCases && businessValues) {
    for (const bv of businessValues) {
      output[bv] = [];
      pocUseCases.forEach(element => {
        if (element.businessValues && element.businessValues.includes(bv)) {
          output[bv].push(element);
        }
      });
    }
  }
  return output;
};

export const addNewInput = async (e, handleAdd) => {
  // please wrap your input with form element
  // add name="input" at all input
  if (e.key === "Enter") {
    const form = e.target.form;
    const elements = [...form.elements];
    let inputIdxs = [];
    elements.forEach((x, i) => {
      x.name === "input" && inputIdxs.push(i);
    });
    const index = [...form].indexOf(e.target);
    if (inputIdxs.indexOf(index) === inputIdxs.length - 1) {
      await handleAdd();
      form.elements[inputIdxs[inputIdxs.indexOf(index)] + 2].focus();
    } else {
      form.elements[inputIdxs[inputIdxs.indexOf(index) + 1]].focus();
    }
  }
};

export function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function invertColor(hex, bw) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function reorderListAfterDragEnd(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function renderTags(tags = []) {
  return tags.map((tag, i) => (
    <span key={i} className="tag mr-1">
      {tag}
    </span>
  ));
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export function truncate(str, charLengthth = 80) {
  return str.length > charLengthth
    ? str.substring(0, charLengthth) + "..."
    : str;
}

export function getHeaviestWeight(items = []) {
  if (!items.length) {
    return 0;
  }

  const heaviestItem = items.reduce((p, c) => (p?.weight > c?.weight ? p : c));

  return heaviestItem ? heaviestItem.weight : 0;
}

export function htmlEncode(string) {
  return string
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/'/g, "&#39;")
    .replace(/"/g, "&#34;")
    .replace(/\//, "&#x2F;");
}

export function htmlDecode(inputStr) {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = inputStr;
  return textarea.value;
}

export function htmlSanitize(html, options = {}) {
  const defaultOptions = {
    allowedTags: [
      "address",
      "article",
      "aside",
      "footer",
      "header",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "hgroup",
      "main",
      "nav",
      "section",
      "blockquote",
      "dd",
      "div",
      "dl",
      "dt",
      "figcaption",
      "figure",
      "hr",
      "li",
      "main",
      "ol",
      "p",
      "pre",
      "ul",
      "a",
      "abbr",
      "b",
      "bdi",
      "bdo",
      "br",
      "cite",
      "code",
      "data",
      "dfn",
      "em",
      "i",
      "kbd",
      "mark",
      "q",
      "rb",
      "rp",
      "rt",
      "rtc",
      "ruby",
      "s",
      "samp",
      "small",
      "span",
      "strong",
      "sub",
      "sup",
      "time",
      "u",
      "var",
      "wbr",
      "caption",
      "col",
      "colgroup",
      "table",
      "tbody",
      "td",
      "tfoot",
      "th",
      "thead",
      "tr",
      "img" // Allowing images
    ],
    disallowedTagsMode: "discard",
    allowedAttributes: {
      a: ["href", "name", "target"],
      // We don't currently allow img itself by default, but
      // these attributes would make sense if we did.
      img: [
        "src",
        "srcset",
        "alt",
        "title",
        "width",
        "height",
        "loading",
        "style"
      ],

      span: ["data-object-type", "data-object-id", "data-object-description"],
      table: ["style", "border"]
    },
    allowedClasses: {
      span: ["embeddedObjectTag", "grayTag", "orangeTag"]
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [
      "img",
      "br",
      "hr",
      "area",
      "base",
      "basefont",
      "input",
      "link",
      "meta"
    ],
    // URL schemes we permit
    allowedSchemes: ["http", "https", "ftp", "mailto", "tel"],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ["href", "src", "cite"],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false
  };

  return sanitize(html, { ...defaultOptions, ...options });
}

export function displayErrorToast(messages) {
  if (!Array.isArray(messages)) {
    messages = [messages];
  }
  messages.map(msg => toast.error(msg));
}

export const subtractDate = date => {
  if (!date) return null;

  var today = moment(new Date(), "YYYYMMDD");
  var dateofvisit = moment(date, "YYYYMMDD");

  return today.diff(dateofvisit, "days") || 0;
};
