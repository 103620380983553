import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["PocAttachments"] }).injectEndpoints({
    endpoints: build => ({
      getPocAttachments: build.query({
        query: pocId => `/pocs/${pocId}/files`,
        providesTags: ["PocAttachments"]
      }),
      addPocAttachments: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const response = await Promise.all(
            body.map(atc =>
              fetchWithBaseQuery({
                url: "/files/upload",
                method: "POST",
                body: atc
              })
            )
          );
          const successes = response
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = response
            .filter(res => !res.data)
            .map(res => res.error);

          if (failures.length > 0) return { data: null, error: failures };
          return { data: successes, error: null };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            api.util.updateQueryData(
              "getPocAttachments",
              body[0].pocId,
              draft => {
                const newPayload = body.map(atc => {
                  return { ...atc, updatedAt: new Date() };
                });
                return newPayload.concat(draft);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
        invalidatesTags: ["PocAttachments"]
      }),
      deletePocAttachments: build.mutation({
        query: ({ id, pocId }) => ({
          url: `/files/${id}`,
          method: "DELETE"
        }),
        async onQueryStarted({ id, pocId }, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            api.util.updateQueryData("getPocAttachments", pocId, draft => {
              const idx = draft.findIndex(atc => atc.id === id);
              if (idx > -1) {
                draft.splice(idx, 1);
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            deleteResult.undo();
          }
        },
        invalidatesTags: params => {
          // Invalidating the whole categories here, because the backend reply doesn't specifically specify which item was deleted
          const rules = ["PocAttachments", { type: "PocUsecases" }];
          return rules;
        }
      })
    })
  });
