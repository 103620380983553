import { useChangePasswordMutation } from "services/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { displayErrorToast } from "utils/helpers";
import { UserRoles } from "config/userRoles";
import useRbac from "../Rbac/useRbac";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";

const ChangeUserPasswordForm = ({
  user = {},
  onSuccess = () => {},
  onCancel = () => {}
}) => {
  const [isOldPassShown, setIsOldPassShown] = useState(false);
  const [isNewPassShown, setIsNewPassShown] = useState(false);

  const [changePassword, { isLoading: isChangePasswordLoading }] =
    useChangePasswordMutation();
  const { userHasRole } = useRbac();

  //   const oldPasswordRequired = !userHasRole([
  //     UserRoles.ADMIN,
  //     UserRoles.SE_MANAGER
  //   ]);
  const oldPasswordRequired = false;

  const onSubmit = e => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    const payload = {
      userId: user.id,
      oldPassword: formdata.get("oldPassword"),
      newPassword: formdata.get("newPassword")
    };

    changePassword(payload)
      .unwrap()
      .catch(error => {
        displayErrorToast(error?.data?.message);
        throw error?.data?.message;
      })
      .then(() => {
        onSuccess();
        toast.success("Password Updated!");
      });
  };

  const VisibilityWidget = ({ state, setter }) => {
    const Component = state ? EyeFill : EyeSlashFill;

    return <Component className="ml-2" onClick={() => setter(!state)} />;
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        {oldPasswordRequired && (
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">
                  Old Password{" "}
                  <VisibilityWidget
                    state={isOldPassShown}
                    setter={setIsOldPassShown}
                  />
                </label>
                <div className="control">
                  <input
                    name="oldPassword"
                    className="input"
                    type={isOldPassShown ? "text" : "password"}
                    placeholder="Enter Old Password"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">
                New Password{" "}
                <VisibilityWidget
                  state={isNewPassShown}
                  setter={setIsNewPassShown}
                />
              </label>
              <div className="control">
                <input
                  name="newPassword"
                  className="input"
                  type={isNewPassShown ? "text" : "password"}
                  placeholder="Enter New Password"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="is-size-7">
          Make sure it's at least 8 characters including a number, a lowercase
          letter, and an uppercase letter.
        </div>

        <nav className="level mt-5">
          <div className="level-left"></div>
          <div className="level-right">
            <p className="level-item">
              <button
                type="submit"
                className={`button is-info ${
                  isChangePasswordLoading ? "is-loading" : ""
                }`}
              >
                Update Password
              </button>
            </p>
            <p className="level-item">
              <button
                className="button is-light"
                onClick={e => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                Cancel
              </button>
            </p>
          </div>
        </nav>
      </form>
    </>
  );
};

export default ChangeUserPasswordForm;
