import { useEffect, useState } from "react";
import Joi from "joi-browser";
import Form from "components/common/Form";
import APIError from "components/common/APIError";
import Loading from "components/common/Loading";
import {
  useAuthenticateMutation,
  useForgotPasswordMutation
} from "services/api";
import evalforceLogo from "assets/img/evalforce.png";
import styles from "./SignInForm.module.css"; // Import css modules stylesheet as styles
import Modal from "components/common/Modal";
import Input from "components/common/Input";
import Button from "components/common/Button";

const schema = {
  email: Joi.string().required().label("Email"),
  password: Joi.string().required().label("Password")
};

const initialState = {
  email: "",
  password: ""
};

const fields = [
  {
    name: "email",
    label: "Email",
    placeholder: "example@example.com"
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "********"
  }
];
const ForgotPassSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } })
});

const SignInForm = () => {
  const [login, { isLoading, error }] = useAuthenticateMutation();
  const [forgotPassword, resForgotPassword] = useForgotPasswordMutation();
  const [showForgotPass, setShowForgotPass] = useState(null);
  const [email, setEmail] = useState("");

  const handleValidSubmit = data => {
    login({ mode: "manual", username: data.email, password: data.password });
  };
  const handleResetPass = () => {
    forgotPassword({ email });
    setShowForgotPass("reset-confirm");
  };

  const isValidEmail = value => {
    const result = Joi.validate({ email: value }, ForgotPassSchema);
    return result.error ? false : true;
  };

  const isRejected = resForgotPassword?.status === "rejected";

  return (
    <main className={`columns is-centered ${styles.mainSection}`}>
      <Modal
        isOpen={!!showForgotPass}
        handleClose={() => setShowForgotPass(null)}
        title={
          showForgotPass === "reset-form" &&
          "Enter your email to reset password"
        }
        className="w-20r"
        body={
          <>
            {showForgotPass === "reset-form" && (
              <div>
                <Input
                  type="email"
                  placeholder="Email"
                  name="reset_pass"
                  onChange={e => setEmail(e.target.value)}
                />
                <div className="is-flex is-justify-content-end">
                  <Button
                    className="is-info mx-2"
                    disabled={!isValidEmail(email)}
                    onClick={handleResetPass}
                  >
                    RESET PASSWORD
                  </Button>
                  <Button
                    className="mx-2"
                    onClick={() => setShowForgotPass(null)}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            )}
            {isRejected && showForgotPass === "reset-confirm" && (
              <div className="has-text-danger">
                {resForgotPassword?.error?.data?.message}
              </div>
            )}
            {!isRejected && showForgotPass === "reset-confirm" && (
              <div className="has-text-centered">
                <div className="has-text-justified">
                  If an account exists for {email}, you will get an email with a
                  new password. If it doesn't arrive, be sure to check your spam
                  folder
                </div>
                <div
                  className="mt-4 has-text-info is-clickable"
                  onClick={() => setShowForgotPass(null)}
                >
                  Back to Login
                </div>
              </div>
            )}
          </>
        }
      />
      <div className="column is-one-quarter-desktop is-half-tablet is-full-mobile my-6">
        <div className="card">
          <div className="card-content">
            <div className="content">
              <div className="mt-4 mb-6">
                <img src={evalforceLogo} alt="Logo" />
              </div>
              {isLoading ? (
                <div className="is-flex is-align-items-center is-justify-content-center">
                  <Loading />
                </div>
              ) : (
                <div>
                  <APIError error={error} />
                  <Form
                    schema={schema}
                    initialState={initialState}
                    fields={fields}
                    submitLabel="Sign In"
                    onValidSubmit={handleValidSubmit}
                    className="mb-5"
                  ></Form>
                  <br />
                  <footer className="card-footer mt-5">
                    {/* <button className="button is-ghost card-footer-item">
                      Sign up
                    </button> */}
                    <button
                      className="button is-ghost card-footer-item"
                      onClick={() => setShowForgotPass("reset-form")}
                    >
                      Forgot password
                    </button>
                  </footer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignInForm;
