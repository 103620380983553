import React, { useMemo, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useAddAttachmentsMutation, useUploadFileMutation } from "services/api";
import Modal from "components/common/Modal";

const PocAddProof = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [add] = useAddAttachmentsMutation();
  const [upload, { isLoading: isUploadLoading }] = useUploadFileMutation();

  const { isFocused, isDragAccept, isDragReject, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
      onDrop: useCallback(
        acceptedFiles => {
          acceptedFiles.forEach(file => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("pocUsecaseId", data.id);

            upload(formData)
              .unwrap()
              .then(res => {
                setIsOpen(false);
              })
              .catch(({ message }) =>
                toast.error(`Error while uploading evidence: ${message}`)
              );
          });
        },
        [data, add]
      )
    });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "25vh",
    justifyContent: "center",
    cursor: "pointer"
  };

  const focusedStyle = {
    borderColor: "#2196f3"
  };

  const acceptStyle = {
    borderColor: "#00e676"
  };

  const rejectStyle = {
    borderColor: "#ff1744"
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <button className="button is-text" onClick={() => setIsOpen(true)}>
        + Evidence
      </button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={"Upload Evidence"}
        isLoading={isUploadLoading}
        body={
          <div {...getRootProps({ className: "dropzone", style })}>
            <input {...getInputProps()} />
            <p>
              Drag & Drop <br />
              or click to browse
            </p>
          </div>
        }
        buttons={[
          <button onClick={() => setIsOpen(false)} className="button is-light">
            Close
          </button>
        ]}
      />
    </>
  );
};

export default PocAddProof;
