import { LoadingCentered } from "components/common/Loading";
import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import {
  useEditVendorMutation,
  useGetSpecdocsectionsQuery,
  useGetVendorQuery,
  useGetVendorRbacQuery
} from "services/api";
import { toast } from "react-toastify";
import RbacMappingTable from "./RbacMappingTable";

export default function VendorDetails() {
  const { currentUser } = useAuth();
  const { data: vendor } = useGetVendorQuery(currentUser?.vendorId);
  const { data: vendorRbac } = useGetVendorRbacQuery(currentUser?.vendorId);
  const { data: specdocsections } = useGetSpecdocsectionsQuery();

  const [editVendor] = useEditVendorMutation();
  const [freemiumTabConfig, setFreemiumTabConfig] = useState(false);

  useEffect(() => {
    setFreemiumTabConfig(
      vendor?.pocTabConfigurations
        ? JSON.stringify(vendor.pocTabConfigurations)
        : null
    );
  }, [vendor]);

  if (!vendor) {
    return <LoadingCentered />;
  }

  const handleSubmit = e => {
    e.preventDefault();
    const formdata = new FormData(e.target);

    const payload = {};
    formdata.forEach((value, key) => {
      switch (key) {
        case "extraSectionTemplates":
        case "pocTabConfigurations":
          value = value ? JSON.parse(value) : null;
          break;
      }

      payload[key] = value;
    });

    editVendor({ id: currentUser?.vendorId, ...payload })
      .unwrap()
      .then(() => toast.success("Vendor info updated"))
      .catch(() => toast.error("Error while updating"));
  };

  const handleChangeFreemium = event => {
    setFreemiumTabConfig(event.target.value);
  };

  const handleSetFreemium = e => {
    e.preventDefault();
    const freemiumTabConfig = [
      { key: "overview", label: "Spec Document" },
      { key: "usecases", label: "Requirements" },
      { key: "collaborators", label: "Collaborators" }
    ];

    setFreemiumTabConfig(JSON.stringify(freemiumTabConfig));

    return false;
  };

  return (
    <>
      <br />
      <div className="card">
        <div className="card-content">
          <h1 className="is-size-2">{vendor.name}</h1>

          <form onSubmit={handleSubmit}>
            <table className="table">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{vendor.id}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{vendor.name}</td>
                </tr>
                <tr>
                  <td>Default Usecase template</td>
                  <td>
                    <input
                      type="text"
                      name="defaultUsecaseTemplate"
                      defaultValue={vendor.defaultUsecaseTemplate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Default Question template</td>
                  <td>
                    <input
                      type="text"
                      name="defaultQuestionTemplate"
                      defaultValue={vendor.defaultQuestionTemplate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Default Spec Doc Section template</td>
                  <td>
                    <input
                      type="text"
                      name="defaultSpecdocsectionTemplate"
                      defaultValue={vendor.defaultSpecdocsectionTemplate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Default Action Plan template</td>
                  <td>
                    <input
                      type="text"
                      name="defaultChecklistitemTemplate"
                      defaultValue={vendor.defaultChecklistitemTemplate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>POC Spec Doc Section templates</td>
                  <td>
                    <table>
                      <tr>
                        <th>Title</th>
                        <th>Body</th>
                        <th>Templates</th>
                        <th>Is deletable?</th>
                        <th>Height</th>
                      </tr>

                      {specdocsections?.map(item => {
                        return (
                          <tr>
                            <td title={`ID: ${item.id}`}>{item.title}</td>
                            <td>{item.body}</td>
                            <td>{item.templates?.join(", ")}</td>
                            <td>{item.isDeletable ? "Yes" : "No"}</td>
                            <td>{item.height}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    POC Tab configuration
                    <br />
                    <button
                      onClick={handleSetFreemium}
                      className="button is-small"
                    >
                      Set Freemium
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="pocTabConfigurations"
                      cols="60"
                      rows="5"
                      value={freemiumTabConfig}
                      onChange={handleChangeFreemium}
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mb-5">
              <button type="submit" className="button is-info">
                Save
              </button>
            </div>
          </form>

          <RbacMappingTable
            userRoles={vendorRbac?.userRoles}
            rolePermissionMapping={vendorRbac?.rolePermissionMapping}
          />
        </div>
      </div>
    </>
  );
}
