import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "services/axios";
import { useParams, useNavigate } from "react-router-dom";
import { resizeAllImages } from "./TextEditor.utils";
import { debounce } from "lodash";
// import "components/common/TextEditor.css";

const defaultToolbar =
  "formatselect | " +
  "bold italic backcolor | alignleft aligncenter alignright alignjustify |" +
  "link table bullist numlist outdent indent | " +
  "removeformat | fullscreen | help";

const TextEditor = React.forwardRef(
  (
    {
      placeholder,
      defaultValue = "",
      height = 300,
      onChange,
      onResizeEditor = () => {},
      disabled = false,
      toolbar = defaultToolbar,
      menubar = false,
      elementpath = false,
      contextmenu = [],
      ...props
    },
    parentRef
  ) => {
    const navigate = useNavigate();
    const { pocId } = useParams();
    const childRef = useRef(null);
    let ref = parentRef || childRef;

    const uploadHandler = async (blobInfo, success, failure) => {
      // const { base64, blob, blobUri, filename, id, name, uri } = blobInfo;
      const formData = new FormData();
      formData.append("file", blobInfo.blob());
      if (pocId) {
        formData.append("pocId", pocId);
      }
      formData.append("isListable", "false"); // Need to pass this as a string because FormData doesn't support booleans
      const { data } = await axios.post("/files/upload", formData, {
        headers: { "content-type": "multipart/form-data" }
      });
      success(data.fileDownloadUrl);
      // success("data:image/png;base64," + base64());
    };
    const updateEditMode = () => {
      const editorElement = ref.current.getElement();
      if (editorElement) {
        const parentEl = editorElement.parentNode;
        const toolbarEl = parentEl.getElementsByClassName("tox-editor-header");
        const statusbarEl = parentEl.getElementsByClassName("tox-statusbar");
        if (toolbarEl.length) {
          if (disabled) {
            toolbarEl[0].className += " is-hidden";
          } else {
            toolbarEl[0].className = "tox-editor-header";
          }
        }
        if (statusbarEl.length) {
          if (disabled) {
            statusbarEl[0].className += " is-hidden";
          } else {
            statusbarEl[0].className = "tox-statusbar";
          }
        }
        // Forcing the focus on the editor
        // ref.current.getBody().setAttribute("contenteditable", true);
        // ref.current.focus();
      }
    };

    const moveTinyMceInsideHeadlessUiModal = e => {
      var tinyMceEditorInHeadlessUiModal = document.querySelector(
        "#headlessui-portal-root .tox-tinymce"
      );
      if (tinyMceEditorInHeadlessUiModal) {
        var tinyMceModal = document.querySelector(".tox-tinymce-aux");

        if (tinyMceModal) {
          tinyMceEditorInHeadlessUiModal.insertBefore(
            tinyMceModal,
            tinyMceEditorInHeadlessUiModal.firstChild
          );
        }
      }

      // removing this event listener, as the job is done!
      document.removeEventListener("focusin", moveTinyMceInsideHeadlessUiModal);
    };

    const redirectToRequirement = e => {
      if (
        window.event.ctrlKey &&
        e.target.className &&
        e.target.className.slice(0, 17) === "embeddedObjectTag" &&
        pocId
      ) {
        navigate(`/pocs/${pocId}/usecases`);
      }
    };

    useEffect(() => {
      if (ref.current) {
        updateEditMode();
      }
    }, [ref.current, disabled]);

    return (
      <Editor
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.5/tinymce.min.js"
        onInit={(evt, editor) => {
          ref.current = editor;
          editor.getBody().setAttribute("spellcheck", true);
          if (contextmenu.length) {
            contextmenu.map((ctx, i) => {
              editor.ui.registry.addMenuItem(
                ctx.name || `contextmenu-${i + 1}`,
                {
                  text: ctx.text || "Context Menu",
                  context: ctx.context || "tools",
                  onAction: ctx.action || null
                }
              );
            });
          }
          updateEditMode();

          editor.on("ResizeEditor", debounce(onResizeEditor, 500));

          // Resizing all images, when pasted
          editor.on("PastePreProcess", function () {
            resizeAllImages(editor);
          });

          // Resizing all images, when added via "SetContent"
          editor.on("SetContent", function () {
            resizeAllImages(editor);
          });

          /*
           * If TinyMCE's modal is outside of a HeadlessUI modal, then we move it inside,
           * so that the Headless' UI focus trap doesn't prevent interaction
           */
          document.addEventListener(
            "focusin",
            moveTinyMceInsideHeadlessUiModal
          );

          editor.on("click", redirectToRequirement);
        }}
        onEditorChange={onChange}
        initialValue={defaultValue}
        init={{
          deprecation_warnings: false,
          selector: "textarea",
          height,
          toolbar,
          plugins: [
            "image table",
            "fullscreen",
            "table paste help",
            "link",
            "lists",
            "contextmenu",
            "nonbreaking",
            "noneditable",
            "autoresize"
          ],
          menubar,
          image_dimensions: true,
          elementpath,
          branding: false,
          contextmenu: `cut copy paste undo redo selectall | link, linkchecker, image, imagetools, permanentpen, table, spellchecker | ${
            contextmenu.length && contextmenu.map(ctx => ctx.name).join(" ")
          }`,
          content_style: `
            @import url("https://fonts.googleapis.com/css?family=Poppins");
            body { font-family: 'Poppins', 'Roboto', sans-serif; }
            p { margin: 0; }
            .embeddedObjectTag {
              align-items: center;
              background-color: white;
              box-shadow: 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px 1px rgba(9, 30, 66, 0.13);
              border-radius: 4px;
              color: #4a4a4a;
              display: inline-flex;
              /* height: 1.8em; */
              justify-content: center;
              line-height: 1.5;
              padding-left: 0.75em;
              padding-right: 0.75em;
              /* white-space: nowrap; */
              margin-bottom: 5px;
              margin-right: 5px;
              cursor: pointer;
            }

            .grayTag {
              background-color: #dce3e8;
              color: #3e5463;
            }

            .orangeTag {
              background-color: #fcddc7;
              color: #8f3516;
            }

            span[data-object-description]{
              position: relative;
            }

            span[data-object-description]:hover::before{
              content: attr(data-object-description);
              position: absolute;
              top: 24px;
              border-radius: 5px;
              padding: 5px;
              height: auto;
              z-index: 10000 !important;
              width: 185px;
              font-size: 12px;
              background: #f3f3f3;
              border: 1px solid #737373;
              color: #737373;
            }

          `,
          paste_data_images: true,
          images_upload_handler: uploadHandler,
          nonbreaking_force_tab: true
        }}
        {...props}
      />
    );
  }
);

export default TextEditor;
