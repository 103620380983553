import { formatDate } from "utils/helpers";
import TableCellSelectableStakeholder from "components/common/TableCells/TableCellSelectableStakeholder";
import FeedbackBodyAndCommentCount from "components/common/TableCells/FeedbackBodyAndCommentCount";
import FeedbackCellSelectableButton from "components/common/TableCells/FeedbackCellSelectableButton";
import FeedbackCellTags from "components/common/TableCells/FeedbackCellTags";
import FeedbackLinkToIssue from "components/common/TableCells/FeedbackLinkToIssue";
import FeedbackCellSelectableText from "components/common/TableCells/FeedbackCellSelectableText";
import EditableDate from "components/common/TableCells/EditableDate";
import {
  FeedbackDoneCell,
  FeedbackActionCell
} from "components/common/TableCells/FeedbackDoneCell";
import FeedbackEntityNotesWidget from "components/common/TableCells/FeedbackEntityNotesWidget";

export const internalColumns = [
  {
    Header: () => null,
    id: "addressed",
    accessor: "addressed",
    Cell: FeedbackDoneCell
  },
  {
    Header: "Collaborator",
    accessor: "stakeholder",
    Cell: TableCellSelectableStakeholder
  },
  {
    Header: "Requests & Blockers",
    accessor: "body",
    Cell: FeedbackBodyAndCommentCount
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: FeedbackCellSelectableButton
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: FeedbackCellSelectableText
  },
  {
    Header: "Priority",
    accessor: "priority",
    Cell: FeedbackCellSelectableButton
  },
  {
    Header: "Due Date",
    accessor: ({ dueDate }) => (dueDate ? formatDate(dueDate) : "-"),
    Cell: EditableDate
  },
  // {
  //   Header: "Success Criteria",
  //   accessor: "tags",
  //   Cell: FeedbackCellTags
  // },
  {
    Header: "Link",
    accessor: "linkedItemId",
    Cell: FeedbackLinkToIssue
  },

  {
    id: "notes",
    Cell: FeedbackEntityNotesWidget
  },

  {
    Header: () => null,
    id: "action",
    Cell: FeedbackActionCell
  }
];

export const prospectColumns = [
  {
    Header: "Order",
    accessor: (_, i) => i + 1
  },
  {
    Header: "Stakeholder",
    accessor: "stakeholder",
    Cell: TableCellSelectableStakeholder
  },
  {
    Header: "Feedback",
    accessor: "body",
    Cell: FeedbackBodyAndCommentCount
  },
  {
    Header: "Due Date",
    accessor: fb => (fb.dueDate ? formatDate(fb.dueDate) : "-")
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: FeedbackCellSelectableButton
  },
  {
    Header: "Priority",
    accessor: "priority",
    Cell: FeedbackCellSelectableButton
  },
  {
    Header: "Done",
    accessor: "addressed",
    Cell: FeedbackDoneCell
  }
];
