import { memo } from "react";
import DatePickerInput from "components/common/DatePickerInput";

const EditableDate = ({ cell, value, edit, disabled, row }) => {
  const addressed = row?.original?.addressed;
  const dateFormat = "MM/dd/yyyy";
  // const dateFormat = "YYYY-MM-DD";
  const original = cell?.row?.original;

  const handleChange = (name, val) => {
    const dateAsStr = val?.toISOString();
    if (original.dueDate !== dateAsStr) {
      edit({ ...original, [name]: dateAsStr });
    }
  };

  return disabled ? (
    value || "-"
  ) : (
    <DatePickerInput
      name="dueDate"
      onChange={handleChange}
      value={value === "-" ? "" : value}
      placeholder="-"
      readOnly={addressed}
      dateFormat={dateFormat}
      className={`${addressed ? "item-completed" : ""}`}
    />
  );
};

export default memo(EditableDate);
