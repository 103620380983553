import { v4 as uuid4 } from "uuid";
import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Comments"] }).injectEndpoints({
    endpoints: build => ({
      getComments: build.query({
        query: fbId => `/feedbacks/${fbId}/comments`,
        providesTags: (result, _, fbId) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Comments", id })),
                { type: "Comments", id: `${fbId}-LIST` }
              ]
            : [{ type: "Comments", id: `${fbId}-LIST` }],
        transformResponse: response =>
          response.sort((c1, c2) => {
            if (c1.createdAt < c2.createdAt) return -1;
            if (c1.createdAt > c2.createdAt) return 1;
            return 0;
          })
      }),
      addComments: build.mutation({
        query: ({ pocId, ...body }) => ({
          url: "/comments",
          method: "POST",
          body
        }),
        async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
          const tempId = uuid4();
          const { user } = getState().auth;
          const postResult = dispatch(
            api.util.updateQueryData("getComments", body.feedbackId, draft => {
              draft.push({
                ...body,
                createdAt: new Date().toISOString(),
                id: tempId,
                // hack since stakeholder records are slightly different than
                // user records
                stakeholder: { ...user, userId: user.id }
              });
            })
          );
          const feedbackResult = dispatch(
            api.util.updateQueryData("getFeedbacks", body.pocId, draft => {
              const feedback = draft.find(
                feedback => feedback.id === body.feedbackId
              );
              feedback.commentCount++;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
            feedbackResult.undo();
          }
        },
        invalidatesTags: (result, error) => {
          if (error) return [];
          return [{ type: "Comments", id: `${result.feedbackId}-LIST` }];
        }
      }),
      editComment: build.mutation({
        query: ({ id, message }) => {
          return {
            url: `/comments/${id}`,
            method: "PATCH",
            body: { body: message }
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData("getComments", body.feedbackId, draft => {
              const comment = draft.find(comment => comment.id === body.id);
              if (comment) {
                comment.body = body.message;
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: ({ id }) => [{ type: "Comments", id }]
      }),
      deleteComment: build.mutation({
        query: ({ id }) => ({
          url: `/comments/${id}`,
          method: "DELETE"
        }),
        async onQueryStarted(
          { id, pocId, feedbackId },
          { dispatch, queryFulfilled }
        ) {
          const deleteResult = dispatch(
            api.util.updateQueryData("getComments", feedbackId, draft => {
              const commentIdx = draft.findIndex(comment => comment.id === id);
              if (commentIdx > -1) {
                draft.splice(commentIdx, 1);
              }
            })
          );
          const feedbackResult = dispatch(
            api.util.updateQueryData("getFeedbacks", pocId, draft => {
              const feedback = draft.find(
                feedback => feedback.id === feedbackId
              );
              feedback.commentCount--;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            feedbackResult.undo();
            deleteResult.undo();
          }
        },
        invalidatesTags: ({ id }) => [{ type: "Comments", id }]
      })
    })
  });
