import { useState, useEffect } from "react";
import { CheckLg, ArrowRepeat, ExclamationLg } from "react-bootstrap-icons";

const ApiCallIndicator = ({ apiResponse }) => {
  const [status, setStatus] = useState("");
  const { isLoading, error, isSuccess } = apiResponse;

  useEffect(() => {
    if (isLoading) {
      setStatus(<ArrowRepeat />);
    }
    if (isSuccess) {
      setStatus(<CheckLg className="has-text-success" />);
    }
    if (error) {
      setStatus(<ExclamationLg className="has-text-danger" />);
    }
  }, [apiResponse]);

  return <span className="icon">{status}</span>;
};

export default ApiCallIndicator;
