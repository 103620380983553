import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CheckCircle, XCircle, PauseCircle } from "react-bootstrap-icons";
import { useEditPocMutation, useGetUsersQuery } from "services/api";

export default function PocStatus({ poc }) {
  const [editable, setEditable] = useState(false);
  const [pocStatus, setpocStatus] = useState("");
  const [editPoc] = useEditPocMutation();

  useEffect(() => {
    setpocStatus(poc.status);
  }, [poc.status]);

  const handleSetStatus = useCallback(
    status => {
      pocStatus === status ? setpocStatus("") : setpocStatus(status);
    },
    [pocStatus]
  );

  const changePocStatus = e => {
    e.preventDefault();
    const payload = {
      id: poc.id,
      status: pocStatus || "Pending",
      outcomeNotes: e.target.note.value
    };
    editPoc(payload);
    setEditable(false);
    toast.info("Opportunity status updated");
  };
  const { data: users } = useGetUsersQuery();
  const outcomeMarkedByUser = users?.find(
    x => x.id === poc.outcomeMarkedByUserId
  );

  return (
    <div className="box">
      <h1 className="is-size-4 mb-3">Outcome</h1>
      <h2 className="is-size-5 mb-3">What was the outcome?</h2>
      {poc.status === "Pending" || editable ? (
        <form onSubmit={changePocStatus}>
          <div className="buttons mb-3">
            <button
              type="button"
              className="button w-8r is-success"
              onClick={() => handleSetStatus("Pass")}
            >
              PASS
              {pocStatus === "Pass" && <CheckCircle className="ml-2" />}
            </button>
            <button
              type="button"
              className="button w-8r is-danger"
              onClick={() => handleSetStatus("Fail")}
            >
              FAIL
              {pocStatus === "Fail" && <XCircle className="ml-2" />}
            </button>
          </div>
          <div className="max-w-50 ">
            <textarea
              required
              defaultValue={poc.outcomeNotes || ""}
              name="note"
              style={{ resize: "none" }}
              placeholder="Outcome Note"
              className="textarea"
              rows={4}
            />
          </div>

          <div className="is-flex is-justify-content-end" style={{ gap: 20 }}>
            <button type="submit" className="button is-info w-6r">
              SAVE
            </button>
            {editable && (
              <button
                type="button"
                onClick={() => setEditable(false)}
                className={`w-6r button is-light`}
              >
                CANCEL
              </button>
            )}
          </div>
        </form>
      ) : (
        <div className="px-4 mt-5">
          <div className="is-flex ">
            <div
              className={`button border-0 w-8r has-background-${
                poc.status === "Pass"
                  ? "success"
                  : poc.status === "Fail"
                  ? "danger"
                  : "warning"
              }`}
            >
              {poc.status === "Pass"
                ? "PASS"
                : poc.status === "Fail"
                ? "FAIL"
                : "PAUSED"}
            </div>
            <div className="is-size-5 ml-4 is-flex is-align-items-center">
              marked by {outcomeMarkedByUser?.firstName}{" "}
              {outcomeMarkedByUser?.lastName} on{" "}
              {dayjs(poc.outcomeMarkedAt).format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="max-w-50 mt-5">
            <textarea
              readOnly
              value={poc.outcomeNotes}
              style={{ resize: "none" }}
              className="textarea"
            />
          </div>
          <div className="is-flex is-justify-content-end">
            <button
              onClick={() => setEditable(true)}
              className={`w-8r  button is-info `}
            >
              EDIT
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
