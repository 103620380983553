import React from "react";
import { GripVertical } from "react-bootstrap-icons";

const TableRowDragHandle = ({}) => {
  return (
    <center>
      <GripVertical className="show-on-row-hover" />
    </center>
  );
};

export default TableRowDragHandle;
