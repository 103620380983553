import APIError from "components/common/APIError";
import Loading from "components/common/Loading";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticateMutation } from "services/api";

export default function AutoLogin({ magicToken }) {
  const [autoLogin, { data, isError, error, isLoading, isSuccess }] =
    useAuthenticateMutation();
  const navigate = useNavigate();
  const handleAutoLogin = () => {
    autoLogin({ mode: "auto", magicToken });
  };
  useEffect(() => {
    handleAutoLogin();
  }, []);
  useEffect(() => {
    if (isSuccess) {
      const redirectTo = data?.payload?.redirectTo
        ? data.payload.redirectTo
        : "/";
      navigate(redirectTo);
    }
  }, [isSuccess, data]);

  if (isLoading) return <Loading />;

  if (isError) return <APIError message={error.data.message} />;
  return <></>;
}
