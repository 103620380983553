import api from "./_createApi";

export default !!api &&
  api
    .enhanceEndpoints({ addTagTypes: ["PocSpecdocsections"] })
    .injectEndpoints({
      endpoints: build => ({
        getPocSpecdocsections: build.query({
          query: pocId => `/pocs/${pocId}/pocSpecdocsections`,
          providesTags: (result, _, pocId) =>
            result
              ? [
                  ...result.map(({ id }) => ({
                    type: "PocSpecdocsections",
                    id
                  })),
                  { type: "PocSpecdocsections", id: `${pocId}-LIST` }
                ]
              : [{ type: "PocSpecdocsections", id: `${pocId}-LIST` }]
        }),
        importPocSpecdocsection: build.mutation({
          query: ({ pocId, template }) => {
            return {
              url: `/pocs/${pocId}/pocSpecdocsections/templates`,
              method: "POST",
              body: {
                template,
                isPreviewMode: false
              }
            };
          }
        }),
        addPocSpecdocsection: build.mutation({
          query: body => {
            return {
              url: `/pocSpecdocsections`,
              method: "POST",
              body
            };
          },
          invalidatesTags: ({ pocId }) => {
            return [{ type: "PocSpecdocsections", id: `${pocId}-LIST` }];
          }
        }),
        addPocSpecdocsectionFromSpecdocsection: build.mutation({
          query: body => {
            return {
              url: `/pocSpecdocsections/fromSpecdocsection`,
              method: "POST",
              body
            };
          },
          invalidatesTags: ({ pocId }) => {
            return [{ type: "PocSpecdocsections", id: `${pocId}-LIST` }];
          }
        }),
        editPocSpecdocsections: build.mutation({
          query: ({ id, ...body }) => {
            return {
              url: `/pocSpecdocsections/${id}`,
              method: "PATCH",
              body
            };
          },
          async onQueryStarted(
            { pocId, id, ...body },
            { dispatch, queryFulfilled }
          ) {
            const patchResult = dispatch(
              api.util.updateQueryData(
                "getPocSpecdocsections",
                pocId,
                draft => {
                  const data = draft.find(s => s.id === id);
                  if (data) {
                    for (let key in body) {
                      data[key] = body[key];
                    }
                  }
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          },
          invalidatesTags: ({ id }) => [{ type: "PocSpecdocsections", id }]
        }),
        deletePocSpecdocsection: build.mutation({
          query: id => ({
            url: `/pocSpecdocsections/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted(id, { dispatch, queryFulfilled }) {
            const deleteResult = dispatch(
              api.util.updateQueryData(
                "getPocSpecdocsections",
                undefined,
                draft => {
                  const itemIdx = draft.findIndex(
                    pocSpecdocsection => pocSpecdocsection.id === id
                  );
                  if (itemIdx > -1) {
                    draft.splice(itemIdx, 1);
                  }
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              deleteResult.undo();
            }
          },
          invalidatesTags: id => [
            { type: "PocSpecdocsections", id },
            "PocSpecdocsections"
          ]
        }),
        reorderPocSpecdocsections: build.mutation({
          query: ({ pocId, ids }) => ({
            url: `pocs/${pocId}/pocSpecdocsections/reorder`,
            method: "POST",
            body: { ids }
          }),
          async onQueryStarted({ pocId, ids }, { dispatch, queryFulfilled }) {
            const result = dispatch(
              api.util.updateQueryData("getPocSpecdocsections", pocId, draft =>
                ids.map(id => draft.find(s => s.id === id))
              )
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          },
          invalidatesTags: (_result, _error, arg) => {
            return ["PocSpecdocsections"];
          }
        })
      })
    });
