import Joi from "joi-browser";
import { memo } from "react";
import Form from "components/common/Form";

const fields = [
  {
    name: "message",
    placeholder: "Reply"
  }
];

const schema = {
  message: Joi.string().required()
};

const FeedbackMessageForm = ({ handleSubmit, message = "" }) => {
  const initialState = { message };
  return (
    <Form
      className="px-4"
      fields={fields}
      schema={schema}
      initialState={initialState}
      onValidSubmit={handleSubmit}
      hideButton
    />
  );
};

export default memo(FeedbackMessageForm);
