import { Dialog } from "@headlessui/react";
import Loading from "components/common/Loading";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDeletePocAttachmentsMutation } from "services/api";
import { ExclamationDiamond } from "react-bootstrap-icons";

const PocProof = ({ data }) => {
  const [deleteAttachment, { isDeleteLoading }] =
    useDeletePocAttachmentsMutation();
  const [deletingEvidence, setDeletingEvidence] = useState(null);

  const handleDeleteProof = attachmentId => {
    deleteAttachment({ id: attachmentId })
      .unwrap()
      .then(res => {
        toast.success(`Evidence deleted successfully`);
      })
      .catch(({ data }) =>
        toast.error(`Error while deleting evidence: ${data.message}`)
      );
  };

  return (
    <>
      {data?.evidences?.map(item => (
        <span className="tag is-white" key={item.id}>
          <a href={item.fileDownloadUrl} target="_blank">
            {item.fileName}
          </a>
          {isDeleteLoading ? (
            <Loading />
          ) : (
            <button
              className="delete is-small"
              onClick={() => setDeletingEvidence(item)}
            ></button>
          )}
        </span>
      ))}
      <Dialog
        as="div"
        open={deletingEvidence !== null}
        className="is-fixed inset-0 z-10 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setDeletingEvidence(null)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <div className="is-flex is-align-items-center gap-2">
            <ExclamationDiamond
              size={"25px"}
              className="has-text-danger mr-2"
            />
            <div className="is-size-4">
              Delete Evidence {deletingEvidence?.fileName} ?
            </div>
          </div>
          <div className="my-5">
            You're about to permanently delete this evidence.
          </div>
          <div className="buttons is-justify-content-end">
            <button
              onClick={() => handleDeleteProof(deletingEvidence?.id)}
              className="button is-danger"
            >
              Delete
            </button>
            <button
              onClick={() => setDeletingEvidence(null)}
              className="button is-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PocProof;
