import PocForm from "pages/PocOverview/PocForm";
import ImportSalesforce from "./ImportSalesforce";
import { useParams } from "react-router-dom";
import NiceTabs from "components/common/NiceTabs";

const NewPoc = () => {
  const { type } = useParams();

  const tabs = [
    {
      urlTo: "/pocs/new/salesforce",
      title: `Import From Salesforce`
    }
    // {
    //   urlTo: "/pocs/new/manual",
    //   title: `Create Manually`
    // }
  ];

  return (
    <>
      <NiceTabs tabs={tabs} />
      <div className="card">
        <div className="card-content">
          {type === "manual" ? <PocForm /> : <ImportSalesforce />}
        </div>
      </div>
    </>
  );
};

export default NewPoc;
