import React from "react";
import PocBasicInfoForm from "./PocBasicInfoForm";
import useRbac from "../../components/common/Rbac/useRbac";
import { UserRoles } from "config/userRoles";
import { UserPermissions } from "../../config/userPermissions";
import SpecDoc from "./SpecDoc";

export default function BasicInfo({ poc }) {
  const { userHasRole, userHasPermission } = useRbac();
  const firstExternalCollab = poc.stakeholders.find(
    item => item.type === "External"
  );
  const pocCreators = [];
  pocCreators.push(
    `<a href="mailto:${poc.createdByUser.email}">${poc.createdByUser.fullName}</a>`
  );
  if (firstExternalCollab) {
    pocCreators.push(
      `<a href="mailto:${firstExternalCollab.email}">${firstExternalCollab.fullName}</a>`
    );
  }

  return (
    <div className="is-relative box">
      {/* <section className="pocEvaluationInfo hero">
        <div className="hero-body">
          <p className="title">{poc.pocName}</p>
          <p
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: `Owner(s): ${pocCreators.join(" and ")}`
            }}
          ></p>
        </div>
      </section> */}
      <br />
      {userHasPermission([UserPermissions.EVALSPECDOCSECTIONS_READ]) && (
        <SpecDoc pocId={poc?.id} />
      )}
    </div>
  );
}
