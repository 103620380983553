import { useMemo, useState, useEffect } from "react";
import { ArrowRepeat, Plus } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { uuid4 } from "@sentry/utils";

import usePocs from "hooks/usePocs";
import APIError from "components/common/APIError";
import PocTopBar from "components/TopBar/TopBarPoc";
import LayoutContainer from "components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import ChecklistGroup from "./ChecklistGroup";
import {
  useGetPocChecklistgroupsQuery,
  useEditPocChecklistgroupsMutation,
  useReorderPocChecklistgroupsMutation,
  useReorderPocChecklistitemsMutation,
  useEditPocChecklistitemsMutation,
  useDeletePocChecklistitemsMutation,
  useAddPocChecklistitemsMutation,
  useDeletePocChecklistgroupsMutation,
  useAddPocChecklistgroupsMutation,
  useGetPocChecklistitemsQuery,
  useImportPocChecklistMutation
} from "services/api";
import ChecklistImport from "./ChecklistImport";
import { arraymove } from "utils/helpers";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";

export default function PocChecklist() {
  const { pocId } = useParams();
  const { currentPoc: poc } = usePocs();
  const [importTemplate, resImport] = useImportPocChecklistMutation();
  const { data: groups, isLoading: isGetGroups } =
    useGetPocChecklistgroupsQuery(poc?.id, { skip: !poc?.id });
  const { data: items, isLoading: isGetItems } = useGetPocChecklistitemsQuery(
    poc.id || pocId
  );
  const [addGroup, { isLoading: isAddGroup }] =
    useAddPocChecklistgroupsMutation();
  const [editGroup, { isLoading: isEditGroup }] =
    useEditPocChecklistgroupsMutation();
  const [removeGroup, { isLoading: isRemoveGroup }] =
    useDeletePocChecklistgroupsMutation();
  const [reorderGroup, { isLoading: isReorderGroup }] =
    useReorderPocChecklistgroupsMutation();
  const [reorderItem, { isLoading: isReorderItem }] =
    useReorderPocChecklistitemsMutation();
  const [addItem] = useAddPocChecklistitemsMutation();
  const [editItem] = useEditPocChecklistitemsMutation();
  const [removeItem] = useDeletePocChecklistitemsMutation();
  const [ucg, setucg] = useState([]);
  const [cg, setcg] = useState([]);
  const ncg = useMemo(() => cg?.map(x => ({ ...x })), [cg]);
  const nucg = useMemo(() => ucg?.map(x => ({ ...x })), [ucg]);

  useEffect(() => {
    if (groups && items) {
      const groupIds = groups.map(x => x.id);
      const ungroupItems = items.filter(
        x => !groupIds?.includes(x?.pocChecklistgroupId)
      );
      const groupItems = groups.reduce((s, { pocChecklistitems, ...group }) => {
        group.pocChecklistitems = [];
        for (const item of items) {
          if (item.pocChecklistgroupId === group.id) {
            group.pocChecklistitems.push(item);
          }
        }
        s.push(group);
        return s;
      }, []);
      setcg(groupItems);
      setucg(ungroupItems);
    }
  }, [groups, items]);

  const onDragEnd = result => {
    const { type, draggableId, source: s, destination: d } = result;
    const itemId = draggableId.replace(/item-/g, "");
    if (d !== null) {
      if (
        type === "group" &&
        s.index !== d.index &&
        draggableId.indexOf("item") < 0
      ) {
        arraymove(ncg, s.index, d.index);
        setcg(ncg);
        reorderGroup({ pocId: poc.id, body: ncg });
      }
      const newItems = [...items];
      const gi = ncg.findIndex(x => x.id === s.droppableId);
      const findIdx = id => newItems.findIndex(x => x.id === id);

      const sUcgIdx = findIdx(ucg[s.index]?.id);
      const dUcgIdx = findIdx(ucg[d.index]?.id);
      const sCgIdx = findIdx(ncg[gi]?.pocChecklistitems[s.index]?.id);
      const dCgIdx = findIdx(ncg[gi]?.pocChecklistitems[d.index]?.id);
      if (type === "item") {
        if (s.droppableId === d.droppableId) {
          if (s.droppableId === "ungroup") {
            arraymove(nucg, s.index, d.index);
            setucg(nucg);
            arraymove(newItems, sUcgIdx, dUcgIdx);
          } else {
            arraymove(newItems, sCgIdx, dCgIdx);
            arraymove(ncg[gi].pocChecklistitems, s.index, d.index);
            ncg[gi] = {
              ...ncg[gi],
              pocChecklistitems: ncg[gi].pocChecklistitems
            };
          }
          reorderItem({ pocId: poc.id, body: newItems });
        } else if (d.droppableId === "ungroup") {
          const gindex = ncg.findIndex(x => x.id === s.droppableId);
          const item = ncg[gindex].pocChecklistitems.find(x => x.id === itemId);

          arraymove(newItems, sCgIdx, dUcgIdx);
          ucg.splice(d.index, 0, item);
          ncg[gindex].pocChecklistitems.splice(s.index, 1);

          reorderItem({ pocId: poc.id, body: newItems });
          editItem({
            pocId: poc.id,
            id: itemId,
            pocChecklistgroupId: null,
            weight: items[dUcgIdx]?.weight
          });
        } else if (s.droppableId === "ungroup") {
          const item = ucg.find(x => x.id === itemId);
          const gindex = ncg.findIndex(x => x.id === d.droppableId);
          const dIdx = findIdx(ncg[gindex]?.pocChecklistitems[d.index]?.id);
          // arraymove(newItems, sUcgIdx, dIdx);
          // reorderItem({ pocId: poc.id, body: newItems })
          editItem({
            id: itemId,
            pocChecklistgroupId: ncg[gindex].id,
            weight:
              items[dIdx]?.weight > 0
                ? items[dIdx]?.weight - 10
                : items[dIdx]?.weight
          });
          ncg[gindex].pocChecklistitems.splice(d.index, 0, item);
          ucg.splice(s.index, 1);
        } else if (s.droppableId !== d.droppableId) {
          const sIndex = ncg.findIndex(x => x.id === s.droppableId);
          const dIndex = ncg.findIndex(x => x.id === d.droppableId);
          const item = ncg[sIndex].pocChecklistitems[s.index];

          editItem({
            id: itemId,
            pocChecklistgroupId: ncg[dIndex].id,
            weight: ncg[dIndex]?.pocChecklistitems[d.index]?.weight
          });
          ncg[sIndex].pocChecklistitems.splice(s.index, 1);
          ncg[dIndex].pocChecklistitems.splice(d.index, 0, item);
        }
      }
    }
  };

  const handleAddGroup = ({ index, body, weight }) => {
    addGroup({ body, pocId: poc.id, weight });
  };

  const handleEditGroup = payload => {
    editGroup(payload);
  };

  const handleRemoveGroup = ({ id }) => {
    removeGroup({ id, pocId: poc.id });
  };

  const handleAddItem = payload => {
    addItem(payload);
  };

  const handleEditItem = ({ id, gid, ...body }) => {
    editItem({ id, pocId: poc.id, ...body });
  };

  const handleRemoveItem = ({ id, gid }) => {
    removeItem({ id, pocId: poc.id });
  };

  const handleImport = (payload, callback) => {
    importTemplate(payload);
  };

  const isLoading =
    resImport.isLoading ||
    isGetGroups ||
    isGetItems ||
    isAddGroup ||
    isEditGroup ||
    isRemoveGroup ||
    isReorderGroup ||
    isReorderItem;
  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar poc={poc} showScore={true} title={poc?.pocName} />
      </LayoutContainer>

      <LayoutContainer>
        <APIError error={""} />
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>
        <div className="is-relative box">
          <div className="is-flex is-align-items-center is-justify-content-space-between mb-4">
            <div>
              {isLoading && (
                <ArrowRepeat className="is-spinning mx-auto w-5r h-5r" />
              )}
            </div>
            <WithRbac
              permissionOneOf={UserPermissions.EVALACTIONPLAN_IMPORTTEMPLATE}
            >
              <ChecklistImport
                response={resImport}
                importTemplate={handleImport}
              />
            </WithRbac>
          </div>

          <ChecklistGroup
            grouped={ncg}
            ungrouped={ucg}
            items={items}
            onDragEnd={onDragEnd}
            addGroup={handleAddGroup}
            editGroup={handleEditGroup}
            removeGroup={handleRemoveGroup}
            addItem={handleAddItem}
            editItem={handleEditItem}
            removeItem={handleRemoveItem}
          />
        </div>
      </LayoutContainer>
    </>
  );
}
