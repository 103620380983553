import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";

const HJ_TRACKING_ID = process.env.REACT_APP_HJ_TRACKING_ID || "";
const HJ_TRACKING_SV = process.env.REACT_APP_HJ_TRACKING_SV || "";

const HotJarTracker = () => {
  const { currentUser } = useAuth();

  let location = useLocation();

  hotjar.initialize(HJ_TRACKING_ID, HJ_TRACKING_SV);
  hotjar.identify("USER_ID", {
    id: currentUser?.id,
    email: currentUser?.email,
    vendorId: currentUser?.vendorId
  });

  useEffect(() => {
    hotjar.stateChange(location.pathname);
  }, [location]);
};

export default HotJarTracker;
