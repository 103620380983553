import React, { useState } from "react";
import { useAddExternalCommentMutation } from "services/api";
import useVendor from "hooks/useVendor";
import { toast } from "react-toastify";

export default function AddToJiraModal({
  feedback,
  itemId,
  placeholder,
  onClose,
  primaryButtonLabel = "Add to Jira",
  onLink = () => {}
}) {
  const [comment, setComment] = useState("");
  const { getVendorProvider } = useVendor();
  const { providerId, vendorProviderId } = getVendorProvider("jira");
  const [commentExternal] = useAddExternalCommentMutation();

  const handleSubmit = e => {
    e.preventDefault();

    // Posting a comment
    if (feedback.linkedItemId) {
      const payload = {
        vendorProviderId,
        linkedItemId: feedback.linkedItemId,
        body: comment
      };

      commentExternal(payload)
        .unwrap()
        .then(() => toast.success(`Comment posted!`))
        .catch(error =>
          toast.error(`Error while posting comment: ${error.data.message}`)
        );
    }

    // Linking to a feedback
    if (itemId) {
      const payload = {
        id: feedback.id,
        itemId,
        providerId,
        comment
      };

      onLink(payload)
        .unwrap()
        .then(() => toast.success(`Feedback linked to Jira ${itemId}!`))
        .catch(error =>
          toast.error(
            `Error while linking to Jira ${itemId}: ${error.data.message}`
          )
        );
    }
    onClose();
  };
  return (
    <div className="is-relative">
      <div className="box w-30r is-absolute z-20 right-5">
        <form onSubmit={handleSubmit}>
          <textarea
            className="input h-30r mb-3"
            cols="30"
            rows="10"
            value={comment}
            onChange={e => setComment(e.target.value)}
            placeholder={placeholder}
          />
          <div className="mb-5">
            <div className="buttons is-pulled-right">
              <button type="submit" className="button is-info">
                {primaryButtonLabel}
              </button>

              <button className="button is-secondary" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
