import Button from "components/common/Button";
import "./GettingStarted.css";

const GettingStarted = ({ message, buttonText, setShow }) => {
  return (
    <div className="GettingStarted p-5 drop-shadow my-4">
      <p className="title is-4">{message}</p>
      <Button onClick={setShow} className="is-info">
        {buttonText}
      </Button>
    </div>
  );
};

export default GettingStarted;
