import React, { useEffect, useState } from "react";
import {
  useAddPocSpecdocsectionFromSpecdocsectionMutation,
  useAddPocSpecdocsectionMutation,
  useGetAttachmentsQuery,
  useGetPocSpecdocsectionsQuery,
  useGetQuestionsQuery,
  useGetSpecdocsectionsQuery,
  useGetUserQuery,
  useGetVendorQuery,
  useReorderPocSpecdocsectionsMutation,
  useSalesforceSearchMutation
} from "services/api";
import PocSpecdocsection from "./PocSpecdocsection";
import useRbac from "../../components/common/Rbac/useRbac";
import { UserPermissions } from "../../config/userPermissions";
import { LoadingCentered } from "../../components/common/Loading";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { getHeaviestWeight, reorderListAfterDragEnd } from "utils/helpers";
import WithRbac from "components/common/Rbac/withRbac";
import useAuth from "hooks/useAuth";
import Modal from "components/common/Modal";
import APIError from "components/common/APIError";
import { Menu } from "@headlessui/react";
import { CaretDown } from "react-bootstrap-icons";
import useFeatureFilter from "../../hooks/useFeatureFilter";
import usePocs from "hooks/usePocs";
import { useNavigate } from "react-router-dom";

export default function SpecDoc({ pocId }) {
  const { currentUser } = useAuth();
  const { currentPoc } = usePocs();
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery(currentUser?.id, {
    skip: !currentUser
  });
  const { data: pocSpecdocsections, isLoading: isPocSpecdocsectionsLoading } =
    useGetPocSpecdocsectionsQuery(pocId);
  const [reorder, { isLoading: isReorderLoading }] =
    useReorderPocSpecdocsectionsMutation();
  const { data: specdocsections, isLoading: isSpecdocsectionsLoading } =
    useGetSpecdocsectionsQuery();
  const [create, { isLoading: isCreateLoading, error: createError }] =
    useAddPocSpecdocsectionFromSpecdocsectionMutation();
  const [createNewSection, { isLoading: isCreateNewLoading }] =
    useAddPocSpecdocsectionMutation();
  const [
    findOpportunities,
    { isLoading: loadingFindOp, error: errorFindOpportunities }
  ] = useSalesforceSearchMutation();

  const [skipPreloading, setSkipPreloading] = React.useState(true);

  const { showVcDemoFeatures } = useFeatureFilter();

  /*
   * Preloading entities to speed up other POC tabs
   */
  useGetQuestionsQuery(undefined, { skip: skipPreloading });
  useGetAttachmentsQuery(currentUser.vendorId, { skip: skipPreloading });

  const [isAddingExtraSection, setIsAddingExtraSection] = useState(false);

  const { userHasPermission } = useRbac();

  const userCanEdit = () => {
    return userHasPermission(UserPermissions.EVALSPECDOCSECTIONS_UPDATE);
  };
  const userCanReorder = () => {
    return userHasPermission(UserPermissions.EVALSPECDOCSECTIONS_REORDER);
  };
  const userCanDelete = data => {
    return (
      (userHasPermission(UserPermissions.EVALSPECDOCSECTIONS_DELETE) &&
        data.isDeletable) ||
      userHasPermission(UserPermissions.EVALSPECDOCSECTIONS_FORCEDELETE)
    );
  };

  // Preloading only for users who have the Edit permission
  useEffect(() => {
    if (userCanEdit()) {
      setSkipPreloading(false);
    }
  }, [userCanEdit]);

  useEffect(() => {
    if (errorFindOpportunities) {
      console.error(errorFindOpportunities);
      // navigate("/settings/integrations");
    }
  }, [errorFindOpportunities]);

  const handleDragEnd = result => {
    const items = reorderListAfterDragEnd(
      pocSpecdocsections,
      result.source?.index,
      result.destination?.index
    );

    reorder({
      pocId: pocId,
      ids: items.map(item => item.id)
    });
  };

  const handleAddSection = async item => {
    if (item.salesforce) {
      if (user?.salesforceIsConnected) {
        const payload = {
          id: user?.id,
          where: {
            id: `'${currentPoc.salesforceOpportunityId}'`
          }
        };
        const { data: sfData } = await findOpportunities(payload);
        createNewSection({
          pocId: pocId,
          specdocsectionId: item ? item.id : null,
          title: item.title,
          body: sfData ? sfData[0][item.salesforce] : "",
          salesforce: item.salesforce,
          tags: item?.templates,
          weight: getHeaviestWeight(pocSpecdocsections) + 10
        });
      } else {
        navigate("/settings/integrations");
      }
    } else {
      create({
        pocId: pocId,
        specdocsectionId: item ? item.id : null,
        weight: getHeaviestWeight(pocSpecdocsections) + 10
      });
    }
  };

  if (isPocSpecdocsectionsLoading) {
    return <LoadingCentered />;
  }

  return (
    <>
      {showVcDemoFeatures && (
        <nav className="level">
          <div className="level-left"></div>
          <div className="level-right">
            <div className="level-item">
              <Menu
                as="div"
                className="is-size-6 dropdown is-active mx-4"
                style={{ verticalAlign: "inherit" }}
              >
                <Menu.Button
                  as="div"
                  className="dropdown-trigger is-flex button small"
                >
                  Import Notes <CaretDown className="ml-2" />
                </Menu.Button>
                <Menu.Items
                  as="div"
                  className="dropdown-menu p-3 w-auto whitespace-nowrap "
                  style={{ outlineStyle: "none" }}
                >
                  <Menu.Item
                    as="div"
                    onClick={() => {}}
                    className="is-clickable mb-2"
                  >
                    Word Doc
                  </Menu.Item>
                  <Menu.Item
                    as="div"
                    onClick={() => {}}
                    className="is-clickable mb-2"
                  >
                    Google Doc
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {}}
                    as="div"
                    className="is-clickable mb-2"
                  >
                    OneNote
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {}}
                    as="div"
                    className="is-clickable mb-2"
                  >
                    Evernote
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        </nav>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={snapshot.isDraggingOver ? "has-background-light" : ""}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {pocSpecdocsections?.map((item, index) => {
                return (
                  <Draggable
                    key={item.id}
                    draggableId={`dragId-${item.id}`}
                    isDragDisabled={!userCanReorder()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <PocSpecdocsection
                          key={index}
                          data={item}
                          isEditable={userCanEdit()}
                          isDeletable={userCanDelete(item)}
                          isReorderable={userCanReorder()}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <WithRbac permissionOneOf={UserPermissions.EVALSPECDOCSECTIONS_CREATE}>
        <div className="buttons is-justify-content-center p-4">
          <button
            className="button is-info"
            onClick={() => setIsAddingExtraSection(true)}
          >
            + Add new section
          </button>
        </div>
      </WithRbac>

      <Modal
        isOpen={isAddingExtraSection}
        setIsOpen={setIsAddingExtraSection}
        title={"Add a new Section"}
        isLoading={isCreateLoading || isCreateNewLoading || loadingFindOp}
        body={
          <div className="my-5 buttons is-justify-content-center">
            <APIError error={createError} />
            {specdocsections?.map((item, i) => (
              <button
                title={
                  item?.templates?.includes("Salesforce")
                    ? "sync salesforce"
                    : ""
                }
                key={i}
                onClick={() => handleAddSection(item)}
                disabled={
                  isCreateLoading ||
                  isCreateNewLoading ||
                  loadingFindOp ||
                  (pocSpecdocsections
                    ? pocSpecdocsections.find(
                        ({ specdocsectionId }) => item.id === specdocsectionId
                      )
                    : null)
                }
                type="button"
                className="button py-1"
              >
                +{item.title}
              </button>
            ))}
            <button
              key="custom"
              onClick={() => handleAddSection(null)}
              type="button"
              className="button py-1"
            >
              +Notes Section
            </button>
          </div>
        }
        buttons={[
          <button
            onClick={() => setIsAddingExtraSection(false)}
            className="button is-light"
          >
            Close
          </button>
        ]}
        className={"w-45r"}
      />
    </>
  );
}
