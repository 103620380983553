/**
 * Scans the body of TexEditor and places a callback on images to resize them if needed
 * @param {TinyMCE} editor
 */
export function resizeAllImages(editor) {
  let imgs = editor.dom.select("img:not([data-apply-max-width])");

  imgs.map(img => {
    img.onload = e => {
      resizeImage(e.target, editor);
    };
  });
}

/**
 * Callback to resize an image that is fully loaded
 * @param {Img Element} img
 * @param {TinyMCE} editor
 */
export function resizeImage(img, editor) {
  const maxWidth = 600;

  let width = img.width;
  let height = img.height;

  let extraAttribs = {
    "data-apply-max-width": "ok"
  };

  if (width > maxWidth) {
    extraAttribs["height"] = height / (width / maxWidth);
    extraAttribs["width"] = maxWidth;
  }

  editor.dom.setAttribs(img, extraAttribs);
}
