import { sortBy } from "utils/helpers";
import { v4 as uuid4 } from "uuid";
import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Usecases"] }).injectEndpoints({
    endpoints: build => ({
      getUseCases: build.query({
        query: () => `/usecases`,
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Usecases", id })),
                { type: "Usecases", id: `LIST` }
              ]
            : [{ type: "Usecases", id: `LIST` }]
        // transformResponse: response => sortBy(response, "body")
      }),
      addUseCases: build.mutation({
        query: ({ ...body }) => ({
          url: "/usecases",
          method: "POST",
          body
        }),
        invalidatesTags: (result, error) => {
          if (error) return [];
          return [{ type: "Usecases", id: "LIST" }];
        }
      }),
      editUseCase: build.mutation({
        query: ({ id, ...body }) => {
          return {
            url: `/usecases/${id}`,
            method: "PATCH",
            body: body
          };
        },
        invalidatesTags: () => [{ type: "Usecases" }, { type: "PocUsecases" }]
      }),
      addUseCasesAndSuccessCriteria: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const { data, error } = await fetchWithBaseQuery({
            url: "/usecases",
            method: "POST",
            body: body.usecase
          });
          if (error) return { data: null, error };
          const usecaseId = data.id;
          const successCriteriaResponse = await Promise.all(
            body.successcriterias.map(answer =>
              fetchWithBaseQuery({
                url: "/successcriterias",
                method: "POST",
                body: { ...answer, usecaseId }
              })
            )
          );
          const successes = successCriteriaResponse
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = successCriteriaResponse
            .filter(res => !res.data)
            .map(res => res.error);
          if (failures.length > 0) return { data: null, error: failures };
          return {
            data: {
              usecase: data,
              successcriterias: successes.length > 0 ? successes : []
            },
            error: null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const tempId = uuid4();
          const postResult = dispatch(
            api.util.updateQueryData("getUseCases", undefined, draft => {
              return draft.concat({
                ...body.usecase,
                createdAt: new Date().toISOString(),
                id: tempId,
                successcriterias: body.successcriterias.map(
                  successcriteria => ({
                    ...successcriteria,
                    id: uuid4(),
                    usecaseId: tempId
                  })
                )
              });
            })
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
        invalidatesTags: (_result, _error, arg) => [
          "PocScore",
          { type: "Usecases", id: "LIST" }
        ]
      }),
      editUseCasesAndSuccessCriteria: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const [
            editedSuccesscriteria,
            deletedSuccesscriteria,
            newSuccesscriteria
          ] =
            body.successcriterias?.reduce(
              (aggregate, successcriteria) => {
                if (!successcriteria.isNew && !successcriteria.toDelete) {
                  aggregate[0].push(successcriteria);
                } else if (!successcriteria.isNew && successcriteria.toDelete) {
                  aggregate[1].push(successcriteria);
                } else if (!successcriteria.toDelete) {
                  aggregate[2].push(successcriteria);
                }
                return aggregate;
              },
              [[], [], []]
            ) || [];
          const usecaseBody = {};
          if ("usecase" in body) {
            usecaseBody.body = body.usecase;
          }
          if ("notes" in body) {
            usecaseBody.notes = body.notes;
          }
          if ("archived" in body) {
            usecaseBody.archived = body.archived;
          }
          if ("templates" in body) {
            usecaseBody.templates = body.templates;
          }
          if ("visibility" in body) {
            usecaseBody.visibility = body.visibility;
          }
          const usecaseRequest = fetchWithBaseQuery({
            url: `/usecases/${body.id}`,
            method: "PATCH",
            body: usecaseBody
          });
          const editRequests = editedSuccesscriteria?.length
            ? editedSuccesscriteria.map(successcriteria =>
                fetchWithBaseQuery({
                  url: `/successcriterias/${successcriteria.id}`,
                  method: "PATCH",
                  body: {
                    body: successcriteria.body,
                    archived: successcriteria.archived
                  }
                })
              )
            : [];
          const deleteRequests = deletedSuccesscriteria?.length
            ? deletedSuccesscriteria.map(successcriteria =>
                fetchWithBaseQuery({
                  url: `/successcriterias/${successcriteria.id}`,
                  method: "DELETE"
                })
              )
            : [];
          const addRequests = newSuccesscriteria?.length
            ? newSuccesscriteria.map(successcriteria =>
                fetchWithBaseQuery({
                  url: `/successcriterias`,
                  method: "POST",
                  body: {
                    body: successcriteria.body,
                    archived: successcriteria.archived,
                    usecaseId: body.id,
                    notes: ""
                  }
                })
              )
            : [];
          const responseArray = await Promise.all([
            usecaseRequest,
            ...editRequests,
            ...deleteRequests,
            ...addRequests
          ]);
          const [successes, failures] = responseArray.reduce(
            (aggregate, response) => {
              if (response.data) {
                aggregate[0].push(response.data);
              } else if (response.error) {
                aggregate[1].push(response.error);
              }
              return aggregate;
            },
            [[], []]
          );
          if (failures.lenth > 0) return { data: null, error: failures };
          return { data: successes, error: null };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const batchResult = dispatch(
            api.util.updateQueryData("getUseCases", undefined, draft => {
              const qIdx = draft.findIndex(usecase => usecase.id === body.id);
              if (body.usecase) {
                draft[qIdx].body = body.usecase;
              }
              if (body.notes) {
                draft[qIdx].notes = body.notes;
              }
              if (body.archived) {
                draft[qIdx].archived = body.archived;
              }
              if (body.templates) {
                draft[qIdx].templates = body.templates;
              }
              if (body.visibility) {
                draft[qIdx].visibility = body.visibility;
              }
              if (body.successcriterias?.length > 0) {
                draft[qIdx].successcriterias = sortBy(
                  body.successcriterias.filter(
                    successcriteria => !successcriteria.toDelete
                  ),
                  "body"
                );
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            batchResult.undo();
          }
        },
        invalidatesTags: (result, _, arg) => {
          let tags = [];
          if (result) {
            tags = [
              { type: "Usecases", id: result[0].id },
              { type: "Usecases", id: "LIST" },
              "PocUsecases"
            ];
          } else {
            tags = [{ type: "Usecases", id: "LIST" }, "PocUsecases"];
          }
          return tags;
        }
      }),
      deleteUseCase: build.mutation({
        query: ({ id }) => ({
          url: `/usecases/${id}`,
          method: "DELETE"
        }),
        // async onQueryStarted(id, { dispatch, queryFulfilled }) {
        //   const deleteResult = dispatch(
        //     api.util.updateQueryData("getUseCases", undefined, draft => {
        //       const usecaseIdx = draft.findIndex(usecase => usecase.id === id);

        //       if (usecaseIdx > -1) {
        //         draft.splice(usecaseIdx, 1);
        //       }
        //     })
        //   );
        //   try {
        //     await queryFulfilled;
        //   } catch {
        //     deleteResult.undo();
        //   }
        // },
        invalidatesTags: (result, error) => {
          if (error) return [];
          return [{ type: "Usecases", id: "LIST" }];
        }
        // invalidatesTags: (id, _, arg) => [
        //   { type: "Usecases", id },
        //   "PocUsecases",
        //   "PocScore"
        // ]
      }),
      reorderUseCases: build.mutation({
        query: ({ vendorId, ids }) => ({
          url: `vendors/${vendorId}/usecases/reorder`,
          method: "POST",
          body: { ids }
        }),
        invalidatesTags: ["Usecases"]
      })
    })
  });
