import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetVendorQuery } from "services/api";

const usePocs = () => {
  const { pocId, feedbackId } = useParams();
  const user = useSelector(state => state.auth.user, shallowEqual);
  const { data: vendor, error, isLoading } = useGetVendorQuery(user.vendorId);

  const vendorProviders = useMemo(
    () =>
      vendor?.vendorProviders.map(vp => {
        return {
          vendorProviderId: vp.id,
          vendorId: vp.vendorId,
          icon: vp.provider?.iconUrl,
          title: vp.provider?.name,
          ticker: vp.provider?.ticker,
          notes: vp.provider?.notes,
          providerId: vp.provider?.id,
          urlTo: `/pocs/${pocId}/feedback/${feedbackId}/${vp.provider?.ticker}`
        };
      }),
    [feedbackId, pocId, vendor?.vendorProviders]
  );

  const getVendorProvider = useCallback(
    ticker => vendorProviders.find(vp => vp.ticker === ticker),
    [vendorProviders]
  );

  return {
    vendorIsLoading: isLoading,
    error,
    vendor,
    vendorProviders,
    getVendorProvider
  };
};

export default usePocs;
