import { Dialog } from "@headlessui/react";
import Loading from "components/common/Loading";
import React, { useState } from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import { toast } from "react-toastify";

export default function CardContainer({
  children,
  className = "",
  addButtonText = false,
  isLoading = false,
  allLoading = false,
  importLoading = false,
  title = "",
  pocId,
  permissionImportTemplates = false,
  templates = false,
  templateList,
  importTemplate,
  setShowAddModal,
  page,
  data
}) {
  const recommendedKey =
    templates && templates.length
      ? templates?.find(t => t === "Recommended") || "Recommended"
      : [];

  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const startImportTemplate = () => {
    setIsOpen(false);
    importTemplate({ pocId, template: selectedTemplate });
    if (!isLoading || !importLoading) {
      toast.success(`Template "${selectedTemplate}" was imported`);
    }
  };

  const handleConfirm = templateName => {
    setSelectedTemplate(templateName);
    if (!templateName || templateName === "-") {
      toast.warning("Please select one of the available templates!");
    } else {
      if (data.length) {
        setIsOpen(true);
      } else {
        startImportTemplate();
      }
    }
  };

  const confirmationDialog = () => {
    return (
      <Dialog
        as="div"
        open={isOpen}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setIsOpen(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <div className="mx-auto has-text-danger-dark">
            Importing ({selectedTemplate}) will overwrite your existing {page}!
          </div>
          <div className="mt-5 is-flex is-justify-content-center">
            Click OK to continue!
          </div>
          <div className="buttons mt-5 is-justify-content-end">
            <button
              onClick={() => setIsOpen(false)}
              className="button is-light"
            >
              CANCEL
            </button>
            <button
              onClick={startImportTemplate}
              className="button is-info w-6r"
            >
              OK
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <section className={className}>
      <div className="card">
        <div className="card-content">
          <div className="is-flex is-justify-content-space-between">
            <div>
              <div>{title} </div>
              <div className="is-flex is-align-items-center">
                {addButtonText && (
                  <button
                    onClick={() => setShowAddModal(true)}
                    className="button is-info"
                  >
                    {addButtonText}
                  </button>
                )}
                {(allLoading || importLoading) && (
                  <ArrowRepeat className="is-spinning ml-5" />
                )}
              </div>
            </div>
            {permissionImportTemplates ? (
              <div className="select mr-4 is-small">
                <select
                  onChange={e => handleConfirm(e.target.value)}
                  className="form-control form-select max-h-10r"
                >
                  <option>Import a template</option>
                  <option
                    disabled={templateList && !templateList[recommendedKey]}
                    value="Recommended"
                  >
                    Recommended (
                    {(templateList && templateList[recommendedKey]) || "-"})
                  </option>
                  {templates.map((t, i) =>
                    t !== "Recommended" ? (
                      <option value={t} key={i}>
                        {t} {`(${templateList[t]})`}
                      </option>
                    ) : null
                  )}
                </select>
              </div>
            ) : null}
          </div>
          {isLoading ? (
            <div className="is-flex is-justify-content-center">
              {" "}
              <Loading />{" "}
            </div>
          ) : (
            <div className="card-content">{children}</div>
          )}
        </div>
      </div>
      {confirmationDialog()}
    </section>
  );
}
