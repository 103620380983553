import React, { useEffect, useState } from "react";
import {
  useDeletePocUseCasesAndSuccessCriteriaMutation,
  useEditMultiPocUseCasesMutation,
  useEditPocUseCasesMutation,
  useGetVendorQuery
} from "services/api";
import { PlusCircle, XCircle } from "react-bootstrap-icons";
import { groupByBusiness } from "utils/helpers";
import ReadMore from "utils/ReadMore";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";

import { fetchBusinessValues, handleEditBusiness } from "./PocUseCaseMatrix";
import useAuth from "hooks/useAuth";
import { useParams } from "react-router-dom";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";

export default function PocUseCaseTableMode({
  poc,
  pocUseCases,
  businessValues,
  setBusinessValue,
  reload,
  setReload,
  setShow,
  setShowType
}) {
  const { pocId } = useParams();
  const [del, { isLoading: batchDeleteIsLoading }] =
    useDeletePocUseCasesAndSuccessCriteriaMutation({
      fixedCacheKey: `batch-delete-pocUseCases-${pocId}`
    });

  const [addBusiness, setAddBusiness] = useState(false);
  const [editable, setEditable] = useState(false);
  const [multiEdit] = useEditMultiPocUseCasesMutation();
  const [edit] = useEditPocUseCasesMutation();
  const [expandNotes, setExpandNotes] = useState([]);
  const { currentUser } = useAuth();
  const { data: vendorData, isLoading: vendorIsLoading } = useGetVendorQuery(
    currentUser.vendorId
  );
  const unAssignedUsecases = pocUseCases.filter(
    ({ businessValues }) => !businessValues?.length > 0
  );

  const grouped = groupByBusiness(pocUseCases, businessValues) || {};
  const handleAddBusiness = e => {
    if (e.key === "Enter") {
      const newData = [...businessValues];
      newData.push(e.target.value);
      setBusinessValue(newData);
      e.target.value = "";
    }
  };

  const removeBVFromUc = (id, businessValues, bv) => {
    const index = businessValues.indexOf(bv);
    let newBv = [...businessValues];
    newBv.splice(index, 1);
    edit({ pocId: poc.id, id, businessValues: newBv });
  };

  const showAssignedBusiness = (bvAssigned, bv) => {
    setShow(true);
    const bvAssignedIds = bvAssigned.map(v => v.usecase.id);
    setShowType({ type: "table", value: bvAssignedIds, payload: bv });
  };
  const showAdd = () => {
    setShow(true);
    setShowType("add");
  };

  const handleExpand = id => {
    let newExpandNotes = [...expandNotes];
    if (newExpandNotes.includes(id)) {
      const index = newExpandNotes.indexOf(id);
      newExpandNotes.splice(index, 1);
    } else {
      newExpandNotes.push(id);
    }
    setExpandNotes(newExpandNotes);
  };

  useEffect(() => {
    fetchBusinessValues(pocUseCases, setBusinessValue, businessValues);
  }, []);

  useEffect(() => {
    if (reload) {
      setBusinessValue([]);
      setReload(false);
    }
  }, [reload, setReload]);

  return (
    <section className="table-container ">
      <table className="table is-bordered">
        <thead className="text-center">
          <tr className="whitespace-nowrap border-none">
            <td className="border-0"></td>
            <td colSpan="3">
              <strong>{vendorData?.name}</strong>
            </td>
          </tr>
          <tr className="whitespace-nowrap border-0">
            <td>
              <strong>{poc.companyName} Required Capabilities</strong>
            </td>
            <td>Success Criteria</td>
            <td className="w-50 min-w-40r">Description</td>
          </tr>
        </thead>
        <tbody>
          {businessValues.map((t, i) => (
            <tr key={i} className="whitespace-nowrap border-0">
              {editable === i ? (
                <td>
                  <input
                    key={i}
                    autoFocus
                    className="w-100 input"
                    defaultValue={t}
                    onKeyUp={e =>
                      handleEditBusiness(
                        e,
                        i,
                        businessValues,
                        pocUseCases,
                        multiEdit,
                        setBusinessValue,
                        setEditable,
                        poc
                      )
                    }
                    onBlur={() => setEditable(false)}
                  />
                </td>
              ) : (
                <td onClick={() => setEditable(i)} className="p-3">
                  {t}
                </td>
              )}
              <td>
                <div className="tags">
                  {grouped[t]?.map((g, i2) => (
                    <div key={i2} className="tag">
                      <EntityVisibilityIcon entity={g.usecase} />
                      <ReadMore charLength={40}>{g.usecase.body}</ReadMore>
                      <XCircle
                        onClick={() =>
                          removeBVFromUc(g.id, g.businessValues, t)
                        }
                        className="ml-1"
                      />
                    </div>
                  ))}
                </div>
                <div className="button is-small is-info is-light">
                  <div onClick={() => showAssignedBusiness(grouped[t], t)}>
                    <PlusCircle className="mr-1" />
                    Add Success Criteria
                  </div>
                </div>
              </td>
              <td>
                <RenderDiff
                  usecases={grouped[t]}
                  uniq={t}
                  expandNotes={expandNotes}
                  handleExpand={handleExpand}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td>
              {addBusiness ? (
                <input
                  autoFocus
                  className="input is-small is-size-6"
                  onKeyDown={handleAddBusiness}
                  onBlur={() => setAddBusiness(false)}
                />
              ) : (
                <div
                  onClick={() => setAddBusiness(true)}
                  className="button is-light is-info is-small"
                >
                  <PlusCircle className="mr-1" /> Add Capability
                </div>
              )}
            </td>
            <td>
              <div className="row row-cols-2">
                {unAssignedUsecases?.map(
                  ({ usecase, id, pocUsecaseSuccesscriterias }, i4) => {
                    return (
                      <div key={i4} className="tag">
                        <EntityVisibilityIcon entity={usecase} />
                        <ReadMore charLength={40}>{usecase.body}</ReadMore>
                        <XCircle
                          onClick={() => {
                            del({
                              pocId,
                              pocUsecaseIds: [id],
                              pocSuccesscriteriaIds:
                                pocUsecaseSuccesscriterias.map(({ id }) => id)
                            });
                          }}
                          className="ml-1"
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </td>
            <td>
              {unAssignedUsecases.length > 0 && (
                <RenderDiff
                  usecases={unAssignedUsecases}
                  uniq={"init"}
                  expandNotes={expandNotes}
                  handleExpand={handleExpand}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

const RenderDiff = ({ usecases, uniq, expandNotes, handleExpand }) => {
  return (
    <>
      <ul>
        {usecases.map(({ usecase }, i6) =>
          !expandNotes.includes(uniq) ? (
            <div
              key={i6}
              className={`whitespace-nowrap is-flex ${i6 !== 0 ? "mt-1" : ""}`}
            >
              -{" "}
              {usecase.notes?.length > 70
                ? `${usecase.notes?.slice(0, 70)}...`
                : usecase.notes}
            </div>
          ) : (
            <div
              className={`text-start lh-sm ${i6 !== 0 ? "mt-2" : ""}`}
              style={{ whiteSpace: "initial" }}
            >
              - {usecase.notes}
            </div>
          )
        )}
      </ul>
      <div className="bottom-0">
        {!expandNotes.includes(uniq) ? (
          <ChevronDown onClick={() => handleExpand(uniq)} />
        ) : (
          <ChevronUp
            className="has-text-info"
            onClick={() => handleExpand(uniq)}
          />
        )}
      </div>
    </>
  );
};
