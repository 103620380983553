import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Search } from "react-bootstrap-icons";
import useAuth from "hooks/useAuth";

export default function FilterCard({ filter, setFilter }) {
  const { isProspect } = useAuth();

  const [open, setOpen] = useState(false);

  const onClickFilter = (value, type) => {
    const key = Object.keys(value)[0];
    const val = value[key];
    const newFilter = { ...filter };
    if (type === "single") {
      if (newFilter[key] === val) {
        delete newFilter[key];
      } else {
        newFilter[key] = val;
      }
    } else {
      if (newFilter[key]?.length) {
        if (newFilter[key].includes(val)) {
          const index = newFilter[key].indexOf(val);
          newFilter[key].splice(index, 1);
        } else {
          newFilter[key].push(val);
        }
      } else {
        newFilter[key] = [val];
      }
    }
    setFilter(newFilter);
  };

  return (
    <>
      {!isProspect && (
        <div className="my-4">
          <div className="control has-icons-left">
            <input
              onChange={e =>
                setFilter({ type: "search", searchValue: e.target.value })
              }
              placeholder="Filter Success Criteria, Test Plan, Assignee, Date, Importance, or Outcome"
              className="input"
            />
            <span className="icon is-left">
              <Search />
            </span>
          </div>
        </div>
      )}
      <Dialog
        as="div"
        open={open}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setOpen(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="p-6 my-8 overflow-hidden has-background-white z-30 rounded-md w-40r">
          <div className="columns">
            <div className="column is-flex is-align-items-center is-one-quarter has-text-weight-semibold">
              Usage
            </div>
            <div className="buttons column is-flex is-align-items-center gap-3">
              <div
                onClick={() => onClickFilter({ usage: 25 }, "single")}
                className={`w-25r h-75 ${
                  filter?.usage === 25
                    ? "button is-info is-outlined"
                    : "button is-light"
                }  is-flex is-align-items-center is-justify-content-center`}
              >
                {"> 25%"}
              </div>
              <div
                onClick={() => onClickFilter({ usage: 50 }, "single")}
                className={`w-25r h-75 ${
                  filter?.usage === 50
                    ? "button is-info is-outlined"
                    : "button is-light"
                } is-flex is-align-items-center is-justify-content-center`}
              >
                {"> 50%"}
              </div>
              <div
                onClick={() => onClickFilter({ usage: 75 }, "single")}
                className={`w-25r h-75 ${
                  filter?.usage === 75
                    ? "button is-info is-outlined"
                    : "button is-light"
                } is-flex is-align-items-center is-justify-content-center`}
              >
                {"> 75%"}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
