import { useState, useCallback, useMemo } from "react";
import {
  useGetFeedbacksQuery,
  useEditFeedbackMutation,
  useDeleteFeedbackMutation
} from "services/api";
import useAuth from "hooks/useAuth";
import APIError from "components/common/APIError";
import Table from "components/common/Table";
import PocTopBar from "components/TopBar/TopBarPoc";
import CardContainer from "components/Container/CardContainer";
import FeedbackConversation from "./FeedbackConversation";
import NewFeedbackRow from "./NewFeedbackRow";
import "./PocFeedback.css";
import { internalColumns, prospectColumns } from "./constants";
import PocFeedbackFilter from "./PocFeedBackFilter";
import dayjs from "dayjs";
import LayoutContainer from "../../components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import useFeatureFilter from "hooks/useFeatureFilter";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";

const PocFeedback = ({ poc, pocId }) => {
  const [filter, setFilter] = useState({});
  const {
    data: feedbacks,
    error,
    isLoading,
    isFetching
  } = useGetFeedbacksQuery(pocId);
  const [edit, { error: editedError }] = useEditFeedbackMutation();
  const [del, { error: deleteError }] = useDeleteFeedbackMutation();
  const deleteFeedbackById = useCallback(
    id => del({ id, pocId }),
    [del, pocId]
  );
  const [conversationIdx, setConversationIdx] = useState(null);
  const { isProspect, getCurrentStakeholder } = useAuth();
  const currentStakeholder = useMemo(
    () => getCurrentStakeholder(poc),
    [poc, getCurrentStakeholder]
  );

  const { showSearchFilters } = useFeatureFilter();

  const columns = useMemo(
    () => (isProspect ? prospectColumns : internalColumns),
    [isProspect]
  );

  const close = useCallback(() => setConversationIdx(null), []);
  const handleClick = (evt, idx) => {
    evt.stopPropagation();
    setConversationIdx(idx);
  };

  const renderBodyHeaderRow = useCallback(
    () => <NewFeedbackRow currentUserStakeholder={currentStakeholder} />,
    [currentStakeholder]
  );
  const extraCellInfo = useMemo(
    () => ({
      edit,
      handleClick,
      del: deleteFeedbackById,
      stakeholders: poc?.stakeholders || []
    }),
    [edit, poc, deleteFeedbackById]
  );
  const feedbackData = useMemo(() => feedbacks || [], [feedbacks]);

  let filteredFeedbackData = [...feedbackData];

  if (filter?.search) {
    const value = filter.search.toLowerCase();
    filteredFeedbackData = filteredFeedbackData.filter(
      x =>
        x.body.toLowerCase().includes(value) ||
        x.type?.toLowerCase()?.includes(value) ||
        x.status?.toLowerCase()?.includes(value) ||
        x.priority?.toLowerCase()?.includes(value) ||
        dayjs(x.dueDate)?.format("MM/DD/YYYY")?.includes(value) ||
        x.stakeholder?.fullName?.toLowerCase()?.includes(value) ||
        x.tags?.findIndex(y => y.toLowerCase().includes(value)) > -1
    );
  }

  if (Object.keys(filter).length) {
    Object.keys(filter).map(key => {
      if (key !== "search" && filter[key].length) {
        filteredFeedbackData = filteredFeedbackData.filter(fd =>
          filter[key].includes(fd[key])
        );
      }
    });
  }
  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar poc={poc} showScore={true} title={poc?.pocName} />
      </LayoutContainer>
      <LayoutContainer>
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>
        <CardContainer
          className="poc-feedback mb-4"
          isLoading={isLoading}
          allLoading={isFetching}
        >
          <APIError error={error || editedError || deleteError} />
          {showSearchFilters && (
            <PocFeedbackFilter filter={filter} setFilter={setFilter} />
          )}
          <Table
            className={`PocFeedback-table ${isProspect ? "prospect" : ""}`}
            columns={columns}
            data={filteredFeedbackData}
            renderBodyHeaderRow={renderBodyHeaderRow}
            extraCellInfo={extraCellInfo}
          />
          {conversationIdx !== null && (
            <FeedbackConversation
              close={close}
              feedbackId={feedbacks[conversationIdx].id}
              currentUserStakeholder={currentStakeholder}
            />
          )}
        </CardContainer>
      </LayoutContainer>
    </>
  );
};

export default PocFeedback;
