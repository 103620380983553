import styles from "./NoData.module.css"; // Import css modules stylesheet as styles

export const NoData = ({ message }) => {
  return (
    <div className="has-text-grey is-flex is-justify-content-center">
      <div>{message ? message : "No data"}</div>
    </div>
  );
};

export const NoDataBlock = ({ message }) => {
  return (
    <div className={`has-text-centered p-6 ${styles.noDataBlock}`}>
      <p className="title is-size-6">{message ? message : "No data"}</p>
    </div>
  );
};

export default NoData;
