import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import useAuth from "hooks/useAuth";
import useModalClose from "hooks/useModalClose";
import {
  useAddCommentsMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useGetCommentsQuery
} from "services/api";
import image from "assets/img/defaultpic.jpg";
import APIError from "components/common/APIError";
import Loading from "components/common/Loading";
import FeedbackMessageForm from "./FeedbackMessageForm";
import "./FeedbackConversation.css";
import ActionsModal from "components/common/ActionsModal";

dayjs.extend(relativeTime);

const FeedbackConversation = ({
  close,
  feedbackId,
  currentUserStakeholder
}) => {
  const [actionsModalOpen, setActionsModalOpen] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const toggleModal = idx =>
    setActionsModalOpen(oldState => {
      if (oldState === idx) return null;
      return idx;
    });
  const toggleEdit = idx => {
    setEditIndex(oldState => {
      if (oldState === idx) return null;
      return idx;
    });
  };
  const { currentUser, isProspect } = useAuth();
  const { pocId } = useParams();
  const { data: messages, error, isLoading } = useGetCommentsQuery(feedbackId);
  const [add, { data: newMessage, error: newMessageError }] =
    useAddCommentsMutation();
  const [del, { data: deleteCommentConfirm, error: deleteError }] =
    useDeleteCommentMutation();
  const [edit, { data: editData, error: editError }] = useEditCommentMutation();

  const addComment = useCallback(
    ({ message }) => {
      add({
        body: message,
        feedbackId,
        stakeholderId: currentUserStakeholder.id,
        pocId
      });
    },
    [add, currentUserStakeholder, feedbackId, pocId]
  );

  const deleteComment = useCallback(
    id => {
      del({
        id,
        pocId,
        feedbackId
      });
    },
    [pocId, feedbackId, del]
  );

  const closeActions = useCallback(() => setActionsModalOpen(null), []);

  useEffect(() => {
    if (newMessage || deleteCommentConfirm || editData) {
      setActionsModalOpen(null);
      setEditIndex(null);
    }
  }, [newMessage, feedbackId, deleteCommentConfirm, editData]);

  useEffect(() => {
    setActionsModalOpen(null);
  }, [editIndex]);

  const modalRef = useModalClose(close);

  if (isLoading || !messages)
    return (
      <div ref={modalRef} className="FeedbackConversation drop-shadow mt-5">
        <Loading />
      </div>
    );
  if (error)
    return (
      <div ref={modalRef} className="FeedbackConversation drop-shadow">
        <APIError message={error} />
      </div>
    );

  return (
    <div ref={modalRef} className="FeedbackConversation drop-shadow">
      {messages.map((message, messageIdx) => {
        const isOwner = message.stakeholder.userId === `${currentUser.id}`;
        return (
          <div
            key={message.createdAt}
            className={`p-4 position-relative ${
              isOwner ? "current-user-message" : ""
            }`}
          >
            <div className="is-flex user-info">
              <img
                src={message.stakeholder.img || image}
                alt={`${message.stakeholder.fullName} profile`}
                className="mr-2 is-rounded"
              />
              <div className="is-flex is-flex-direction-column">
                <p className="username">
                  {message.stakeholder.firstName} {message.stakeholder.lastName}
                </p>
                <small>{dayjs(message.createdAt).fromNow()}</small>
              </div>
              {(isOwner || !isProspect) && (
                <ThreeDotsVertical
                  className="top-right action-icon"
                  onClick={() => toggleModal(messageIdx)}
                />
              )}
              {actionsModalOpen === messageIdx && (
                <ActionsModal
                  close={closeActions}
                  del={deleteComment}
                  toggleEdit={toggleEdit}
                  index={messageIdx}
                  id={message.id}
                />
              )}
            </div>
            {editIndex === messageIdx ? (
              <FeedbackMessageForm
                handleSubmit={data =>
                  edit({
                    id: message.id,
                    message: data.message,
                    feedbackId
                  })
                }
                message={message.body}
              />
            ) : (
              <p className="mt-2">{message.body}</p>
            )}
          </div>
        );
      })}
      {editIndex === null && <FeedbackMessageForm handleSubmit={addComment} />}
      <APIError message={newMessageError} />
      <APIError message={deleteError} />
      <APIError message={editError} />
    </div>
  );
};

export default FeedbackConversation;
