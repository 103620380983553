import React from "react";
import * as _ from "lodash";

const RbacMappingTable = ({ userRoles = {}, rolePermissionMapping = {} }) => {
  return (
    <>
      <h2 className="is-size-4">Rbac Permissions</h2>
      <table className="table is-fullwidth">
        <tr>
          <th>Permission</th>
          {_.map(userRoles, item => (
            <th>Role: {item}</th>
          ))}
        </tr>

        {_.map(rolePermissionMapping, (categoryRules, categoryName) =>
          _.map(categoryRules, (rule, actionName) => (
            <tr>
              <td>
                {categoryName}_{actionName}
              </td>
              {_.map(userRoles, roleName => (
                <td>{rule.roles.indexOf(roleName) !== -1 ? "X" : "-"}</td>
              ))}
            </tr>
          ))
        )}
      </table>
    </>
  );
};

export default RbacMappingTable;
