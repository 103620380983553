import { v4 as uuid4 } from "uuid";
import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Specdocsections"] }).injectEndpoints({
    endpoints: build => ({
      getSpecdocsections: build.query({
        query: () => ({
          url: "/specdocsections"
        }),
        providesTags: (result, _, fbId) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Specdocsections", id })),
                { type: "Specdocsections", id: `${fbId}-LIST` }
              ]
            : [{ type: "Specdocsections", id: `${fbId}-LIST` }]
        // transformResponse: response =>
        //   response.sort((c1, c2) => {
        //     if (c1.createdAt < c2.createdAt) return -1;
        //     if (c1.createdAt > c2.createdAt) return 1;
        //     return 0;
        //   })
      }),
      addSpecdocsections: build.mutation({
        query: ({ ...body }) => ({
          url: "/specdocsections",
          method: "POST",
          body
        }),
        // async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
        //   const tempId = uuid4();
        //   const { user } = getState().auth;
        //   const postResult = dispatch(
        //     api.util.updateQueryData(
        //       "getSpecdocsections",
        //       body.feedbackId,
        //       draft => {
        //         draft.push({
        //           ...body,
        //           createdAt: new Date().toISOString(),
        //           id: tempId,
        //           // hack since stakeholder records are slightly different than
        //           // user records
        //           stakeholder: { ...user, userId: user.id }
        //         });
        //       }
        //     )
        //   );
        //   const feedbackResult = dispatch(
        //     api.util.updateQueryData("getFeedbacks", body.pocId, draft => {
        //       const feedback = draft.find(
        //         feedback => feedback.id === body.feedbackId
        //       );
        //       feedback.commentCount++;
        //     })
        //   );
        //   try {
        //     await queryFulfilled;
        //   } catch {
        //     postResult.undo();
        //     feedbackResult.undo();
        //   }
        // },
        invalidatesTags: () => [{ type: "Specdocsections" }]

        // invalidatesTags: (result, error) => {
        //   if (error) return [];
        //   return [{ type: "Specdocsections", id: `${result.id}-LIST` }];
        // }
      }),
      editSpecdocsection: build.mutation({
        query: ({ id, ...body }) => {
          return {
            url: `/specdocsections/${id}`,
            method: "PATCH",
            body: body
          };
        },
        // async onQueryStarted(body, { dispatch, queryFulfilled }) {
        //   const patchResult = dispatch(
        //     api.util.updateQueryData(
        //       "getSpecdocsections",
        //       body.feedbackId,
        //       draft => {
        //         const comment = draft.find(comment => comment.id === body.id);
        //         if (comment) {
        //           comment.body = body.message;
        //         }
        //       }
        //     )
        //   );
        //   try {
        //     await queryFulfilled;
        //   } catch {
        //     patchResult.undo();
        //   }
        // },
        invalidatesTags: ({ id }) => [{ type: "Specdocsections", id }]
      }),
      deleteSpecdocsection: build.mutation({
        query: ({ id }) => ({
          url: `/specdocsections/${id}`,
          method: "DELETE"
        }),
        // async onQueryStarted(
        //   { id, pocId, feedbackId },
        //   { dispatch, queryFulfilled }
        // ) {
        //   const deleteResult = dispatch(
        //     api.util.updateQueryData(
        //       "getSpecdocsections",
        //       feedbackId,
        //       draft => {
        //         const commentIdx = draft.findIndex(
        //           comment => comment.id === id
        //         );
        //         if (commentIdx > -1) {
        //           draft.splice(commentIdx, 1);
        //         }
        //       }
        //     )
        //   );
        //   const feedbackResult = dispatch(
        //     api.util.updateQueryData("getFeedbacks", pocId, draft => {
        //       const feedback = draft.find(
        //         feedback => feedback.id === feedbackId
        //       );
        //       feedback.commentCount--;
        //     })
        //   );
        //   try {
        //     await queryFulfilled;
        //   } catch {
        //     feedbackResult.undo();
        //     deleteResult.undo();
        //   }
        // },
        invalidatesTags: ({ id }) => [{ type: "Specdocsections", id }]
      }),
      reorderSpecdocsections: build.mutation({
        query: ({ vendorId, ids }) => ({
          url: `vendors/${vendorId}/specdocsections/reorder`,
          method: "POST",
          body: { ids }
        }),
        invalidatesTags: (_result, _error, arg) => {
          return ["Specdocsections"];
        }
      })
    })
  });
