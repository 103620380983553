import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";

import useAuth from "hooks/useAuth";
import { useGetChecklistTemplateListQuery } from "services/api";
import usePocs from "hooks/usePocs";

export default function ChecklistImport({ response, importTemplate }) {
  const { currentPoc: poc } = usePocs();
  const { currentUser, isProspect } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const { data: templates } = useGetChecklistTemplateListQuery(
    currentUser.vendorId || ""
  );

  const handleImport = templateName => {
    setSelectedTemplate(templateName);
    if (!templateName) {
      toast.warning("Please select one of the available templates!");
    } else {
      setIsOpen(true);
    }
  };

  const handleConfirm = () => {
    importTemplate({
      pocId: poc.id,
      template: selectedTemplate
    });
    setIsOpen(false);
  };

  useEffect(() => {
    if (response.status === "rejected") {
      toast.error(response.error.data.message.join(", "));
      setSelectedTemplate("");
    }
    if (response.status === "fulfilled") {
      toast.success(`Template "${selectedTemplate}" was imported`);
      setSelectedTemplate("");
    }
  }, [response]);

  return (
    <>
      {!isProspect && (
        <span className="is-flex gap-3">
          <div className="select mr-4 is-small">
            <select
              onChange={e => handleImport(e.target.value)}
              value={selectedTemplate}
              className="form-control h-10r form-select"
            >
              <option value="">Import a template</option>
              {!!templates &&
                Object.keys(templates)?.map((key, i) => {
                  return (
                    <option value={key} key={i}>
                      {key} {`(${templates[key]})`}
                    </option>
                  );
                })}
            </select>
          </div>
        </span>
      )}
      <Dialog
        as="div"
        open={isOpen}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setIsOpen(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <div className="mx-auto has-text-danger-dark">
            Importing ({selectedTemplate}) will overwrite your existing
            Checklists!
          </div>
          <div className="mt-5 is-flex is-justify-content-center">
            Click OK to continue!
          </div>
          <div className="buttons mt-5 is-justify-content-end">
            <button
              onClick={() => setIsOpen(false)}
              className="button is-light"
            >
              CANCEL
            </button>
            <button onClick={handleConfirm} className="button is-info w-6r">
              OK
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
