import { useMemo, useCallback, useState } from "react";
import {
  useLinkFeedbackToExternalItemMutation,
  useUnlinkFeedbackToExternalItemMutation,
  useGetExternalSearchFeedbacksQuery
} from "services/api";
import Table from "components/common/Table";
import Loading from "components/common/Loading";
import { currency } from "utils/helpers";
import "./ModalLinkExternal.css";
import AddToJiraModal from "../ActionsModal/AddToJiraModal";
import APIError from "../APIError";

const ToggleLinkButton = ({ cell, providerId, feedback }) => {
  const [jiraModalOpen, setJiraModalOpen] = useState(false);
  const [link, { isLoading: isLinkLoading }] =
    useLinkFeedbackToExternalItemMutation();
  const [unlink, { isLoading: isUnlinkLoading }] =
    useUnlinkFeedbackToExternalItemMutation();

  const text = `Type a Jira comment here`;

  if (isLinkLoading || isUnlinkLoading) return <Loading />;

  const linkButton = (
    <button
      className="button is-small is-info"
      onClick={() => setJiraModalOpen(true)}
    >
      Link
    </button>
  );

  const unlinkButton = (
    <button
      className="button is-small is-danger"
      onClick={() => unlink({ id: feedback.id })}
    >
      Unlink
    </button>
  );

  return (
    <>
      {jiraModalOpen ? (
        <AddToJiraModal
          placeholder={text}
          primaryButtonLabel={"Link to Jira"}
          feedback={feedback}
          itemId={cell.row.original.id}
          onClose={() => setJiraModalOpen(false)}
          onLink={link}
        />
      ) : (
        ""
      )}
      {feedback.linkedItemId
        ? cell.row.original.id == feedback.linkedItemId
          ? unlinkButton
          : ""
        : linkButton}
    </>
  );
};

const linkToExternalColumns = [
  { Header: "Id", accessor: item => item?.id?.slice(0, 8) },
  {
    Header: "Summary",
    accessor: "title",
    Cell: ({ row, value }) => (
      <a href={row.original.externalUrl} target="_blank" rel="noreferrer">
        {value}
      </a>
    )
  },
  { Header: "# Opps", accessor: "nbPocs" },
  {
    Header: "ACV",
    accessor: item => (item?.totalAcv ? currency(item?.totalAcv) : "-")
  },
  { Header: "Status", accessor: item => item?.status || "-" },
  { id: "link", Cell: ToggleLinkButton }
];

const nestedTableColumns = [
  { Header: "Opportunity", accessor: "pocName" },
  { Header: "Feedback", accessor: "feedbackBody" },
  {
    Header: "ACV",
    accessor: ({ pocTotalAcv }) => (pocTotalAcv ? currency(pocTotalAcv) : "-")
  },
  {
    Header: "Due Date",
    accessor: ({ feedbackDueDate }) => feedbackDueDate || "-"
  },
  {
    Header: "Sales Engineer",
    accessor: ({ salesEngineerFirstName, salesEngineerLastName }) =>
      salesEngineerFirstName
        ? `${salesEngineerFirstName} ${salesEngineerLastName}`
        : "-"
  }
];

const NestedTable = ({ row, vendorProviderId }) => {
  const linkedItemId = row?.original?.id;

  const { data, isLoading } = useGetExternalSearchFeedbacksQuery({
    vendorProviderId,
    linkedItemId
  });

  if (isLoading)
    return (
      <div className="p-4">
        <Loading />
      </div>
    );

  if (!data.length) return <p className="p-4">No linked feedbacks.</p>;
  return (
    <Table
      data={data}
      columns={nestedTableColumns}
      className="m-0 Nested-Feedback-Table"
    />
  );
};

const ModalLinkExternal = ({
  data = [],
  errorData,
  feedback,
  providerId,
  vendorProviderId,
  headerHidden = false,
  dataFilter,
  isLoading
}) => {
  const tableData = useMemo(
    () =>
      data.filter(d =>
        typeof dataFilter === "function" ? dataFilter(d) : true
      ),
    [data, dataFilter]
  );

  const renderFeedbacks = useCallback(
    ({ row, vendorProviderId }) => (
      <NestedTable row={row} vendorProviderId={vendorProviderId} />
    ),
    []
  );

  return (
    <>
      <APIError error={errorData} />
      {isLoading ? (
        <Loading />
      ) : (
        <Table
          columns={linkToExternalColumns}
          data={tableData}
          className="ModalLinkExternal"
          extraCellInfo={{ vendorProviderId, feedback, providerId }}
          isExpandable
          renderExpanded={renderFeedbacks}
          headerHidden={headerHidden}
        />
      )}
    </>
  );
};

export default ModalLinkExternal;
