import { sortBy } from "utils/helpers";
import api from "./_createApi";

export default !!api &&
  api
    .enhanceEndpoints({ addTagTypes: ["PocUsecasesSuccesscriteria"] })
    .injectEndpoints({
      endpoints: build => ({
        getPocUseCaseSuccessCriteria: build.query({
          query: () => `/pocUsecaseSuccesscriterias`,
          transformResponse: response =>
            sortBy(response, usecase => usecase.successcriteria.body),
          providesTags: ["PocUsecasesSuccesscriteria"]
        }),
        editPocUseCaseSuccessCriteria: build.mutation({
          query: ({ id, pocId, ...body }) => {
            const unpermitedKeys = [
              "createdAt",
              "updatedAt",
              "deletedAt",
              "version",
              "successcriteria",
              "assignedToStakeholder"
            ];
            for (const key of Object.keys(body)) {
              if (unpermitedKeys.includes(key)) {
                delete body[key];
              }
            }
            return {
              url: `/pocUsecaseSuccesscriterias/${id}`,
              method: "PATCH",
              body
            };
          },
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            let result = null;
            if (body.assignedToStakeholder?.pocId) {
              result = dispatch(
                api.util.updateQueryData(
                  "getPocUseCases",
                  body.assignedToStakeholder.pocId,
                  draft => {
                    const pocUsecaseIdx = draft.findIndex(
                      element => element.id === body.pocUsecaseId
                    );
                    const successIdx = draft[
                      pocUsecaseIdx
                    ].pocUsecaseSuccesscriterias.findIndex(
                      element => element.id === body.id
                    );
                    const pocUseCaseData =
                      draft[pocUsecaseIdx].pocUsecaseSuccesscriterias[
                        successIdx
                      ];
                    for (const key of Object.keys(body)) {
                      pocUseCaseData[key] = body[key];
                    }
                    return draft;
                  }
                )
              );
            } else {
              result = dispatch(
                api.util.updateQueryData(
                  "getPocUseCaseSuccessCriteria",
                  null,
                  draft => {
                    const index = draft.findIndex(
                      element => element.id === body.id
                    );
                    draft[index] = Object.assign(draft[index], {
                      ...body
                    });
                    return draft;
                  }
                )
              );
            }
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          },
          invalidatesTags: (result, _, body) => {
            return ["PocUsecases", "PocUsecasesSuccesscriteria"];
          }
        }),
        deletePocSuccesscriterias: build.mutation({
          query: ({ id }) => ({
            url: `/pocUsecaseSuccesscriterias/${id}`,
            method: "DELETE"
          }),

          async onQueryStarted({ pocId, id }, { dispatch, queryFulfilled }) {
            const result = dispatch(
              api.util.updateQueryData("getPocUseCases", pocId, draft => {
                const pocUsecaseIdx = draft.findIndex(element =>
                  element.pocUsecaseSuccesscriterias.map(x => x.id).includes(id)
                );
                const criteriaIndex = draft[
                  pocUsecaseIdx
                ].pocUsecaseSuccesscriterias.findIndex(x => x.id === id);

                draft[pocUsecaseIdx].pocUsecaseSuccesscriterias.splice(
                  criteriaIndex,
                  1
                );
                return draft;
              })
            );
            try {
              await queryFulfilled;
            } catch {
              result.undo();
            }
          },
          invalidatesTags: (_result, _error_, body) => [
            "PocUsecases",
            "PocUsecasesSuccesscriteria",
            { type: "PocUsecases", id: `${body.pocId}-LIST` }
          ]
        })
      })
    });
