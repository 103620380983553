import { configureStore, combineReducers } from "@reduxjs/toolkit";
import api from "services/api/_createApi";
import authSliceReducer, { logout } from "services/authSlice";
import pocSlice from "services/pocSlice";

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authSliceReducer,
  poc: pocSlice
});

const rootReducer = (state, action) => {
  if (logout.match(action)) {
    state = undefined;
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: gDM => gDM().concat(api.middleware)
});
