import React, { useMemo, useCallback, useState } from "react";

import {
  useGetQuestionsQuery,
  useAddQuestionsAndAnswersMutation,
  useDeleteQuestionMutation,
  useEditQuestionsAndAnswersMutation,
  useGetMetaQuery,
  useReorderQuestionsMutation,
  useReorderAnswersMutation
} from "services/api";
import Loading from "components/common/Loading";
import APIError from "components/common/APIError";
import ConfirmChange from "components/common/Settings/ConfirmChange";
import "./ManageQuestions.css";
import { DashSquare, PlusSquare, Search } from "react-bootstrap-icons";
import NiceTabs from "components/common/NiceTabs";
import TableHeader from "../../components/common/Questions/TableHeader";
import {
  NewManageAnswerColumns,
  NewManageQuestionColumns
} from "../PocQuestions/constants";
import AddNewQuestion from "../../components/common/AddQuestionWizard/AddNewQuestion";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TableBody from "../../components/common/Questions/TableBody";
import useAuth from "../../hooks/useAuth";
import CreateNewQuestionModal from "../PocQuestions/CreateNewQuestionModal";
import { reorderListAfterDragEnd } from "utils/helpers";
import useSearch from "hooks/useSearch";
import useOption from "hooks/useOption";
import SelectFilter from "components/common/SelectFilter";
import SearchBox from "components/common/SearchBox";

const separateQuestions = questions => {
  if (questions) {
    return questions.reduce(
      (aggregate, question) => {
        if (question.archived) {
          aggregate[0].push({ ...question });
        } else {
          aggregate[1].push({ ...question });
        }
        return aggregate;
      },
      [[], []]
    );
  }
  return [[], []];
};

const ManageQuestions = ({ type }) => {
  const { currentUser } = useAuth();
  const { data: metadata } = useGetMetaQuery();

  const { scopes, tags } = useOption("question");
  const [isCreate, setIsCreate] = useState(false);
  const { data, error, isLoading } = useGetQuestionsQuery();
  const { error: addError } = useAddQuestionsAndAnswersMutation({
    fixedCacheKey: "batch-add-questions"
  })[1];
  const [reorderQuestions, { isLoading: isorderLoading }] =
    useReorderQuestionsMutation();
  const [reorderAnswers, { isLoading: isorderAnswerLoading }] =
    useReorderAnswersMutation();
  const [edit, { error: editError }] = useEditQuestionsAndAnswersMutation();
  const [del, { error: deleteError }] = useDeleteQuestionMutation();

  const [filter, setfilter] = useState({ tag: "", scope: "" });

  const [confirmData, setConfirmData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [showAllAnswer, setShowAllAnswer] = useState(false);
  const [sortBy, setSortBy] = useState({
    key: "",
    order: ""
  });
  const [archivedQuestions, setArchivedQuestions] = useState([]);
  const [activeQuestions, setActiveQuestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const filterOptions = useMemo(
    () => [
      {
        label: "Tag",
        defaultValue: "Show All",
        options: tags
      },
      {
        label: "Scope",

        defaultValue: "All Requirements",
        options: scopes
      }
    ],
    [tags, scopes]
  );

  useMemo(() => {
    let questions = separateQuestions(data);
    setArchivedQuestions(questions[0]);
    setActiveQuestions(questions[1]);
  }, [data]);

  const closeConfirm = useCallback(() => setConfirmData(null), []);
  const cancelEdit = useCallback(() => {
    setEditData(null);
    closeConfirm();
  }, [closeConfirm]);
  const editTemplate = useCallback(
    (id, templates) => {
      edit({ id, templates });
    },
    [edit]
  );
  const handleDelete = useCallback(
    id => {
      const question = data.find(question => question.id === id);
      if (question?.nbPocs === 0) {
        del(id);
        cancelEdit();
      } else if (question) {
        setConfirmData({
          message:
            "Other Opportunities are referencing this question, are you sure you want to delete it?",
          onAccept: () => {
            del(id);
            cancelEdit();
          },
          onCancel: closeConfirm
        });
      }
    },
    [data, del, closeConfirm, cancelEdit]
  );
  const handleArchiveToggle = useCallback(
    id => {
      const question = data.find(question => question.id === id);
      const editData = { id, archived: !question?.archived, answers: [] };
      if (question?.nbPocs === 0) {
        edit(editData);
        cancelEdit();
      } else if (question) {
        const verb = question.archived ? "activate" : "archive";
        setConfirmData({
          message: `Other Opportunities are referencing this question, are you sure you want to ${verb} it?`,
          onAccept: () => {
            edit(editData);
            cancelEdit();
          },
          onCancel: closeConfirm
        });
      }
    },
    [cancelEdit, closeConfirm, data, edit]
  );

  let fullData = type === "active" ? activeQuestions : archivedQuestions;

  const displayData = useMemo(() => {
    let newFilteredData = (searchValue ? filteredData : data) || [];
    const { tag, scope } = filter;

    if (tag) {
      newFilteredData = newFilteredData.filter(item =>
        item.templates?.includes(tag)
      );
    }
    if (scope) {
      newFilteredData = newFilteredData.filter(
        item => item.visibility === scope
      );
    }

    return newFilteredData;
  }, [filteredData, filter, searchValue, data]);

  if (isLoading) return <Loading />;

  const tabs = [
    {
      urlTo: "/settings/manage-questions",
      title: `Active`,
      counter: activeQuestions.length
    },
    {
      urlTo: "/settings/manage-questions/archived",
      title: `Archived`,
      counter: archivedQuestions.length
    }
  ];

  const sortTable = column => {
    let newSort = {
      key: column,
      order:
        sortBy.key === column
          ? sortBy.order === "desc"
            ? "asc"
            : "desc"
          : "asc"
    };
    setSortBy(newSort);
    if (type === "active") {
      setActiveQuestions(
        activeQuestions.sort((a, b) => {
          if (newSort.order === "asc") {
            return a[column] > b[column] ? 1 : -1;
          } else {
            return a[column] < b[column] ? 1 : -1;
          }
        })
      );
    } else {
      setArchivedQuestions(
        archivedQuestions.sort((a, b) => {
          if (newSort.order === "asc") {
            return a[column] > b[column] ? 1 : -1;
          } else {
            return a[column] < b[column] ? 1 : -1;
          }
        })
      );
    }
  };

  const onDragEndQuestions = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let tempQuestions =
      type === "active" ? [...activeQuestions] : [...archivedQuestions];
    const items = reorderListAfterDragEnd(
      tempQuestions,
      result.source.index,
      result.destination.index
    );
    if (type === "active") {
      setActiveQuestions(items);
    } else {
      setArchivedQuestions(items);
    }
    reorderQuestions({
      vendorId: currentUser.vendorId,
      ids: items.map(item => item.id)
    });
  };

  const onDragEndAnswers = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let questionId = result.draggableId.split("*")[2];
    let tempQuestions =
      type === "active" ? [...activeQuestions] : [...archivedQuestions];
    let foundQuestion = tempQuestions.find(item => item.id === questionId);
    if (foundQuestion) {
      let items = reorderListAfterDragEnd(
        foundQuestion.answers,
        result.source.index,
        result.destination.index
      );
      foundQuestion.answers = items;
      reorderAnswers({
        vendorId: currentUser.vendorId,
        ids: items.map(item => item.id)
      });
      if (type === "active") {
        setActiveQuestions(tempQuestions);
      } else {
        setArchivedQuestions(tempQuestions);
      }
    }
  };

  return (
    <>
      <NiceTabs tabs={tabs} className="is-centered" />

      <section className="card">
        <APIError error={error || addError || editError || deleteError} />
        <div className="ManageQuestions p-5">
          <div className="mb-5 level">
            <div className="level-left is-size-4">
              Manage Questions
              <button
                onClick={() => setIsCreate(true)}
                className="ml-3 button is-info"
              >
                Create a New Question
              </button>
            </div>
            <div className="level-right w-20r">
              <SearchBox
                dataSet={fullData}
                keys={["body", "answers.body", "templates"]}
                setDataSet={setFilteredData}
                setLiftedSearchValue={setSearchValue}
              />
            </div>
          </div>

          <div
            className={`${isCreate ? "" : "hidden"} card is-relative p-3 w-50`}
          >
            <CreateNewQuestionModal
              modalOpen={isCreate}
              handleClose={() => {
                setIsCreate(false);
              }}
              defaultVisibility={metadata?.entityVisibility?.COMPANY_WIDE}
            />
          </div>

          <AddNewQuestion />
          <div className="is-flex mb-5" style={{ gap: "1rem" }}>
            {filterOptions.map((item, idx) => (
              <SelectFilter
                key={idx}
                filter={filter}
                setFilter={setfilter}
                defaultValue={item.options[0]}
                controlLabel={item.label}
                options={item.options}
              />
            ))}
          </div>

          <DragDropContext onDragEnd={onDragEndQuestions}>
            <div className="table-container">
              <TableHeader
                columns={NewManageQuestionColumns}
                isMenuAvailable={true}
                sortTable={sortTable}
                sortBy={sortBy}
                icon={showAllAnswer ? <DashSquare /> : <PlusSquare />}
                iconOnClick={() => setShowAllAnswer(!showAllAnswer)}
              />
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {displayData?.map((row, i) => (
                      <Draggable
                        key={row.id}
                        draggableId={`${row.id}`}
                        index={i}
                      >
                        {provided => (
                          <TableBody
                            questionSnapshot={snapshot?.isDraggingOver}
                            provided={provided}
                            filterKey={"manageQuestions"}
                            onDragEndAnswers={onDragEndAnswers}
                            showAnswersInitial={showAllAnswer}
                            questionData={row}
                            toggleId={() => {}}
                            answerData={row.answers}
                            selectedAnswers={[]}
                            selectedAnswersIds={[]}
                            setSelectedAnswersIds={() => {}}
                            answerColumns={NewManageAnswerColumns}
                            answerColumnsEditMode={NewManageAnswerColumns}
                            isManageQuestion={true}
                            handleArchiveToggle={handleArchiveToggle}
                            handleDeleteQuestion={handleDelete}
                            editTemplate={editTemplate}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
        {confirmData && <ConfirmChange {...confirmData} />}
      </section>
    </>
  );
};

export default ManageQuestions;
