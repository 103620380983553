import useAuth from "hooks/useAuth";
import { hasPermission } from "./rbac.utils";

const WithRbac = ({
  children,
  RenderError = () => <></>,
  errorProps = null,
  permissionOneOf = [],
  roleOneOf = []
}) => {
  const { currentUser } = useAuth();

  let granted = false;
  if (permissionOneOf.length) {
    granted = hasPermission(currentUser?.permissions, permissionOneOf);
  }
  if (roleOneOf.length) {
    granted = hasPermission(currentUser?.roles, roleOneOf);
  }

  if (!granted && !errorProps) return <RenderError />;

  return <>{children}</>;
};

export default WithRbac;
