import React, { useMemo, useState } from "react";
import { Menu, Popover } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { ThreeDotsVertical, ChatLeft } from "react-bootstrap-icons";
import {
  useDeletePocQuestionsMutation,
  useEditPocQuestionsMutation,
  useGetPocQuestionAnswersQuery
} from "services/api";
import { debounce } from "debounce";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";
import Modal from "components/common/Modal";
import { toast } from "react-toastify";

export const QuestionTableActions = ({
  value,
  handleEdit,
  questionVisibility,
  handleArchiveToggle,
  handleDeleteQuestion,
  isManageQuestion,
  deleteId,
  metadata
}) => {
  const { userHasPermission } = useRbac();
  const { pocId } = useParams();
  const [remove, { isLoading: batchDeleteIsLoading }] =
    useDeletePocQuestionsMutation({
      fixedCacheKey: `batch-delete-pocQuestions-${pocId}`
    });
  const [edit] = useEditPocQuestionsMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemBeingDeleted, setItemBeingDeleted] = useState(null);

  const addNote = (e, close) => {
    e.preventDefault();
    edit({
      id: value.poc_question_id,
      pocId,
      questionId: value.id,
      notes: e.target.note.value
    });
    close();
  };

  const removeNote = () => {
    edit({ id: value.poc_question_id, pocId, questionId: value.id, notes: "" });
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = item => {
    setIsDeleteModalOpen(true);
    setItemBeingDeleted(item);
  };

  const onDeleteItem = () => {
    remove([{ pocId, id: deleteId }])
      .unwrap()
      .then(() => {
        toast.success(`Deleted!`);
        setItemBeingDeleted({});
        setIsDeleteModalOpen(false);
      })
      .catch(error => toast.error(`Error: ${error.message}`));
  };

  return (
    <>
      <Popover
        as="div"
        className="is-2 is-flex is-align-items-center is-justify-content-flex-end gap-2 is-relative discovery-item"
      >
        {value?.poc_question_notes ? (
          <Popover.Button className="is-absolute note-icon">
            <ChatLeft className="bi bi-chat-left" />
          </Popover.Button>
        ) : null}

        <Popover.Panel
          as="div"
          className="box top-0 right-4 is-absolute z-30 w-20r"
        >
          {({ open, close }) => (
            <form onSubmit={e => addNote(e, close)} className="field">
              <label className="label">Add Note</label>
              <div className="control">
                <textarea
                  autoFocus
                  placeholder="Enter notes here"
                  className="textarea"
                  name="note"
                  cols="30"
                  rows="4"
                  defaultValue={value.poc_question_notes || ""}
                />
              </div>
              <div className="control mt-3">
                <button type="submit" className="button is-info">
                  Save
                </button>
                <button className="button is-light" onClick={() => close()}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        </Popover.Panel>
        <Menu
          as="div"
          className="w-1r dropdown is-flex is-align-items-center is-right is-active is-relative"
        >
          <Menu.Button
            as="div"
            className="dropdown-trigger is-align-content-end is-absolute"
            // disabled={item.addressed}
          >
            <ThreeDotsVertical />
          </Menu.Button>
          <Menu.Items
            as="div"
            className="dropdown-menu card"
            style={{ outlineStyle: "none", textAlign: "left" }}
          >
            {userHasPermission(UserPermissions.EVALQUESTIONNAIRE_DELETE) && (
              <>
                <Menu.Item
                  as="div"
                  className="p-2 pl-4 is-clickable"
                  onClick={() =>
                    isManageQuestion
                      ? handleDeleteQuestion(value.id)
                      : handleOpenDeleteModal(value)
                  }
                >
                  Delete
                </Menu.Item>
                {((value.visibility === metadata?.entityVisibility?.ONE_OFF &&
                  userHasPermission(
                    UserPermissions.EVALQUESTIONNAIRE_UPDATE
                  )) ||
                  (isManageQuestion &&
                    userHasPermission(
                      UserPermissions.EVALQUESTIONNAIRE_MANAGE
                    ))) && (
                  <Menu.Item
                    as="div"
                    className="p-2 pl-4 is-clickable"
                    onClick={e => {
                      e.preventDefault();
                      handleEdit();
                    }}
                  >
                    Edit
                  </Menu.Item>
                )}
              </>
            )}
            {!isManageQuestion ? (
              <Popover.Button
                as="div"
                className="py-3 is-flex is-align-items-center p-2 pl-4 is-clickable"
              >
                <Menu.Item as="span">Add Note</Menu.Item>
              </Popover.Button>
            ) : (
              <Menu.Item
                as="div"
                className="p-2 pl-4 is-clickable"
                onClick={() => handleArchiveToggle(value.id)}
              >
                {value.archived ? "Reactive" : "Archive"}
              </Menu.Item>
            )}
            {value.poc_question_notes && (
              <Menu.Item
                as="div"
                className="p-2 pl-4 is-clickable"
                onClick={removeNote}
              >
                Delete Note
              </Menu.Item>
            )}
          </Menu.Items>
        </Menu>
      </Popover>

      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          setIsOpen={handleCloseDeleteModal}
          title={`Deleting question "${itemBeingDeleted?.body}" ?`}
          body="You're about to permanently delete this question, and its data."
          buttons={[
            <button
              onClick={() => onDeleteItem()}
              className={`button is-danger ${
                batchDeleteIsLoading ? "is-loading" : ""
              }`}
            >
              Delete
            </button>,
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="button is-light"
            >
              Cancel
            </button>
          ]}
        />
      )}
    </>
  );
};

const Actions = ({ value }) => {
  const { userHasPermission } = useRbac();

  const { pocId } = useParams();
  const [remove, { isLoading: batchDeleteIsLoading }] =
    useDeletePocQuestionsMutation({
      fixedCacheKey: `batch-delete-pocQuestions-${pocId}`
    });
  const [edit] = useEditPocQuestionsMutation();

  const deletePocQuestions = () => {
    remove([{ pocId, id: value.id }]);
  };
  const addNote = (e, close) => {
    e.preventDefault();
    edit({ pocId, id: value.id, notes: e.target.note.value });
    close();
  };
  const removeNote = () => {
    edit({ pocId, id: value.id, notes: "" });
  };

  return (
    <>
      <Popover
        as="div"
        className="column is-2 is-flex is-align-items-center is-justify-content-flex-end gap-2 is-relative"
      >
        {value.notes ? (
          <Popover.Button className="button is-ghost">
            <ChatLeft className="bi bi-chat-left " />
          </Popover.Button>
        ) : null}
        <Popover.Panel
          as="div"
          className="box top-0 right-4 is-absolute z-30 w-20r"
        >
          {({ open, close }) => (
            <form onSubmit={e => addNote(e, close)} className="field">
              <label className="label">Add Note</label>
              <div className="control">
                <textarea
                  autoFocus
                  placeholder="Enter prerequisite notes here"
                  className="textarea"
                  name="note"
                  cols="30"
                  rows="4"
                  defaultValue={value.notes || ""}
                />
              </div>
              <div className="control mt-3">
                <button type="submit" className="button is-info">
                  Save
                </button>
              </div>
            </form>
          )}
        </Popover.Panel>
        <Menu
          as="div"
          className="w-1r dropdown is-flex is-align-items-center is-right is-active is-relative"
        >
          <Menu.Button
            as="div"
            className="dropdown-trigger is-align-content-end is-absolute"
            // disabled={item.addressed}
          >
            <ThreeDotsVertical />
          </Menu.Button>
          <Menu.Items
            as="div"
            className="dropdown-menu card"
            style={{ outlineStyle: "none" }}
          >
            {userHasPermission(UserPermissions.EVALQUESTIONNAIRE_DELETE) && (
              <Menu.Item
                as="div"
                className="p-2 pl-4 is-clickable"
                style={{ textAlign: "left" }}
                onClick={deletePocQuestions}
              >
                Delete
              </Menu.Item>
            )}
            <Popover.Button
              as="div"
              className="py-3 is-flex is-align-items-center p-2 pl-4 is-clickable "
            >
              <Menu.Item as="span">Add Note</Menu.Item>
            </Popover.Button>
            {value.notes && (
              <Menu.Item
                as="div"
                className="p-2 pl-4 is-clickable"
                onClick={removeNote}
                style={{ textAlign: "left" }}
              >
                Delete Note
              </Menu.Item>
            )}
          </Menu.Items>
        </Menu>
      </Popover>
    </>
  );
};

const AnswerBodyCell = ({ handleFreeText, row, toggleId, ids }) => {
  const { pocId } = useParams();
  const { id, body, isFreeText } = row.original;
  const { data: answerData } = useGetPocQuestionAnswersQuery(pocId);
  const answer = answerData.find(x => x.answerId === id);
  const isExist = useMemo(() => Array.from(ids).includes(id), [ids, id]);

  const handleChangeBody = e => {
    handleFreeText({ answerId: id, body: e.target.value });
  };
  const handleFocusBody = e => {
    if (!isExist) {
      toggleId(id);
    }
  };
  const handleBlurBody = e => {
    const hasValue = e.target.value;
    if (!hasValue && isExist) {
      toggleId(id);
    }
  };
  return isFreeText ? (
    <input
      // disabled={!!!answer}
      placeholder="Your Answer"
      defaultValue={answer?.body}
      onChange={debounce(handleChangeBody, 500)}
      onFocus={handleFocusBody}
      onBlur={handleBlurBody}
      className="input"
    />
  ) : (
    <div>{body}</div>
  );
};

const batchCell = {
  id: "bulk-attach",
  Header: ({ ids, data, setIds, existingIds }) => (
    <input
      type="checkbox"
      className="form-check-input larger"
      onChange={e => {
        const allids = new Set(ids);
        data.map(x => allids.add(x.id));
        if (e.target.checked) {
          setIds(allids);
        } else {
          setIds(existingIds);
        }
      }}
    />
  ),
  Cell: ({ toggleId, ids, row, existingIds }) => {
    return (
      <input
        type="checkbox"
        className="form-check-input larger"
        onChange={() => toggleId(row.original.id)}
        checked={ids.has(row.original.id) || existingIds.has(row.original.id)}
        disabled={row.original.archived || existingIds.has(row.original.id)}
      />
    );
  }
};

export const NewQuestionColumns = [
  {
    label: "Question",
    key: "question",
    isSortable: true,
    isProspectUser: true
  },
  {
    label: "Answer",
    key: "answer",
    isSortable: true,
    isProspectUser: true
  },
  // {
  //   label: "Usage %",
  //   key: "usage",
  //   isSortable: true,
  //   isProspectUser: false
  // },
  // {
  //   label: "Win Rate",
  //   key: "winRate",
  //   isSortable: true,
  //   isProspectUser: false
  // },
  {
    // label: "Actions",
    key: "actions",
    isSortable: false,
    isProspectUser: true
  }
];

export const NewAnswerColumns = [
  {
    // label: "Answer",
    key: "answer",
    isSortable: true,
    isProspectUser: true
  }
  // {
  //   label: "Usage %",
  //   key: "usage",
  //   isSortable: true,
  //   isProspectUser: false
  // },
  // {
  //   label: "Win Rate",
  //   key: "winRate",
  //   isSortable: true,
  //   isProspectUser: false
  // }
];

export const NewAnswerColumnsEditMode = [
  {
    label: "Answer",
    key: "answer",
    isSortable: true
  },
  {
    label: "Free Text",
    key: "isFreeText",
    isSortable: false
  },
  {
    label: "Active",
    key: "archived",
    isSortable: true
  }
  // {
  //   label: "Usage %",
  //   key: "usage",
  //   isSortable: true
  // },
  // {
  //   label: "Win Rate",
  //   key: "winRate",
  //   isSortable: true
  // }
];

export const NewManageQuestionColumns = [
  {
    label: "Question",
    key: "question",
    isSortable: true
  },
  {
    label: "Answers",
    key: "answers",
    isSortable: true
  },
  {
    label: "Tags",
    key: "templates",
    isSortable: false
  },
  {
    label: "Scope",
    key: "visibility",
    isSortable: true
  },
  // {
  //   label: "Usage %",
  //   key: "usage",
  //   isSortable: true
  // },
  {
    // label: "Action",
    key: "actions",
    isSortable: false
  }
];

export const NewManageAnswerColumns = [
  {
    label: "Answers",
    key: "answers",
    isSortable: true
  },
  {
    label: "Free Text",
    key: "isFreeText",
    isSortable: true
  },
  {
    label: "Active",
    key: "archived",
    isSortable: true
  }
  // {
  //   label: "Usage %",
  //   key: "usage",
  //   isSortable: true
  // },
  // {
  //   label: "Win Rate",
  //   key: "winRate",
  //   isSortable: true
  // }
];

export const questionColumns = [
  {
    Header: "Question",
    accessor: pocQ => {
      let prefix = "";
      if (pocQ.question.archived) {
        prefix = "ARCHIVED: ";
      }
      return (
        <React.Fragment>
          <span>{`${prefix}${pocQ.question?.body}`}</span>
          <EntityVisibilityIcon entity={pocQ.question} />
        </React.Fragment>
      );
    }
  },
  {
    Header: "Answer",
    Cell: ({ row }) => {
      return row.original.answerValue;
    }
  },
  // {
  //   Header: "Usage %",
  //   accessor: pocQ => pocQ.question.usage,
  //   Cell: ({ value }) => (value ? value + "%" : "0%")
  // },
  // {
  //   Header: "Win Rate",
  //   Cell: ({ row }) => {
  //     return row.original.winrateValue !== null
  //       ? row.original.winrateValue.toFixed(0) + "%"
  //       : "-";
  //   }
  // },
  {
    Header: "Actions",
    accessor: pocQ => pocQ,
    Cell: cell => <Actions {...cell} />
  }
];

export const answerColumns = [
  { ...batchCell },
  {
    Header: "Answer",
    Cell: cell => <AnswerBodyCell {...cell} />
  }
  // {
  //   Header: "Usage %",
  //   accessor: "usage",
  //   Cell: ({ value }) => (value ? value + "%" : "0%")
  // },
  // {
  //   Header: "Win Rate",
  //   accessor: "winRate",
  //   Cell: ({ value }) => (value ? value + "%" : "0%")
  // }
];

export const bulkAttachColumns = [
  { ...batchCell },
  {
    Header: "Question",
    accessor: question => {
      let prefix = "";
      if (question.archived) {
        prefix = "ARCHIVED: ";
      }
      return (
        <React.Fragment>
          <span>{`${prefix}${question?.body}`}</span>
          <EntityVisibilityIcon entity={question} />
        </React.Fragment>
      );
    }
  },
  {
    Header: "Answers",
    accessor: question =>
      question.answers
        .filter(ans => !ans.archived)
        .map(ans => (ans.isFreeText ? "Free Text" : ans.body))
        .sort()
        .join(", ")
  }
  // {
  //   Header: "Visibility",
  //   accessor: question => question.visibility
  // },
  // {
  //   Header: "Usage %",
  //   thClassName: "w-6r",
  //   accessor: question => question.usage,
  //   Cell: ({ value }) => (value ? value + "%" : "0%")
  // }
];

export const prospectHeaders = ["Question", "Answer", "Answers", "Actions"];
