import * as React from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationDiamond, XLg } from "react-bootstrap-icons";
import { LoadingIconOnly } from "../Loading/index";

const Modal = ({
  isOpen = false,
  setIsOpen = () => {},
  handleClose = () => {},
  isLoading = false,
  type = "",
  title = "",
  body = "",
  buttons = [],
  className = "",
  classTitle = "",
  overflowAuto = true
}) => {
  let icon = "";
  switch (type) {
    case "danger":
      icon = (
        <ExclamationDiamond size="25px" className="has-text-danger mr-3" />
      );
      break;
  }

  return (
    <Dialog
      as="div"
      open={isOpen}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => {}}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
      <div className={`card max-h-screen p-3 min-w-50 ${className}`}>
        <div>
          <nav className="level">
            <div className="level-left">
              <div className="level-item">{icon}</div>
              <div className="level-item">
                <div className={`is-size-4 mt-3 mb-3 ml-5 ${classTitle}`}>
                  {title}
                </div>
              </div>
              <div
                className={`level-item ${
                  isLoading ? "is-visible" : "is-hidden"
                }`}
              >
                <LoadingIconOnly />
              </div>
            </div>

            <div className="level-right">
              <p className="level-item">
                <button
                  className="button is-white"
                  onClick={() => {
                    setIsOpen(false);
                    handleClose();
                  }}
                  title="Close"
                >
                  <XLg className="is-size-4" />
                </button>
              </p>
            </div>
          </nav>
        </div>
        <div className={`card-content ${overflowAuto ? "overflow-auto" : ""}`}>
          {body}
        </div>
        <div className="buttons is-justify-content-end mt-2">
          {buttons.map((button, i) => (
            <React.Fragment key={i}>{button}</React.Fragment>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
