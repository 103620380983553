import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import {
  convertArrayToSelectOptions,
  copyText,
  inputWithCommas,
  isDateExpired,
  urlFriendly
} from "utils/helpers";
import {
  useEditPocMutation,
  useGetMetaQuery,
  useGetChecklistTemplateListQuery,
  useGetQuestionTemplateListQuery,
  useGetUsecaseTemplateListQuery,
  useImportPocUseCaseMutation,
  useImportPocChecklistMutation,
  useImportPocQuestionsMutation,
  useInviteStakeholderMutation,
  useCreateAutoLoginLinkMutation
} from "services/api";
import useAuth from "hooks/useAuth";
import APIError from "components/common/APIError";
import { LoadingIconOnly } from "components/common/Loading";

export default function PocBasicInfoForm({
  poc = {},
  onSuccess = () => {},
  onCancel = () => {}
}) {
  const {
    currentUser: { vendorId }
  } = useAuth();
  const { pocId } = useParams();
  const [startDate, setStartDate] = useState(poc.startDate || null);
  const [endDate, setEndDate] = useState(poc.endDate || null);
  const [startDateActual, setStartDateActual] = useState(
    poc.startDateActual || null
  );
  const [endDateActual, setEndDateActual] = useState(poc.endDateActual || null);
  const [autoLoginLink, setAutoLoginLink] = useState("");
  const [scenarioWarning, setScenarioWarning] = useState(false);
  const isPaidRef = useRef(null);
  const createOptions = data =>
    data ? convertArrayToSelectOptions(Object.values(data)) : [];
  const [
    editPoc,
    { isLoading: editIsLoading, error: editPocError, isSuccess: editIsSuccess }
  ] = useEditPocMutation();
  const { data: metadata, isLoading: metadataLoading } = useGetMetaQuery();
  const { industries, geographies, segments } = metadata || {};

  const { data: checklistTemplates, isLoading: loadingChecklist } =
    useGetChecklistTemplateListQuery(vendorId);
  const { data: usecaseTemplates, isLoading: loadingUseCases } =
    useGetUsecaseTemplateListQuery(vendorId);
  const { data: questionTemplates, isLoading: loadingQuestions } =
    useGetQuestionTemplateListQuery(vendorId);
  const templateLoading =
    loadingChecklist || loadingUseCases || loadingQuestions;

  const [importChecklist, { isLoading: loadingImportChecklist }] =
    useImportPocChecklistMutation();
  const [importUsecase, { isLoading: loadingImportUsecase }] =
    useImportPocUseCaseMutation();
  const [importQuestion, { isLoading: loadingImportQuestion }] =
    useImportPocQuestionsMutation();
  const importLoading =
    loadingImportChecklist || loadingImportUsecase || loadingImportQuestion;

  const [inviteStakeholder, { isLoading: inviteLoading }] =
    useInviteStakeholderMutation();
  const [createAutoLoginLink, { isLoading: autologinLoading }] =
    useCreateAutoLoginLinkMutation();
  const generateViewLoading = inviteLoading || autologinLoading;

  const templateList = Object.keys({
    ...usecaseTemplates,
    ...questionTemplates,
    ...checklistTemplates
  }).sort();

  const createLink = async stakeholder => {
    const { data } = await createAutoLoginLink({
      userId: stakeholder?.userId,
      notifyByEmail: false,
      emailToNotify: stakeholder?.email,
      payload: {
        key1: "value1",
        redirectTo: `/pocs/${pocId}/overview`
      }
    });
    setAutoLoginLink(data.autologinLink);
  };

  const generateViewLink = useCallback(async () => {
    // const firstId = poc?.id.split("-")[0];
    const name = urlFriendly(poc.pocName);
    const email = `${name}_view@evalforce.com`;
    const stakeholder = poc?.stakeholders.find(x => x.email === email);
    if (stakeholder) {
      createLink(stakeholder);
    } else {
      const { data } = await inviteStakeholder({
        email,
        firstName: name,
        lastName: "view",
        roles: ["ProspectUser"],
        pocId
      });
      createLink(data.newStakeholder);
    }
  }, [poc?.id, poc?.stakeholders, inviteStakeholder]);

  const onSubmit = async e => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    const payload = { isPaid: false };
    formdata.forEach((value, key) => {
      switch (key) {
        case "amount":
        case "totalAcv":
          value = parseFloat(value.replace(/,/g, ""));
          break;
        case "startDate":
        case "endDate":
        case "startDateActual":
        case "endDateActual":
          value = value ? dayjs(value).toISOString() : null;
          break;
        case "isPaid":
          value = isPaidRef.current.checked;
          break;
        default:
          break;
      }
      payload[key] = value;
    });
    if (payload.template) {
      await importChecklist({ pocId, template: payload.template });
      await importUsecase({ pocId, template: payload.template });
      await importQuestion({ pocId, template: payload.template });
    }
    delete payload.template;
    editPoc({ id: poc.id, ...payload });
  };

  useEffect(() => {
    if (editIsSuccess && !importLoading) {
      toast.success("Opportunity details updated");
      onSuccess();
    }
  }, [editIsSuccess, importLoading]);

  const disabled = poc.phase === "Archived";

  const diff = dayjs(endDate).diff(new Date(), "day");
  const expired = isDateExpired(endDate, new Date());

  let bubbleText = dayjs(endDate).fromNow();
  let bubbleBg = "has-background-success";
  if (diff < 6) {
    bubbleBg = "has-background-danger";
    if (diff > 0) {
      bubbleText = dayjs(endDate).fromNow(true) + " left";
    }
  }
  if (diff === 0) {
    if (expired) {
      bubbleText = "Today";
    } else {
      bubbleText = "d day Left";
    }
  }

  return (
    <>
      <APIError error={editPocError} />
      <form className="p-4" onSubmit={onSubmit}>
        <div className="columns">
          <div className="column is-half py-0  field">
            <label className="label">Name</label>
            <div className="control">
              <input
                disabled={disabled}
                name="pocName"
                defaultValue={poc.pocName}
                className="input"
                type="text"
                placeholder=""
              />
            </div>
          </div>
          <div className="column py-0  field">
            <label className="label">Projected Start Date</label>
            <div className="control">
              <DatePicker
                disabled={disabled}
                className="input is-fullwidth"
                name="startDate"
                selected={new Date(startDate)}
                onChange={date => setStartDate(date)}
              />
            </div>
          </div>
          <div className="column py-0  field">
            <label className="label">Projected End Date</label>
            <div className="control">
              <DatePicker
                disabled={disabled}
                className="input"
                name="endDate"
                selected={new Date(endDate)}
                onChange={date => setEndDate(date)}
              />
            </div>
          </div>
          <div className="column py-0 is-flex is-align-items-center">
            <div
              className={`${bubbleBg} rounded border w-75 p-1 has-text-centered has-text-white-bis mt-4`}
            >
              {bubbleText}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column py-0 is-3 field">
            <label className="label">ACV</label>
            <p className="control has-icons-left has-icons-right">
              <input
                disabled={disabled}
                name="amount"
                defaultValue={poc.totalAcv?.toLocaleString("en-US")}
                onChange={inputWithCommas}
                className="input"
                type="text"
                placeholder="12345"
              />
              <span className="icon is-small is-left">$</span>
            </p>
          </div>
          <div className="column py-0 is-3 field">
            <label className="label">Total Contract Value</label>
            <p className="control has-icons-left has-icons-right">
              <input
                disabled={disabled}
                name="totalAcv"
                defaultValue={poc.amount?.toLocaleString("en-US")}
                onChange={inputWithCommas}
                className="input"
                type="text"
                placeholder="12345"
              />
              <span className="icon is-small is-left">$</span>
            </p>
          </div>
          <div className="column py-0  field">
            <label className="label">Actual Start Date</label>
            <div className="control">
              <DatePicker
                disabled={disabled}
                className="input"
                name="startDateActual"
                selected={startDateActual ? new Date(startDateActual) : ""}
                onChange={date => setStartDateActual(date)}
              />
            </div>
          </div>
          <div className="column py-0  field">
            <label className="label">Actual End Date</label>
            <div className="control">
              <DatePicker
                disabled={disabled}
                className="input"
                name="endDateActual"
                selected={endDateActual ? new Date(endDateActual) : ""}
                onChange={date => setEndDateActual(date)}
              />
            </div>
          </div>
          <div className="column py-0 is-flex">
            <div></div>
          </div>
        </div>
        <div className="columns">
          <div className="column py-0 is-3 field">
            <label className="label">Industry</label>
            <div className="control select z-0">
              <select
                disabled={disabled}
                name="industryName"
                defaultValue={poc.industryName}
                className={`form-control form-select`}
              >
                {createOptions(industries)?.map(option => (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="column py-0 is-3 field">
            <label className="label">Segment</label>
            <div className="control select z-10">
              <select
                disabled={disabled}
                name="segmentName"
                defaultValue={poc.segmentName}
                className={`form-control form-select`}
              >
                {createOptions(segments)?.map(option => (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="column py-0 is-2 field">
            <label className="label">Geography</label>
            <div className="control select z-0">
              <select
                disabled={disabled}
                name="geographyName"
                defaultValue={poc.geographyName}
                className={`form-control form-select`}
              >
                {createOptions(geographies)?.map(option => (
                  <option key={option.key} value={option.key}>
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="column py-0 is-4 field">
            <label className="label is-flex is-align-items-center">
              Scenario {templateLoading && <LoadingIconOnly className="ml-2" />}
            </label>
            <div className="control select z-0">
              <select
                disabled={templateLoading}
                name="template"
                className={`form-control form-select`}
                onChange={e => setScenarioWarning(!!e.target.value)}
              >
                <option value="">-</option>
                {templateList?.map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            {scenarioWarning && (
              <div className="has-text-danger">
                Saving a scenario will overwrite the current Discovery,
                Requirement, and Action Plan items
              </div>
            )}
          </div>
        </div>
        <div className="columns">
          <div className="column py-0 is-3 field">
            <label className="label">Paid PoV?</label>
            <div className="control">
              <input
                type="checkbox"
                name="isPaid"
                ref={isPaidRef}
                defaultChecked={!!poc.isPaid}
                className="larger"
              />
            </div>
          </div>
          <div className="column py-0 is-5 field my-auto ">
            <div className="is-flex is-align-items-center">
              <button
                type="button"
                disabled={generateViewLoading}
                className={`button is-small is-info h-full is-flex`}
                onClick={!generateViewLoading && generateViewLink}
              >
                Generate View Only Link
                {generateViewLoading && <LoadingIconOnly className="ml-2" />}
              </button>
              {!!autoLoginLink && (
                <>
                  <input
                    value={autoLoginLink}
                    readOnly
                    className="input is-small w-100 ml-4"
                  />

                  <button
                    type="button"
                    className="button is-small is-info h-full"
                    onClick={() => copyText(autoLoginLink, "Link Copied!")}
                  >
                    Copy
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="buttons is-justify-content-end mt-2">
          <button
            className="button is-info"
            type="submit"
            disabled={disabled || importLoading}
          >
            Save {importLoading && <LoadingIconOnly className="ml-2" />}
          </button>
          <button className="button is-light" onClick={onCancel}>
            Cancel
          </button>
        </div>

        {/* <div className="is-flex is-justify-content-flex-end mb-5">
          <button
            type="submit"
            disabled={disabled}
            className="button control is-info px-5 mr-5"
          >
            Save
          </button>
        </div> */}
      </form>
    </>
  );
}
