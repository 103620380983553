import React, { useCallback } from "react";
import Select, { components } from "react-select";

export default function SelectFilter({
  filter,
  setFilter,
  defaultValue,
  options,
  controlLabel
}) {
  const handleFiltering = useCallback(
    (key, newValue) => {
      const newFilter = { ...filter };
      newFilter[key] = newValue;
      setFilter(newFilter);
    },
    [filter]
  );

  return (
    <Select
      components={{
        Control,
        IndicatorSeparator: () => null
      }}
      onChange={selected =>
        handleFiltering(controlLabel.toLowerCase(), selected.value)
      }
      defaultValue={defaultValue}
      controlLabel={controlLabel}
      options={options}
      styles={{
        control: css => ({
          ...css,
          outlineWidth: "0px",
          boxShadow: "none",
          border: "none",
          color: "#515e67"
        }),
        option: (provided, state) => {
          return {
            ...provided,
            backgroundColor: state.isSelected
              ? "#f5f5f5"
              : state.isFocused
              ? "#f5f5f5"
              : "white",
            color: "#515e67"
          };
        }
      }}
    />
  );
}

const Control = ({ children, selectProps, ...props }) => {
  return (
    <components.Control {...props}>
      <div className=" button is-light ">
        {selectProps.controlLabel}: {children}
      </div>
    </components.Control>
  );
};
