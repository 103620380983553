import { useCallback, useMemo } from "react";
import useForm from "hooks/useForm";
import Button from "./Button";
import Checkboxes from "./Checkboxes";
import DatePickerInput from "./DatePickerInput";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import Loading from "./Loading";

const componentMapping = {
  button: Button,
  checkboxes: Checkboxes,
  datepicker: DatePickerInput,
  input: Input,
  select: Select,
  textarea: Textarea
};

const Form = ({
  children,
  className,
  disabled = false,
  hideButton = false,
  fields,
  initialState,
  loading = false,
  resetOnSubmit = true,
  onValidSubmit,
  onChange,
  schema,
  submitLabel = "Save"
}) => {
  const {
    validate,
    handleChange,
    handleChangeDatePicker,
    data,
    errors,
    reset
  } = useForm(schema, initialState);
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const { error, value } = validate();
      if (Object.keys(error).length === 0) {
        onValidSubmit(value);
        if (resetOnSubmit) {
          reset();
        }
      }
    },
    [onValidSubmit, validate, reset, resetOnSubmit]
  );

  const handleChangeUniversal = useCallback(
    inputType =>
      (...args) => {
        if (inputType === "datepicker") {
          handleChangeDatePicker(...args);
        } else {
          handleChange(...args);
        }
        if (onChange) {
          onChange(...args);
        }
      },
    [handleChange, handleChangeDatePicker, onChange]
  );

  if (loading) return <Loading />;

  return (
    <form onSubmit={handleSubmit} className={className}>
      {fields.map(field => {
        const { inputType, name, hidden, ...otherProps } = field;
        const Component = componentMapping[inputType || "input"];

        return (
          <div key={name}>
            {!hidden && (
              <Component
                name={name}
                value={data[name] || ""}
                error={errors[name]}
                onChange={handleChangeUniversal(inputType)}
                {...otherProps}
              />
            )}
          </div>
        );
      })}
      {children}
      {!hideButton && (
        <>
          <Button
            label={submitLabel}
            className="button is-info"
            disabled={hasErrors || disabled}
          />
        </>
      )}
    </form>
  );
};

export default Form;
