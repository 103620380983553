import React from "react";

export default function ReadMore({ children, charLength }) {
  const fixedCharLength = charLength ? charLength : 100;

  return (
    <div>
      <div className="dropdown is-hoverable">
        <div className="dropdown-trigger">
          {children?.length > fixedCharLength
            ? `${children?.slice(0, fixedCharLength)}...`
            : children}
        </div>
        {children.length > fixedCharLength ? (
          <div className="dropdown-menu  p-0 mt-3">
            <div className="dropdown-content px-3 ">{children}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
