import React from "react";
import { createKey } from "utils/helpers";

const Checkboxes = ({ name, label, value, options, error, ...rest }) => (
  <div className="form-group mb-3">
    <label htmlFor={name}>{label}</label>
    <br />
    {options.map((item, index) => (
      <div
        key={createKey(name, index, "container")}
        className="form-check form-check-inline"
      >
        <input
          className="form-check-input"
          type="checkbox"
          name={name}
          value={item.label}
          checked={value === item.label}
          id={createKey(name, index)}
          {...rest}
        />
        <label className="form-check-label" htmlFor={createKey(name, index)}>
          {item.label}
        </label>
      </div>
    ))}

    {error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export default Checkboxes;
