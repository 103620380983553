import React from "react";
import { Route, Routes } from "react-router-dom";
import Analytics from ".";
import Opportunities from "./Opportunities";
import TeamUtilization from "./TeamUtilization";
import FeedbackRoutes from "pages/FeedbackRoutes";

export default function AnalyticsRoutes() {
  return (
    <Routes>
      <Route path="" element={<Analytics />} />
      <Route path="feedback/*" element={<FeedbackRoutes />} />
      <Route path="opportunities" element={<Opportunities />} />
      <Route path="utilization" element={<TeamUtilization />} />
    </Routes>
  );
}
