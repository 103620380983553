import { useCallback, useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetPocUseCasesQuery,
  useAddPocUseCasesAndSuccessCriteriaMutation,
  useDeletePocUseCasesAndSuccessCriteriaMutation,
  useEditMultiPocUseCasesMutation,
  useGetUseCasesQuery,
  useGetUsecaseTemplateListQuery
} from "services/api";
import useBatch from "hooks/useBatch";
import useAuth from "hooks/useAuth";
import Loading from "components/common/Loading";
import Table from "components/common/Table";
import { createMap, groupByTemplate, sortBy } from "utils/helpers";
import { bulkAttachColumns } from "./constants";
import NestedSuccessCriteriaTable from "./NestedSuccessCriteriaTable";
import usePocs from "hooks/usePocs";
import SearchBox from "components/common/SearchBox";
import { NoDataBlock } from "components/common/NoData";
import Modal from "components/common/Modal";
import EditUsecaseForm from "components/common/EditUsecaseForm";
import SelectFilter from "components/common/SelectFilter";
import useOption from "hooks/useOption";

const PocUseCasesBulkAttach = ({
  setShow,
  showType,
  handleClose,
  currentEdit,
  // templatesData,
  addSection = null,
  highlightedSection = null
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { currentPoc: poc } = usePocs();
  const { pocId } = useParams();
  const { data: pocUsecaseData, isLoading: pocUsecaseIsLoading } =
    useGetPocUseCasesQuery(pocId);
  const { data: usecases, isLoading } = useGetUseCasesQuery();
  const { currentUser, isProspect, getCurrentStakeholder, isSalesEngineer } =
    useAuth();

  const { data: templateList } = useGetUsecaseTemplateListQuery(
    currentUser?.vendorId
  );

  const CompanyWide = "Company-wide";

  const [searchValue, setSearchValue] = useState(highlightedSection || "");
  const [searchResult, setSearchResult] = useState([]);

  const currentStakeholder = useMemo(
    () => getCurrentStakeholder(poc),
    [poc, getCurrentStakeholder]
  );
  const groupedTemplate = useMemo(
    () =>
      groupByTemplate(usecases, templateList ? Object.keys(templateList) : []),
    [usecases, templateList]
  );

  const useCaseByProduct = useMemo(
    () =>
      poc.usecaseAssignedTemplate &&
      groupedTemplate[poc.usecaseAssignedTemplate]
        ? groupedTemplate[poc.usecaseAssignedTemplate]
        : null,
    [groupedTemplate, poc.usecaseAssignedTemplate]
  );

  const assignedTemplate = useMemo(
    () => poc.usecaseAssignedTemplate,
    [poc.usecaseAssignedTemplate]
  );

  const filterUseCases = ucs => {
    if (!ucs) return [];

    const vendorIds = (poc?.stakeholders || []).map(item => item.vendorId);
    const vendorIdsDistinct = [...new Set(vendorIds)];

    return (
      ucs.filter(
        item =>
          item?.visibility === CompanyWide ||
          vendorIdsDistinct.includes(item?.vendorId)
      ) || []
    );
  };

  // const memoizedData = useMemo(
  //   () => (usecases ? sortBy(filterUseCases(usecases), "body") : []),
  //   [usecases]
  // );

  const memoizedData = useMemo(
    () => (usecases ? filterUseCases(usecases) : []),
    [usecases]
  );

  const pocUseCases = useMemo(
    () => pocUsecaseData?.filter(({ usecase }) => usecase),
    [pocUsecaseData]
  );

  const PocUseCaseIds = useMemo(
    () => new Set(pocUsecaseData?.map(({ usecase }) => usecase?.id)),
    [pocUsecaseData]
  );
  const Unselected = memoizedData?.filter(x => !PocUseCaseIds.has(x.id));
  const selected = pocUseCases?.map(({ usecase }) => usecase);

  const mergedMemoized = sortBy(
    [
      ...new Map(memoizedData?.concat(selected)?.map(v => [v?.id, v]))?.values()
    ],
    "body"
  );

  let mergedUsecaseByProduct = [];
  if (assignedTemplate) {
    const filteredSelected = selected.filter(({ templates }) =>
      templates?.includes(assignedTemplate)
    );
    mergedUsecaseByProduct = sortBy(
      [
        ...new Map(
          useCaseByProduct?.concat(filteredSelected).map(v => [v.id, v])
        ).values()
      ],
      "body"
    );
  }

  const UnAssignedBusiness =
    showType?.type === "table"
      ? assignedTemplate
        ? mergedUsecaseByProduct.filter(
            ({ id }) => !showType?.value?.includes(id)
          )
        : mergedMemoized.filter(({ id }) => !showType.value.includes(id))
      : [];

  const { ids, toggleId, setIds } = useBatch();
  const [add, { isLoading: batchAddIsLoading }] =
    useAddPocUseCasesAndSuccessCriteriaMutation({
      fixedCacheKey: `batch-add-pocUseCases-${pocId}`
    });
  const [multiEdit] = useEditMultiPocUseCasesMutation();
  // const [edit] = useEditPocUseCasesMutation();

  const [del, { isLoading: batchDeleteIsLoading }] =
    useDeletePocUseCasesAndSuccessCriteriaMutation({
      fixedCacheKey: `batch-delete-pocUseCases-${pocId}`
    });

  const [successCriteriaIds, setSuccessCriteriaIds] = useState({});
  // const columns = useMemo(
  //   () =>
  //     isProspect
  //       ? bulkAttachColumns.filter(
  //           ({ Header, Id }) =>
  //             typeof Header !== "string" ||
  //             prospectHeaders.includes(Header) ||
  //             prospectHeaders.includes(Id)
  //         )
  //       : bulkAttachColumns,
  //   [isProspect]
  // );

  // const mode = location.pathname.split("/").pop();
  const currentPocUseCaseIds = useMemo(
    () => new Set(pocUsecaseData?.map(({ usecaseId }) => usecaseId)),
    [pocUsecaseData]
  );
  const currentPocUsecaseIdsMap = useMemo(
    () =>
      createMap(
        pocUsecaseData?.filter(pocUC => pocUC.pocId === pocId),
        "usecaseId"
      ),
    [pocUsecaseData, pocId]
  );

  const handleAddSuccessCriteria = useCallback(
    (usecaseId, successCriteriaIds) => {
      setSuccessCriteriaIds(oldMap => ({
        ...oldMap,
        [usecaseId]: new Set([
          ...(oldMap[usecaseId] || []),
          ...successCriteriaIds
        ])
      }));
    },
    []
  );

  const handleRemoveSuccessCriteria = useCallback(
    (usecaseId, successCriteriaIds) => {
      setSuccessCriteriaIds(oldMap => {
        const updatedIds = new Set([...(oldMap[usecaseId] || [])]);
        for (let id of successCriteriaIds) {
          updatedIds.delete(id);
        }
        return { ...oldMap, [usecaseId]: updatedIds };
      });
    },
    []
  );

  // adding a success criteria should auto-add the use case
  useEffect(() => {
    const usecaseIds = Object.keys(successCriteriaIds).filter(
      key => successCriteriaIds[key].size > 0
    );
    if (!showType?.value) {
      showType !== "section" &&
        setIds(oldIds => {
          const idsCopy = new Set([...oldIds]);
          for (const id of usecaseIds) {
            if (!idsCopy.has(id)) {
              idsCopy.add(id);
            }
          }
          return idsCopy;
        });
    }
  }, [successCriteriaIds, setIds, showType]);

  const handleAssignBusinessUsecase = () => {
    let payload = [];
    ids.forEach(id => {
      if (PocUseCaseIds.has(id)) {
        let newBv = [];
        let uc = pocUseCases.find(x => x.usecase.id === id);
        let bv = uc.businessValues;
        if (bv) {
          newBv = [...bv];
          newBv.push(showType.payload);
        } else {
          newBv = [showType.payload];
        }
        payload.push({ id: uc.id, businessValues: newBv });
      }
    });
    updateUseCase();
    multiEdit({ pocId: poc.id, payload });
    handleClose();
  };

  const [usecaseIdsToAdd, pocUsecaseIdsToRemove] = useMemo(() => {
    if (usecases) {
      const nonArchivedCurrentIds = new Set(
        usecases
          .filter(
            usecase => !usecase.archived && currentPocUseCaseIds.has(usecase.id)
          )
          .map(({ id }) => id)
      );
      return usecases
        .map(({ id }) => id)
        .filter(({ archived }) => !archived)
        .reduce(
          ([add, remove], usecaseId) => {
            const shouldRemoveId =
              !ids.has(usecaseId) && nonArchivedCurrentIds.has(usecaseId);
            const shouldAddId =
              ids.has(usecaseId) && !currentPocUseCaseIds.has(usecaseId);
            if (shouldRemoveId) {
              if (!showType.value) {
                remove.push(currentPocUsecaseIdsMap[usecaseId]?.id);
              }
            } else if (shouldAddId) {
              if (!showType.value) {
                add.push(usecaseId);
              } else {
                add.push({ id: usecaseId, businessValues: [showType.payload] });
              }
            }
            return [add, remove];
          },
          [[], []]
        );
    } else {
      return [[], []];
    }
  }, [usecases, showType, ids, currentPocUseCaseIds, currentPocUsecaseIdsMap]);

  const updateSection = useCallback(
    newCreated => {
      if (addSection) {
        const ucToAdd = usecases.filter(({ id }) => ids.has(id));
        newCreated?.id && ucToAdd.push(newCreated);
        const sectionPayload = ucToAdd
          .map(
            ({ id, body, notes }) =>
              `<span class="embeddedObjectTag grayTag"
              data-object-type="usecase" data-object-id="${id}"
              data-object-description="${body}"
              >${" " + highlightedSection}</span>
              ${ucToAdd.length > 1 ? "<br/>" : ""}`
          )
          .join("");
        addSection(sectionPayload);
      }
    },
    [addSection, usecases, ids]
  );

  const updateUseCase = useCallback(
    newCreated => {
      newCreated.id ? updateSection(newCreated) : updateSection();
      const previousSuccessCriteria = {};
      for (const usecaseId in currentPocUsecaseIdsMap) {
        previousSuccessCriteria[usecaseId] = new Set(
          currentPocUsecaseIdsMap[usecaseId].pocUsecaseSuccesscriterias.map(
            ({ successcriteriaId }) => successcriteriaId
          )
        );
      }
      const scToRemove = [];
      // 1. remove all success criteria from use cases that are set to be removed
      for (const pocUsecaseId of pocUsecaseIdsToRemove) {
        const pocUsecase = pocUsecaseData?.find(
          ({ id }) => id === pocUsecaseId
        );
        scToRemove.push(
          ...pocUsecase.pocUsecaseSuccesscriterias.map(({ id }) => id)
        );
      }
      // 2. remove newly unchecked success criteria from use cases we are keeping
      for (let usecaseId in successCriteriaIds) {
        for (let scId of previousSuccessCriteria[usecaseId] || []) {
          if (!successCriteriaIds[usecaseId].has(scId)) {
            scToRemove.push(
              currentPocUsecaseIdsMap[
                usecaseId
              ].pocUsecaseSuccesscriterias.find(
                el => el.successcriteriaId === scId
              ).id
            );
          }
        }
      }
      // 1. add sc to existing use cases
      const scToAdd = [];
      for (let usecaseId in previousSuccessCriteria) {
        for (let scId of successCriteriaIds[usecaseId] || []) {
          if (!previousSuccessCriteria[usecaseId].has(scId)) {
            scToAdd.push({
              usecaseId,
              successcriteriaId: scId,
              pocUsecaseId: currentPocUsecaseIdsMap[usecaseId].id
            });
          }
        }
      }
      // 2. add sc to new use cases
      for (let usecaseId in successCriteriaIds) {
        if (!currentPocUsecaseIdsMap[usecaseId]) {
          scToAdd.push(
            [...successCriteriaIds[usecaseId]].map(successcriteriaId => ({
              usecaseId,
              successcriteriaId,
              pocUsecaseId: null
            }))
          );
        }
      }
      const newIds = [...usecaseIdsToAdd];
      newCreated.id && newIds.push(newCreated.id);

      if (newIds.length > 0 || scToAdd.length > 0) {
        add({
          pocId,
          assignedToStakeholderId:
            currentStakeholder?.id || poc?.stakeholders[0]?.id,
          usecaseIds:
            showType === "section" && pocUsecaseData.length
              ? newIds.filter(id =>
                  pocUsecaseData.find(({ usecase }) => usecase.id !== id)
                )
              : newIds,
          successCriteria: scToAdd,
          isBusiness: showType.type === "table"
        });
      }
      if (pocUsecaseIdsToRemove.length > 0 || scToRemove.length > 0) {
        showType !== "section" &&
          del({
            pocId,
            pocUsecaseIds: pocUsecaseIdsToRemove,
            pocSuccesscriteriaIds: scToRemove
          });
      }
      handleClose();
    },
    [
      pocUseCases,
      showType,
      usecases,
      handleClose,
      setShow,
      currentPocUseCaseIds,
      ids,
      currentPocUsecaseIdsMap,
      pocUsecaseData,
      successCriteriaIds,
      add,
      pocId,
      currentStakeholder?.id,
      poc?.stakeholders,
      del
    ]
  );

  useEffect(() => {
    if (pocUsecaseData?.length) {
      const usecaseIds = pocUsecaseData
        ?.filter(({ usecase }) => usecase && !usecase.archived)
        ?.map(({ usecaseId }) => usecaseId);
      if (!showType.value) {
        showType !== "section" && setIds(new Set(usecaseIds));
      }
      setSuccessCriteriaIds(
        usecaseIds?.reduce((accumulator, usecaseId) => {
          accumulator[usecaseId] = new Set(
            currentPocUsecaseIdsMap[usecaseId]?.pocUsecaseSuccesscriterias?.map(
              ({ successcriteriaId }) => successcriteriaId
            )
          );
          return accumulator;
        }, {})
      );
    }
  }, [pocUsecaseData, setIds, currentPocUsecaseIdsMap]);

  const getRowProps = useCallback(
    row => {
      if (row.original.archived) return { className: "archived" };
      const newSelection = ids.has(row.original.id);
      const oldSelection = currentPocUseCaseIds.has(row.original.id);
      const highlight =
        (oldSelection && !newSelection) || (!oldSelection && newSelection);
      return { className: highlight ? "active" : "" };
    },
    [currentPocUseCaseIds, ids]
  );
  const renderSuccessCriteria = useCallback(
    ({ row }) => {
      if (row?.original?.successcriterias.length === 0) return null;
      return (
        <NestedSuccessCriteriaTable
          data={sortBy(row?.original?.successcriterias, "body")}
          pocUsecaseId={currentPocUsecaseIdsMap[row?.original?.id]?.id}
          usecaseId={row?.original?.id}
          onAdd={handleAddSuccessCriteria}
          onRemove={handleRemoveSuccessCriteria}
          parentChecked={ids.has(row?.original?.id)}
        />
      );
    },
    [
      handleAddSuccessCriteria,
      handleRemoveSuccessCriteria,
      currentPocUsecaseIdsMap,
      ids
    ]
  );

  let data = memoizedData;
  if (showType === "section") {
    data = memoizedData;
  } else if (showType?.type === "table") {
    data = UnAssignedBusiness;
  } else if (showType === "currentEdit") {
    data = currentEdit;
  } else if (useCaseByProduct) {
    data = useCaseByProduct.filter(x => !PocUseCaseIds.has(x.id));
  } else if (showType === "add") {
    data = Unselected;
  }

  const [filter, setfilter] = useState({ tag: "", scope: "This Opportunity" });

  const { tags } = useOption("usecase");

  const filterOptions = useMemo(
    () => [
      {
        label: "Tag",
        defaultValue: "Show All",
        options: tags
      },
      {
        label: "Scope",
        defaultValue: "This Opportunity",
        options: [
          { label: "This Opportunity", value: "This Opportunity" },
          { label: "All Requirements", value: "All Requirements" }
        ]
      }
    ],
    [tags]
  );

  const displayData = useMemo(() => {
    const { tag, scope } = filter;

    let filteredData = searchValue ? searchResult : memoizedData;
    if (tag) {
      filteredData = filteredData.filter(item => item.templates?.includes(tag));
    }
    if (scope === "This Opportunity") {
      filteredData = filteredData.filter(
        item =>
          item?.createdViaPocId === pocId || item?.visibility === "Company-wide"
      );
    }

    return filteredData;
  }, [memoizedData, searchResult, searchValue, filter]);

  if (
    isLoading ||
    pocUsecaseIsLoading ||
    batchAddIsLoading ||
    batchDeleteIsLoading
  ) {
    return <Loading />;
  }

  return (
    <>
      <section
        className={`card w-75 h-auto min-h-50screen max-h-screen p-3 is-relative ${
          isEditModalOpen ? "hidden" : ""
        }`}
      >
        <div className="p-3">
          <nav className="columns">
            <div className="column is-flex is-align-items-center">
              <h1 className="is-size-4">
                {showType === "currentEdit"
                  ? "Edit Requirements"
                  : "Import Requirements"}
              </h1>
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="ml-3 button is-info is-small"
              >
                Create new Requirement
              </button>
            </div>
            <div className="column">
              <SearchBox
                dataSet={data}
                keys={["body", "notes", "templates"]}
                setDataSet={setSearchResult}
                initialSearchValue={searchValue}
                setLiftedSearchValue={setSearchValue}
              />
            </div>
          </nav>
        </div>

        <div className="ml-3 is-flex mb-5" style={{ gap: "1rem" }}>
          {filterOptions.map((item, idx) => (
            <SelectFilter
              key={idx}
              filter={filter}
              setFilter={setfilter}
              defaultValue={item.options[0]}
              controlLabel={item.label}
              options={item.options}
            />
          ))}
        </div>

        <div className="overflow-auto p-3">
          {displayData.length ? (
            <Table
              className="PocUseCases-table PocUseCasesBulkAttach mb-3 overflow-y-auto"
              columns={bulkAttachColumns}
              data={displayData}
              extraCellInfo={{
                ids,
                setIds,
                toggleId,
                existingIds: currentPocUseCaseIds,
                showType: showType
              }}
              getRowProps={getRowProps}
              renderExpanded={renderSuccessCriteria}
            />
          ) : (
            <NoDataBlock
              message={searchValue ? "No items match your search" : "No items"}
            />
          )}
        </div>

        <div className="px-3 pb-3 is-justify-content-end buttons">
          <button
            className="button is-info is-uppercase"
            onClick={
              showType.type !== "table"
                ? updateUseCase
                : handleAssignBusinessUsecase
            }
          >
            {showType === "add" || showType.type === "table"
              ? "Add Selected Requirements"
              : "Update"}
          </button>
          <button onClick={handleClose} className="button is-uppercase">
            Cancel
          </button>
        </div>
      </section>

      <Modal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        title={`Create New Requirement`}
        body={
          <EditUsecaseForm
            usecase={{ body: searchValue }}
            onSuccess={newCreated => {
              setIsCreateModalOpen(false);
              handleClose();
              newCreated?.id ? updateUseCase(newCreated) : updateUseCase();
            }}
            onCancel={() => {
              setIsCreateModalOpen(false);
              handleClose();
            }}
          />
        }
      />
    </>
  );
};

export default PocUseCasesBulkAttach;
