import axios from "axios";
import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import usePocs from "hooks/usePocs";
import useAuth from "hooks/useAuth";
import { baseUrl, capitalize } from "utils/helpers";
import useFeatureFilter from "hooks/useFeatureFilter";
import useRbac from "../common/Rbac/useRbac";
import { UserPermissions } from "../../config/userPermissions";
import Modal from "components/common/Modal/index";
import { useDeletePocMutation } from "services/api";
import PocBasicInfoForm from "pages/PocOverview/PocBasicInfoForm";
import { UserRoles } from "config/userRoles";
import { saveAs } from "file-saver";
import TopBarExportWord from "./TopBarExportWord";
import "./TopBarStyle.css";
import { TypeExport } from "./TopBarExportWord";
import SyncSalesforce from "pages/Dashboard/SyncSalesforce";

export default function TopBarMenu() {
  const { token, currentUser } = useAuth();
  const { currentPoc } = usePocs();
  const navigate = useNavigate();
  const payloadReq = {
    responseType: "blob",
    headers: {
      Authorization: `bearer ${token}`,
      "x-tenant-id": currentUser.vendorId
    }
  };

  const [deletePoc, { isLoading: isDeletePocLoading }] = useDeletePocMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSyncSalesforceOpen, setIsSyncSalesforceOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const { showPocExportPdf } = useFeatureFilter();

  const { userHasPermission, userHasRole } = useRbac();

  const handleDeletePoc = async () => {
    const poc = { ...currentPoc };
    setIsDeleteModalOpen(false);
    const response = await deletePoc({ id: currentPoc.id });
    if (!response.error) {
      navigate("/dashboard");
      toast.success(`Opportunity ${poc.pocName} was deleted`);
    }
  };

  const exportPDF = async (items = "") => {
    setIsFetching(true);
    const poc = currentPoc;

    const queryUrl = `${baseUrl}/pocs/${poc.id}/pdf`;
    const fileName = `${poc.pocName}.pdf`;

    axios
      .post(queryUrl, { sections: items }, payloadReq)
      .then(response => {
        saveAs(response.data, fileName);
      })
      .catch(error => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsFetching(false);
        setIsExportModalOpen(false);
      });
  };

  const exportWord = async (items = "") => {
    setIsFetching(true);
    const poc = currentPoc;
    const queryUrl = `${baseUrl}/pocs/${poc.id}/word`;
    const fileName = `${poc.pocName}.docx`;

    axios
      .post(queryUrl, { sections: items }, payloadReq)
      .then(response => {
        saveAs(response.data, fileName);
      })
      .catch(error => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsFetching(false);
        setIsExportModalOpen(false);
      });
  };

  const exportFunc = (type, items) => {
    if (type === TypeExport.PDF) {
      exportPDF(items);
    } else {
      exportWord(items);
    }
  };

  return (
    <React.Fragment>
      <Menu
        as="div"
        className="is-size-6 dropdown is-active mx-4"
        style={{ verticalAlign: "inherit" }}
      >
        <Menu.Button as="div" className="dropdown-trigger is-flex">
          <ThreeDotsVertical size="20" />
        </Menu.Button>
        <Menu.Items
          as="div"
          className="dropdown-menu p-3 w-auto whitespace-nowrap "
          style={{ outlineStyle: "none" }}
        >
          {userHasRole([
            UserRoles.ADMIN,
            UserRoles.SE_MANAGER,
            UserRoles.SALES_ENGINEER
          ]) && (
            <Menu.Item
              as="div"
              onClick={() => setIsEditModalOpen(true)}
              className="is-clickable mb-2"
            >
              Edit details
              <hr className="my-2" />
            </Menu.Item>
          )}

          {userHasPermission(UserPermissions.EVAL_READ) && showPocExportPdf && (
            <>
              <Menu.Item
                as="div"
                onClick={() => setIsExportModalOpen(true)}
                className="is-clickable mb-2"
              >
                Export Opportunity
              </Menu.Item>
              <hr className="my-2" />
              <Menu.Item
                as="div"
                onClick={() => setIsSyncSalesforceOpen(true)}
                className="is-clickable mb-2"
              >
                Sync with Salesforce
              </Menu.Item>
            </>
          )}
          {userHasPermission(UserPermissions.EVAL_DELETE) && (
            <Menu.Item
              as="div"
              onClick={() => setIsDeleteModalOpen(true)}
              className="is-clickable mb-2"
            >
              <hr className="my-2" />
              <Trash className="mr-2" />
              Delete Opportunity
            </Menu.Item>
          )}
        </Menu.Items>
      </Menu>

      <Modal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        title={`Editing Opportunity "${currentPoc?.pocName}"`}
        overflowAuto={false}
        body={
          <PocBasicInfoForm
            poc={currentPoc}
            onSuccess={() => {
              setIsEditModalOpen(false);
            }}
            onCancel={() => {
              setIsEditModalOpen(false);
            }}
          />
        }
        buttons={[]}
      />

      <Modal
        isOpen={isExportModalOpen}
        setIsOpen={setIsExportModalOpen}
        title={`Choose sections`}
        body={
          <TopBarExportWord
            onSuccess={(typeExport, selectedItems) => {
              exportFunc(typeExport, selectedItems);
            }}
            onCancel={() => {
              setIsExportModalOpen(false);
            }}
            isFetching={isFetching}
          />
        }
        buttons={[]}
        className="popup-export"
        classTitle="title"
        overflowAuto={false}
      />

      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={`Deleting Opportunity "${currentPoc?.pocName}"?`}
        body="You're about to permanently delete this opportunity, and its data."
        type="danger"
        buttons={[
          <button
            onClick={() => handleDeletePoc()}
            className={`button is-danger ${
              isDeletePocLoading ? "is-loading" : ""
            }`}
          >
            Delete
          </button>,
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="button is-light"
          >
            Cancel
          </button>
        ]}
      />

      <SyncSalesforce
        pocs={[currentPoc]}
        show={isSyncSalesforceOpen}
        close={() => setIsSyncSalesforceOpen(false)}
      />
    </React.Fragment>
  );
}
