import { useCallback, useMemo, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useLazyGetExternalSearchQuery } from "services/api";
import ModalLinkExternal from "./ModalLinkExternal";

const CurrentlyLinkedItem = ({ feedback, providers }) => {
  const [searchExternal, { data }] = useLazyGetExternalSearchQuery();

  const currentLinkedProvider = useMemo(
    () =>
      providers.find(
        ({ ticker }) => ticker === feedback?.linkedItemProviderTicker
      ),
    [feedback, providers]
  );

  const dataFilter = useCallback(
    ({ id }) => id === feedback?.linkedItemId,
    [feedback]
  );

  useEffect(() => {
    if (currentLinkedProvider?.vendorProviderId) {
      searchExternal({
        id: currentLinkedProvider?.vendorProviderId,
        query: ""
      });
    }
  }, [searchExternal, currentLinkedProvider?.vendorProviderId]);

  return (
    <>
      {currentLinkedProvider ? (
        <div className="p-4 mb-4 BugAndFeedbackList-current-link">
          <span>
            Currently linked to:{" "}
            <img
              src={currentLinkedProvider.icon}
              alt={`${currentLinkedProvider.title} logo`}
              className="h-4r mx-1"
            />
            {currentLinkedProvider.title}
            <Routes>
              <Route
                path={`:feedbackId/:ticker`}
                element={
                  <ModalLinkExternal
                    data={data}
                    feedback={feedback}
                    providerId={currentLinkedProvider?.providerId}
                    vendorProviderId={currentLinkedProvider?.vendorProviderId}
                    headerHidden
                    dataFilter={dataFilter}
                  />
                }
              />
            </Routes>
          </span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CurrentlyLinkedItem;
