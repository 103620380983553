import { useCallback } from "react";
import { useParams } from "react-router-dom";
import TableCellSelectableStakeholder from "components/common/TableCells/TableCellSelectableStakeholder";
import Joi from "joi-browser";
import useForm from "hooks/useForm";
import Input from "components/common/Input";
import { useAddFeedbacksMutation } from "services/api";

const schema = {
  body: Joi.string().optional().allow("").label("Feedback")
};

const initialState = {
  body: ""
};

const NewFeedbackRow = ({ currentUserStakeholder }) => {
  const { pocId } = useParams();
  const { validate, handleChange, data, errors, reset } = useForm(
    schema,
    initialState
  );
  const [addFeedback] = useAddFeedbacksMutation();

  const handleSubmit = useCallback(async () => {
    const { error, value } = validate();
    if (Object.keys(error).length === 0 && value.body?.trim()) {
      addFeedback({
        body: value.body,
        tags: [],
        pocId,
        stakeholderId: currentUserStakeholder?.id
      });
      reset();
    }
  }, [addFeedback, currentUserStakeholder, pocId, validate, reset]);

  const checkForSubmit = useCallback(
    e => {
      if (e.code === "Enter" || e.code === "NumpadEnter") handleSubmit();
    },
    [handleSubmit]
  );

  return (
    <tr className="Feedback-new">
      <td>
        <TableCellSelectableStakeholder value={currentUserStakeholder} />
      </td>
      <td colSpan="7">
        <Input
          name="body"
          inputClassName="input"
          error={errors.body}
          value={data.body}
          placeholder="Type a new feedback here + Enter"
          onChange={handleChange}
          onBlur={handleSubmit}
          onKeyPress={checkForSubmit}
          spellcheck
        />
      </td>
    </tr>
  );
};

export default NewFeedbackRow;
