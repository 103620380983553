import { v4 as uuid4 } from "uuid";
import api from "./_createApi";

export default !!api &&
  api
    .enhanceEndpoints({ addTagTypes: ["Feedbacks", "AggregateFeedbacks"] })
    .injectEndpoints({
      endpoints: build => ({
        getFeedbacks: build.query({
          query: pocId => `/pocs/${pocId}/feedbacks`,
          providesTags: (result, _, pocId) =>
            result
              ? [
                  ...result.map(({ id }) => ({ type: "Feedbacks", id })),
                  { type: "Feedbacks", id: `${pocId}-LIST` }
                ]
              : [{ type: "Feedbacks", id: `${pocId}-LIST` }],
          transformResponse: response =>
            response.sort((fb1, fb2) => {
              if (fb1.createdAt < fb2.createdAt) return 1;
              if (fb1.createdAt > fb2.createdAt) return -1;
              if (fb1.body < fb2.body) return 1;
              if (fb1.body > fb2.body) return -1;
              return 0;
            })
        }),
        getAllFeedbacks: build.query({
          query: () => `/feedbacks`,
          providesTags: [{ type: "Feedbacks", id: "LIST" }]
        }),
        addFeedbacks: build.mutation({
          query: body => ({
            url: "/feedbacks",
            method: "POST",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
            const tempId = uuid4();
            const postResult = dispatch(
              api.util.updateQueryData("getFeedbacks", body.pocId, draft => {
                draft.unshift({
                  ...body,
                  createdAt: new Date().toISOString(),
                  id: tempId,
                  type: "Issue",
                  commentCount: 0,
                  priority: "Low",
                  stakeholder: getState().auth.user
                });
              })
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: (result, error) => {
            if (error) return [];
            return [
              { type: "Feedbacks", id: `${result.pocId}-LIST` },
              "AggregateFeedbacks"
            ];
          }
        }),
        editFeedback: build.mutation({
          query: ({ id, ...body }) => {
            const patchBody = {};
            const permittedKeys = [
              "body",
              "type",
              "tags",
              "priority",
              "status",
              "notes",
              "dueDate",
              "addressed",
              "stakeholderId",
              "parentFeedbackId"
            ];
            permittedKeys.forEach(key => (patchBody[key] = body[key]));
            return {
              url: `/feedbacks/${id}`,
              method: "PATCH",
              body: patchBody
            };
          },
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              api.util.updateQueryData("getFeedbacks", body.pocId, draft => {
                const feedback = draft.find(
                  feedback => feedback.id === body.id
                );
                if (feedback) {
                  for (let key in body) {
                    feedback[key] = body[key];
                  }
                }
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          },
          invalidatesTags: ({ id }) => [
            { type: "Feedbacks", id },
            "AggregateFeedbacks"
          ]
        }),
        deleteFeedback: build.mutation({
          query: ({ id }) => ({
            url: `/feedbacks/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted({ id, pocId }, { dispatch, queryFulfilled }) {
            const deleteResult = dispatch(
              api.util.updateQueryData("getFeedbacks", pocId, draft => {
                const feedbackIdx = draft.findIndex(
                  feedback => feedback.id === id
                );
                if (feedbackIdx > -1) {
                  draft.splice(feedbackIdx, 1);
                }
              })
            );
            try {
              await queryFulfilled;
            } catch {
              deleteResult.undo();
            }
          },
          invalidatesTags: ({ id }) => [
            { type: "Feedbacks", id },
            "AggregateFeedbacks"
          ]
        }),
        linkFeedbackToExternalItem: build.mutation({
          query: ({ id, ...body }) => ({
            url: `/feedbacks/${id}/linkToItem`,
            method: "PATCH",
            body
          }),
          invalidatesTags: (_result, _error, arg) => {
            const { id } = arg;
            return [
              { type: "Feedbacks", id },
              "AggregateFeedbacks",
              { type: "Feedbacks", id: "LIST" }
            ];
          }
        }),
        unlinkFeedbackToExternalItem: build.mutation({
          query: ({ id }) => ({
            url: `/feedbacks/${id}/unlinkFromItem`,
            method: "PATCH"
          }),
          invalidatesTags: ({ id }) => [
            { type: "Feedbacks", id },
            "AggregateFeedbacks",
            { type: "Feedbacks", id: "LIST" }
          ]
        }),
        getFeedbackByExternalItems: build.query({
          query: filter => {
            let url = "/analytics/feedbacks/byExternalItems";
            if (filter.length > 0) {
              url += filter;
            }
            return url;
          },
          providesTags: ["AggregateFeedbacks"]
        })
      })
    });
