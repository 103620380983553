import { useTable, useSortBy } from "react-table";
import { CaretUpFill, CaretDownFill } from "react-bootstrap-icons";
import { currency, daysSince } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import PocProgress from "./PocProgress";
import TopBarScore from "components/TopBar/TopBarScore";
import PocSalesforceLinkIcon from "components/common/PocSalesforceLinkIcon";
import React from "react";
import UserAvatar from "components/common/UserAvatar";

const columns = [
  {
    Header: "Name",
    id: "Poc Name",
    accessor: poc => poc,
    width: "35%",
    Cell: props => {
      const notifications = props.row.original.notifications;
      const poc = props.value;
      const navigate = useNavigate();
      return (
        <div
          className={`columns ${notifications ? "border-left-warning" : ""}`}
        >
          <div className="column is-10 is-flex is-align-items-center">
            {/* {notifications ? (
              <ExclamationTriangleFill className="icon-notifications has-text-warning is-rounded is-size-5 mr-2" />
            ) : null}{" "} */}
            <div
              className="is-clickable py-2"
              onClick={() => navigate("/pocs/" + poc.id + "/overview")}
            >
              {poc.pocName}
            </div>
            {<PocSalesforceLinkIcon poc={poc} />}
          </div>
        </div>
      );
    }
  },
  {
    Header: "Owner",
    id: "owner",
    accessor: "createdByUser",
    Cell: ({ value: user }) => {
      return <UserAvatar user={user} showName={false} />;
    }
  },
  {
    Header: "Progress",
    accessor: "percentComplete",
    width: "15%",
    Cell: PocProgress
  },
  {
    Header: "Days",
    accessor: poc => daysSince(poc.startDate),
    width: "10%"
  },
  {
    Header: "ACV",
    className: "is-hidden-mobile",
    accessor: "amount",
    Cell: props => currency(props.value),
    width: "10%"
  },
  {
    Header: "Issues",
    accessor: "nbIssues",
    width: "10%",
    className: "is-hidden-mobile-only is-hidden-tablet-only",
    Cell: props => props.value || 0,
    hideColumn: true // Temporary hide for everyone
  },
  {
    Header: "Requests",
    accessor: "nbRequests",
    width: "10%",
    className: "is-hidden-mobile-only is-hidden-tablet-only",
    Cell: props => props.value || 0,
    hideColumn: true // Temporary hide for everyone
  },
  { Header: "Phase", accessor: "phase", width: "10%" },
  {
    Header: "Score",
    accessor: "score",
    width: "10%",
    Cell: props => (
      <TopBarScore poc={props.row.original} className="is-size-6" {...props} />
    )
  }
];

const PocTable = ({ pocs }) => {
  const { headerGroups, prepareRow, rows } = useTable(
    { columns, data: pocs },
    useSortBy
  );
  const headers = headerGroups[0].headers;

  return (
    <div className="table-container">
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            {headers
              .filter(cell => cell.hideColumn !== true)
              .map(cell => (
                <th
                  {...cell.getHeaderProps(cell.getSortByToggleProps())}
                  width={cell.width}
                  className={`has-text-grey-light has-text-weight-light	${
                    cell.className ? cell.className : ""
                  }`}
                >
                  {cell.render("Header")}
                  <span>
                    {cell.isSorted ? (
                      cell.isSortedDesc ? (
                        <CaretUpFill />
                      ) : (
                        <CaretDownFill />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr key={row.id}>
                {row.cells
                  .filter(cell => cell.column.hideColumn !== true)
                  .map(cell => {
                    return (
                      <td
                        key={cell.column.id}
                        className={`py-4 is-vcentered ${
                          cell.column.className ? cell.column.className : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PocTable;
