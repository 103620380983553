import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetQuestionsQuery,
  useGetPocQuestionsQuery,
  useAddPocQuestionsMutation,
  useGetMetaQuery
} from "services/api";
import useBatch from "hooks/useBatch";
import useAuth from "hooks/useAuth";
import Loading from "components/common/Loading";
import Table from "components/common/Table";
import { bulkAttachColumns as allColumns, prospectHeaders } from "./constants";
import { createMap } from "utils/helpers";
import CreateNewQuestionModal from "./CreateNewQuestionModal";
import SearchBox from "components/common/SearchBox";
import { NoDataBlock } from "components/common/NoData";
import SelectFilter from "components/common/SelectFilter";
import useOption from "hooks/useOption";

const PocQuestionsBulkAttach = ({
  handleClose,
  addSection = null,
  highlightedSection
}) => {
  const [isCreate, setIsCreate] = useState(false);
  const { pocId } = useParams();
  const { data: metadata } = useGetMetaQuery();
  const { data, isLoading } = useGetQuestionsQuery();
  const { data: pocQData, isLoading: pocQIsLoading } =
    useGetPocQuestionsQuery(pocId);
  const memoizedData = useMemo(() => data || [], [data]);
  const [addQs, { isLoading: batchAddIsLoading }] = useAddPocQuestionsMutation({
    fixedCacheKey: `batch-add-pocQuestions-${pocId}`
  });
  const { isProspect } = useAuth();
  const { ids, toggleId, setIds } = useBatch();
  const columns = useMemo(
    () =>
      isProspect
        ? allColumns.filter(({ Header }) => prospectHeaders.includes(Header))
        : allColumns,
    [isProspect]
  );

  const [searchValue, setSearchValue] = useState(highlightedSection || "");
  const [searchResult, setSearchResult] = useState([]);

  const questionMap = useMemo(() => createMap(data || []), [data]);
  const currentPocQuestionIds = useMemo(
    () => new Set(pocQData?.map(({ questionId }) => questionId)),
    [pocQData]
  );
  const currentPocQuestionIdsMap = useMemo(
    () => createMap(pocQData, "questionId"),
    [pocQData]
  );

  const updateQuestions = useCallback(
    newCreated => {
      const nonArchivedCurrentIds = new Set(
        data
          .filter(
            question =>
              !question.archived && currentPocQuestionIds?.has(question.id)
          )
          .map(({ id }) => id)
      );

      const [idsToAdd, idsToRemove] = data
        .map(({ id }) => id)
        .filter(({ archived }) => !archived)
        .reduce(
          ([add, remove], questionId) => {
            const shouldRemoveId =
              !ids?.has(questionId) && nonArchivedCurrentIds?.has(questionId);
            const shouldAddId =
              ids?.has(questionId) && !currentPocQuestionIds?.has(questionId);
            if (shouldRemoveId) {
              remove.push(currentPocQuestionIdsMap[questionId].id);
            } else if (shouldAddId) {
              add.push(questionId);
            }
            return [add, remove];
          },
          [[], []]
        );

      if (addSection) {
        const qsToAdd = data.filter(({ id }) => ids?.has(id));
        newCreated.id && qsToAdd.push(newCreated);
        const sectionPayload = qsToAdd
          .map(({ id, body, answers }) => {
            const inlineAnswers = answers.map(item => item.body).join(", ");
            return `<span class="embeddedObjectTag orangeTag mceNonEditable"
              data-object-type="question" data-object-id="${id}">${body}
              </span>${inlineAnswers ? `- Answers (${inlineAnswers})` : ""}
              ${qsToAdd.length > 1 ? "<br/>" : ""}`;
          })
          .join("");
        addSection(sectionPayload);
      }
      const newIds = [...idsToAdd];
      newCreated.id && newIds.push(newCreated.id);

      if (newIds.length > 0) {
        addQs(
          newIds.map(questionId => ({
            pocId,
            questionId,
            question: questionMap[questionId]
              ? questionMap[questionId]
              : newCreated
          }))
        );
      }
      handleClose();
    },
    [
      addQs,
      currentPocQuestionIds,
      currentPocQuestionIdsMap,
      data,
      ids,
      pocId,
      questionMap
    ]
  );

  const getRowProps = useCallback(
    row => {
      if (row.original.archived) return { className: "archived" };
      const newSelection = ids?.has(row.original.id);
      const oldSelection = currentPocQuestionIds?.has(row.original.id);
      const highlight =
        (oldSelection && !newSelection) || (!oldSelection && newSelection);
      return { className: highlight ? "active" : "" };
    },
    [currentPocQuestionIds, ids]
  );

  const [filter, setfilter] = useState({ tag: "" });

  const { tags } = useOption("question");

  const filterOptions = useMemo(
    () => [
      {
        label: "Tag",
        defaultValue: "Show All",
        options: tags
      }
    ],
    [tags]
  );

  const displayData = useMemo(() => {
    const { tag } = filter;
    let filteredData = searchValue ? searchResult : memoizedData;
    if (tag) {
      filteredData = filteredData.filter(item => item.templates?.includes(tag));
    }

    return filteredData;
  }, [searchResult, memoizedData, filter]);

  if (isLoading || pocQIsLoading || batchAddIsLoading) return <Loading />;

  return (
    <>
      <div
        className={`card w-75 is-relative max-h-screen p-3 ${
          isCreate ? "hidden" : ""
        }`}
      >
        <div className="p-3">
          <nav className="columns">
            <div className="column is-flex is-align-items-center">
              <h1 className="is-size-4">Import Questions</h1>
              <button
                onClick={() => setIsCreate(true)}
                className="ml-3 button is-info is-small"
              >
                Create a new Question
              </button>
            </div>
            <div className="column">
              <SearchBox
                dataSet={data}
                keys={["body", "answers.body", "templates"]}
                setDataSet={setSearchResult}
                initialSearchValue={searchValue}
                setLiftedSearchValue={setSearchValue}
              />
            </div>
          </nav>
        </div>

        <div className="ml-3 is-flex mb-5" style={{ gap: "1rem" }}>
          {filterOptions.map((item, idx) => (
            <SelectFilter
              key={idx}
              filter={filter}
              setFilter={setfilter}
              defaultValue={item.options[0]}
              controlLabel={item.label}
              options={item.options}
            />
          ))}
        </div>

        <div className="overflow-auto">
          {displayData.length ? (
            <Table
              className="PocQuestions-table PocQuestionsBulkAttach mb-3"
              style={{ maxHeight: "90vh" }}
              columns={columns}
              data={displayData}
              extraCellInfo={{
                metadata,
                toggleId,
                ids,
                setIds,
                existingIds: currentPocQuestionIds
              }}
              isPaginated
              getRowProps={getRowProps}
            />
          ) : (
            <NoDataBlock
              message={searchValue ? "No items match your search" : "No items"}
            />
          )}
        </div>
        <div className="buttons is-justify-content-end mt-2">
          <button className="button is-info" onClick={updateQuestions}>
            Add Selected Questions
          </button>
          <div onClick={() => handleClose()} className="button is-light">
            Cancel
          </div>
        </div>
      </div>
      <CreateNewQuestionModal
        modalOpen={isCreate}
        handleClose={() => {
          setIsCreate(false);
        }}
        handleCloseAdd={handleClose}
        defaultVisibility={metadata.entityVisibility.ONE_OFF}
        defaultBody={searchValue}
        updateQuestions={updateQuestions}
        isLoading={isLoading || pocQIsLoading || batchAddIsLoading}
      />
    </>
  );
};

export default PocQuestionsBulkAttach;
