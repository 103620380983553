import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { ArrowRepeat } from "react-bootstrap-icons";
import { useGetMetaQuery } from "services/api";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";

const PocStakeholderAdd = ({
  showModalAdd,
  hidenForm,
  createUser,
  updateUser,
  isCreating,
  userEdit
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [persona, setPersona] = useState("");
  const [role, setRole] = useState("");
  const { data: dataMeta } = useGetMetaQuery();
  const { isAdmin } = useAuth();

  useEffect(() => {
    if (!showModalAdd && !userEdit) {
      setDefaultData(null);
    } else {
      setDefaultData(userEdit);
    }
  }, [userEdit, showModalAdd]);

  const setDefaultData = data => {
    setFirstName(data?.firstName || "");
    setLastName(data?.lastName || "");
    setEmail(data?.email || "");
    setPersona(data?.persona || "");
    setRole(data?.roles.toString() || "");
  };

  const onSubmitHandler = () => {
    let data;

    if (typeof role === "string") {
      data = {
        email: email,
        firstName: firstName,
        lastName: lastName
      };
      if (role !== "") {
        let ele = {
          roles: [role] //adding field **roles**
        };
        data = { ...data, ...ele };
      }
      if (persona) {
        let ele = {
          persona: persona //adding field **persona**
        };
        data = { ...data, ...ele };
      }
    } else {
      data = {
        email: email,
        firstName: firstName,
        lastName: lastName
      };
    }

    if (!userEdit) {
      createUser(data);
    } else {
      updateUser({ ...data, id: userEdit?.id });
    }
  };

  return (
    <Dialog
      as="div"
      open={showModalAdd}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => null}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
      <div
        className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md"
        style={{ minWidth: "800px" }}
      >
        <div>
          <div className="is-size-4 mb-6">
            {!userEdit ? "Create a new collaborator" : "Edit collaborator"}
          </div>

          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmitHandler();
            }}
          >
            <div className="columns">
              <div className="column is-6">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <br />
                <input
                  value={email}
                  type="email"
                  className="input input-box"
                  placeholder="Email"
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-6">
                <label htmlFor="firstName" className="form-label">
                  First name
                </label>
                <br />
                <input
                  value={firstName}
                  type="text"
                  className="input input-box"
                  placeholder="First name"
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="column is-6">
                <label htmlFor="lastName" className="form-label">
                  Last name
                </label>
                <br />
                <input
                  value={lastName}
                  type="text"
                  className="input input-box"
                  placeholder="Last name"
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="columns">
              <div className="column is-6">
                <label htmlFor="persona" className="form-label">
                  Persona
                </label>
                <br />
                <div className="select">
                  <select
                    value={persona}
                    className="input-box"
                    onChange={e => {
                      setPersona(e.target.value);
                    }}
                  >
                    <option value="">Select </option>
                    {Object.keys(dataMeta?.personas || {}).map(function (
                      key,
                      index
                    ) {
                      let value = dataMeta?.personas[key];
                      return (
                        <option value={value} key={`persona${index}`}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="column is-6">
                <label htmlFor="roles" className="form-label">
                  Role
                </label>
                <br />
                <div className="select">
                  <select
                    disabled={isAdmin}
                    value={role}
                    className="form-control input-box"
                    onChange={e => {
                      setRole(e.target.value);
                    }}
                  >
                    <option value="">Select </option>
                    {Object.keys(dataMeta?.userRoles || {}).map(function (
                      key,
                      index
                    ) {
                      let value = dataMeta?.userRoles[key];
                      return (
                        value !== "Admin" && (
                          <option value={value} key={`userroles${index}`}>
                            {value}
                          </option>
                        )
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="buttons is-justify-content-end mt-6">
              {isCreating && (
                <span className="arrowloader" style={{ marginRight: "15px" }}>
                  <ArrowRepeat className="is-spinning" />
                </span>
              )}
              <button
                type="submit"
                className="button is-info"
                disabled={isCreating}
              >
                {!userEdit ? "Create collaborator" : "Save"}
              </button>
              <button
                type="button"
                className="button is-light"
                onClick={hidenForm}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};
export default PocStakeholderAdd;
