import { createMap } from "utils/helpers";
import { v4 as uuid4 } from "uuid";
import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["PocUsecases"] }).injectEndpoints({
    endpoints: build => ({
      getPocUseCases: build.query({
        query: pocId => `/pocs/${pocId}/pocUsecases`,
        providesTags: (result, _, pocId) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "PocUsecases", id })),
                { type: "PocUsecases", id: `${pocId}-LIST` }
              ]
            : [{ type: "PocUsecases", id: `${pocId}-LIST` }]
      }),
      importPocUseCase: build.mutation({
        query: ({ pocId, template }) => {
          return {
            url: `/pocs/${pocId}/pocUsecases/templates`,
            method: "POST",
            body: {
              template,
              isPreviewMode: false
            }
          };
        },
        async onQueryStarted({ pocId, ...body }, { dispatch, queryFulfilled }) {
          const result = dispatch(
            api.util.updateQueryData("getPocUseCases", pocId, draft => {
              return draft;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            result.undo();
          }
        },
        invalidatesTags: (_result, _error_, body) => [
          "PocScore",
          "PocUsecases",
          "PocUsecasesSuccesscriteria",
          { type: "PocUsecases", id: `${body.pocId}-LIST` }
        ]
      }),
      editPocUseCases: build.mutation({
        query: ({ id, ...body }) => {
          return {
            url: `/pocUsecases/${id}`,
            method: "PATCH",
            body
          };
        },
        async onQueryStarted(
          { pocId, id, ...body },
          { dispatch, queryFulfilled }
        ) {
          const result = dispatch(
            api.util.updateQueryData("getPocUseCases", pocId, draft => {
              const pocUsecaseIdx = draft.findIndex(
                element => element.id === id
              );
              draft[pocUsecaseIdx] = Object.assign(draft[pocUsecaseIdx], {
                ...body
              });
              return draft;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            result.undo();
          }
        }
      }),
      deletePocUseCases: build.mutation({
        query: ({ id, ...body }) => {
          return {
            url: `/pocUsecases/${id}`,
            method: "DELETE",
            body
          };
        },
        invalidatesTags: ({ pocId }) => [
          "PocUsecases",
          "PocUsecasesSuccesscriteria",
          { type: "PocUsecases", id: `${pocId}-LIST` }
        ]
      }),
      editMultiPocUseCases: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const response = await Promise.all(
            body.payload.map(({ id, ...b }) =>
              fetchWithBaseQuery({
                url: `/pocUsecases/${id}`,
                method: "PATCH",
                body: { pocId: body.pocId, ...b }
              })
            )
          );
          const successes = response
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = response
            .filter(res => !res.data)
            .map(res => res.error);
          return {
            data: successes.length > 0 ? successes : null,
            error: failures.length > 0 ? failures : null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const result = dispatch(
            api.util.updateQueryData("getPocUseCases", body.pocId, draft => {
              body.payload.forEach(({ id, ...el }) => {
                const pocUsecaseIdx = draft.findIndex(
                  element => element.id === id
                );
                draft[pocUsecaseIdx] = Object.assign(draft[pocUsecaseIdx], {
                  ...el
                });
              });
              return draft;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            result.undo();
          }
        },
        invalidatesTags: (_result, _error_, body) => [
          "PocUsecases",
          "PocUsecasesSuccesscriteria"
        ]
      }),
      addPocUseCasesAndSuccessCriteria: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          let pocUseCaseResponse;
          if (!body.isBusiness) {
            pocUseCaseResponse = await Promise.all(
              body.usecaseIds.map(usecaseId =>
                fetchWithBaseQuery({
                  url: "/pocUsecases",
                  method: "POST",
                  body: {
                    pocId: body.pocId,
                    usecaseId,
                    assignedToStakeholderId: body.assignedToStakeholderId
                  }
                })
              )
            );
          } else {
            pocUseCaseResponse = await Promise.all(
              body.usecaseIds.map((v, index) =>
                fetchWithBaseQuery({
                  url: "/pocUsecases",
                  method: "POST",
                  body: {
                    pocId: body.pocId,
                    usecaseId: v.id,
                    assignedToStakeholderId: body.assignedToStakeholderId,
                    businessValues: v.businessValues
                  }
                })
              )
            );
          }

          const failures = pocUseCaseResponse
            .filter(res => res.error)
            .map(res => res.error);
          if (failures.length > 0) {
            return { data: null, error: failures };
          }
          const data = pocUseCaseResponse.map(res => res.data);
          const pocUseCaseByUseCaseId = createMap(data, "usecaseId");
          const successCriteriaResponse = await Promise.all(
            body.successCriteria.map(
              ({ usecaseId, successcriteriaId, pocUsecaseId }) =>
                fetchWithBaseQuery({
                  url: "/pocUsecaseSuccesscriterias",
                  method: "POST",
                  body: {
                    pocUsecaseId:
                      pocUsecaseId || pocUseCaseByUseCaseId[usecaseId].id,
                    successcriteriaId,
                    assignedToStakeholderId: body.assignedToStakeholderId
                  }
                })
            )
          );
          const scFailures = successCriteriaResponse
            .filter(res => res.error)
            .map(res => res.error);
          if (scFailures.length > 0) {
            return { data: null, error: scFailures };
          }
          return {
            data: {
              useCases: data,
              successCritera: successCriteriaResponse.map(res => res.data)
            },
            error: null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled, getState }) {
          const addResult = dispatch(
            api.util.updateQueryData("getPocUseCases", body.pocId, draft => {
              return draft.concat(
                body.usecaseIds.map(usecaseId => ({
                  id: uuid4(),
                  usecaseId,
                  usecase: { body: "loading-from-api" },
                  pocUsecaseSuccesscriterias: []
                }))
              );
            })
          );
          try {
            await queryFulfilled;
          } catch {
            addResult.undo();
          }
        },
        invalidatesTags: (_result, _error_, body) => [
          "PocUsecases",
          "PocUsecasesSuccesscriteria",
          { type: "PocUsecases", id: `${body.pocId}-LIST` }
        ]
      }),
      deletePocUseCasesAndSuccessCriteria: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const pocSuccessCriteriaResponse = await Promise.all(
            [...new Set(body.pocSuccesscriteriaIds)].map(id =>
              fetchWithBaseQuery({
                url: `/pocUsecaseSuccesscriterias/${id}`,
                method: "DELETE"
              })
            )
          );
          const failures = pocSuccessCriteriaResponse
            .filter(res => res.error)
            .map(res => res.error);
          if (failures.length > 0) {
            return { data: null, error: failures };
          }
          const pocUseCaseResponse = await Promise.all(
            [...new Set(body.pocUsecaseIds)].map(id =>
              fetchWithBaseQuery({
                url: `/pocUsecases/${id}`,
                method: "DELETE"
              })
            )
          );
          const pocUseCaseFailures = pocUseCaseResponse
            .filter(res => res.error)
            .map(res => res.error);
          if (pocUseCaseFailures.length > 0) {
            return { data: null, error: pocUseCaseFailures };
          }
          return {
            data: {
              usecases: pocUseCaseResponse.map(({ data }) => data),
              successcritera: pocSuccessCriteriaResponse.map(({ data }) => data)
            },
            error: null
          };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            api.util.updateQueryData("getPocUseCases", body.pocId, draft => {
              return draft
                .filter(({ id }) => !body.pocUsecaseIds.includes(id))
                .map(pocUC => ({
                  ...pocUC,
                  pocUsecaseSuccesscriterias:
                    pocUC.pocUsecaseSuccesscriterias.filter(
                      ({ id }) => !body.pocSuccesscriteriaIds.includes(id)
                    )
                }));
            })
          );
          try {
            await queryFulfilled;
          } catch {
            deleteResult.undo();
          }
        },
        invalidatesTags: (_result, _error_, arg) => [
          "PocUsecases",
          "PocUsecasesSuccesscriteria",
          { type: "PocUsecases", id: `${arg.pocId}-LIST` }
        ]
      }),
      reorderPocUsecases: build.mutation({
        query: ({ pocId, ids }) => ({
          url: `pocs/${pocId}/pocUsecases/reorder`,
          method: "POST",
          body: { ids }
        }),
        invalidatesTags: (_result, _error, arg) => [
          "PocUsecases",
          { type: "PocUsecases", id: `${arg.pocId}-LIST` }
        ]
      }),
      approvalInvitePocUsecases: build.mutation({
        query: ({ pocId, approverUserIds }) => ({
          url: `pocs/${pocId}/scoping/sendForApproval`,
          method: "POST",
          body: { approverUserIds }
        }),
        invalidatesTags: (_result, _error, arg) => [
          "POCs",
          "PocUsecases",
          { type: "PocUsecases", id: `${arg.pocId}-LIST` }
        ]
      }),
      approvePocUsecases: build.mutation({
        query: ({ pocId }) => ({
          url: `pocs/${pocId}/scoping/approve`,
          method: "POST",
          body: { puScopingAgreedAt: new Date().toISOString() }
        }),
        invalidatesTags: (_result, _error, arg) => [
          "POCs",
          "PocUsecases",
          { type: "PocUsecases", id: `${arg.pocId}-LIST` }
        ]
      }),

      revisePocUsecases: build.mutation({
        query: ({ pocId }) => ({
          url: `pocs/${pocId}/scoping/revise`,
          method: "POST"
        }),
        invalidatesTags: (_result, _error, arg) => [
          "POCs",
          "PocUsecases",
          { type: "PocUsecases", id: `${arg.pocId}-LIST` }
        ]
      })
    })
  });
