import DatePicker from "react-datepicker";
import { stringToDate } from "utils/helpers";
import Error from "./Error";

const DatePickerInput = ({
  className,
  dateFormat = "yyyy-MM-dd",
  error,
  label,
  name,
  onChange,
  placeholder,
  value,
  readOnly = false
}) => (
  <div className="form-group">
    {label && (
      <>
        <label className="label" htmlFor={name}>
          {label}
        </label>
      </>
    )}
    <DatePicker
      selected={value ? stringToDate(value) : ""}
      onChange={newDate => onChange(name, newDate)}
      name={name}
      readOnly={readOnly}
      dateFormat={dateFormat}
      className={`form-control input is-small ${error ? "error" : ""} ${
        className || ""
      }`}
      placeholderText={placeholder}
      autoComplete="off"
    />
    <Error message={error} />
  </div>
);

export default DatePickerInput;
