import { useParams } from "react-router-dom";
import { useGetPocsQuery } from "services/api";

const usePocs = () => {
  const { pocId } = useParams();
  const { data, error, isLoading } = useGetPocsQuery();

  let userPocs = data ? data : [];

  const currentPoc = userPocs.find(item => item.id === pocId) || {};

  return { userPocs, isLoading, error, currentPoc };
};

export default usePocs;
