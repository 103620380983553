import { useState } from "react";
import useModalClose from "hooks/useModalClose";
import AddToSalesForceModal from "../AddToSalesForceModal";
import AddToJiraModal from "./AddToJiraModal";

const ActionsModal = ({
  addToSalesforce,
  archivedView,
  close,
  del,
  feedback,
  id,
  toggleEdit,
  toggleArchive,
  index,
  right = "7rem"
}) => {
  const modalRef = useModalClose(close);
  const [salesforceModalOpen, setSalesforceModalOpen] = useState(false);
  const [jiraModalOpen, setJiraModalOpen] = useState(false);

  return (
    <div
      ref={modalRef}
      className="ActionsModal FeedbackCell-modal drop-shadow-dark relative"
      style={{ right }}
    >
      <div
        className={`${salesforceModalOpen || jiraModalOpen ? "hidden" : ""}`}
      >
        <ul>
          <li
            className="my-2"
            onClick={() => {
              del(id);
              close();
            }}
          >
            Delete
          </li>
          {toggleEdit && !archivedView && (
            <li className="my-2" onClick={() => toggleEdit(index)}>
              Edit
            </li>
          )}
          {addToSalesforce && (
            <li className="my-2" onClick={() => setSalesforceModalOpen(true)}>
              Add to Salesforce
            </li>
          )}
          {!!feedback?.linkedItemId && (
            <li className="my-2" onClick={() => setJiraModalOpen(true)}>
              Add Comment to Jira
            </li>
          )}
          {toggleArchive && (
            <li className="my-2" onClick={() => toggleArchive(id)}>
              {archivedView ? "Reactivate" : "Archive"}
            </li>
          )}
        </ul>
      </div>
      {salesforceModalOpen && <AddToSalesForceModal feedback={feedback} />}
      {jiraModalOpen && (
        <AddToJiraModal
          feedback={feedback}
          placeholder="Type a comment here"
          primaryButtonLabel="Add Comment to Jira"
          onClose={() => {
            setJiraModalOpen(false);
            close();
          }}
        />
      )}
    </div>
  );
};

export default ActionsModal;
