import classNames from "classnames";
import { useGetPocProgressQuery } from "services/api";

const PocProgress = props => {
  const { id: pocId } = props.row.original;

  const { data: pocProgressData } = useGetPocProgressQuery(pocId);

  let progressBarClasses = classNames("progress is-small", {
    // success: percentComplete > 66,
    // warning: percentComplete >= 33 && percentComplete <= 66,
    // danger: percentComplete < 33
    "is-info": true
  });

  const pocProgress = pocProgressData?.find(item => item.pocId === pocId);
  const percentProgress = pocProgress ? pocProgress.progress : 0;

  return (
    <div className="progress-container">
      <progress
        className={progressBarClasses}
        value={percentProgress}
        min="0"
        max="100"
        title={`${percentProgress}%`}
      ></progress>
    </div>
  );
};

export default PocProgress;
