import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useGetMetaQuery } from "services/api";
import {
  useApprovalInvitePocUsecasesMutation,
  useGetStakeholdersQuery
} from "services/api";
import { useParams } from "react-router-dom";
import usePocs from "hooks/usePocs";

const SendApprovalDialog = ({ poc }) => {
  const { createdByUserId } = poc;
  const { pocId } = useParams();
  const { currentPoc } = usePocs();
  const [sendApproval, setSendApproval] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const { data } = useGetMetaQuery();

  const [
    approvalInvitePocUsecases,
    { error: errorInvite, isLoading: inviteLoading }
  ] = useApprovalInvitePocUsecasesMutation();

  const {
    data: stakeholders,
    isLoading: shListLoading,
    isFetching
  } = useGetStakeholdersQuery(pocId);

  const activeStakeholders = (stakeholders || [])?.filter(
    item =>
      item?.status !== data?.userStatuses?.INACTIVE &&
      createdByUserId !== item.userId
  );

  const handleCheck = (event, sh) => {
    let updatedList = [...inviteList];
    if (event.target.checked) {
      updatedList = [...inviteList, sh.userId];
    } else {
      updatedList = inviteList.filter(obj => obj != sh.userId);
    }

    setInviteList(updatedList);
  };

  const sendInvite = () => {
    approvalInvitePocUsecases({
      pocId: pocId,
      approverUserIds: stakeholders
        .filter(sh => inviteList.includes(sh.userId))
        .map(u => u.userId)
    })
      .unwrap()
      .then(() => {
        setInviteList([]);
        setSendApproval(false);
      })
      .catch(({ data }) =>
        toast.error(`Error while sending for approval: ${data.message}`)
      );
  };

  return (
    <>
      <button
        className={`button is-info`}
        disabled={inviteLoading}
        onClick={() => setSendApproval(true)}
      >
        Send to Customer for approval
      </button>

      <Dialog
        as="div"
        open={sendApproval}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setSendApproval(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <h1 className="is-size-4">Send for Approval</h1>
          <div className="my-5">
            {!shListLoading && activeStakeholders.length
              ? activeStakeholders.map(sh => (
                  <div key={sh.userId} className="py-1">
                    <label className="checkbox">
                      <input
                        id={sh.userId}
                        type="checkbox"
                        value={sh.userId}
                        className="larger mr-2"
                        checked={inviteList.includes(sh.userId)}
                        onChange={e => handleCheck(e, sh)}
                      />
                      {sh.fullName}
                    </label>
                  </div>
                ))
              : "Please invite a Prospect User to your project first"}
          </div>

          <div className="is-flex">
            <button
              disabled={inviteLoading || activeStakeholders.length === 0}
              onClick={() => sendInvite()}
              className="ml-3 button is-info"
            >
              Send for Approval
            </button>

            <button
              disabled={inviteLoading}
              onClick={() => setSendApproval(false)}
              className="ml-3 button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SendApprovalDialog;
