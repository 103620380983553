import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import PocStakeholderInviteSuccess from "./PocStakeholderInviteSuccess";
import NewStakeholderForm from "./NewStakeholderForm";
import PocStakeholderAutoComplete from "./PocStakeholderAutoComplete";
import {
  useInviteStakeholderMutation,
  useSearchUserAutocompleteQuery
} from "services/api";
import useAuth from "hooks/useAuth";

const PocStakeholderInvite = ({
  inviteModal,
  setInviteModal,
  successModal,
  setSuccessModal
}) => {
  const { pocId } = useParams();
  const { currentUser } = useAuth();
  const [search, setSearch] = useState("");
  const [isInviteDisabled, setIsInviteDisabled] = useState(true);
  const [newCollabModal, setNewCollabModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    pocId,
    email: "",
    firstName: "",
    lastName: "",
    persona: "",
    roles: []
  });

  const [invite, { error: errorInvite, isLoading: inviteLoading }] =
    useInviteStakeholderMutation();

  const { data: users, isLoading: loadingSearch } =
    useSearchUserAutocompleteQuery(
      {
        vendorId: currentUser.vendorId || "",
        search
      },
      { skip: !search || !isInviteDisabled }
    );

  const filterdUsers = useMemo(
    () => users?.filter(x => x.lastName !== "view") || [],
    [users]
  );

  const onCloseModal = () => {
    setIsInviteDisabled(true);
    setInviteModal(false);
    setNewCollabModal(false);
    setSearch("");
  };

  const onSelectUser = ({ email, firstName, lastName, roles, persona }) => {
    setIsInviteDisabled(false);
    setSelectedUser({
      pocId,
      email,
      firstName,
      lastName,
      roles,
      persona
    });
    setSearch(`${firstName} ${lastName} [${roles[0]}]`);
  };

  const onCreateCollaborator = () => {
    setNewCollabModal(true);
    setSearch("");
  };

  const onSubmitHandler = () => {
    invite(selectedUser);
    onCloseModal();
  };

  useEffect(() => {
    if (errorInvite) {
      toast.error(errorInvite.data.message);
    }
  }, [errorInvite]);

  return (
    <Dialog
      as="div"
      open={inviteModal}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => null}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
      {!successModal && (
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <div>
            <div className="is-size-4 mb-6">
              {newCollabModal
                ? "Create and invite a new collaborator to this Opportunity"
                : "Invite a collaborator to this Opportunity"}
            </div>
            {newCollabModal ? (
              <NewStakeholderForm
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                onSubmitHandler={onSubmitHandler}
                setNewCollabModal={setNewCollabModal}
                isLoading={inviteLoading}
              />
            ) : (
              <PocStakeholderAutoComplete
                data={filterdUsers}
                search={search}
                setSearch={setSearch}
                onSelectUser={onSelectUser}
                isInviteDisabled={isInviteDisabled}
                setIsInviteDisabled={setIsInviteDisabled}
                onCreateCollaborator={onCreateCollaborator}
                onSubmitHandler={onSubmitHandler}
                isLoading={loadingSearch}
                onCancel={onCloseModal}
                setNewCollabModal={setNewCollabModal}
              />
            )}
          </div>
        </div>
      )}
      {successModal && (
        <PocStakeholderInviteSuccess
          userCreated={inviteModal}
          setUserCreated={setInviteModal}
          successModal={successModal}
          setSuccessModal={setSuccessModal}
          setNewCollabModal={setNewCollabModal}
        />
      )}
    </Dialog>
  );
};
export default PocStakeholderInvite;
