import useAuth from "hooks/useAuth";
import { useGetMetaQuery } from "services/api";
import { Envelope, Slack } from "react-bootstrap-icons";

const FeedbackSourceChannelIcon = ({ sourceChannel }) => {
  const { isProspect } = useAuth();
  const { data: metadata } = useGetMetaQuery();

  if (isProspect) {
    return null;
  }

  let icon = "";
  switch (sourceChannel) {
    case metadata?.feedbackSourceChannel.SLACK:
      icon = <Slack />;
      break;
    case metadata?.feedbackSourceChannel.EMAIL:
      icon = <Envelope />;
      break;
  }

  return <span title={`From ${sourceChannel}`}>{icon}</span>;
};

export default FeedbackSourceChannelIcon;
