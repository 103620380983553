import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { difference } from "lodash";
import {
  useGetPocQuestionsQuery,
  useGetPocQuestionAnswersQuery,
  useAddPocQuestionAnswersMutation,
  useDeletePocQuestionAnswersMutation,
  useEditPocQuestionAnswersMutation,
  useReorderAnswersMutation,
  useReorderPocQuestionsMutation
} from "services/api";
import GettingStarted from "components/common/GettingStarted";
import Loading from "components/common/Loading";
import useAuth from "hooks/useAuth";
import { NewQuestionColumns } from "./constants";
import { createMap, reorderListAfterDragEnd } from "utils/helpers";
import { Disclosure } from "@headlessui/react";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import useBatch from "hooks/useBatch";
import Questions from "../../components/common/Questions/Questions";
import AddNewQuestion from "../../components/common/AddQuestionWizard/AddNewQuestion";
import useFeatureFilter from "hooks/useFeatureFilter";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";
import WithRbac from "../../components/common/Rbac/withRbac";

const PocQuestionsTable = ({ filter, setShowAddModal }) => {
  const { pocId } = useParams();
  const { isProspect, currentUser } = useAuth();
  const [groupedData, setGroupedData] = useState([]);
  const [freeTextFields, setFreeTextFields] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [selectedAnswersIds, setSelectedAnswersIds] = useState([]);
  const { data: pocQuestions, isLoading: questionsLoading } =
    useGetPocQuestionsQuery(pocId);
  const { data: pocQuestionAnswers, isLoading: answersLoading } =
    useGetPocQuestionAnswersQuery(pocId);
  const [addAnswers, { isLoading: isAdd }] =
    useAddPocQuestionAnswersMutation(pocId);
  const [editAnswers, { isLoading: isEdit }] =
    useEditPocQuestionAnswersMutation();
  const [deleteAnswers, { isLoading: isDelete }] =
    useDeletePocQuestionAnswersMutation();
  const [reorderPocQuestions, { isLoading: isorderLoading }] =
    useReorderPocQuestionsMutation();
  const [reorderAnswers, { isLoading: isorderAnswerLoading }] =
    useReorderAnswersMutation();
  const { ids, toggleId, setIds } = useBatch();

  const { showExtraFeatures } = useFeatureFilter();

  const { userHasPermission } = useRbac();

  useEffect(() => {
    if (pocQuestions && pocQuestionAnswers) {
      const selected =
        pocQuestions?.map(pocQuestion => {
          const selectedAnswers =
            pocQuestionAnswers.filter(
              x => x.pocQuestionId === pocQuestion.id
            ) || [];
          const winrate = selectedAnswers.map(x => x.answer.winRate);
          return {
            ...pocQuestion,
            // expanded: !inititalIds.includes(pocQuestion.id),
            selectedAnswers,
            answerValue: selectedAnswers.map(y => y.body)?.join(", "),
            winrateValue: winrate.length
              ? winrate.reduce((a, b) => a + b, 0) / winrate.length
              : null
          };
        }) || [];
      const mapByAnswerId = createMap(pocQuestionAnswers || [], "answerId");
      setIds(new Set(Object.keys(mapByAnswerId)));
      setGroupedData(selected);
    }
  }, [pocQuestions, pocQuestionAnswers, pocId]);

  useEffect(() => {
    let filteredData = {
      pending: groupedData.filter(x => !x.selectedAnswers.length) || [],
      answered: groupedData.filter(x => x.selectedAnswers.length) || []
    };
    setFilteredData(filteredData);
    let allAnswers = [];
    filteredData.answered?.map(x =>
      x?.selectedAnswers?.map(item => allAnswers.push(item.answerId))
    );
    let newFreeText = {};
    filteredData.answered?.map(item => {
      item?.selectedAnswers?.map(a => {
        newFreeText[a.answerId] = a.body;
      });
    });
    setFreeTextFields(newFreeText);
    setSelectedAnswersIds(allAnswers);
  }, [groupedData]);

  const onDragEndQuestions = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let findKey = result.draggableId.split("-")[0];
    const items = reorderListAfterDragEnd(
      filteredData[findKey],
      result.source.index,
      result.destination.index
    );
    let tempFiltered = { ...filteredData };
    tempFiltered[findKey] = items;
    setFilteredData(tempFiltered);
    reorderPocQuestions({
      pocId: pocId,
      ids: tempFiltered[findKey].map(item => item.id)
    });
  };

  const onDragEndAnswers = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let findKey = result.draggableId.split("*")[0];
    let questionId = result.draggableId.split("*")[2];
    let tempFiltered = { ...filteredData };
    let foundQuestion = tempFiltered[findKey].find(
      item => item.questionId === questionId
    );

    if (foundQuestion) {
      let items = reorderListAfterDragEnd(
        foundQuestion["question"].answers,
        result.source.index,
        result.destination.index
      );
      foundQuestion.question = {
        ...foundQuestion.question,
        answers: items
      };
      setFilteredData(tempFiltered);
      reorderAnswers({
        vendorId: currentUser.vendorId,
        ids: items.map(item => item.id)
      });
    }
  };
  const getInitialExpanded = data => {
    return data.reduce((x, r, i) => {
      x[i] = true;
      return x;
    }, {});
  };

  const onCancel = () => {
    const answeredIds = pocQuestionAnswers.map(x => x.answerId);
    setIds(new Set(answeredIds));
    setSelectedAnswersIds([...new Set(answeredIds)]);
  };

  const onSave = () => {
    const checkedIds = selectedAnswersIds;
    const answeredIds = pocQuestionAnswers.map(x => x.answerId);
    const addIds = difference(checkedIds, answeredIds);
    const deleteIds = difference(answeredIds, checkedIds);
    if (addIds.length) {
      const payload = pocQuestions.reduce((s, q) => {
        for (const a of q.question.answers) {
          if (addIds.includes(a.id)) {
            s.push({
              pocQuestionId: q.id,
              answerId: a.id,
              body: freeTextFields[a.id] || a.body
            });
          }
        }
        return s;
      }, []);
      addAnswers(payload);
    }
    if (deleteIds.length) {
      const payload = pocQuestionAnswers.reduce((s, x) => {
        if (deleteIds.includes(x.answerId)) s.push({ id: x.id });
        return s;
      }, []);
      deleteAnswers(payload);
    } else {
      for (const x of pocQuestionAnswers) {
        if (
          answeredIds.includes(x.answerId) &&
          x.isFreeText &&
          x.body !== freeTextFields[x.answerId]
        ) {
          editAnswers({ id: x.id, body: freeTextFields[x.answerId] });
        }
      }
    }
  };

  if (questionsLoading || answersLoading) return <Loading />;
  return pocQuestions?.length > 0 ? (
    <>
      <AddNewQuestion />
      {Object.keys(filteredData).map(key => {
        return (
          <Disclosure
            as="div"
            key={key}
            className="box mb-5"
            defaultOpen={true}
          >
            {({ open }) => (
              <>
                <Disclosure.Button
                  as="div"
                  className="is-flex is-align-items-center has-background-white is-clickable"
                >
                  {!!filteredData[key].length && open ? (
                    <ChevronDown className="is-size-7" />
                  ) : (
                    <ChevronRight className="is-size-7" />
                  )}
                  <div className="is-size-5 ml-3">
                    <span className="is-capitalized">{key}</span> Questions{" "}
                    <span className="tag is-info is-light ml-2">
                      {filteredData[key].length}
                    </span>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel>
                  {!!filteredData[key].length && (
                    <Questions
                      columns={
                        isProspect
                          ? NewQuestionColumns.filter(
                              item => item.isProspectUser
                            )
                          : NewQuestionColumns
                      }
                      questionData={filteredData[key]}
                      onDragEndQuestions={onDragEndQuestions}
                      filterKey={key}
                      onDragEndAnswers={onDragEndAnswers}
                      toggleId={toggleId}
                      selectedAnswersIds={selectedAnswersIds}
                      setSelectedAnswersIds={setSelectedAnswersIds}
                      freetextFields={freeTextFields}
                      setFreeTextFields={setFreeTextFields}
                    />
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      })}
      <div className="is-flex is-justify-content-end mt-2">
        {userHasPermission(UserPermissions.EVALQUESTIONNAIRE_ANSWER) && (
          <>
            <button className="mx-1 button is-info" onClick={e => onSave()}>
              Save
            </button>
            {showExtraFeatures && (
              <>
                <button className="mx-1 button is-secondary" onClick={onCancel}>
                  Cancel
                </button>
                <button className="mx-1 button is-info">Submit</button>
              </>
            )}
          </>
        )}
      </div>
    </>
  ) : (
    <WithRbac permissionOneOf={UserPermissions.EVALQUESTIONNAIRE_CREATE}>
      <GettingStarted
        message="Start your project by adding a question!"
        buttonText="Import Questions"
        setShow={() => setShowAddModal(true)}
      />
    </WithRbac>
  );
};

export default PocQuestionsTable;

// useEffect(() => {
//   if (pocQuestions && pocQuestionAnswers) {
//     const selected =
//       pocQuestions?.map(pocQuestion => {
//         const selectedAnswers =
//           pocQuestionAnswers.filter(
//             x => x.pocQuestionId === pocQuestion.id
//           ) || [];
//         const winrate = selectedAnswers.map(x => x.answer.winRate);
//         return {
//           ...pocQuestion,
//           // expanded: !inititalIds.includes(pocQuestion.id),
//           selectedAnswers,
//           answerValue: selectedAnswers.map(y => y.body)?.join(", "),
//           winrateValue: winrate.length
//             ? winrate.reduce((a, b) => a + b, 0) / winrate.length
//             : null
//         };
//       }) || [];

//     if (!inititalIds.length) {
//       setInitialIds(selected.map(x => x.id));
//       setGroupedData(selected);
//     } else {
//       let newGroupedData = [];
//       for (const s of selected) {
//         const idsExist = inititalIds.includes(s.id);
//         const dataExist = groupedData.find(x => x.id === s.id);
//         if (idsExist && dataExist) {
//           // question is exist in first load
//           dataExist.answerValue = s.answerValue;
//           dataExist.winrateValue = s.winrateValue;
//           newGroupedData.push(dataExist);
//         }
//         if (!idsExist) {
//           // questions added / removed
//           newGroupedData.push(s);
//           setInitialIds(newGroupedData.map(x => x.id));
//         }
//       }
//       setGroupedData(newGroupedData);
//     }
//   }
// }, [pocQuestions, pocQuestionAnswers, pocId]);
