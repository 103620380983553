/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowRepeat } from "react-bootstrap-icons";

import usePocs from "hooks/usePocs";
import PocTable from "./PocTable";
import "./Dashboard.css";
import NiceTabs from "components/common/NiceTabs";
import APIError from "components/common/APIError";
import { LoadingCentered } from "components/common/Loading";
import GettingStarted from "components/common/GettingStarted";
import { useAddPocMutation, useGetMetaQuery } from "services/api";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";
import { Dialog } from "@headlessui/react";
import SyncSalesforce from "./SyncSalesforce";
import useAuth from "hooks/useAuth";

const Dashboard = ({ type }) => {
  const { currentUser } = useAuth();
  // const [page, setPage] = useState({ active: 0, perPage: 10, total: 0 });
  // const [paginatedData, setPaginatedData] = useState([]);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const { userPocs, isLoading, error } = usePocs();
  const [createPoc, { isLoading: createIsLoading }] = useAddPocMutation();
  const { data: metadata } = useGetMetaQuery();

  const navigate = useNavigate();

  const [archivePOC, activePOC] = useMemo(
    () =>
      userPocs.reduce(
        (aggregate, poc) => {
          // Showing as archived any POC with the archived flag, OR with a status "Completed"
          if (poc.archived || poc.phase === metadata?.pocPhases?.COMPLETED) {
            aggregate[0].push(poc);
          } else {
            aggregate[1].push(poc);
          }
          return aggregate;
        },
        [[], []]
      ),
    [userPocs]
  );
  const currentData = (type === "active" ? activePOC : archivePOC).filter(
    x => x.vendorId === currentUser.vendorId
  );

  const salesforcePocs = currentData.filter(
    x =>
      x.salesforceOpportunityId &&
      x.createdByUser.salesforceIdentity &&
      x.vendorId === currentUser.vendorId
  );

  const tabs = [
    { urlTo: "/dashboard", title: `Active`, counter: activePOC.length },
    {
      urlTo: "/dashboard/archived",
      title: `Completed`,
      counter: archivePOC.length
    }
  ];

  const handleCreateNewPoc = () => {
    createPoc({ pocName: "New Opportunity", companyName: "Company X" });
  };

  // useEffect(() => {
  //   if (currentData.length) {
  //     setPage({ ...page, active: 1, total: currentData.length });
  //     const slicedData = currentData.slice(0, page.perPage);
  //     setPaginatedData(slicedData);
  //   }
  // }, [type, activePOC, archivePOC]);

  // const loadMoreData = () => {
  //   const newPage = { ...page };
  //   const activeData = type === "active" ? activePOC : archivePOC;
  //   const totalPage = newPage.total / newPage.perPage;
  //   if (newPage.active < totalPage) {
  //     newPage.active = newPage.active + 1;
  //     setPage(newPage);

  //     const start = newPage.active * newPage.perPage;
  //     const end = (newPage.active + 1) * newPage.perPage;
  //     const slicedData = activeData.slice(start, end);
  //     const newData = [...paginatedData, ...slicedData];
  //     setPaginatedData(newData);
  //   }
  // };

  return (
    <div className="poc-dashboard">
      <NiceTabs tabs={tabs} className="is-centered" />
      <div className="card">
        <div className="card-content">
          <APIError error={error} />
          <WithRbac permissionOneOf={UserPermissions.EVAL_CREATE}>
            <div className="is-flex is-justify-content-space-between is-align-items-start">
              <div className="buttons">
                <button
                  className={`button is-info ${
                    createIsLoading ? "is-loading" : ""
                  }`}
                  onClick={() => handleCreateNewPoc()}
                >
                  + New Opportunity
                </button>
                <NavLink
                  to={"/pocs/new/salesforce"}
                  className="button is-secondary"
                >
                  Import from Salesforce
                </NavLink>
              </div>
              <div className="buttons">
                <button
                  className="button is-secondary"
                  onClick={() => setShowSyncModal(true)}
                >
                  <ArrowRepeat className="mr-2" /> Sync with Salesforce
                </button>
              </div>
            </div>
          </WithRbac>
          <div>
            {isLoading ? (
              <LoadingCentered />
            ) : currentData.length ? (
              <PocTable pocs={currentData} />
            ) : (
              <GettingStarted
                message="Create your first Opportunity"
                buttonText="Import from Salesforce"
                setShow={() => navigate("/pocs/new/salesforce")}
              />
            )}
          </div>
        </div>
      </div>

      <SyncSalesforce
        pocs={salesforcePocs}
        show={showSyncModal}
        close={() => setShowSyncModal(false)}
      />

      {/* {!!page.total && (
        <div className="w-100 has-text-centered">
          <button className="mt-4 button" onClick={() => loadMoreData()}>
            Load More
          </button>
        </div>
      )} */}
    </div>
  );
};

export default Dashboard;
