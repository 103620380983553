import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Stakeholders"] }).injectEndpoints({
    endpoints: build => ({
      inviteStakeholder: build.mutation({
        query: ({ pocId, ...body }) => ({
          url: `/pocs/${pocId}/invite`,
          method: "POST",
          body
        }),
        async onQueryStarted(body, { queryFulfilled }) {
          await queryFulfilled;
        },
        invalidatesTags: (_result, _error_, { pocId }) => [
          "Stakeholders",
          { type: "POCs", id: pocId }
        ]
      }),
      getStakeholders: build.query({
        query: pocId => `/pocs/${pocId}`,
        transformResponse: response => {
          const stakeholders = response.stakeholders || [];
          stakeholders.sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
          });
          return stakeholders;
        },
        providesTags: ["Stakeholders"]
      }),
      editStakeholder: build.mutation({
        query: ({ id, ...body }) => ({
          url: `/stakeholders/${id}`,
          method: "PATCH",
          body
        }),
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData("getStakeholders", body.pocId, draft => {
              const data = draft.find(x => x.id === body.id);
              for (let key in body) {
                data[key] = body[key];
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: ["Stakeholders"]
      }),
      deleteStakeholder: build.mutation({
        query: ({ id }) => ({
          url: `/stakeholders/${id}`,
          method: "DELETE"
        }),
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData("getStakeholders", body.pocId, draft => {
              const index = draft.findIndex(x => x.id === body.id);
              draft.splice(index, 1);
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: (_result, _error_, { pocId }) => [
          "Stakeholders",
          { type: "POCs", id: pocId }
        ]
      })
    })
  });
