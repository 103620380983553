import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useRef } from "react";

const PocStakeholderAutoComplete = ({
  data,
  search,
  setSearch,
  isInviteDisabled,
  setIsInviteDisabled,
  onSelectUser,
  onCancel,
  onSubmitHandler,
  isLoading,
  setNewCollabModal
}) => {
  const refInput = useRef(null);
  useEffect(() => {
    if (!isInviteDisabled) {
      refInput.current.value = search;
    }
  }, [isInviteDisabled, search]);

  return (
    <div>
      <div className="my-5">
        <div className={`control ${isLoading ? "is-loading" : ""}`}>
          <input
            ref={refInput}
            type="text"
            className="input search-input"
            onChange={debounce(e => {
              setSearch(e.target.value);
              setIsInviteDisabled(true);
            }, 500)}
          />
        </div>

        {isInviteDisabled && search && (
          <ul className="list-group mt-2 autocomplete-container">
            {data?.length ? (
              data?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="list-group-item"
                    onClick={() => onSelectUser(item)}
                  >
                    {item.firstName} {item.lastName} [{item.roles[0]}]
                  </li>
                );
              })
            ) : (
              <li className="list-group-item">
                <i>No results found</i>
              </li>
            )}
            <li
              className="list-group-item invite-new"
              onClick={() => {
                setNewCollabModal(true);
                setSearch("");
              }}
            >
              + Invite a new collaborator
            </li>
          </ul>
        )}
      </div>
      <div className="buttons is-flex is-justify-content-end">
        <button
          className="button is-info"
          onClick={() => onSubmitHandler()}
          disabled={isInviteDisabled}
        >
          Invite collaborator
        </button>
        <button className="button is-light" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PocStakeholderAutoComplete;
