import api from "./_createApi";

export default !!api &&
  api
    .enhanceEndpoints({ addTagTypes: ["PocQuestionAnswers"] })
    .injectEndpoints({
      endpoints: build => ({
        getPocQuestionAnswers: build.query({
          query: pocId => `/pocs/${pocId}/pocQuestionAnswers`,
          providesTags: () => [{ type: "PocQuestionAnswers", id: `LIST` }]
        }),
        addPocQuestionAnswers: build.mutation({
          async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
            const answerResponse = await Promise.all(
              body.map(body =>
                fetchWithBaseQuery({
                  url: "/pocQuestionAnswers",
                  method: "POST",
                  body
                })
              )
            );
            const successes = answerResponse
              .filter(res => !res.error)
              .map(res => res.data);
            const failures = answerResponse
              .filter(res => !res.data)
              .map(res => res.error);

            if (failures.length > 0) return { data: null, error: failures };
            return { data: successes, error: null };
          },
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              api.util.updateQueryData(
                "getPocQuestionAnswers",
                undefined,
                draft => {
                  draft.concat(body);
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              postResult.undo();
            }
          },
          invalidatesTags: (_result, _error, arg) => [
            "PocScore",
            { type: "PocQuestionAnswers", id: `LIST` }
          ]
        }),

        editPocQuestionAnswers: build.mutation({
          query: ({ id, ...payload }) => ({
            url: `pocQuestionAnswers/${id}`,
            method: "PATCH",
            body: payload
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              api.util.updateQueryData(
                "getPocQuestionAnswers",
                undefined,
                draft => {
                  const index = draft.findIndex(x => x.id === body.id);
                  for (let key in body) {
                    draft[index][key] = body[key];
                  }
                  return draft;
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          },
          invalidatesTags: (_result, _error, arg) => [
            "PocScore",
            { type: "PocQuestionAnswers", id: `LIST` }
          ]
        }),

        deletePocQuestionAnswers: build.mutation({
          async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
            const deleteResponse = await Promise.all(
              body.map(({ id }) =>
                fetchWithBaseQuery({
                  url: `/pocQuestionAnswers/${id}`,
                  method: "DELETE"
                })
              )
            );
            const successes = deleteResponse
              .filter(res => !res.error)
              .map(res => res.data);
            const failures = deleteResponse
              .filter(res => !res.data)
              .map(res => res.error);
            if (failures.length > 0) return { data: null, error: failures };
            return { data: successes, error: null };
          },
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const deleteResult = dispatch(
              api.util.updateQueryData(
                "getPocQuestionAnswers",
                undefined,
                draft => {
                  const deleteIds = new Set(body.map(({ id }) => id));
                  return draft.filter(pocQA => !deleteIds.has(pocQA.id));
                }
              )
            );
            try {
              await queryFulfilled;
            } catch {
              deleteResult.undo();
            }
          },
          invalidatesTags: (_result, _error, arg) => [
            "PocScore",
            { type: "PocQuestionAnswers", id: `LIST` }
          ]
        })
      })
    });
