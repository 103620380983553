/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import useAuth from "hooks/useAuth";
import {
  useGetMetaQuery,
  useGetUserQuery,
  useGetVendorQuery
} from "services/api";
import dayjs from "dayjs";

export default function SettingsIntegrations() {
  const { currentUser } = useAuth();
  const { data: user } = useGetUserQuery(currentUser?.id);
  const { data: vendor } = useGetVendorQuery(currentUser?.vendorId);
  const { data: metadata } = useGetMetaQuery();

  const startOauth = () => {
    const { salesforceOAuthInfo } = metadata;
    const { clientId, redirectUri } = salesforceOAuthInfo;
    const { salesforceInstanceUrl } = vendor;
    const loginUrl = `${salesforceInstanceUrl}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.replace(loginUrl);
  };
  const identity = useMemo(
    () =>
      user?.salesforceIdentity ? JSON.parse(user?.salesforceIdentity) : {},
    [user?.salesforceIdentity]
  );

  return (
    <div>
      <div className="is-relative box mt-4">
        <div className="is-size-4 is-flex is-justify-content-space-between">
          <div className="is-size-4">Integrations</div>
        </div>
        <section className="is-relative my-5">
          <div className="columns">
            <div className="column is-4">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title is-flex is-justify-content-space-between">
                    Salesforce{" "}
                    {user?.salesforceIsConnected ? (
                      <span className="tag is-light is-success">Enabled</span>
                    ) : (
                      <span className="tag">Not Enabled</span>
                    )}
                  </p>
                </header>
                <div className="card-content">
                  <div className="content">
                    {user?.salesforceIsConnected ? (
                      <ul>
                        <li>
                          Registered as:{" "}
                          <a href={vendor?.salesforceConnectedAccountUrl}>
                            {identity?.display_name}
                          </a>
                        </li>
                        <li>User type: {identity?.user_type}</li>
                        <li>
                          Last activity:{" "}
                          <time dateTime={identity?.last_modified_date}>
                            {dayjs(identity?.last_modified_date).fromNow()}
                          </time>
                        </li>
                        <li>
                          Last connection:{" "}
                          <time dateTime={user?.salesforceLastActivity}>
                            {dayjs(user?.salesforceLastActivity).fromNow()}
                          </time>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <footer className="card-footer is-justify-content-center py-1">
                  {user?.salesforceIsConnected ? (
                    <Link
                      to="/oauth-salesforce/disconnect"
                      className="button is-light is-danger"
                    >
                      Disconnect
                    </Link>
                  ) : (
                    <a
                      href="#"
                      className="button is-light"
                      onClick={startOauth}
                    >
                      Connect
                    </a>
                  )}
                </footer>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
