import { useState, useEffect, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BugAndFeedbackList from "./BugAndFeedbackList";

const FeedbackLinkToIssue = ({ cell, disabled, row }) => {
  let feedback = cell?.row?.original;
  const addressed = row?.original?.addressed;

  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (feedback) {
      let modalOpenRegex = new RegExp(feedback.id + "/(.*)$");
      setIsModalOpen(modalOpenRegex.test(location.pathname));
    }
  }, [feedback, location.pathname]);

  const to =
    feedback?.id && !disabled
      ? `${feedback?.id}/${feedback?.linkedItemProviderTicker || "evalforce"}`
      : "";
  if (!feedback)
    return (
      <button className="button FeedbackCell-button px-4 is-info">Link</button>
    );

  return (
    <>
      {addressed ? (
        <div className="item-completed">
          {feedback?.linkedItemId?.slice(0, 8)}
        </div>
      ) : (
        <Link
          to={to}
          className={`FeedbackLinkToIssue-link ${disabled ? "disabled" : ""}`}
        >
          {feedback?.linkedItemId ? (
            <button
              className={`button FeedbackCell-button px-4 is-ghost w-100`}
            >
              {feedback?.linkedItemId.slice(0, 8)}
            </button>
          ) : (
            <button className="button FeedbackCell-button px-4 is-info w-100">
              Link
            </button>
          )}
        </Link>
      )}
      {isModalOpen && (
        <BugAndFeedbackList
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
            navigate(`/pocs/${feedback.pocId}/feedback`);
          }}
          feedback={feedback}
        />
      )}
    </>
  );
};

export default memo(FeedbackLinkToIssue);
