import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { getDisplayName } from "./helpers";

const withReordering = WrappedComponent => {
  class WithReordering extends React.Component {
    render() {
      // Filter out extra props that are specific to this HOC and shouldn't be
      // passed through
      const { data, onDragEnd = () => {}, ...passThroughProps } = this.props;

      return (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className={
                    snapshot.isDraggingOver ? "has-background-light" : ""
                  }
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <WrappedComponent
                    {...this.props}
                    isDraggable={true}
                    provided={provided}
                    {...passThroughProps}
                  />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      );
    }
  }
  WithReordering.displayName = `withReordering(${getDisplayName(
    WrappedComponent
  )})`;

  return WithReordering;
};

export default withReordering;
