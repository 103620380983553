import { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { Plus } from "react-bootstrap-icons";

import ChecklistDueDate from "./ChecklistDueDate";
import { SelectableStakeholder } from "components/common/TableCells/SelectableStakeholder";
import useAuth from "hooks/useAuth";
import usePocs from "hooks/usePocs";
import useModalClose from "hooks/useModalClose";

export default function CreateChecklistItem({
  gid = null,
  itemWeightValue = 0,
  add
}) {
  const { pocId } = useParams();
  const [assignee, setAssignee] = useState();
  const [dueDate, setDueDate] = useState();
  const { currentPoc: poc } = usePocs();
  const { currentUser: user } = useAuth();
  const [isAdd, setIsAdd] = useState(false);
  const checklistFormRef = useModalClose(() => setIsAdd(false));

  const stakeholders = poc?.stakeholders || [];
  const defaultAssignee = stakeholders.findIndex(x => x.userId === user.id);
  const selectAssignee = assignee => {
    const value = stakeholders.find(item => item.id === assignee);
    setAssignee(value);
  };

  useEffect(() => {
    if (!assignee) {
      setAssignee(stakeholders[defaultAssignee || 0]);
    }
  }, [stakeholders, assignee]);
  useEffect(() => {
    if (assignee) {
      setAssignee(stakeholders[defaultAssignee || 0]);
      checklistFormRef.current.reset();
    }
  }, [poc.id]);

  const createPocChecklist = e => {
    e.preventDefault();
    if (e.target.body.value) {
      if (pocId) {
        const payloadPoc = {
          body: e.target.body.value,
          pocId: poc.id,
          pocChecklistgroupId: gid || null,
          assignedToStakeholderId: assignee?.id,
          dueDate: dueDate ? dueDate.toISOString() : null,
          weight: itemWeightValue
        };
        add(payloadPoc);
      } else {
        const payload = {
          body: e.target.body.value,
          checklistgroupId: gid || null,
          vendorId: user.vendorId,
          weight: itemWeightValue
          // tags = ["tag1", "tag2"];
          // templates = ["template1", "template2"];
        };
        add(payload);
      }

      setDueDate(null);
      e.target.body.value = "";
      setIsAdd(false);
    } else {
      toast.warning("Action item cannot be empty");
    }
  };

  const useOutsideAlerter = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.blur();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const inputRef = useRef(null);
  useOutsideAlerter(inputRef);

  return isAdd ? (
    <form
      className="columns p-0 m-0"
      onSubmit={createPocChecklist}
      ref={checklistFormRef}
    >
      <div className="column is-7">
        <input
          autoFocus
          placeholder={`Add new item`}
          type="text"
          className="input CreateChecklistItem_input"
          name="body"
          onKeyDown={e => {
            if (e.key === "Escape") setIsAdd(false);
          }}
          ref={inputRef}
          data-weight={itemWeightValue}
        />
      </div>
      <div className="column is-5 columns is-flex is-align-items-center">
        {!!pocId && (
          <>
            <SelectableStakeholder
              className="column is-5"
              stakeholders={stakeholders}
              selectedStakeholderId={assignee?.id}
              onChange={selectAssignee}
              editable={true}
            />
            <div className="column is-4">
              <ReactDatePicker
                selected={dueDate}
                onChange={setDueDate}
                customInput={<ChecklistDueDate />}
              />
            </div>
          </>
        )}
        <div className="column is-3"></div>
      </div>
    </form>
  ) : (
    <div className="buttons">
      <button
        className="button is-light ml-5 mt-5"
        onClick={() => setIsAdd(true)}
      >
        <span className="icon">
          <Plus className="has-text-info is-size-3" />
        </span>
        <span>Add Item</span>
      </button>
    </div>
  );
}
