import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const CreateUserSuccess = ({
  userCreated,
  setUserCreated,
  isOpenModal,
  setIsOpenModal,
  setShowModalAdd
}) => {
  const [show, setshow] = useState(false);

  const copyPassword = async (e, text) => {
    toast.success("Password copied into clipboard");
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  return (
    <Dialog
      as="div"
      open={isOpenModal}
      className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
      onClose={() => null}
    >
      <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30 " />
      <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
        <div className="is-flex is-align-items-center gap-2">
          <div className="is-size-4">Collaborator created!</div>
        </div>

        <div className="my-5">
          An email invitation has been sent to{" "}
          {userCreated?.newStakeholder?.firstName}'s email address.
        </div>
        {userCreated?.passwordGen && (
          <>
            <div className="my-2 is-flex is-align-items-center">
              <span>Generated password: &nbsp;</span>
              <span>
                {!show
                  ? Array(userCreated?.passwordGen.length).fill("x").join("")
                  : userCreated?.passwordGen}
              </span>
              {!show ? (
                <Eye className="ml-4" onClick={() => setshow(!show)} />
              ) : (
                <EyeSlash className="ml-4" onClick={() => setshow(!show)} />
              )}
              <button
                className="button is-info ml-4 is-small"
                onClick={e => copyPassword(e, userCreated?.passwordGen)}
              >
                copy
              </button>
            </div>

            <div className="my-2 is-size-7">
              (This is the only time this password will be displayed)
            </div>
          </>
        )}
        <div className="buttons is-flex is-align-items-center is-justify-content-end mt-6">
          <button
            className="button is-light is-info is-pulled-right"
            onClick={() => [setIsOpenModal(false), setShowModalAdd(true)]}
          >
            Create another Collaborator
          </button>
          <button
            className="button is-info"
            onClick={() => [setUserCreated(null), setIsOpenModal(false)]}
          >
            Close
          </button>
        </div>
      </div>
    </Dialog>
  );
};
export default CreateUserSuccess;
