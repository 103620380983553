import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEditPocMutation, useGetMetaQuery } from "services/api";
import usePocs from "hooks/usePocs";
import defaultPocRoutes from "utils/pocRoutes";
import { values as objectValues } from "lodash";
import useRbac from "../common/Rbac/useRbac";

export default function TopBarVisibility() {
  const { register, setValue } = useForm();
  const location = useLocation();
  const { currentPoc } = usePocs();
  const { data: metadata } = useGetMetaQuery();
  const [editPoc] = useEditPocMutation();

  const currentPage = defaultPocRoutes(currentPoc.id).find(
    x => x.urlTo === location.pathname
  );
  const onChange = e => {
    const metaArray = objectValues(metadata.pocTabList);

    const access = currentPoc.prospectUserTabAccess.filter(
      x => metaArray.indexOf(x) >= 0 && x !== currentPage.key
    );
    if (e.target.value === "external") {
      access.push(currentPage.key);
    }
    editPoc({ id: currentPoc.id, prospectUserTabAccess: access });
  };
  useEffect(() => {
    setValue(
      "visibility",
      currentPoc.prospectUserTabAccess?.includes(currentPage?.key)
        ? "external"
        : "internal"
    );
  }, [currentPoc, currentPage]);

  return (
    <div className="is-flex is-justify-content-end is-align-items-center mt-1 mb-2">
      <div>Visibility:</div>
      <div className="select ml-1 is-small">
        <select
          className="form-control form-select"
          {...register("visibility")}
          onChange={onChange}
        >
          <option value="internal">Internal Only</option>
          <option value="external">Internal & External</option>
        </select>
      </div>
    </div>
  );
}
