const Textarea = ({ name, label, error, value, ...rest }) => (
  <div className="form-group mb-3">
    <label htmlFor={name}>{label}</label>
    <textarea
      {...rest}
      name={name}
      id={name}
      className="form-control"
      value={value}
    />
    {error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export default Textarea;
