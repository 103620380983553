import React from "react";
import { ArrowRepeat } from "react-bootstrap-icons";
import { useGetMetaQuery } from "services/api";

const NewStakeholderForm = ({
  selectedUser,
  setSelectedUser,
  onSubmitHandler,
  setNewCollabModal,
  isLoading
}) => {
  const { data } = useGetMetaQuery();
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmitHandler();
      }}
    >
      {isLoading && (
        <span className="arrowloader">
          <ArrowRepeat className="is-spinning" />
        </span>
      )}

      <div className="columns">
        <div className="column is-6">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
          <br />
          <input
            type="email"
            className="input input-box"
            placeholder="Email"
            onChange={e =>
              setSelectedUser({ ...selectedUser, email: e.target.value })
            }
            required
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <label htmlFor="firstName" className="form-label">
            First name
          </label>
          <br />
          <input
            type="text"
            className="input input-box"
            placeholder="First name"
            onChange={e =>
              setSelectedUser({ ...selectedUser, firstName: e.target.value })
            }
            required
          />
        </div>
        <div className="column is-6">
          <label htmlFor="lastName" className="form-label">
            Last name
          </label>
          <br />
          <input
            type="text"
            className="input input-box"
            placeholder="Last name"
            onChange={e =>
              setSelectedUser({ ...selectedUser, lastName: e.target.value })
            }
            required
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <label htmlFor="persona" className="form-label">
            Persona
          </label>
          <br />
          <div className="select">
            <select
              className="input-box"
              onChange={e =>
                setSelectedUser({ ...selectedUser, persona: e.target.value })
              }
            >
              <option value="">Select </option>
              {Object.keys(data?.personas).map(function (key, index) {
                let value = data?.personas[key];
                return (
                  <option value={value} key={`persona${index}`}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="column is-6">
          <label htmlFor="roles" className="form-label">
            Role
          </label>
          <br />
          <div className="select">
            <select
              className="form-control input-box"
              onChange={e =>
                setSelectedUser({ ...selectedUser, roles: [e.target.value] })
              }
            >
              <option value="">Select </option>
              {Object.keys(data?.userRoles).map(function (key, index) {
                let value = data?.userRoles[key];
                return (
                  <option value={value} key={`userroles${index}`}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="buttons is-justify-content-end mt-6">
        <button type="submit" className="button is-info">
          Create and invite collaborator
        </button>
        <button
          className="button is-light"
          onClick={() => setNewCollabModal(false)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default NewStakeholderForm;
