import { useMemo, useEffect, useState } from "react";
import { usePagination, useTable } from "react-table";
import { toast } from "react-toastify";

import FooterPaginatedTable from "components/common/FooterPaginatedTable";
import PocTopBar from "components/TopBar/TopBarPoc";
import PocStakeholderInvite from "./PocStakeholderInvite";
import {
  CellInput,
  CellAction,
  AutoLoginLinkCard,
  LastActivityCell
} from "./PocStakeholderComponents";
import {
  useGetStakeholdersQuery,
  useInviteStakeholderMutation,
  useDeleteStakeholderMutation,
  useEditStakeholderMutation,
  useCreateAutoLoginLinkMutation
} from "services/api";
import "./PocStakeholder.css";
import CardContainer from "components/Container/CardContainer";
import LayoutContainer from "../../components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";
import UserAvatar from "components/common/UserAvatar";
import ChangeUserPasswordForm from "../../components/common/ChangeUserPasswordForm/index";
import Modal from "components/common/Modal/index";

const tableColumns = [
  {
    Header: "",
    accessor: "profilePic",
    editable: false,
    width: 40,
    Cell: ({ row }) => <UserAvatar user={row.original} showName={false} />
  },
  {
    Header: "First Name",
    accessor: "firstName",
    type: "text",
    width: 150,
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    type: "text",
    width: 150,
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Email",
    accessor: "email",
    type: "email",
    width: "auto",
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Role",
    accessor: item => item.user?.roles?.toString(),
    type: "role",
    width: 300,
    editable: false,
    Cell: CellInput
  },
  {
    Header: "Last Activity",
    accessor: item => item.user?.lastActivityAt,
    type: "role",
    width: 300,
    editable: false,
    Cell: LastActivityCell
  },
  // {
  //   Header: "Sentiment",
  //   accessor: item => item.sentiment,
  //   width: 50,
  //   Cell: SentimentIndicatorCell
  // },
  // {
  //   Header: "Persona",
  //   accessor: "persona",
  //   type: "select",
  //   width: 150,
  //   Cell: CellSelect
  // },
  // {
  //   Header: "External?",
  //   accessor: "type",
  //   createable: false,
  //   type: "checkbox",
  //   width: 100,
  //   class: "bg-primary",
  //   Cell: CellCheckbox
  // },
  {
    Header: "",
    accessor: "action",
    type: "submit",
    Cell: CellAction
  }
];

export default function PocStakeholder({ poc, pocId }) {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState({});

  const [createLink, { isLoading: isLoadingCreateLink }] =
    useCreateAutoLoginLinkMutation();

  const [userChangePassword, setUserChangePassword] = useState(false);

  const {
    data: stakeholders,
    isLoading: listLoading,
    isFetching
  } = useGetStakeholdersQuery(pocId);
  const [
    invite,
    { data: inviteData, error: errorInvite, isLoading: inviteLoading }
  ] = useInviteStakeholderMutation();
  const [updateStakeholder, { error: errorUpdate, isLoading: updateLoading }] =
    useEditStakeholderMutation();
  const [deleteStakeholder, { error: errorDelete, isLoading: deleteLoading }] =
    useDeleteStakeholderMutation();
  const [inviteModal, setInviteModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const { userHasPermission } = useRbac();

  const handleDeleteStakeholder = data => {
    deleteStakeholder({ id: data?.id });
  };

  const filteredData = useMemo(() => {
    // return stakeholders || []
    return stakeholders
      ? stakeholders.filter(x => !x.email.includes("_view@evalforce.com"))
      : [];
  }, [stakeholders]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      open: open,
      setOpen: setOpen,
      setLink: setLink,
      setUserChangePassword: setUserChangePassword,
      createLink: createLink,
      columns: tableColumns,
      data: filteredData,
      initialState: { pageIndex: 0 },
      updateStakeholder,
      deleteStakeholder: handleDeleteStakeholder,
      autoResetPage: false
    },
    usePagination
  );

  useEffect(() => {
    let error = errorUpdate || errorDelete || errorInvite;
    let errorMessage = error?.data?.message || "";
    errorMessage &&
      toast.error(
        typeof errorMessage === "string" ? errorMessage : errorMessage[0]
      );
    setSuccessModal(false);
  }, [errorUpdate, errorDelete, errorInvite]);

  useEffect(() => {
    if (inviteData) {
      setSuccessModal(true);
      setInviteModal(inviteData);
    }
  }, [inviteData]);

  const openModal = () => {
    setInviteModal(true);
  };

  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar poc={poc} showScore={true} title={poc?.pocName} />
      </LayoutContainer>

      <LayoutContainer>
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>
        <CardContainer
          title=""
          page="Collaborator"
          addButtonText={
            userHasPermission(UserPermissions.EVALCOLLABORATORS_CREATE)
              ? "Add Collaborator"
              : false
          }
          setShowAddModal={openModal}
          className="poc-stakeholder mb-4"
          allLoading={
            isFetching ||
            listLoading ||
            inviteLoading ||
            updateLoading ||
            deleteLoading
          }
          isLoading={listLoading}
        >
          <div className="table-container">
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map(group => (
                  <tr {...group.getHeaderGroupProps()}>
                    {group.headers.map(column => (
                      <th
                        {...column.getHeaderProps()}
                        width={column.width}
                        className="py-4 is-size-6 has-text-grey-light has-text-weight-light"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {/* <InviteStakeholder
                pocId={pocId}
                columns={tableColumns}
                invite={invite}
              /> */}
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map(cell => (
                        <td className="py-4" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <FooterPaginatedTable
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            handlePageSize={e => setPageSize(+e.target.value)}
            handleNext={() => nextPage()}
            handlePrevious={() => previousPage()}
            numRows={rows.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
          <PocStakeholderInvite
            inviteModal={inviteModal}
            setInviteModal={setInviteModal}
            successModal={successModal}
            setSuccessModal={setSuccessModal}
          />
        </CardContainer>
        <AutoLoginLinkCard
          open={open}
          setOpen={setOpen}
          link={link}
          setLink={setLink}
          createLink={createLink}
          isLoadingCreateLink={isLoadingCreateLink}
        />

        <Modal
          isOpen={!!userChangePassword}
          setIsOpen={setUserChangePassword}
          title={`Change ${userChangePassword.firstName}'s Password`}
          body={
            <ChangeUserPasswordForm
              user={userChangePassword}
              onSuccess={() => setUserChangePassword(false)}
              onCancel={() => setUserChangePassword(false)}
            />
          }
          buttons={[]}
        />
      </LayoutContainer>
    </>
  );
}
