import { NavLink, useLocation } from "react-router-dom";
import { useEditPocMutation, useGetVendorQuery } from "services/api";
import useAuth from "hooks/useAuth";
import TopBarScore from "./TopBarScore";
import TopBarMenu from "./TopBarMenu";
import { ArrowRepeat } from "react-bootstrap-icons";
import PocSalesforceLinkIcon from "components/common/PocSalesforceLinkIcon";

import defaultPocRoutes from "utils/pocRoutes";
import { useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import TopBarPocSteps from "./TopBarPocSteps";
import UserAvatarGroup from "components/common/UserAvatarGroup";
import PocOutcomeIcon from "../common/PocOutcomeIcon/index";
import useRbac from "components/common/Rbac/useRbac";
import { UserPermissions } from "config/userPermissions";
import PocPaidIcon from "components/common/PocPaidIcon";

export default function PocTopBar({ poc = {}, isLoading, title = "" }) {
  const { pathname } = useLocation();
  const { currentUser, isProspect } = useAuth();
  const { data: vendorData, isLoading: vendorIsLoading } = useGetVendorQuery(
    currentUser.vendorId
  );
  const [editPoc] = useEditPocMutation();
  const [isStepperOpen, setIsStepperOpen] = useState(false);
  const { userHasPermission } = useRbac();

  // Building the list of POC tabs to display
  let pocRoutes = [];
  if (vendorData) {
    pocRoutes = defaultPocRoutes(poc.id);
    if (vendorData?.pocTabConfigurations) {
      pocRoutes = [];
      vendorData?.pocTabConfigurations.map(item => {
        pocRoutes.push({
          key: item.key,
          title: item.label,
          urlTo: `/pocs/${poc.id}/${item.key}`
        });
      });
    }
  }

  const stepperDialog = () => {
    return (
      <Dialog
        as="div"
        open={isStepperOpen}
        className="is-fixed inset-0 z-40 is-flex is-align-items-center is-justify-content-center"
        onClose={() => setIsStepperOpen(false)}
      >
        <Dialog.Overlay className="is-fixed inset-0 has-background-dark opacity-30" />
        <div className="px-6 py-5 my-8 overflow-hidden has-background-white z-30 rounded-md">
          <TopBarPocSteps poc={poc} />
          <div className="buttons is-justify-content-end">
            <button
              onClick={() => setIsStepperOpen(false)}
              className="button is-light"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const [userGroupInternal, userGroupExternal] = useMemo(() => {
    let userGroupInternal = [];
    let userGroupExternal = [];

    poc?.stakeholders?.map(stakeholder => {
      if (stakeholder.user?.roles?.includes("ProspectUser")) {
        userGroupExternal.push(stakeholder);
      } else {
        userGroupInternal.push(stakeholder);
      }
    });

    return [userGroupInternal, userGroupExternal];
  }, [poc.stakeholders]);

  return (
    <div id="pocTopBar">
      <nav className="container is-flex-desktop is-justify-content-space-between is-align-items-center mb-2 pt-2">
        <div className="w-90 is-flex-desktop is-align-items-center">
          <div className="is-size-3 word-break max-w-80">
            {title}
            <PocSalesforceLinkIcon poc={poc} />
          </div>
          <div className="is-flex">
            <PocPaidIcon poc={poc} />
            <PocOutcomeIcon poc={poc} />
            {!isProspect && <TopBarMenu />}
            <div
              className="tags has-addons is-clickable m-0 mr-4"
              onClick={
                userHasPermission(UserPermissions.EVAL_UPDATEPHASE)
                  ? () => setIsStepperOpen(true)
                  : () => {}
              }
            >
              <span className="tag m-0">Phase</span>
              <span className="tag is-info m-0">{poc?.phase}</span>
            </div>
            {isLoading && (
              <ArrowRepeat
                size={20}
                className="is-spinning is-flex is-align-items-center my-0"
              />
            )}
            <UserAvatarGroup users={userGroupInternal} pocId={poc?.id} />
          </div>
        </div>

        <div>{!isProspect ? <TopBarScore poc={poc} /> : null}</div>
      </nav>

      <div className="column">
        <div className="tabs">
          <ul>
            {pocRoutes.map(({ key, title, urlTo }) => {
              const allowAccess =
                !isProspect || poc?.prospectUserTabAccess?.includes(key);
              return allowAccess ? (
                <li
                  key={urlTo}
                  className={urlTo === pathname ? "is-active" : ""}
                >
                  <NavLink to={urlTo}>{title}</NavLink>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      </div>
      {stepperDialog()}
    </div>
  );
}
