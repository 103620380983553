import { Link as LinkIcon } from "react-bootstrap-icons";
import styles from "./PocSalesforceLinkIcon.module.css"; // Import css modules stylesheet as styles

const PocSalesforceLinkIcon = ({ poc }) => {
  if (!poc.salesforceOpportunityId || !poc.createdByUser?.salesforceIdentity) {
    return null;
  }
  const instanceUrl = JSON.parse(poc.createdByUser?.salesforceIdentity)?.urls
    .custom_domain;
  return (
    <a
      href={`${instanceUrl}/lightning/r/Opportunity/${poc.salesforceOpportunityId}/view`}
      target="_blank"
      rel="noreferrer"
      title={"Linked to Salesforce Opportunity " + poc.salesforceOpportunityId}
    >
      <LinkIcon className={styles.pocSalesforceLinkIcon} />
    </a>
  );
};

export default PocSalesforceLinkIcon;
