import { Popover } from "@headlessui/react";
import { ChatLeft } from "react-bootstrap-icons";

const EntityNotesWidget = ({
  entity = {},
  editNotesHandler = () => {},
  remove = () => {},
  canEdit = false
}) => {
  return (
    <Popover
      as="div"
      className="is-flex is-justify-content-flex-end gap-2 is-relative reveal-items-on-hover"
    >
      <Popover.Button
        as="div"
        className={`is-flex is-align-items-center is-white is-compact ${
          entity?.notes ? "" : "hidden-item"
        } `}
      >
        <ChatLeft className="has-text-info" />
      </Popover.Button>

      <Popover.Panel
        as="div"
        className="box top-0 right-4 is-absolute z-30 w-20r"
      >
        {({ open, close }) => (
          <form
            onSubmit={e => {
              const formdata = new FormData(e.target);
              editNotesHandler(formdata.get("notes"));
              close();
              e.preventDefault();
            }}
            className="field"
          >
            <label className="label">Notes</label>
            <div className="control">
              <textarea
                placeholder="Enter notes here"
                className="textarea"
                name="notes"
                cols="30"
                rows="4"
                defaultValue={entity?.notes || ""}
                disabled={canEdit ? "" : "disabled"}
              />
            </div>
            <nav class="level buttons mt-2">
              {canEdit ? (
                <>
                  <div class="level-left">
                    <div class="level-item">
                      <button type="submit" className="button is-info is-small">
                        Save
                      </button>
                    </div>
                  </div>
                  {entity?.notes && (
                    <div class="level-right">
                      <div class="level-item">
                        <button
                          type="submit"
                          className="button is-danger is-light is-small"
                          onClick={() => remove()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </nav>
          </form>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default EntityNotesWidget;
