import {
  useAddSpecdocsectionsMutation,
  useEditSpecdocsectionMutation
} from "services/api";
import { useEffect, useState } from "react";
import TextEditor from "../TextEditor";
import { toast } from "react-toastify";
import { debounce } from "lodash";

const EditSpecdocsectionForm = ({
  specdocsection = {},
  onSuccess = () => {},
  onCancel = () => {}
}) => {
  const [addItem, { isLoading: isAddItemLoading }] =
    useAddSpecdocsectionsMutation();
  const [editItem, { isLoading: isEditItemLoading }] =
    useEditSpecdocsectionMutation();

  const [bodyValue, setBodyValue] = useState(specdocsection?.body);
  const [isSalesforce, setisSalesforce] = useState(false);

  useEffect(() => {
    specdocsection?.salesforce && setisSalesforce(true);
  }, [specdocsection?.salesforce]);

  const onSubmit = e => {
    e.preventDefault();
    const { isDeletable, title, salesforce } = e.target;
    const payload = {
      title: title.value,
      body: bodyValue || "",
      salesforce: salesforce?.value || "",
      isDeletable: isDeletable.checked ? true : false
    };
    if (specdocsection?.id) {
      payload.id = specdocsection.id;
      editItem(payload)
        .unwrap()
        .then(toast.success("Updated!"))
        .then(onSuccess)
        .catch(error => toast.error(`Error: ${error.message}`));
    } else {
      addItem(payload)
        .unwrap()
        .then(toast.success("Added!"))
        .then(onSuccess)
        .catch(error => toast.error(`Error: ${error.message}`));
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="columns">
          <div className={`column ${isSalesforce ? "is-4" : "is-8"}`}>
            <div className="field">
              <label className="label">Title</label>
              <div className="control">
                <input
                  name="title"
                  defaultValue={specdocsection?.title}
                  className="input"
                  type="text"
                  placeholder="Title"
                  required
                />
              </div>
            </div>
          </div>
          {isSalesforce && (
            <div className="column is-4">
              <div className="field">
                <label className="label">Salesforce Field</label>
                <div className="control">
                  <input
                    required={isSalesforce}
                    // name="salesforce_field"
                    name="salesforce"
                    defaultValue={specdocsection?.salesforce}
                    className="input"
                    type="text"
                    placeholder="Salesforce field"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="column field">
            <label htmlFor="isSalesForce" className="label">
              Is Salesforce?
            </label>
            <div className="control is-flex">
              <input
                onChange={() => setisSalesforce(!isSalesforce)}
                type="checkbox"
                id="isSalesForce"
                className="form-check-input larger"
                disabled={specdocsection?.id}
                checked={isSalesforce}
              />
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Is Deletable?</label>
              <div className="control">
                <input
                  type="checkbox"
                  name="isDeletable"
                  className="form-check-input larger"
                  defaultChecked={specdocsection?.isDeletable}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div
              className="field"
              style={
                isSalesforce
                  ? {
                      pointerEvents: "none",
                      opacity: 0.5,
                      cursor: "not-allowed"
                    }
                  : {}
              }
            >
              <label className="label">Body</label>
              <div className="control">
                <TextEditor
                  // defaultValue={specdocsection?.body}
                  defaultValue={isSalesforce ? "" : specdocsection?.body}
                  height={
                    specdocsection?.height ? specdocsection?.height : "300px"
                  }
                  onChange={debounce(body => setBodyValue(body), 100)}
                />
              </div>
            </div>
          </div>
        </div>

        <nav className="level">
          <div className="level-left"></div>
          <div className="level-right">
            <p className="level-item">
              <button
                type="submit"
                className={`button is-info ${
                  isAddItemLoading || isEditItemLoading ? "is-loading" : ""
                }`}
              >
                Save
              </button>
            </p>
            <p className="level-item">
              <button
                className="button is-light"
                onClick={e => {
                  e.preventDefault();
                  onCancel();
                }}
              >
                Cancel
              </button>
            </p>
          </div>
        </nav>
      </form>
    </>
  );
};

export default EditSpecdocsectionForm;
