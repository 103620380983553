import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Users", "User"] }).injectEndpoints({
    endpoints: build => ({
      getUsers: build.query({
        query: () => `/users`,
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Users", id })),
                { type: "Users", id: "LIST" }
              ]
            : [{ type: "Users", id: "LIST" }]
      }),
      getUser: build.query({
        query: id => `/users/${id}`,
        providesTags: ["User"]
      }),
      getUserActivityLogs: build.query({
        query: id => `/users/${id}/activityLogs`
      }),
      createAutoLoginLink: build.mutation({
        query: ({ userId, ...body }) => ({
          url: `/users/${userId}/autologinLink`,
          method: "POST",
          body
        }),
        invalidatesTags: result => [{ type: "Users", id: result.id }]
      }),
      changePassword: build.mutation({
        query: ({ userId, ...body }) => ({
          url: `/users/${userId}/changePassword`,
          method: "POST",
          body
        })
      }),
      forgotPassword: build.mutation({
        query: body => ({
          url: `/users/forgot-password`,
          method: "POST",
          body
        })
      }),
      linkSalesforceOAuth: build.mutation({
        query: ({ id, body }) => ({
          url: `/users/${id}/salesforce/link`,
          body,
          method: "POST"
        }),
        invalidatesTags: ({ id }) => ["User"]
      }),
      unlinkSalesforceOAuth: build.mutation({
        query: ({ id }) => ({
          url: `/users/${id}/salesforce/unlink`,
          method: "POST"
        }),
        invalidatesTags: ({ id }) => ["User"]
      }),
      salesforceSearch: build.mutation({
        query: ({ id, ...body }) => ({
          url: `/users/${id}/salesforce/search`,
          method: "POST",
          body
        })
      }),
      salesforceSync: build.mutation({
        query: ({ id, body }) => ({
          url: `/users/${id}/salesforce/sync`,
          method: "POST",
          body
        }),
        invalidatesTags: pocs => {
          const arr = pocs.map(a => ({ type: "POCs", id: a.id }));
          return [...arr, "PocSpecdocsections"];
        }
      }),
      addUser: build.mutation({
        query: ({ ...body }) => ({
          url: "/users/without-password",
          method: "POST",
          body
        }),
        invalidatesTags: (result, error) => {
          if (error) return null;
          return [{ type: "Users", id: "LIST" }];
        }
      }),
      editUser: build.mutation({
        query: ({ id, ...body }) => ({
          url: `/users/${id}`,
          method: "PATCH",
          body
        }),
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData("getUsers", undefined, draft => {
              const data = draft.find(x => x.id === body.id);
              for (let key in body) {
                data[key] = body[key];
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: result => [{ type: "Users" }, { type: "Stakeholders" }]
      })
    })
  });
