import { createFilterFeedbackUQuery } from "pages/FeedbackRoutes";
import { Link } from "react-router-dom";
import { currency, formatDate } from "utils/helpers";

const FeedbackBodyLink = ({ cell, value, filter }) => {
  // const topFeedback = cell.row.original.feedbacks[0];
  // const id = cell.row.original.linkedItemId || topFeedback.id;
  const id = cell.row.original.linkedItemId;
  let filterQuery = createFilterFeedbackUQuery(filter);
  const replacedFilterQuery = filterQuery.replaceAll("[]=", "=");
  if (id) {
    return <Link to={id + replacedFilterQuery}>{value}</Link>;
  } else {
    return value;
  }
};

const FeedbackExternalLink = ({ cell, value }) => {
  const topFeedback = cell.row.original.feedbacks[0];
  const externalUrl = topFeedback?.linkedItemExternalUrl;
  if (!externalUrl) return value;
  return (
    <a href={externalUrl} target="_blank" rel="noreferrer">
      {value}
    </a>
  );
};

export default FeedbackBodyLink;

export const columns = [
  {
    Header: "# Opps",
    accessor: "nbPocs",
    Cell: FeedbackBodyLink
  },
  {
    Header: "ACV",
    accessor: ({ totalAcv }) => currency(totalAcv),
    Cell: FeedbackBodyLink
  },
  {
    Header: "Description",
    accessor: row =>
      row.linkedItemTitle ||
      row.feedbacks[0].linkedItemTitle ||
      row.feedbacks[0].body,
    Cell: FeedbackExternalLink
  },
  {
    Header: "Link",
    accessor: row =>
      row.linkedItemId?.slice(0, 8) ||
      row.feedbacks[0].linkedItemId?.slice(0, 8) ||
      "Unlinked",
    Cell: FeedbackExternalLink
  },
  {
    Header: "Type",
    accessor: row =>
      row.linkedItemType ||
      row.feedbacks[0].linkedItemType ||
      row.feedbacks[0].type ||
      "-"
  },
  {
    Header: "Status",
    accessor: row =>
      row.linkedItemStatus ||
      row.feedbacks[0].linkedItemStatus ||
      row.feedbacks[0].status ||
      "-"
  },
  {
    Header: "Due Date",
    accessor: row => {
      const formattedDate = formatDate(
        row.linkedItemDueDate ||
          row.feedbacks[0].linkedItemDueDate ||
          row.feedbacks[0].dueDate
      );
      if (formattedDate === "Invalid Date") return "-";
      return formattedDate;
    }
  }
  // {
  //   Header: "Priority",
  //   accessor: row =>
  //     row.linkedItemPriority ||
  //     row.feedbacks[0].linkedItemPriority ||
  //     row.feedbacks[0].priority ||
  //     "-"
  // }
];
