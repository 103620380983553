import { useEffect, useMemo, useState } from "react";
// import APIError from "components/common/APIError";
import Table from "components/common/Table";
import { columns } from "./constants";
import "./Feedback.css";
import FilterCard from "./FilterCard";
import dayjs from "dayjs";
import { ArrowRepeat } from "react-bootstrap-icons";
import AnalyticsMenu from "pages/Analytics/AnalyticsMenu";

const Feedback = ({ filter, setFilter, feedbacks, isFetching }) => {
  let filteredFeedbackData = useMemo(() => feedbacks || [], [feedbacks]);

  if (filter?.search) {
    const value = filter.search.toLowerCase();
    filteredFeedbackData = filteredFeedbackData.filter(
      x =>
        x?.feedbacks[0]?.linkedItemTitle?.toLowerCase()?.includes(value) ||
        x?.totalAcv?.toString()?.includes(value) ||
        x?.nbPocs.includes(value) ||
        x?.linkedItemStatus?.toLowerCase()?.includes(value) ||
        x?.feedbacks[0]?.linkedItemType?.toLowerCase()?.includes(value) ||
        dayjs(x?.linkedItemDueDate).format("MM/DD/YYYY")?.includes(value) ||
        x?.feedbacks[0]?.priority?.toLowerCase()?.includes(value)
    );
  }

  return (
    <>
      {isFetching && <ArrowRepeat className="is-spinning" />}
      <AnalyticsMenu />
      <div className="box mt-5 p-5">
        {/* <APIError message={error} /> */}
        <FilterCard filter={filter} setFilter={setFilter} />
        <div className="table-container">
          <Table
            className="Feedback-table"
            data={filteredFeedbackData}
            columns={columns}
            extraCellInfo={{ disabled: true, filter }}
          />
        </div>
      </div>
    </>
  );
};

export default Feedback;
