const Loading = ({ className = "" }) => (
  <span className={`bulma-loader-mixin is-size-3 ${className}`}></span>
);

export const LoadingOverlay = () => (
  <div className="loading-overlay is-flex is-align-items-center is-justify-content-center">
    <Loading />
  </div>
);
export const LoadingCentered = () => (
  <div className="is-flex is-justify-content-center">
    <Loading />
  </div>
);

export const LoadingIconOnly = ({ className = "" }) => (
  <span className={`bulma-loader-mixin ${className}`}></span>
);

export default Loading;
