import { UserPermissions } from "config/userPermissions";
import { memo } from "react";
import { useEditFeedbackMutation } from "services/api";
import EntityNotesWidget from "components/common/EntityNotesWidget";
import useRbac from "../Rbac/useRbac";

const FeedbackEntityNotesWidget = ({ cell, disabled, row }) => {
  let feedback = cell?.row?.original;
  const [editFeedback] = useEditFeedbackMutation();
  const { userHasPermission } = useRbac();

  return (
    <EntityNotesWidget
      entity={feedback}
      canEdit={userHasPermission(UserPermissions.EVALFEEDBACK_READ)}
      editNotesHandler={notes => {
        editFeedback({
          id: feedback.id,
          notes
        });
      }}
      remove={() => {
        editFeedback({
          id: feedback.id,
          notes: ""
        });
      }}
    />
  );
};

export default memo(FeedbackEntityNotesWidget);
