import React from "react";
import { Route, Routes } from "react-router-dom";
import SettingsIntegrations from "pages/SettingsIntegrations";
import SettingsIntegrationCallback from "./SettingsIntegrationCallback";

export default function SettingsIntegrationRoutes() {
  return (
    <Routes>
      <Route
        path=":integrationName/callback"
        element={<SettingsIntegrationCallback />}
      />
      <Route path="" element={<SettingsIntegrations />} />
    </Routes>
  );
}
