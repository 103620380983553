import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InfoCircle, PlusLg } from "react-bootstrap-icons";
import useAuth from "hooks/useAuth";
import SearchBox from "components/common/SearchBox";
import {
  useGetMetaQuery,
  useGetUseCasesQuery,
  useGetPocUseCasesQuery,
  useAddUseCasesAndSuccessCriteriaMutation,
  useAddPocUseCasesAndSuccessCriteriaMutation
} from "services/api";
import { sortBy } from "utils/helpers";
import EntityVisibilityIcon from "components/common/EntityVisibilityIcon";
import { truncate } from "../../utils/helpers";

const SearchCreateRequirement = ({
  poc,
  setShowSearchUsecases,
  onBrowseAll = () => {}
}) => {
  const [filteredData, setFilteredData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [itemsToShow, setItemsToShow] = useState(10);

  const { pocId } = useParams();
  const { currentUser, getCurrentStakeholder, isProspect } = useAuth();
  const { data: metadata } = useGetMetaQuery();

  const currentStakeholder = useMemo(
    () => getCurrentStakeholder(poc),
    [poc, getCurrentStakeholder]
  );

  const { data: usecases, isLoading: isLoadingUsecases } =
    useGetUseCasesQuery();
  const memoizedData = useMemo(
    () => (usecases ? sortBy(usecases, "body") : []),
    [usecases]
  );

  const { data: pocUsecaseData, isLoading: pocUsecaseIsLoading } =
    useGetPocUseCasesQuery(pocId);
  const PocUseCaseIds = useMemo(
    () => new Set(pocUsecaseData?.map(({ usecase }) => usecase?.id)),
    [pocUsecaseData]
  );

  const unselectedUseCases = memoizedData?.filter(
    x => !PocUseCaseIds.has(x.id)
  );

  const fullData = [...unselectedUseCases];

  const [add, { isLoading: batchAddIsLoading }] =
    useAddPocUseCasesAndSuccessCriteriaMutation({
      fixedCacheKey: `batch-add-pocUseCases-${pocId}`
    });

  const [addNewRequirement, { isLoading: addNewRequirementIsLoading }] =
    useAddUseCasesAndSuccessCriteriaMutation({
      fixedCacheKey: "batch-add-usecases"
    });

  const displayData = useMemo(() => {
    return searchValue ? filteredData : !isLoadingUsecases ? fullData : [];
  }, [filteredData, fullData]);

  const addRequirement = async requirement => {
    const payload = {
      assignedToStakeholderId:
        currentStakeholder?.id || poc?.stakeholders[0]?.id,
      pocId,
      usecaseIds: [requirement.id],
      successCriteria: [],
      isBusiness: false
    };
    const rty = await add(payload);
    if (rty.data.useCases) setShowSearchUsecases(false);
  };

  const handlePressEnter = async () => {
    if (searchValue) {
      const newPayload = {
        successcriterias: [],
        usecase: {
          archived: false,
          body: searchValue,
          notes: searchValue,
          vendorId: currentUser.vendorId,
          visibility: metadata.entityVisibility.ONE_OFF
        }
      };
      const newRequirement = await addNewRequirement(newPayload);

      const oldPayload = {
        assignedToStakeholderId:
          currentStakeholder?.id || poc?.stakeholders[0]?.id,
        pocId,
        usecaseIds: [newRequirement.data.usecase.id],
        successCriteria: [],
        isBusiness: false
      };
      const addedToPOC = await add(oldPayload);
      if (addedToPOC.data.useCases) setShowSearchUsecases(false);
    }
  };

  return (
    <div className="drop-shadow p-5">
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <div className={`dropdown ${searchValue ? "is-active" : ""}`}>
              <div className="dropdown-trigger">
                <SearchBox
                  dataSet={fullData}
                  keys={["body", "notes"]}
                  setDataSet={setFilteredData}
                  setLiftedSearchValue={setSearchValue}
                  onPressEnter={handlePressEnter}
                  onCloseSearch={setShowSearchUsecases}
                  placeholder={"Type your new Requirement"}
                  icon={<PlusLg />}
                />
              </div>
              <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                <div className="dropdown-content">
                  {displayData.length == 0 && (
                    <div className="dropdown-item">No results...</div>
                  )}
                  {displayData.length > 0 &&
                    displayData.slice(0, itemsToShow).map(requirement => (
                      <a
                        className="dropdown-item"
                        key={requirement.id}
                        onClick={() => addRequirement(requirement)}
                      >
                        {requirement.body}{" "}
                        <EntityVisibilityIcon entity={requirement} />
                        {requirement.notes ? (
                          <small> - {truncate(requirement.notes)}</small>
                        ) : (
                          ""
                        )}
                      </a>
                    ))}
                  {!isProspect && (
                    <>
                      <hr className="dropdown-divider" />
                      <div className="dropdown-item">
                        <button
                          className="button is-info is-outlined is-small"
                          onClick={() => onBrowseAll()}
                        >
                          Browse all Requirements
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="level-item">
            <div className="buttons">
              <button
                className={`button is-info ${
                  isLoadingUsecases ||
                  pocUsecaseIsLoading ||
                  batchAddIsLoading ||
                  addNewRequirementIsLoading
                    ? "is-loading"
                    : ""
                }`}
                onClick={() => handlePressEnter()}
              >
                Save
              </button>
              <button
                className="button is-ghost"
                onClick={() => setShowSearchUsecases(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <small>
              <InfoCircle /> Type Enter to save
            </small>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SearchCreateRequirement;
