import React from "react";
import {
  CheckLg,
  DashCircle,
  GripVertical,
  PlusCircle,
  XLg
} from "react-bootstrap-icons";

/**
 * @param snapshot it takes dragging status
 * @param questionSnapshot it takes questions dragging status
 * @param icon it takes JSX object for render as icon
 * @param firstColumn first column of row, required
 * @param secondColumn optional
 * @param thirdColumn optional
 * @param fourthColumn optional
 * @param fifthColumn optional
 * @param MenuComponent it takes JSX object for render as menu component
 * @param answer if row renders for answer, it takes answer object
 * @param editMode it will provide from tablebody component for edit state controlling
 * @param isQuestion if row renders for question, you should pass it as true, otherwise it can be false or null
 * @param answersLength count of total answers, we are using that number for removing minus icon (delete) when editing answers
 * @param handleAddAnswer it takes function and this function add answer to state
 * @param handleDeleteAnswer it takes function and this function delete specific answer from state
 * @param handleAnswerChanges it takes function and this function make state changes for specific answer
 * @param editQuestionBody it takes state variable for visualize edit question mode
 * @param editQuestionVisibility it takes state variable for visualize edit question mode
 * @param handleQuestionBodyChange it takes function and this function edits question context
 * @param handleQuestionVisibilityChange it takes function and this function edits question visibility (eg. One-off or Company-wide)
 * @param dragHandleProps its a object from drag drop context
 * @param isManageQuestion if this row rendering for settings -> manage question page make it true, otherwise it should be false or null
 * @param metadata is using just for question visibility changes in settings -> manage question page.
 * @param answerBodyError This array keeps, answer ids which has not body value without isFreeText property.
 * @returns {JSX.Element}
 * @constructor
 */

function TableRow({
  snapshot,
  questionSnapshot,
  icon,
  firstColumn,
  secondColumn,
  thirdColumn,
  fourthColumn,
  fifthColumn,
  MenuComponent,
  answer,
  editMode,
  isQuestion,
  answersLength,
  handleAddAnswer,
  handleDeleteAnswer,
  handleAnswerChanges,
  editQuestionBody,
  editQuestionVisibility,
  handleQuestionBodyChange,
  handleQuestionVisibilityChange,
  dragHandleProps,
  isManageQuestion,
  metadata,
  answerBodyError
}) {
  return (
    <div className="table-row">
      <div className="is-flex icon-row is-relative is-justify-content-center">
        {dragHandleProps && (
          <div {...dragHandleProps} className={"drag-icon"}>
            {!snapshot && !questionSnapshot && <GripVertical />}
          </div>
        )}
        {editMode && !isQuestion ? (
          <div className="is-flex-direction-column">
            <PlusCircle onClick={handleAddAnswer} />
            {answersLength > 1 && (
              <DashCircle
                className="mt-1"
                onClick={() => handleDeleteAnswer(answer.id)}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {icon && icon}
      {editMode ? (
        <input
          type="text"
          className={`py-2 is-flex is-align-items-center question-row table-row-input ${
            answerBodyError?.find(item => item === answer.id)
              ? "answer-input-error"
              : ""
          }`}
          value={
            !isQuestion && answer.isFreeText
              ? ""
              : !isQuestion
              ? answer.body
              : editQuestionBody
          }
          placeholder={!isQuestion && answer.isFreeText ? "Free text" : ""}
          onChange={e => {
            if (isQuestion) {
              handleQuestionBodyChange(e.target.value);
            } else {
              handleAnswerChanges(answer.id, "body", e.target.value);
            }
          }}
          disabled={!isQuestion && answer.isFreeText}
        />
      ) : (
        <div className="py-2 is-flex is-align-items-center question-row table-row-item">
          {firstColumn}
        </div>
      )}
      {editMode && !isQuestion && (
        <>
          <div className="py-2 table-row-item">
            <input
              type="checkbox"
              className="form-check-input larger"
              checked={answer.isFreeText}
              onChange={e => {
                handleAnswerChanges(answer.id, "isFreeText", e.target.checked);
              }}
            />
          </div>
          <div className="py-2 table-row-item">
            <input
              type="checkbox"
              className="form-check-input larger"
              checked={!answer.archived}
              onChange={e => {
                handleAnswerChanges(answer.id, "archived", !e.target.checked);
              }}
            />
          </div>
        </>
      )}
      {isManageQuestion && !editMode && !isQuestion ? (
        <div className="py-2 table-row-item">
          {answer.isFreeText ? <CheckLg /> : <XLg />}
        </div>
      ) : (
        secondColumn && (
          <div className="py-2 table-row-item">{secondColumn}</div>
        )
      )}
      {isManageQuestion && !editMode && !isQuestion ? (
        <div className="py-2 table-row-item">
          {!answer.archived ? <CheckLg /> : <XLg />}
        </div>
      ) : (
        thirdColumn && <div className="py-2 table-row-item">{thirdColumn}</div>
      )}
      {fourthColumn &&
        (isManageQuestion && editMode && isQuestion ? (
          <div className="py-2 table-row-item select">
            <select
              value={editQuestionVisibility}
              name="visibility"
              onChange={e => handleQuestionVisibilityChange(e.target.value)}
              className="form-select visibility-select"
            >
              {metadata?.entityVisibility &&
                Object.keys(metadata?.entityVisibility).map((k, i) => (
                  <option key={i}>{metadata?.entityVisibility[k]}</option>
                ))}
            </select>
          </div>
        ) : (
          <div className="py-2 table-row-item">{fourthColumn}</div>
        ))}
      {fifthColumn && <div className="py-2 table-row-item">{fifthColumn}</div>}
      {MenuComponent && (
        <div className={`py-2 table-row-item ${MenuComponent ? "menu" : ""}`}>
          {MenuComponent}
        </div>
      )}
    </div>
  );
}

export default TableRow;
