import { Menu } from "@headlessui/react";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const ThreeDotMenu = ({ items = [] }) => {
  if (!items.length) {
    return "";
  }

  return (
    <div className="is-flex">
      <Menu
        as="div"
        className="dropdown is-flex is-align-items-center is-right is-active"
      >
        <Menu.Button className="button is-white is-compact">
          <ThreeDotsVertical />
        </Menu.Button>

        <Menu.Items
          as="div"
          className="dropdown-menu card"
          style={{ outlineStyle: "none" }}
        >
          {items.map((item, i) => (
            <Menu.Item as="div" key={i}>
              {({ active }) => (
                <button
                  className={`button is-white is-fullwidth`}
                  onClick={item.onClick}
                >
                  {item.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default ThreeDotMenu;
