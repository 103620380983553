import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PocUsecaseCard from "./PocUseCaseCard";
import { UserPermissions } from "../../config/userPermissions";
import useRbac from "../../components/common/Rbac/useRbac";

const UsecaseCardList = ({
  poc,
  filteredDiscovery,
  onOpenEditModal,
  onOpenDeleteModal,
  onDragEnd
}) => {
  const { userHasPermission } = useRbac();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {filteredDiscovery.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <PocUsecaseCard
                      key={index}
                      data={item}
                      poc={poc}
                      onOpenEditModal={onOpenEditModal}
                      onOpenDeleteModal={onOpenDeleteModal}
                      dragHandleProps={
                        userHasPermission(UserPermissions.EVALSCOPING_REORDER)
                          ? provided.dragHandleProps
                          : false
                      }
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default UsecaseCardList;
