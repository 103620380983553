import React, { useCallback, useMemo, useState } from "react";
import { reorderListAfterDragEnd } from "utils/helpers";
import {
  useDeleteSpecdocsectionMutation,
  useEditSpecdocsectionMutation,
  useGetSpecdocsectionsQuery,
  useReorderSpecdocsectionsMutation
} from "services/api";
import useAuth from "hooks/useAuth";
import ThreeDotMenu from "components/common/ThreeDotMenu/index";
import withReordering from "utils/WithReordering";
import Modal from "components/common/Modal";
import EditSpecdocsectionForm from "components/common/EditSpecdocsectionForm/index";
import { LoadingCentered } from "components/common/Loading";
import APIError from "components/common/APIError/index";
import Table from "components/common/Table";
import TemplatePicker from "components/common/TemplatePicker/index";
import TableRowDragHandle from "components/common/TableRowDragHandle/index";
import { toast } from "react-toastify";
import { ArrowRepeat } from "react-bootstrap-icons";
import SearchBox from "components/common/SearchBox";
import { NoDataBlock } from "components/common/NoData";

const ManageSpecdocsections = () => {
  const { currentUser } = useAuth();
  const {
    data: specdocsectionData,
    isLoading: isListLoading,
    error
  } = useGetSpecdocsectionsQuery(currentUser?.vendorId);
  const [reorderItems, { isLoading: isReorderItemsLoading }] =
    useReorderSpecdocsectionsMutation();

  const [editItem, { isLoading: isEditItemLoading }] =
    useEditSpecdocsectionMutation();
  const [deleteItem, { isLoading: isDeleteItemLoading }] =
    useDeleteSpecdocsectionMutation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState({});
  const [itemBeingDeleted, setItemBeingDeleted] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(false);

  const handleEditTemplate = useCallback(
    (id, templates) => {
      editItem({ id, templates });
    },
    [editItem]
  );

  const data = React.useMemo(() => {
    return specdocsectionData
      ? specdocsectionData?.map(item => {
          // build 3 dot menu
          const menuItems = [
            {
              label: "Edit",
              onClick: () => {
                setItemBeingEdited(item);
                setIsEditModalOpen(true);
              }
            },
            {
              label: "Delete",
              onClick: () => {
                {
                  setItemBeingDeleted(item);
                  setIsDeleteModalOpen(true);
                }
              }
            }
          ];

          const rowItem = { ...item };
          rowItem.menu = <ThreeDotMenu items={menuItems} />;

          return rowItem;
        })
      : [];
  }, [specdocsectionData]);

  const columns = React.useMemo(
    () => [
      {
        id: "dragHandle",
        isDragHandle: true,
        showsWhileDragging: true,
        Cell: TableRowDragHandle
      },
      {
        Header: "Section Title",
        accessor: "title", // accessor is the "key" in the data
        showsWhileDragging: true,
        Cell: ({ value, row }) => {
          return (
            <div
              onClick={() => {
                setItemBeingEdited(row.original);
                setIsEditModalOpen(true);
              }}
              className="is-clickable"
            >
              {value}
            </div>
          );
        }
      },
      {
        Header: "Tags",
        accessor: "templates",
        Cell: ({ row, value }) => (
          <TemplatePicker
            row={row}
            value={value}
            editTemplate={handleEditTemplate}
          />
        )
      },
      {
        Header: "Is Deletable?",
        accessor: "isDeletable",
        Cell: ({ value }) => (value ? "Yes" : "No")
      },
      {
        Header: "Action",
        accessor: "menu"
      }
    ],
    [specdocsectionData]
  );

  const TableWithReordering = withReordering(Table);

  const onDragEnd = result => {
    const items = reorderListAfterDragEnd(
      data,
      result.source.index,
      result.destination.index
    );

    reorderItems({
      vendorId: currentUser.vendorId,
      ids: items.map(item => item.id)
    });
  };

  const onDeleteSection = () => {
    deleteItem({ id: itemBeingDeleted.id })
      .unwrap()
      .then(() => {
        toast.success(`Section deleted`);
        setItemBeingDeleted({});
        setIsDeleteModalOpen(false);
      })
      .catch(error => toast.error(`Error: ${error.message}`));
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    // Making sure that the item being edited is reset
    setItemBeingEdited({});
  };

  const displayData = useMemo(() => {
    return searchValue ? filteredData : data;
  }, [filteredData, data, searchValue]);

  return (
    <>
      <section className="card mt-4">
        <APIError error={error} />
        <div className="ManageQuestions p-5">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="is-size-4">Manage Overview Sections</div>
              </div>
              <div className="level-item">
                <button
                  onClick={() => setIsEditModalOpen(true)}
                  className="ml-3 button is-info is-small"
                >
                  Create a New Section
                </button>
              </div>
              <div className="level-item">
                {(isReorderItemsLoading || isEditItemLoading) && (
                  <ArrowRepeat className="is-spinning" />
                )}
              </div>
            </div>

            <div className="level-right">
              <SearchBox
                dataSet={data}
                keys={["title"]}
                setDataSet={setFilteredData}
                setLiftedSearchValue={setSearchValue}
              />
            </div>
          </nav>

          {isListLoading ? (
            <LoadingCentered />
          ) : (
            <>
              {displayData.length ? (
                <TableWithReordering
                  data={displayData}
                  columns={columns}
                  onDragEnd={onDragEnd}
                  isPaginated={true}
                />
              ) : (
                <NoDataBlock
                  message={
                    searchValue ? "No items match your search" : "No items"
                  }
                />
              )}
            </>
          )}

          <Modal
            isOpen={isEditModalOpen}
            setIsOpen={handleCloseEditModal}
            title={
              itemBeingEdited.id
                ? `Editing Section "${itemBeingEdited?.title}"`
                : "Add New Section"
            }
            className="w-75"
            body={
              <EditSpecdocsectionForm
                specdocsection={itemBeingEdited}
                onSuccess={() => {
                  setItemBeingEdited({});
                  setIsEditModalOpen(false);
                }}
                onCancel={() => {
                  setItemBeingEdited({});
                  setIsEditModalOpen(false);
                }}
              />
            }
          />
          <Modal
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
            title={`Deleting Section "${itemBeingDeleted?.title}"?`}
            body="You're about to permanently delete this section, and its data."
            buttons={[
              <button
                onClick={() => onDeleteSection()}
                className={`button is-danger ${
                  isDeleteItemLoading ? "is-loading" : ""
                }`}
              >
                Delete
              </button>,
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="button is-light"
              >
                Cancel
              </button>
            ]}
          />
        </div>
      </section>
    </>
  );
};

export default ManageSpecdocsections;
