import PocTopBar from "components/TopBar/TopBarPoc";
import usePocs from "hooks/usePocs";
import AttachmentCard from "pages/Attachments/AttachmentCard";
import React, { useCallback } from "react";
import {
  useGetPocAttachmentsQuery,
  useAddPocAttachmentsMutation,
  useDeletePocAttachmentsMutation
} from "services/api";
import LayoutContainer from "../../components/Container/LayoutContainer";
import TopBarVisibility from "components/TopBar/TopBarVisibility";
import WithRbac from "../../components/common/Rbac/withRbac";
import { UserPermissions } from "../../config/userPermissions";

export const PocAttachmentsCard = ({ addSection }) => {
  const { currentPoc } = usePocs();
  const { data, isLoading, isFetching } = useGetPocAttachmentsQuery(
    currentPoc.id
  );
  const [del, { isLoading: deleteLoading, error: deleteError }] =
    useDeletePocAttachmentsMutation();
  const [add, { isLoading: addLoading, isError, error }] =
    useAddPocAttachmentsMutation();

  const onDrop = useCallback(
    acceptedFiles => {
      const payload = [];
      acceptedFiles.forEach(file => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("pocId", currentPoc.id);
        formData.append("isListable", "true");
        payload.push(formData);
      });
      add(payload);
    },
    [currentPoc.id]
  );

  const handleAddToSection = fileUrl => {
    addSection(`<img src=${fileUrl} />`);
  };

  return (
    <AttachmentCard
      addToSection={!!addSection ? handleAddToSection : null}
      data={data || []}
      onDelete={id => del({ id })}
      onDrop={onDrop}
      isLoading={isLoading}
      isFetching={isFetching || addLoading || deleteLoading}
      isError={isError}
      error={error || deleteError}
    />
  );
};

export default function PocAttachments() {
  const { currentPoc } = usePocs();
  return (
    <>
      <LayoutContainer parentClassName="is-white-background mb-2">
        <PocTopBar
          poc={currentPoc}
          showScore={true}
          title={currentPoc?.pocName}
        />
      </LayoutContainer>
      <LayoutContainer>
        <WithRbac permissionOneOf={UserPermissions.EVAL_UPDATE}>
          <TopBarVisibility />
        </WithRbac>
        <PocAttachmentsCard />
      </LayoutContainer>
    </>
  );
}
