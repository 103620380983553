import { useCallback, useMemo } from "react";
import {
  useTable,
  usePagination,
  useExpanded,
  useRowSelect
} from "react-table";
import { ChevronRight, ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { formatHeader } from "utils/helpers";
import FooterPaginatedTable from "../FooterPaginatedTable";
import "./Table.css";
import { useState } from "react";
import { LoadingOverlay } from "../Loading";
import NoData from "../NoData/index";
import { Draggable } from "react-beautiful-dnd";

const collapsedIcon = <ChevronRight className="mr-4" />;
const expandedIcon = <ChevronDown className="mr-4" />;

const noop = () => null;
const defaultRowProps = () => ({});
const Table = ({
  className = "",
  columns,
  data,
  extraCellInfo = {},
  getRowProps = defaultRowProps,
  headerHidden = false,
  isPaginated = false,
  isExpandable = false,
  isLoading = false,
  onNext,
  onPrevious,
  onPageSize,
  initialState = {},
  renderExpanded = noop,
  renderBodyHeaderRow = noop,
  isDraggable = false,
  provided = {} // from react-beautiful-dnd
}) => {
  const allColumns = useMemo(
    () =>
      isExpandable
        ? [
            {
              id: "expander",
              Header: () => null,
              Cell: ({ row }) => (
                <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? expandedIcon : collapsedIcon}
                </span>
              )
            },
            ...columns
          ]
        : columns,
    [columns, isExpandable]
  );

  // Building a custom callback to resolve the row ID (using the ID injected in the data object)
  const customGetRowId = (row, relativeIndex, parent) => {
    return row.id ? row.id : relativeIndex;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize },
    visibleColumns
  } = useTable(
    {
      columns: allColumns,
      data,
      autoResetExpanded: false,
      autoResetPage: false,
      initialState,
      getRowId: customGetRowId,
      ...extraCellInfo
    },
    useExpanded,
    usePagination,
    useRowSelect
  );
  const [sort, setSort] = useState(null);
  const handleNext = useCallback(
    evt => {
      onNext && onNext(evt);
      nextPage();
    },
    [onNext, nextPage]
  );

  const handlePrevious = useCallback(
    evt => {
      onPrevious && onPrevious(evt);
      previousPage();
    },
    [onPrevious, previousPage]
  );

  const handlePageSize = useCallback(
    evt => {
      onPageSize && onPageSize(evt);
      setPageSize(+evt.target.value);
    },
    [onPageSize, setPageSize]
  );
  const handleSort = data => {
    if (sort === data.id) {
      setSort(`-${data.id}`);
    } else {
      setSort(data.id);
    }
  };

  const displayRows = isPaginated ? page : rows;
  let sortKey = "";
  let sortReverse = false;
  if (sort) {
    sortReverse = sort?.startsWith("-");
    sortKey = sortReverse ? sort.substring(1) : sort;
    displayRows.sort((a, b) => {
      let valA = a.values[sortKey];
      let valB = b.values[sortKey];
      if (valA && valB) {
        if (typeof valA === "object") {
          return false;
        }
        if (isNaN(valA) && isNaN(valB)) {
          if (valA.startsWith("$") && valB.startsWith("$")) {
            valA = valA.replace(/\D/g, "");
            valB = valB.replace(/\D/g, "");
          }
          return (sortReverse ? valA > valB : valA < valB) ? -1 : 1;
        } else {
          return sortReverse ? valA - valB : valB - valA;
        }
      }
    });
  }

  const renderRow = (row, snapshot, provided) => {
    const { isDragging } = snapshot || {};

    // Loop over the rows cells
    return row.cells.map((cell, i) => {
      // Apply the cell props

      if (isDragging && !cell.column.showsWhileDragging) {
        return <td></td>;
      }

      const dragProps = cell.column.isDragHandle
        ? { ...provided.dragHandleProps }
        : {};

      return (
        <td {...cell.getCellProps()} {...dragProps}>
          {
            // Render the cell contents
            cell.render("Cell")
          }
        </td>
      );
    });
  };

  return (
    <>
      {!displayRows.length && !renderBodyHeaderRow ? (
        <NoData />
      ) : (
        <div>
          <table
            {...getTableProps()}
            className={`table is-fullwidth is-hoverable table-hover px-5 Table ${className}`}
          >
            <thead className={`${headerHidden ? "Table-head-hidden" : ""}`}>
              {headerGroups.map((group, i) => (
                <tr key={i} {...group.getHeaderGroupProps()}>
                  {group.headers.map((column, idx) => {
                    const isBulkAttach = column.id === "bulk-attach";
                    return (
                      <th
                        key={idx}
                        {...column.getHeaderProps()}
                        className={`py-4 has-text-grey-light is-clickable has-text-weight-light whitespace-nowrap ${
                          column.thClassName || ""
                        } ${formatHeader(column)} ${
                          idx === 0 && isExpandable ? "Table-expand-column" : ""
                        }`}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}{" "}
                        {!isBulkAttach && sortKey && column.id === sortKey ? (
                          sortReverse ? (
                            <ChevronDown />
                          ) : (
                            <ChevronUp />
                          )
                        ) : null}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {renderBodyHeaderRow({ columns, data })}

              {
                // Loop over the table rows
                displayRows.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);

                  const { ...otherProps } = row.getRowProps(getRowProps(row));
                  let expandedContent = null;
                  if (isExpandable && row?.isExpanded) {
                    expandedContent = renderExpanded({ row, ...extraCellInfo });
                  }

                  const loadingRow = isLoading && (
                    <tr key={i}>
                      <td colSpan={visibleColumns.length} className="px-6">
                        <LoadingOverlay />
                      </td>
                    </tr>
                  );

                  const expandedContentRow = expandedContent && (
                    <tr
                      key={i}
                      className={`Table-no-hover ${
                        row.isExpanded ? "" : "hidden"
                      }`}
                    >
                      <td colSpan={visibleColumns.length} className="px-6">
                        {expandedContent}
                      </td>
                    </tr>
                  );

                  return isDraggable ? (
                    <Draggable
                      key={row.id}
                      draggableId={`dragId-${row.id}`}
                      isDragDisabled={!isDraggable}
                      index={i++}
                    >
                      {(provided, snapshot) => (
                        <>
                          <tr
                            {...row.getRowProps()}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={row.id}
                          >
                            {renderRow(row, snapshot, provided)}
                          </tr>
                          {loadingRow}
                          {expandedContentRow}
                        </>
                      )}
                    </Draggable>
                  ) : (
                    <>
                      <tr {...row.getRowProps()} key={row.id}>
                        {renderRow(row)}
                      </tr>
                      {loadingRow}
                      {expandedContentRow}
                    </>
                  );
                })
              }

              {/* {displayRows.map(row => {
                prepareRow(row);
                const { key, ...otherProps } = row.getRowProps(
                  getRowProps(row)
                );
                let expandedContent = null;
                if (isExpandable && row?.isExpanded) {
                  expandedContent = renderExpanded({ row, ...extraCellInfo });
                }
                return (
                  <Fragment key={key}>
                    <tr {...otherProps}>
                      {row.cells.map(cell => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`py-2 ${cell.column.tdClassName || ""}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>

                    {isLoading && (
                      <tr>
                        <td colSpan={visibleColumns.length} className="px-6">
                          <LoadingOverlay />
                        </td>
                      </tr>
                    )}
                    {expandedContent && (
                      <tr
                        className={`Table-no-hover ${
                          row.isExpanded ? "" : "hidden"
                        }`}
                      >
                        <td colSpan={visibleColumns.length} className="px-6">
                          {expandedContent}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })} */}
            </tbody>
          </table>
          {isPaginated && (
            <FooterPaginatedTable
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              handleNext={handleNext}
              handlePageSize={handlePageSize}
              handlePrevious={handlePrevious}
              numRows={rows.length}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Table;
