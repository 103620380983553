import React, { useState } from "react";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import debounce from "debounce";
import { currency } from "utils/helpers";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export default function SliderCard({ filter, onClickFilter }) {
  const [sliderValues, setSliderValues] = useState([0, 0]);

  const handleChange = newSliderValues => {
    onClickFilter({
      pocTotalAcvMin: newSliderValues[0],
      pocTotalAcvMax: newSliderValues[1]
    });
  };

  return (
    <div>
      <Range
        min={0}
        max={5000000}
        defaultValue={[filter.pocTotalAcvMin || 0, filter.pocTotalAcvMax || 0]}
        onChange={debounce(handleChange, 1500)}
        tipProps={{ visible: true }}
        tipFormatter={value => <span>{currency(value)}</span>}
      />
    </div>
  );
}
