import { NavLink, useLocation } from "react-router-dom";

export default function NiceTabs({ tabs, className }) {
  const { pathname } = useLocation();

  return (
    <div className={`nice-tabs tabs mt-4 ${className}`}>
      <ul>
        {tabs.map(({ urlTo, title, counter, icon }) => (
          <li key={title} className={urlTo === pathname ? "is-active" : ""}>
            <NavLink to={urlTo}>
              {icon && (
                <span className="icon is-small">
                  <img src={icon} alt={`${title} logo`} className="mr-1" />
                </span>
              )}{" "}
              <span>{title}</span>{" "}
              {counter ? (
                <span className="tag is-white ml-2">{counter}</span>
              ) : (
                ""
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
