import useVendor from "hooks/useVendor";

const useFeatureFilter = () => {
  const { vendor } = useVendor();

  let vendorType = "freemium";
  if (
    vendor?.id === "3fe76a18-b7f7-4a1a-8a34-ab24c90deb3e" || // SpectroCloud
    vendor?.id === "e9f24d56-d9f2-4448-ba1d-1197d0d36f9b" || // Acme Corp
    vendor?.id === "3dfaaa4e-00fd-409f-80ec-27bc2cc4016b" ||
    vendor?.id === "c286185e-f65a-4d1b-b03a-9220480eeb7a" ||
    vendor?.id === "7ec41557-bd35-4e5b-9c81-616fb4ab01bd" ||
    vendor?.id === "9a9476d1-d4a2-4d16-9753-2403697170dc" /* Securiti */
  ) {
    vendorType = "enterprise";
  }

  const features = {
    // General Navigation
    showResourceTab: true,
    showAnalyticsTab: vendorType === "enterprise" ? true : false,

    // POC Navigation
    showPocFeedback: vendorType === "enterprise" ? true : false,
    showPocAttachments: vendorType === "enterprise" ? true : false,

    // Elements
    showPocExportPdf: vendorType === "enterprise" ? true : false,
    showPocExtendedFields: false,
    showPercentages: false,
    showSearchFilters: false,
    showChecklistPoints: vendorType === "enterprise" ? true : false,
    showUsecaseDisplayModes: false,
    showUsecaseLineOfProduct: false,

    showVcDemoFeatures: [
      "3dfaaa4e-00fd-409f-80ec-27bc2cc4016b" /* Freemium Demo */,
      "e9f24d56-d9f2-4448-ba1d-1197d0d36f9b" /* Acme Corp */,
      "9a9476d1-d4a2-4d16-9753-2403697170dc" /* Securiti */
    ].includes(vendor?.id),

    showExtraFeatures: false
  };

  return features;
};

export default useFeatureFilter;
