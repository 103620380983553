import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Attachments"] }).injectEndpoints({
    endpoints: build => ({
      getAttachments: build.query({
        query: vendorId => `/vendors/${vendorId}/files`,
        providesTags: ["Attachments"]
      }),

      uploadFile: build.mutation({
        query: body => {
          return {
            url: `/files/upload`,
            method: "POST",
            body
          };
        },
        invalidatesTags: ["Attachments", { type: "PocUsecases" }]
      }),
      addAttachments: build.mutation({
        async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
          const response = await Promise.all(
            body.payload.map(atc =>
              fetchWithBaseQuery({
                url: "/files/upload",
                method: "POST",
                body: atc
              })
            )
          );
          const successes = response
            .filter(res => !res.error)
            .map(res => res.data);
          const failures = response
            .filter(res => !res.data)
            .map(res => res.error);

          if (failures.length > 0) return { data: null, error: failures };
          return { data: successes, error: null };
        },
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            api.util.updateQueryData("getAttachments", body.vendorId, draft => {
              const newPayload = body.payload.map(atc => {
                return { ...atc, updatedAt: new Date() };
              });
              return newPayload.concat(draft);
            })
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
        invalidatesTags: ["Attachments"]
      }),
      deleteAttachments: build.mutation({
        query: ({ id }) => ({
          url: `/files/${id}`,
          method: "DELETE"
        }),
        async onQueryStarted({ id, vendorId }, { dispatch, queryFulfilled }) {
          const deleteResult = dispatch(
            api.util.updateQueryData("getAttachments", vendorId, draft => {
              const idx = draft.findIndex(atc => atc.id === id);
              if (idx > -1) {
                draft.splice(idx, 1);
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            deleteResult.undo();
          }
        },
        invalidatesTags: ["Attachments"]
      })
    })
  });
