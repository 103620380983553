import React from "react";
import { Route, Routes } from "react-router-dom";
import ManageUsecases from "./index";

export default function ManageUsecasesRoutes() {
  return (
    <Routes>
      <Route path="" element={<ManageUsecases type="active" />} />
      <Route path="archived" element={<ManageUsecases type="archived" />} />
    </Routes>
  );
}
