import Error from "./Error";

const Input = ({
  className = "mb-5",
  inputClassName = "",
  name,
  label,
  error,
  addOnPrefix,
  type = "text",
  ...rest
}) => (
  <div className={`form-group ${className}`}>
    {label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div className="input-group">
      {addOnPrefix ? (
        <span className="input-group-text">{addOnPrefix}</span>
      ) : (
        ""
      )}
      <input
        {...rest}
        name={name}
        id={name}
        type={type}
        className={`${
          type === "checkbox" ? "form-check-input" : "form-control input"
        } ${error ? "error" : ""} ${inputClassName}`}
        autoFocus
      />
    </div>
    <Error message={error} />
  </div>
);

export default Input;
