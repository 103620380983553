import api from "./_createApi";

export default !!api &&
  api.enhanceEndpoints({ addTagTypes: ["Vendors"] }).injectEndpoints({
    endpoints: build => ({
      getVendor: build.query({
        query: id => `/vendors/${id}`,
        providesTags: result => [{ type: "Vendors", id: result?.id }]
      }),
      getVendorUsers: build.query({
        query: id => `/vendors/${id}/users`,
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Users", id })),
                { type: "Users", id: "LIST" }
              ]
            : [{ type: "Users", id: "LIST" }]
      }),
      editVendor: build.mutation({
        query: ({ id, ...body }) => ({
          url: `/vendors/${id}`,
          method: "PATCH",
          body
        }),
        async onQueryStarted(body, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData("getVendor", body.vendorId, draft => {
              const data = draft.find(x => x.id === body.id);
              for (let key in body) {
                data[key] = body[key];
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: result => [{ type: "Vendors", id: result.id }]
      }),
      getExternalSearch: build.query({
        query: ({ id, ...body }) => ({
          method: "POST",
          url: `/vendorProviders/${id}/search`,
          body
        }),
        providesTags: ["AggregateFeedbacks"]
      }),
      getExternalSearchFeedbacks: build.query({
        query: ({ vendorProviderId, linkedItemId, filter }) => {
          let url = `/vendorProviders/${vendorProviderId}/${linkedItemId}/feedbackBreakdown`;
          if (filter && filter.length > 0) {
            url += filter;
          }
          return url;
        },
        providesTags: ["AggregateFeedbacks"]
      }),
      addExternalComment: build.mutation({
        query: ({ vendorProviderId, linkedItemId, ...body }) => ({
          method: "POST",
          url: `/vendorProviders/${vendorProviderId}/${linkedItemId}/comments`,
          body
        }),
        providesTags: ["AggregateFeedbacks"]
      }),
      getChecklistTemplateList: build.query({
        query: vendorId =>
          vendorId ? `/vendors/${vendorId}/checklistitems/templates` : ""
      }),
      getUsecaseTemplateList: build.query({
        query: vendorId => `vendors/${vendorId}/usecases/templates`
      }),
      getQuestionTemplateList: build.query({
        query: vendorId => `vendors/${vendorId}/questions/templates`
      }),
      getVendorRbac: build.query({
        query: id => `/vendors/${id}/rbac`
      }),
      searchUserAutocomplete: build.query({
        query: ({ vendorId, search }) =>
          `/vendors/${vendorId}/users/autocomplete/${search}`
      })
    })
  });
