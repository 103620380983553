import React from "react";
import "./index.css";

const Stepper = ({ phases, phase, mainClassName }) => {
  const stepsArray = Object.values(phases);
  const index = stepsArray.indexOf(phase);

  return (
    <div className="column wrapper option-1 option-1-1">
      <ol className={`${mainClassName} stepper`}>
        {stepsArray.map((step, i) => {
          return (
            <li
              key={i}
              data-content={i + 1}
              className={`stepper__item ${
                i < index ? "completed" : i === index ? "active" : ""
              }`}
            >
              <div className="stepper__title">{step}</div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Stepper;
