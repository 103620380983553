import { XCircle } from "react-bootstrap-icons";

// TODO: move the props from message to error
// standardize on rtk error shape:
// { error: { status, data: { message } } } or null
const APIError = ({ message, clear, error }) => {
  let innerMessage = message || error?.data?.message || error?.error;
  if (Array.isArray(error)) {
    innerMessage = error.map(err => err.data.message);
  }

  if (!innerMessage) return null;

  return (
    <div className="notification is-danger is-light">
      {Array.isArray(innerMessage) ? (
        <ul>
          {innerMessage.map((m, i) => (
            <li key={`${m}-${i}`}>{m}</li> // eslint-disable-line
          ))}
        </ul>
      ) : (
        innerMessage
      )}
      {clear && <XCircle onClick={clear} />}
    </div>
  );
};

export default APIError;
