import React from "react";
import PocBasicInfo from "./BasicInfo";
import PocReview from "./PocReview";
import PocStatus from "./PocStatus";

export default function PocOverviewCards({ poc }) {
  const cards = poc.id ? [<PocBasicInfo key="info" poc={poc} />] : [];

  if (poc.phase === "Completed" || poc.phase === "Archived") {
    cards.unshift(<PocStatus key="status" poc={poc} />);
    // cards.unshift(<PocReview key="review" poc={poc} />);
  }

  return cards.map(card => card);
}
