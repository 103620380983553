import { createSlice } from "@reduxjs/toolkit";

const overviewSlice = createSlice({
  name: "poc",
  initialState: {
    id: "",
    phase: ""
  },
  reducers: {
    setPhase(state, action) {
      state.phase = action.payload;
    },
    setPoc(state, action) {
      state.id = action.payload.id;
      state.phase = action.payload.phase;
    }
  }
});
export const { setPhase, setPoc } = overviewSlice.actions;
export default overviewSlice.reducer;
