import { useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";
import useAuth from "hooks/useAuth";
import useBatch from "hooks/useBatch";
import {
  useGetMetaQuery,
  useAddPocsMutation,
  useGetPocsQuery
} from "services/api";
import Button from "components/common/Button";
import APIError from "components/common/APIError";
import Loading from "components/common/Loading";
import FooterPaginatedTable from "components/common/FooterPaginatedTable";
import { currency } from "utils/helpers";

const getSetValues = obj => (obj ? new Set(Object.values(obj)) : new Set());

const getValue = (field, fields, data) => {
  const { key } = fields.find(x => x.as === field);
  if (key.includes(".")) {
    const [a, b] = key.split(".");
    return data[a][b];
  } else {
    return data[key] || "";
  }
};

const BatchImportTable = ({ data, vendor }) => {
  const { ids: createIds, clearIds, toggleId } = useBatch();
  const { data: pocs } = useGetPocsQuery();
  const [
    createPocs,
    { data: createData, isLoading: createIsLoading, error: pocError }
  ] = useAddPocsMutation();
  const { currentUser } = useAuth();
  const {
    data: metaData,
    error: metaError,
    isLoading: metaIsLoading
  } = useGetMetaQuery();
  const { industries, geographies, segments } = metaData || {};

  const industriesSet = useMemo(() => getSetValues(industries), [industries]);
  const geographiesSet = useMemo(
    () => getSetValues(geographies),
    [geographies]
  );
  const segmentsSet = useMemo(() => getSetValues(segments), [segments]);

  const salesforceIds = useMemo(
    () =>
      pocs
        ? pocs?.reduce(
            (a, r) =>
              !!r.salesforceOpportunityId
                ? [...a, r.salesforceOpportunityId]
                : a,
            []
          )
        : [],
    [pocs]
  );

  const fields = useMemo(
    () =>
      vendor?.salesforceFieldsMap
        ? JSON.parse(vendor?.salesforceFieldsMap)
        : [
            {
              label: "Opportunity Name",
              key: "Name",
              as: "pocName",
              width: 300
            },
            {
              label: "Assigned To",
              key: "Owner.Name",
              as: "companyName",
              width: 150
            },
            { label: "ACV", key: "Amount", as: "totalAcv", type: "currency" },
            { label: "Stage", key: "StageName" },
            { label: "Industry", key: "Account.Industry", as: "industryName" },
            { label: "Segment", key: "Account.Segment__c", as: "segmentName" },
            {
              label: "Geography",
              key: "Account.Geography__c",
              as: "geographyName"
            }
          ],
    [vendor?.salesforceFieldsMap]
  );

  const columns = useMemo(() => {
    return fields.map(({ label, key, width, type }) => ({
      Header: label,
      accessor: v => {
        let value = "";
        const isCurrency = type === "currency";
        if (key.includes(".")) {
          const [a, b] = key.split(".");
          value = v[a][b];
        } else {
          value = v[key];
        }
        if (isCurrency) value = currency(value);
        return value || "-";
      },
      width
    }));
  }, [fields]);

  const batchCreate = () => {
    const dataToPost = data
      .filter(d => createIds.has(d.Id))
      .map(d => {
        return {
          salesforceOpportunityId: d.Id,
          pocName: getValue("pocName", fields, d),
          companyName: getValue("companyName", fields, d),
          totalAcv: getValue("totalAcv", fields, d),
          amount: d.Amount,
          segmentName: segmentsSet.has(getValue("segmentName", fields, d))
            ? getValue("segmentName", fields, d)
            : null,
          geographyName: geographiesSet.has(
            getValue("geographyName", fields, d)
          )
            ? getValue("geographyName", fields, d)
            : null,
          industryName: industriesSet.has(getValue("industryName", fields, d))
            ? getValue("industryName", fields, d)
            : null,
          img: "https://benti-energies.com/asset/images/clients/logo-default.svg",
          startDate: dayjs().format(),
          endDate: dayjs().add(90, "day").format(),
          createdByUserId: currentUser.id,
          vendorId: currentUser.vendorId
        };
      });
    createPocs(dataToPost);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setSortBy,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize }
  } = useTable({ columns, data }, useSortBy, usePagination);

  if (metaIsLoading || createIsLoading) return <Loading />;

  if (createData) return <Navigate to="/dashboard" />;

  const handlePrevious = () => {
    clearIds();
    previousPage();
  };

  const handleNext = () => {
    clearIds();
    nextPage();
  };

  const handlePageSize = e => {
    clearIds();
    setPageSize(+e.target.value);
  };

  return (
    <div>
      <APIError error={pocError || metaError} />
      <div className="table-container">
        <table {...getTableProps()} className="table is-fullwidth is-hoverable">
          <thead>
            {headerGroups.map(group => (
              <tr {...group.getHeaderGroupProps()}>
                <th width="50" className="is-vcentered"></th>
                {group.headers.map(cell => (
                  <th
                    {...cell.getHeaderProps()}
                    width={cell.width}
                    className="is-size-6 has-text-grey-light has-text-weight-light has-text-weight-light is-clickable"
                    onClick={() => {
                      const desc =
                        cell.isSortedDesc === true
                          ? undefined
                          : cell.isSortedDesc === false
                          ? true
                          : false;
                      setSortBy([{ id: cell.id, desc }]);
                    }}
                  >
                    {cell.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              const isImported = salesforceIds.includes(row.original.Id);
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={isImported ? "has-text-grey" : ""}
                >
                  <td className="is-vcentered">
                    <input
                      type="checkbox"
                      className="form-check-input larger"
                      onChange={() => toggleId(row.original.Id)}
                      checked={createIds.has(row.original.Id) || isImported}
                      disabled={isImported}
                    />
                  </td>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <FooterPaginatedTable
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        handleNext={handleNext}
        handlePageSize={handlePageSize}
        handlePrevious={handlePrevious}
        numRows={rows.length}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
      <div className="is-flex is-justify-content-flex-end">
        <Button
          disabled={createIds.size === 0}
          className="is-info"
          onClick={batchCreate}
        >
          Create {createIds.size} Opportunity(ies)
        </Button>
      </div>
    </div>
  );
};

export default BatchImportTable;
