import { useState, useEffect, useCallback } from "react";
import Joi from "joi-browser";
import DateFnsUtils from "@date-io/date-fns";

const dateFns = new DateFnsUtils();

const copyObjectKeys = obj => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    newObj[key] = "";
  });
  return newObj;
};

const useForm = (schema, initialState = copyObjectKeys(schema)) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const reset = useCallback(() => {
    setData(initialState);
    setErrors({});
  }, [initialState]);

  useEffect(reset, [reset]);

  const validate = useCallback(() => {
    const options = { abortEarly: false };

    const { error, value } = Joi.validate(data, schema, options);
    const formErrors = {};
    if (error) {
      error.details.forEach(item => {
        formErrors[item.path[0]] = item.message;
      });
      console.warn("validation errors:", formErrors);
    }
    setErrors(formErrors);
    return { error: formErrors, value };
  }, [data, schema]);

  const validateProperty = useCallback(
    (name, value) => {
      if (!schema[name]) {
        return null;
      }

      const obj = { [name]: value };
      const singleSchema = { [name]: schema[name] };
      const { error } = Joi.validate(obj, singleSchema);

      return error ? error.details[0].message : null;
    },
    [schema]
  );

  const handleChange = useCallback(
    ({ currentTarget: input }) => {
      setErrors(oldErrors => {
        const errorCopy = { ...oldErrors };
        const errorMessage = validateProperty(input.name, input.value);
        if (errorMessage) errorCopy[input.name] = errorMessage;
        else delete errorCopy[input.name];
        return errorCopy;
      });

      let { value } = input;

      // Special case to handle "multiple" select element
      if (input.type === "select-multiple") {
        // We have more than one selected
        if (input.selectedOptions.length > 1) {
          value = Array.from(input.selectedOptions, option => option.value);
        } else {
          // We have a unique value selected
          const { selectedIndex } = input.options;
          value = [input.options[selectedIndex].value];
        }
      }

      setData(oldData => ({ ...oldData, [input.name]: value }));
    },
    [validateProperty]
  );

  // As the MUI DatePicker component doesn't return the event, but the selected date, creating a fake Input is necessary to properly save the data to the state
  const handleChangeDatePicker = useCallback(
    (name, date) => {
      // 2021-03-20 14:32:20
      const fakeInput = { name };
      try {
        const dateString = dateFns.format(date, "yyyy-MM-dd");
        fakeInput.value = dateString;
      } catch (err) {
        fakeInput.value = date;
        setErrors(errs => ({ ...errs, [name]: err.message }));
      }
      handleChange({ currentTarget: fakeInput });
    },
    [handleChange]
  );

  return {
    validate,
    handleChange,
    handleChangeDatePicker,
    data,
    errors,
    reset
  };
};

export default useForm;
