import { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";

const useAuth = () => {
  const user = useSelector(state => state.auth.user, shallowEqual);
  const token = useSelector(state => state.auth.token, shallowEqual);
  const getCurrentStakeholder = useCallback(
    poc => {
      if (!user) return null;
      const stakeholder = poc?.stakeholders?.find(s => s.userId === user?.id);
      return stakeholder;
    },
    [user]
  );

  return {
    token,
    currentUser: user,
    isAdmin: user?.roles?.includes("Admin") || false,
    isProspect: user?.roles?.includes("ProspectUser") || false,
    isSalesEngineer:
      user?.roles?.includes("SalesEngineer") ||
      user?.roles?.includes("SeManager") ||
      false,
    isFreemium: true,
    getCurrentStakeholder
  };
};

export default useAuth;
