import { formatDate, currency } from "utils/helpers";
import FeedbackCellSelectableButton from "components/common/TableCells/FeedbackCellSelectableButton";

export const columns = [
  {
    Header: "Account Name",
    accessor: "pocName"
  },
  {
    Header: "Feedback",
    accessor: "feedbackBody"
  },
  {
    Header: "Sales Engineer",
    accessor: ({ salesEngineerFirstName, salesEngineerLastName }) =>
      salesEngineerFirstName
        ? `${salesEngineerFirstName} ${salesEngineerLastName}`
        : "-"
  },
  {
    Header: "ACV",
    accessor: ({ pocTotalAcv }) => (pocTotalAcv ? currency(pocTotalAcv) : "-")
  },
  {
    Header: "Priority",
    accessor: ({ feedbackPriority }) => feedbackPriority,
    Cell: FeedbackCellSelectableButton
  },
  {
    Header: "Due Date",
    accessor: ({ feedbackDueDate }) =>
      feedbackDueDate ? formatDate(feedbackDueDate) : "-"
  },
  {
    Header: "Eval Close Date",
    accessor: ({ pocEndDate }) => (pocEndDate ? formatDate(pocEndDate) : "-")
  }
];
