import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import {
  useAddQuestionsAndAnswersMutation,
  useGetMetaQuery
} from "services/api";
import { addNewInput } from "utils/helpers";
import Modal from "components/common/Modal";
import WithRbac from "components/common/Rbac/withRbac";
import { UserPermissions } from "config/userPermissions";

export default function CreateNewQuestionModal({
  modalOpen: isModalOpen,
  handleClose,
  handleCloseAdd,
  updateQuestions,
  defaultVisibility,
  defaultBody,
  isLoading
}) {
  const { currentUser } = useAuth();
  const { data: metadata } = useGetMetaQuery();

  const initPayload = {
    question: {
      body: defaultBody || "",
      notes: "",
      vendorId: currentUser.vendorId,
      archived: false,
      visibility: defaultVisibility
    },
    answerList: [{ body: "", isFreeText: false, archived: false, notes: "" }]
  };
  const [payload, setPayload] = useState(initPayload);
  let newPayload = { ...payload };

  const [add, { isLoading: addLoading }] = useAddQuestionsAndAnswersMutation({
    fixedCacheKey: "batch-add-questions"
  });

  useEffect(() => {
    newPayload.question.body = defaultBody;
    setPayload(newPayload);
  }, [defaultBody]);

  const handleChangeQuestion = (e, key) => {
    newPayload.question[key] = e.target.value;
    setPayload(newPayload);
  };
  const handleChangeAnswer = (index, key, value) => {
    newPayload.answerList[index][key] = value;
    setPayload(newPayload);
  };

  const onEnter = e => {
    if (e.key === "Enter") {
      addAnswer();
    }
  };
  const addAnswer = () => {
    newPayload.answerList.push({
      body: "",
      isFreeText: false,
      archived: false,
      notes: ""
    });
    setPayload(newPayload);
  };
  const removeAnswer = index => {
    newPayload.answerList.splice(index, 1);
    setPayload(newPayload);
  };

  const onSubmit = async () => {
    const { question, answerList } = payload;
    const finalPayload = {
      question,
      answers: answerList.filter(({ body, isFreeText }) => body || isFreeText)
    };
    const isQuestionLess = question.body?.length < 1;
    const isAnswerLess =
      answerList.filter(({ body, isFreeText }) => body || isFreeText).length !==
      answerList.length;

    if (!isQuestionLess && !isAnswerLess) {
      if (updateQuestions || handleCloseAdd) {
        const res = await add(finalPayload);
        updateQuestions({ ...res.data.question, answers: res.data.answers });
        handleCloseAdd();
      } else {
        add(finalPayload);
      }

      handleClose();
      setPayload(initPayload);
    }
    isQuestionLess && toast.error("Question must not be empty!");
    isAnswerLess && toast.error("Answer must not be empty!");
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={handleClose}
      title={"Create a new Question"}
      body={
        <>
          <div className="columns">
            <div className="column is-two-thirds field ">
              <label className="label">Question</label>
              <div className="control">
                <input
                  onChange={e => handleChangeQuestion(e, "body")}
                  name="body"
                  className="input form-control"
                  defaultValue={payload.question.body}
                  placeholder="Question"
                />
              </div>
            </div>

            <div className="column">
              <WithRbac permissionOneOf={UserPermissions.SCOPING_MANAGE}>
                <div className="field">
                  <label className="label">Scope</label>
                  <div className="control">
                    <div className="select">
                      <select
                        defaultValue={
                          metadata?.entityVisibility?.ONE_OFF || "One-Off"
                        }
                        onChange={e =>
                          setPayload({ ...payload, visibility: e.target.value })
                        }
                        name="visibility"
                      >
                        {Object.keys(metadata?.entityVisibility)?.map(
                          (key, i) => {
                            const value = metadata?.entityVisibility[key];
                            return (
                              <option value={value} key={i}>
                                {value}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </WithRbac>
            </div>
          </div>

          {/* <div className="mt-3">Description</div>
          <textarea
            onChange={e => handleChangeQuestion(e, "notes")}
            name="notes"
            className="input form-control w-100 h-30r"
            value={payload.question.notes}
          /> */}
          <form onSubmit={e => e.preventDefault()}>
            <div className="mt-3 mr-3 is-flex is-justify-content-end">
              Free text?
            </div>
            {payload.answerList.map((a, i) => (
              <div
                key={i}
                className="is-flex mt-3 is-align-items-center mr-5 pl-2"
              >
                <div className="w-4r is-flex is-justify-content-end gap-1 pr-1">
                  {payload.answerList.length > 1 && (
                    <DashCircle onClick={() => removeAnswer(i)} size={21} />
                  )}
                  {payload.answerList.length - 1 === i && (
                    <PlusCircle size={21} onClick={addAnswer} />
                  )}
                </div>
                <input
                  name="input"
                  value={a.isFreeText ? "" : a.body}
                  onChange={e => handleChangeAnswer(i, "body", e.target.value)}
                  onKeyDown={e => addNewInput(e, addAnswer)}
                  className={`input form-control w-100 ml-1 mr-2 ${
                    a.isFreeText ? "has-background-light has-text-grey" : ""
                  }`}
                  placeholder={
                    a.isFreeText ? "Free Text" : "Type new answer here"
                  }
                />
                <div className="w-6r text-center is-flex is-align-items-center is-justify-content-center">
                  <input
                    defaultChecked={a.isFreeText}
                    onChange={e =>
                      handleChangeAnswer(i, "isFreeText", e.target.checked)
                    }
                    className="form-check-input larger"
                    type="checkbox"
                  />
                </div>
              </div>
            ))}
          </form>
        </>
      }
      buttons={[
        <button
          onClick={onSubmit}
          className={`button is-info ${
            isLoading || (updateQuestions && addLoading) ? "is-loading" : ""
          }`}
        >
          Create
        </button>,
        <button onClick={handleClose} className="button is-light">
          Cancel
        </button>
      ]}
    />
  );
}
