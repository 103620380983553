import api from "./_createApi";

export default !!api &&
  api
    .enhanceEndpoints({ addTagTypes: ["POCs", "PocScore", "PocProgress"] })
    .injectEndpoints({
      endpoints: build => ({
        getPocs: build.query({
          query: () => `/pocs`,
          providesTags: result =>
            result
              ? [
                  ...result.map(({ id }) => ({ type: "POCs", id })),
                  { type: "POCs", id: "LIST" }
                ]
              : [{ type: "POCs", id: "LIST" }]
        }),
        addPoc: build.mutation({
          async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
            const pocResponse = await fetchWithBaseQuery({
              url: "/pocs",
              method: "POST",
              body
            });
            return pocResponse;
          },
          invalidatesTags: [{ type: "POCs", id: "LIST" }]
        }),
        addPocs: build.mutation({
          async queryFn(body, _queryApi, _extraOptions, fetchWithBaseQuery) {
            const pocResponse = await Promise.all(
              body.map(poc =>
                fetchWithBaseQuery({ url: "/pocs", method: "POST", body: poc })
              )
            );
            const successes = pocResponse
              .filter(res => !res.error)
              .map(res => res.data);
            const failures = pocResponse
              .filter(res => !res.data)
              .map(res => res.error);
            return {
              data: successes.length > 0 ? successes : null,
              error: failures.length > 0 ? failures : null
            };
          },
          invalidatesTags: [{ type: "POCs", id: "LIST" }]
        }),
        editPoc: build.mutation({
          query: ({ id, ...body }) => ({
            url: `/pocs/${id}`,
            method: "PATCH",
            body
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              api.util.updateQueryData("getPocs", undefined, draft => {
                const poc = draft.find(poc => poc.id === body.id);
                if (poc) {
                  for (let key in body) {
                    poc[key] = body[key];
                  }
                }
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          },
          invalidatesTags: ({ id }) => [{ type: "POCs", id }]
        }),
        deletePoc: build.mutation({
          query: ({ id }) => ({
            url: `/pocs/${id}`,
            method: "DELETE"
          }),
          async onQueryStarted(body, { dispatch, queryFulfilled }) {
            const patchResult = dispatch(
              api.util.updateQueryData("getPocs", undefined, draft => {
                const pocIndex = draft.findIndex(poc => poc.id === body.id);
                draft.splice(pocIndex, 1);
              })
            );
            try {
              await queryFulfilled;
            } catch {
              patchResult.undo();
            }
          },
          invalidatesTags: ({ id }) => [{ type: "POCs", id }]
        }),
        getPocScore: build.query({
          query: pocId => (pocId ? `/pocs/${pocId}/score` : ""),
          providesTags: (result, _, pocId) => {
            return [{ type: "PocScore", id: pocId }];
          }
        }),
        getPocProgress: build.query({
          query: pocId => (pocId ? `/pocs/${pocId}/progress` : ""),
          providesTags: (result, _, pocId) => {
            return [{ type: "PocProgress", id: pocId }];
          }
        })
      })
    });
