/**
 * These permissions can be directly copy pasted from the endpoint /admin/metadata
 */
export const UserPermissions = {
  EVAL_READ: "eval:read",
  EVAL_CREATE: "eval:create",
  EVAL_UPDATE: "eval:update",
  EVAL_UPDATEPHASE: "eval:updatePhase",
  EVAL_DELETE: "eval:delete",
  EVALSPECDOCSECTIONS_READ: "evalspecdocsections:read",
  EVALSPECDOCSECTIONS_CREATE: "evalspecdocsections:create",
  EVALSPECDOCSECTIONS_UPDATE: "evalspecdocsections:update",
  EVALSPECDOCSECTIONS_DELETE: "evalspecdocsections:delete",
  EVALSPECDOCSECTIONS_FORCEDELETE: "evalspecdocsections:forceDelete",
  EVALSPECDOCSECTIONS_IMPORTTEMPLATE: "evalspecdocsections:importTemplate",
  EVALSPECDOCSECTIONS_REORDER: "evalspecdocsections:reorder",
  EVALSCOPING_READ: "evalscoping:read",
  EVALSCOPING_CREATE: "evalscoping:create",
  EVALSCOPING_UPDATE: "evalscoping:update",
  EVALSCOPING_DELETE: "evalscoping:delete",
  EVALSCOPING_MANAGE: "evalscoping:manage",
  EVALSCOPING_REORDER: "evalscoping:reorder",
  EVALSCOPING_IMPORTTEMPLATE: "evalscoping:importTemplate",
  EVALSCOPING_MARKCOMPLETE: "evalscoping:markComplete",
  EVALSCOPING_REVISE: "evalscoping:revise",
  EVALSCOPING_APPROVE: "evalscoping:approve",
  EVALSCOPING_SENDAPPROVAL: "evalscoping:sendApproval",
  EVALQUESTIONNAIRE_READ: "evalquestionnaire:read",
  EVALQUESTIONNAIRE_CREATE: "evalquestionnaire:create",
  EVALQUESTIONNAIRE_UPDATE: "evalquestionnaire:update",
  EVALQUESTIONNAIRE_DELETE: "evalquestionnaire:delete",
  EVALQUESTIONNAIRE_REORDER: "evalquestionnaire:reorder",
  EVALQUESTIONNAIRE_MANAGE: "evalquestionnaire:manage",
  EVALQUESTIONNAIRE_IMPORTTEMPLATE: "evalquestionnaire:importTemplate",
  EVALQUESTIONNAIRE_ANSWER: "evalquestionnaire:answer",
  EVALACTIONPLAN_READ: "evalactionplan:read",
  EVALACTIONPLAN_CREATE: "evalactionplan:create",
  EVALACTIONPLAN_UPDATE: "evalactionplan:update",
  EVALACTIONPLAN_DELETE: "evalactionplan:delete",
  EVALACTIONPLAN_REORDER: "evalactionplan:reorder",
  EVALACTIONPLAN_MANAGE: "evalactionplan:manage",
  EVALACTIONPLAN_IMPORTTEMPLATE: "evalactionplan:importTemplate",
  EVALACTIONPLAN_MARKCOMPLETE: "evalactionplan:markComplete",
  EVALATTACHMENTS_READ: "evalattachments:read",
  EVALATTACHMENTS_CREATE: "evalattachments:create",
  EVALATTACHMENTS_UPDATE: "evalattachments:update",
  EVALATTACHMENTS_DELETE: "evalattachments:delete",
  EVALATTACHMENTS_REORDER: "evalattachments:reorder",
  EVALATTACHMENTS_MANAGE: "evalattachments:manage",
  EVALATTACHMENTS_UPLOAD: "evalattachments:upload",
  EVALFEEDBACK_READ: "evalfeedback:read",
  EVALFEEDBACK_MANAGE: "evalfeedback:manage",
  EVALFEEDBACK_CREATE: "evalfeedback:create",
  EVALFEEDBACK_UPDATE: "evalfeedback:update",
  EVALFEEDBACK_MARKCOMPLETE: "evalfeedback:markComplete",
  EVALFEEDBACK_REPLY: "evalfeedback:reply",
  EVALFEEDBACK_LINKTICKET: "evalfeedback:linkticket",
  EVALFEEDBACK_DELETE: "evalfeedback:delete",
  EVALFEEDBACK_REORDER: "evalfeedback:reorder",
  EVALCOLLABORATORS_READ: "evalcollaborators:read",
  EVALCOLLABORATORS_CREATE: "evalcollaborators:create",
  EVALCOLLABORATORS_MANAGE: "evalcollaborators:manage",
  EVALMETRICS_READ: "evalmetrics:read",
  VENDORSALESFORCE_READ: "vendorsalesforce:read",
  VENDORSALESFORCE_MANAGE: "vendorsalesforce:manage",
  VENDOR_READ: "vendor:read",
  VENDOR_CREATE: "vendor:create",
  VENDOR_MANAGE: "vendor:manage",
  VENDOR_UPDATE: "vendor:update",
  VENDOR_DELETE: "vendor:delete",
  QUESTIONNAIRE_READ: "questionnaire:read",
  QUESTIONNAIRE_CREATE: "questionnaire:create",
  QUESTIONNAIRE_UPDATE: "questionnaire:update",
  QUESTIONNAIRE_DELETE: "questionnaire:delete",
  QUESTIONNAIRE_MANAGE: "questionnaire:manage",
  QUESTIONNAIRE_REORDER: "questionnaire:reorder",
  ACTIONPLAN_MANAGE: "actionplan:manage",
  ACTIONPLAN_READ: "actionplan:read",
  ACTIONPLAN_REORDER: "actionplan:reorder",
  SCOPING_READ: "scoping:read",
  SCOPING_CREATE: "scoping:create",
  SCOPING_UPDATE: "scoping:update",
  SCOPING_DELETE: "scoping:delete",
  SCOPING_MANAGE: "scoping:manage",
  SCOPING_REORDER: "scoping:reorder",
  SPECDOCSECTIONS_READ: "specdocsections:read",
  SPECDOCSECTIONS_CREATE: "specdocsections:create",
  SPECDOCSECTIONS_UPDATE: "specdocsections:update",
  SPECDOCSECTIONS_DELETE: "specdocsections:delete",
  SPECDOCSECTIONS_MANAGE: "specdocsections:manage",
  SPECDOCSECTIONS_REORDER: "specdocsections:reorder",
  INTEGRATION_READ: "integration:read",
  INTEGRATION_CREATE: "integration:create",
  INTEGRATION_UPDATE: "integration:update",
  INTEGRATION_DELETE: "integration:delete",
  INTEGRATION_MANAGE: "integration:manage",
  RESOURCES_MANAGE: "resources:manage",
  RESOURCES_READ: "resources:read",
  RESOURCES_REORDER: "resources:reorder",
  ACTIVITYLOG_READ: "activitylog:read",
  ACTIVITYLOG_CREATE: "activitylog:create",
  ACTIVITYLOG_UPDATE: "activitylog:update",
  ACTIVITYLOG_DELETE: "activitylog:delete",
  PROVIDERS_READ: "providers:read",
  PROVIDERS_CREATE: "providers:create",
  PROVIDERS_UPDATE: "providers:update",
  PROVIDERS_DELETE: "providers:delete",
  ANALYTICS_READ: "analytics:read",
  USERS_READ: "users:read",
  USERS_CREATE: "users:create",
  USERS_UPDATE: "users:update",
  USERS_DELETE: "users:delete",
  USERS_MANAGE: "users:manage",
  USERS_READOWN: "users:readOwn",
  USERS_CREATEAUTOLOGINLINK: "users:createAutologinLink"
};
