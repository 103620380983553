import Joi from "joi-browser";
import Form from "components/common/Form";
import "./AddToSalesForceModal.css";

const schema = {
  fullName: Joi.string().required(),
  body: Joi.string().required(),
  type: Joi.string().required(),
  linkedItemId: Joi.string().required(),
  status: Joi.string().required(),
  priority: Joi.string().required()
};

const initialState = {
  Name: "",
  Feedback: "",
  Type: "",
  "External Linked ID": "",
  Status: "",
  Priority: ""
};

const AddToSalesForceModal = ({ feedback, right = "26.5rem" }) => {
  const fields = [
    {
      inputType: "textarea",
      name: "addToSalesforceTextArea",
      defaultValue: [
        `Name: ${[
          feedback?.stakeholder.firstName,
          feedback?.stakeholder.lastName
        ].join(" ")}`,
        `Feedback: ${feedback?.body}`,
        `Type: ${feedback?.type}`,
        `External ID: ${feedback?.linkedItemId}`,
        `Status: ${feedback?.status}`,
        `Priority: ${feedback?.priority}`
      ].join("\n"),
      rows: "10",
      cols: "50"
    }
  ];

  return (
    <div
      className="AddToSalesForceModal FeedbackCell-modal drop-shadow-dark"
      style={{ right }}
    >
      <Form
        className="my-2 salesforce-modal-open-textarea"
        fields={fields}
        submitLabel="Add"
        schema={schema}
        initialState={initialState}
      />
    </div>
  );
};

export default AddToSalesForceModal;
