import { useEffect, useState, memo, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Search } from "react-bootstrap-icons";
import { Route, Routes } from "react-router-dom";
import debounce from "debounce";
import useAuth from "hooks/useAuth";
import {
  useLazyGetExternalSearchQuery,
  useGetVendorQuery,
  useGetAllFeedbacksQuery
} from "services/api";
import { LoadingCentered } from "components/common/Loading";
import ModalLinkExternal from "./ModalLinkExternal";
import CurrentlyLinkedItem from "./CurrentlyLinkedItem";
import "./BugAndFeedbackList.css";
import NiceTabs from "../NiceTabs";
import Modal from "components/common/Modal";

const BugAndFeedbackList = ({
  isModalOpen,
  setIsModalOpen,
  feedback,
  handleClose
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { data: vendorData, isLoading: vendorIsLoading } = useGetVendorQuery(
    currentUser.vendorId
  );
  const [
    searchExternal,
    {
      isLoading: dataIsLoading,
      isFetching: dataIsFetching,
      data,
      error: errorData
    }
  ] = useLazyGetExternalSearchQuery();
  const [search, setSearch] = useState("");

  const { data: allFeedbacks, isLoading: allFeedbacksIsLoading } =
    useGetAllFeedbacksQuery();

  const linkedFeedbackIds = useMemo(
    () =>
      new Set(
        allFeedbacks?.filter(fb => fb.linkedItemId !== null).map(fb => fb.id) ||
          []
      ),
    [allFeedbacks]
  );

  const providers = useMemo(
    () =>
      vendorData?.vendorProviders.map(vendorProvider => ({
        icon: vendorProvider.provider.iconUrl,
        title: vendorProvider.provider.name,
        urlTo: `/pocs/${feedback.pocId}/feedback/${feedback.id}/${vendorProvider.provider.ticker}`,
        vendorProviderId: vendorProvider.id,
        ticker: vendorProvider.provider.ticker,
        providerId: vendorProvider.provider.id
      })) || [],
    [vendorData, feedback.id]
  );

  const currentProvider = useMemo(
    () => providers.find(({ ticker }) => location.pathname.endsWith(ticker)),
    [location, providers]
  );

  const dataFilter = useCallback(
    d => {
      if (currentProvider?.ticker !== "evalforce") return true;
      // for evalforce linking,
      // disallow linking a feedback to itself
      // or to another piece of feedback which is already linked
      return d.id !== feedback.id && !linkedFeedbackIds.has(d.id);
    },
    [feedback, currentProvider?.ticker, linkedFeedbackIds]
  );

  useEffect(() => {
    if (providers.length && !currentProvider && feedback?.pocId) {
      navigate(`/pocs/${feedback.pocId}/feedback`);
    }
  }, [providers, currentProvider, feedback?.pocId, navigate]);

  useEffect(() => {
    if (currentProvider?.vendorProviderId) {
      searchExternal({ id: currentProvider?.vendorProviderId, query: search });
    }
  }, [searchExternal, currentProvider?.vendorProviderId]);

  const debouncedReq = useMemo(
    () =>
      debounce(searchTerm => {
        searchExternal({
          id: currentProvider?.vendorProviderId,
          query: searchTerm
        });
      }, 400),
    [data]
  );

  const handleChange = evt => {
    debouncedReq(evt.target.value);
    setSearch(evt.target.value);
  };

  const loading =
    dataIsLoading || dataIsFetching || vendorIsLoading || allFeedbacksIsLoading;

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      handleClose={handleClose}
      title={"Link Feedback"}
      className={"w-90 h-screen"}
      body={
        <div>
          {!dataIsLoading && (
            <CurrentlyLinkedItem feedback={feedback} providers={providers} />
          )}
          <NiceTabs tabs={providers} className="is-centered" />

          <div className="control has-icons-right mb-2 w-30r">
            <input
              className="input"
              type="email"
              placeholder="Search"
              value={search}
              onChange={handleChange}
              name="external-search"
              id="external-search"
            />
            <span className="icon is-small is-right">
              <Search />
            </span>
          </div>

          <div className="overflow-auto">
            {loading ? (
              <LoadingCentered />
            ) : (
              <Routes>
                <Route
                  path={`:feedbackId/:ticker`}
                  element={
                    <ModalLinkExternal
                      data={data}
                      errorData={errorData}
                      feedback={feedback}
                      vendorProviderId={currentProvider?.vendorProviderId}
                      providerId={currentProvider?.providerId}
                      dataFilter={dataFilter}
                      isLoading={loading}
                    />
                  }
                />
              </Routes>
            )}
          </div>
        </div>
      }
      buttons={[
        <button
          onClick={() => {
            setIsModalOpen(false);
            handleClose();
          }}
          className="button is-light"
        >
          Close
        </button>
      ]}
    />
  );
};

export default memo(BugAndFeedbackList);
