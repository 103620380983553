import React, { useMemo } from "react";
import {
  useGetMetaQuery,
  useGetQuestionTemplateListQuery,
  useGetUsecaseTemplateListQuery
} from "services/api";
import useAuth from "./useAuth";

export default function useOption(optionFor = "") {
  const {
    currentUser: { vendorId }
  } = useAuth();

  const { data: metadata } = useGetMetaQuery() || {};
  const { entityVisibility } = metadata || {};

  const { data: usecaseTemplate } = useGetUsecaseTemplateListQuery(vendorId, {
    skip: optionFor !== "usecase"
  });
  const { data: questionTemplate } = useGetQuestionTemplateListQuery(vendorId, {
    skip: optionFor !== "question"
  });

  const templateList = useMemo(() => {
    switch (optionFor) {
      case "usecase":
        return usecaseTemplate;
      case "question":
        return questionTemplate;
      default:
        return [];
    }
  }, [optionFor, usecaseTemplate, questionTemplate]);

  const scopeOptions = useMemo(
    () =>
      Object.keys(entityVisibility || {}).map(key => ({
        label: entityVisibility[key],
        value: entityVisibility[key]
      })) || [],
    [entityVisibility]
  );
  const tagOptions = useMemo(
    () =>
      Object.keys(templateList || {}).map(key => ({
        label: `${key} (${templateList[key]})`,
        value: key
      })) || [],
    [templateList]
  );

  return {
    scopes: [{ label: "All Requirements", value: "" }, ...scopeOptions],
    tags: [{ label: "Show All", value: "" }, ...tagOptions]
  };
}
