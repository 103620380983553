import { memo } from "react";
import Loading from "components/common/Loading";
import { SelectableStakeholder } from "./SelectableStakeholder";

const TableCellSelectableStakeholder = ({
  cell,
  value,
  edit,
  stakeholders,
  row
}) => {
  const addressed = row?.original?.addressed;
  if (!cell) return null;
  const original = cell?.row?.original;
  if (edit && !stakeholders) return <Loading />;
  let key = "stakeholderId";
  if (!(key in original)) {
    key = "assignedToStakeholderId";
  }

  const onChange = stakeholderId => {
    const newObj = {
      ...original,
      [key]: stakeholderId,
      [key.slice(0, -2)]: stakeholders.find(({ id }) => id === stakeholderId)
    };
    edit(newObj);
  };

  return (
    <SelectableStakeholder
      stakeholders={stakeholders}
      selectedStakeholderId={value?.id}
      editable={edit}
      onChange={onChange}
      addressed={addressed}
    />
  );
};

export default memo(TableCellSelectableStakeholder);
