import { useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetVendorQuery,
  useGetExternalSearchFeedbacksQuery
} from "services/api";
import useAuth from "hooks/useAuth";
import APIError from "components/common/APIError";
import Loading from "components/common/Loading";
import Table from "components/common/Table";
import { columns } from "./constants";
import { formatDate } from "utils/helpers";
import "./FeedbackIssue.css";
import { createFilterFeedbackUQuery } from "pages/FeedbackRoutes";

const FeedbackIssue = ({ feedbackCollections, error, filter, setFilter }) => {
  const filterQuery = createFilterFeedbackUQuery(filter);

  const { currentUser } = useAuth();
  const {
    data: vendorData,
    isLoading: vendorIsLoading,
    error: vendorError
  } = useGetVendorQuery(currentUser.vendorId);
  const { feedbackCollectionId } = useParams();
  // we find the appropriate collection either by external link id,
  // or, if there's no external link id, by finding the unique piece of feedback
  // matching the id in the url
  const feedbackCollection = useMemo(
    () =>
      feedbackCollections?.find(
        f =>
          f.linkedItemId === feedbackCollectionId ||
          (f.feedbackIds.length === 1 &&
            f.feedbackIds[0] === feedbackCollectionId)
      ),
    [feedbackCollections, feedbackCollectionId]
  );
  const providerTicker = feedbackCollection?.providerTicker;
  const currentVP = useMemo(
    () =>
      vendorData?.vendorProviders?.find(
        vp => vp.provider.ticker === providerTicker
      ),
    [vendorData, providerTicker]
  );

  const {
    data: feedbackData,
    isLoading: feedbackIsLoading,
    error: feedbackError
  } = useGetExternalSearchFeedbacksQuery({
    vendorProviderId: currentVP.id,
    linkedItemId: feedbackCollectionId,
    filter: filterQuery
  });

  const navigate = useNavigate();

  if (!feedbackCollection && feedbackCollections?.length > 0)
    navigate("/feedbacks");
  let dueDate = "-";
  if (feedbackCollection?.feedbacks[0]?.linkedItemDueDate) {
    dueDate = formatDate(feedbackCollection?.feedbacks[0]?.linkedItemDueDate);
  } else if (feedbackCollection?.feedbacks[0]?.priority) {
    dueDate = formatDate(feedbackCollection?.feedbacks[0]?.priority);
  }

  if (vendorIsLoading || feedbackIsLoading) return <Loading />;
  return (
    <>
      <span>/Feedback Analysis</span>
      <h2 className="my-4 is-size-4">
        {feedbackCollection?.linkedItemId} -{" "}
        {feedbackCollection?.feedbacks[0].linkedItemTitle ||
          feedbackCollection?.feedbacks[0].body}
      </h2>
      <div className="Feedback drop-shadow mt-5">
        <APIError message={error || vendorError || feedbackError} />
        <div className="is-flex is-justify-content-space-between FeedbackIssue-header">
          <p>
            {feedbackCollection?.feedbacks[0]?.linkedItemType ||
              feedbackCollection?.feedbacks[0]?.type}
          </p>
          <p>
            {feedbackCollection?.feedbacks[0].linkedItemTitle ||
              feedbackCollection?.feedbacks[0].body}
          </p>
          <p>{feedbackCollection?.linkedItemId || "Unlinked"}</p>
          <p>Due Date: {dueDate}</p>
          {/* <p>
            {feedbackCollection?.feedbacks[0]?.linkedItemPriority ||
              feedbackCollection?.feedbacks[0]?.priority}
          </p> */}
        </div>
        <div className="Feedback-table-wrapper mt-5">
          <Table
            className="Feedback-issue-table"
            columns={columns}
            data={feedbackData}
          />
        </div>
      </div>
    </>
  );
};

export default FeedbackIssue;
