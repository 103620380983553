import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "services/authSlice";
import { baseUrl } from "utils/helpers";

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.auth?.token;
    const tenantId = getState()?.auth?.user?.vendorId;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    if (tenantId) {
      headers.set("x-tenant-id", tenantId);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    // if (refreshResult.data) {
    //   // api.dispatch(tokenReceived(refreshResult.data))
    //   result = await baseQuery(args, api, extraOptions)
    // } else {
    api.dispatch(logout());
    // }
  }
  return result;
};
const api = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "api",
  // tagTypes: [],
  endpoints: () => ({})
});

export default api;
